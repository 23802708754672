import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Cookies from "universal-cookie";
import * as Endpoint from "../../configFile";

function ActivateUser(props) {
    useEffect(() => {
        const cookies = new Cookies();

        fetch(Endpoint.activateUser, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN')
            },
            body: window.location.href.split('?key=')[1]
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response;
            })
            .then(res => res.json())
            .then(res => {
                props.history.push('/login');
            })
            .catch((error) => props.history.push('/login'));
    }, [props.history]);

    return null;
}

export default withRouter(ActivateUser);
