import React, { Component } from "react";
import './dashboard.scss';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import {Checkbox, FormControlLabel, LinearProgress, TextField} from "@material-ui/core";
import {
    ExtendIcon,
    ListIcon,
    secondListIcon,
    upload,
    checklistArrow,
    fullScreen,
    download, editAdminIcon, pdfFile
} from "../login/loginConstants";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import Confirm from "../confirm/confirm";
import avatar from '../../assets/avatar.jpg';
import {initLoader, removeLoader} from "../loader/loader";
import logo from '../../assets/logoBizantium.svg';
import {Output} from "../companyType/output";
import {Link} from "react-router-dom";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quickstart: false,
            progress: 0,
            pdfUrl: '',
            extend: false,
            step: 1,
            extendBank: 1,
            checkpointData: [],
            checkListData: [],
            companyTypeProgress: 0,
            scheduleACallProgress: 0,
            establishmentFlowProgress: 0,
            limitedCompanyProgress: 0,
            scheduleACallEstablishmentProgress: 0,
            checklistProgress: 0,
            data: [],
            confirmDashboard: false,
            message: localStorage.getItem('i18nextLng') === 'en' ? 'Do you want to schedule a call? ' : 'Wilt u een gesprek plannen?',
            limitedCompany: false,
            category: 'ct',
            filesChecklist: [],
            financialChecklist: [],
            bankChecklist: [],
            notaryChecklist: [],
            administrationChecklist: [],
            socialChecklist: [],
            remunerationChecklist: [],
            filesChecklistProgress: 0,
            financialChecklistProgress: 0,
            bankChecklistProgress: 0,
            notaryChecklistProgress: 0,
            administrationChecklistProgress: 0,
            socialChecklistProgress: 0,
            remunerationChecklistProgress: 0,
            filesDescription: '',
            financialDescription: '',
            bankDescription: '',
            notaryDescription: '',
            administrationDescription: '',
            socialDescription: '',
            remunerationDescription: '',
            userFiles: [],
            userBank: [],
            bankFile: '',
            administrationStep: 0,
            companyData: [],
            overviewDocument: null,
            lastActivityDate: []
        }
    }

    componentDidMount() {
        initLoader(document.querySelector('.dashboard'));
        this.getData();
        setTimeout(() => {
            removeLoader(document.querySelector('.dashboard'));
        }, 2500);
    }

    handleConfirm = (value) => {
        this.setState({
            confirmDashboard: value
        })
        this.scheduleCall('false', this.state.category);
    }

    handleSave = () => {
        this.scheduleCall('true', this.state.category);
    }

    scheduleCall = (value, category) => {
        Connect(`${Endpoint.callSchedule}/${category}?value=${value}`, 'PUT').then(response => {
            this.setState({
                confirmDashboard: false,
                scheduleACallProgress: 100,
                step: category === 'ct' ? 3 : 6
            })
            if (category !== 'ct') {
                this.setState({
                    scheduleACallEstablishmentProgress: 100
                })
            }
        })
    }

    handleQuickstart = (value) => {
        this.setState({
            quickstart: value
        })
    }

    handleNotary = (value, type, index = 0) => {
        if (type === 'financial') {
            this.setState({
                financialDescription: value
            })
        }
        if (type === 'bank') {
            this.setState({
                bankDescription: value
            })
        }
        if (type === 'notary') {
            this.setState({
                notaryDescription: value
            })
        }
        if (type === 'administration') {
            this.setState({
                administrationDescription: value
            })
        }
        if (type === 'social') {
            this.setState({
                socialDescription: value
            })
        }
        if (type === 'remuneration') {
            this.setState({
                remunerationDescription: value
            })
        }
        this.setState({
            administrationStep: index
        })
    }

    handleIban = (e) => {
        e.target.closest('.visible').querySelector('.row:first-child').classList.add('d-none');
        e.target.closest('.visible').querySelector('.row:nth-child(2)').classList.remove('d-none');
    }

    confirmBank = (value) => {
        if (value === 1) {
            let body = {
                ibanNumber: document.querySelector('#iban').value
            }
            Connect(`${Endpoint.bankData}`, 'PATCH', body).then(response => {
                this.setState({
                    extendBank: 2
                })
                this.handleChecklist();
            })
        } else {
            Connect(`${Endpoint.blockBank}`, 'PATCH').then(response => {
                this.setState({
                    extendBank: 3
                })
                this.handleChecklist();
            })
        }
    }

    handleDocumentChange = async (e, pid) => {
        const file = e.target.files[0];

        if (file) {
            const base64Image = await this.convertToBase64(file);
            let body = {
                certificateFile: `[bank_certificate.pdf]${base64Image}`
            }
            Connect(`${Endpoint.bankData}`, 'PATCH', body).then(response => {
                this.setState({
                    bankFile: `[bank_certificate.pdf]${base64Image}`
                })
                this.handleChecklist();
            })
        }
    };

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    fetchData = async (url, fileName) => {
        try {
            const response = await fetch(url, {
                headers: {
                    'authorization': (typeof window !== 'undefined') ? localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token') : '',
                    'Content-Type': 'application/pdf'
                },
            });

            const blob = await response.blob();
            const pdfUrl = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = pdfUrl;

            a.download = fileName || 'defaultFileName.pdf';

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Błąd pobierania danych:', error);
        }
    };

    open = (url, fileName) => {
        this.fetchData(url, fileName).then((res) => console.log(res));
    }

    handleExtendBank = (e, index) => {
        e.target.closest('.dashboard__checklist-extend').classList.add('active');
        this.setState({
            extendBank: index
        })
        if (index === 1) {
            this.handleNotary(this.state.bankChecklist[0].descriptionUser, 'bank');
        }
        if (index === 2) {
            this.handleNotary(this.state.bankChecklist[1].descriptionUser, 'bank');
        }
        if (index === 3) {
            this.handleNotary(this.state.bankChecklist[2].descriptionUser, 'bank');
        }
    }

    handleEstablishment = (legalForm) => {
        Connect(`${Endpoint.progressSurvey}/establishment-flow`, 'GET').then(response => {
            let completed = 0;
            response.map((data) => {
                if ((data.requiredProgress === data.userProgress) && data.requiredProgress !== 0) {
                    completed += 1;
                }
            })
            this.setState({
                checkpointData: response,
                establishmentFlowProgress: legalForm === 'LIMITED_COMPANY' ? (completed/9) * 100 : (completed/6) * 100,
                message: localStorage.getItem('i18nextLng') === 'en' ? 'Do you want to schedule a call from Establishment flow?' : 'Wilt u een gesprek plannen vanuit Oprichtingsflow?',
                category: 'ef'
            })
            if ((legalForm === 'LIMITED_COMPANY' ? (completed/9) * 100 : (completed/6) * 100) === 100) {
                Connect(`${Endpoint.callSchedule}`, 'GET').then(response => {
                    if (response.isCallScheduleEf === null) {
                        this.setState({
                            step: 5,
                            confirmDashboard: true
                        })
                    } else {
                        this.setState({
                            step: 6,
                            scheduleACallEstablishmentProgress: 100
                        })
                    }
                });
            }
        });
    }

    getData = () => {
        Connect(`${Endpoint.progressSurvey}/company-type`, 'GET').then(response => {
            let completed = 0;
            response.map((data) => {
                if ((data.requiredProgress === data.userProgress) && data.requiredProgress !== 0) {
                    completed += 1;
                }
            })
            this.setState({
                checkpointData: response,
                companyTypeProgress: (completed/5) * 100
            })
            if (((completed/5) * 100) === 100) {
                sessionStorage.setItem('completeSurvey', 'true');
                Connect(`${Endpoint.callSchedule}`, 'GET').then(response => {
                    if (response.isCallScheduleCt === null) {
                        this.setState({
                            step: 2,
                            confirmDashboard: true
                        })
                    } else {
                        Connect(`${Endpoint.companyDetails}`, 'GET').then(response => {
                            sessionStorage.setItem('legalForm', response.legalForm);
                            if (response.legalForm === null) {
                                this.setState({
                                    step: 3,
                                    scheduleACallProgress: 100,
                                    companyData: response
                                })
                            } else {
                                this.setState({
                                    step: 4,
                                    scheduleACallProgress: 100,
                                    limitedCompanyProgress: 100,
                                    companyData: response
                                })
                                this.handleEstablishment(response.legalForm);
                            }
                        })
                    }
                });
            }
            this.continueSurvey()
        });
        Connect(`${Endpoint.getDashboardCompanyType}`, 'GET').then(response => {
            let checkpointCategory = [];
            response.map((data) => {
                checkpointCategory.push(data.checkpointCategory);
            })
            sessionStorage.setItem('checkpointCategory', JSON.stringify(checkpointCategory));
            this.setState({
                data: response
            })
        })
        Connect(`${Endpoint.getDashboardEstablishmentFlow}`, 'GET').then(response => {
            let checkpointCategory = [];
            response.map((data) => {
                checkpointCategory.push(data.checkpointCategory);
            })
            sessionStorage.setItem('checkpointCategoryEstablishment', JSON.stringify(checkpointCategory));
            this.setState({
                data: response
            })
        })
        this.handleChecklist();
        Connect(`${Endpoint.userFiles}`, 'GET').then(response => {
            this.setState({
                userFiles: response
            })
        })
        Connect(`${Endpoint.userFiles}?type=OVERVIEW_DOCUMENT`, 'GET').then(response => {
            this.setState({
                overviewDocument: response
            })
        })
        Connect(`${Endpoint.bank}`, 'GET').then(response => {
            this.setState({
                userBank: response
            })
        })
    }

    handleChecklist = () => {
        Connect(`${Endpoint.getChecklist}`, 'GET').then(response => {
            let filesChecklist = [];
            let financialChecklist = [];
            let bankChecklist = [];
            let notaryChecklist = [];
            let administrationChecklist = [];
            let socialChecklist = [];
            let remunerationChecklist = [];
            let checklistProgress = 0;
            let filesChecklistProgress = 0;
            let financialChecklistProgress = 0;
            let bankChecklistProgress = 0;
            let notaryChecklistProgress = 0;
            let administrationChecklistProgress = 0;
            let socialChecklistProgress = 0;
            let remunerationChecklistProgress = 0;
            let filesDescription = '';
            let financialDescription = '';
            let bankDescription = '';
            let notaryDescription = '';
            let administrationDescription = '';
            let socialDescription = '';
            let remunerationDescription = '';
            let lastActivityDate = [];
            response.map((data) => {
                if (data.checkpointCategory === 'Files' || data.checkpointCategory === 'Documenten') {
                    filesChecklist.push(data);
                    if (data.checked === true) {
                        filesChecklistProgress++;
                        checklistProgress++;
                    }
                    if (filesDescription === '') {
                        filesDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[0] = data.lastActivityDate;
                    }
                }
                if (data.checkpointCategory === 'Financial plan' || data.checkpointCategory === 'Financieel plan') {
                    financialChecklist.push(data);
                    if (data.checked === true) {
                        financialChecklistProgress++;
                        checklistProgress++;
                    }
                    if (financialDescription === '') {
                        financialDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[1] = data.lastActivityDate;
                    }
                }
                if (data.checkpointCategory === 'Bank') {
                    bankChecklist.push(data);
                    if (data.checked === true) {
                        bankChecklistProgress++;
                        checklistProgress++;
                    }
                    if (bankDescription === '') {
                        bankDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[2] = data.lastActivityDate;
                    }
                }
                if (data.checkpointCategory === 'Notary' || data.checkpointCategory === 'Notaris') {
                    notaryChecklist.push(data);
                    if (data.checked === true) {
                        notaryChecklistProgress++;
                        checklistProgress++;
                    }
                    if (notaryDescription === '') {
                        notaryDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[3] = data.lastActivityDate;
                    }
                }
                if (data.checkpointCategory === 'Administration' || data.checkpointCategory === 'Administratie') {
                    administrationChecklist.push(data);
                    if (data.checked === true) {
                        administrationChecklistProgress++;
                        checklistProgress++;
                    }
                    if (administrationDescription === '') {
                        administrationDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[4] = data.lastActivityDate;
                    }
                }
                if (data.checkpointCategory === 'Social Security' || data.checkpointCategory === 'Sociale zekerheid') {
                    socialChecklist.push(data);
                    if (data.checked === true) {
                        socialChecklistProgress++;
                        checklistProgress++;
                    }
                    if (socialDescription === '') {
                        socialDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[5] = data.lastActivityDate;
                    }
                }
                if (data.checkpointCategory === 'Remuneration' || data.checkpointCategory === 'Bezoldiging') {
                    remunerationChecklist.push(data);
                    if (data.checked === true) {
                        remunerationChecklistProgress++;
                        checklistProgress++;
                    }
                    if (remunerationDescription === '') {
                        remunerationDescription = data.descriptionUser;
                    }
                    if (data.lastActivityDate !== '' && data.lastActivityDate !== null) {
                        lastActivityDate[6] = data.lastActivityDate;
                    }
                }
            })
            this.setState({
                filesChecklist,
                financialChecklist,
                bankChecklist,
                notaryChecklist,
                administrationChecklist,
                socialChecklist,
                remunerationChecklist,
                filesChecklistProgress,
                financialChecklistProgress,
                bankChecklistProgress,
                notaryChecklistProgress,
                administrationChecklistProgress,
                socialChecklistProgress,
                remunerationChecklistProgress,
                filesDescription,
                financialDescription,
                bankDescription,
                notaryDescription,
                administrationDescription,
                socialDescription,
                remunerationDescription,
                lastActivityDate,
                checklistProgress: checklistProgress*3.33
            })
        })
    }

    handleExtend = () => {
        this.setState({
            extend: !this.state.extend,
        })
    }

    continueSurvey = () => {
        if (this.state.companyTypeProgress >= 80) {
            sessionStorage.setItem('companyTypeStep', '5');
        } else if ((this.state.companyTypeProgress >= 60) && (this.state.companyTypeProgress <= 79)) {
            sessionStorage.setItem('companyTypeStep', '4');
        } else if ((this.state.companyTypeProgress >= 40) && (this.state.companyTypeProgress <= 59)) {
            sessionStorage.setItem('companyTypeStep', '3');
        } else if ((this.state.companyTypeProgress >= 20) && (this.state.companyTypeProgress <= 39)) {
            sessionStorage.setItem('companyTypeStep', '2');
        } else {
            sessionStorage.setItem('companyTypeStep', '1');
        }
    }

    render() {
        return (
            <div className="container">
                <Navbar currentStep={this.state.step} progress={this.state.progress} handleQuickstart={this.handleQuickstart} name={'Dashboard'} handleDashboard={this.getData} {...this.props} />
                <Menu role={'user'} permissions={null} isMobile={false} component={'company'}/>
                <Quickstart status={this.state.quickstart} handleQuickstart={this.handleQuickstart} />
                <div className="dashboard">
                    <p><Trans>dashboard.intro</Trans></p>
                    <div className="dashboard__row">
                        {this.state.step > 3 && this.state.extend === false ?
                            <>
                                <div className={this.state.step >= 4 ? "dashboard__box secondary active" : "dashboard__box secondary"}>
                                    <div className="dashboard__box-title"><Trans>menu.survey</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{parseInt(this.state.establishmentFlowProgress)}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.establishmentFlowProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.setup</Trans></button>
                                </div>
                                <div className={this.state.step >= 5 ? "dashboard__box secondary active" : "dashboard__box secondary"}>
                                    <div className="dashboard__box-title"><Trans>dashboard.schedule</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{this.state.scheduleACallEstablishmentProgress}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.scheduleACallEstablishmentProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.finalize</Trans></button>
                                </div>
                                <div className={this.state.step >= 6 ? "dashboard__box filesContent active" : "dashboard__box filesContent"}>
                                    <div className="dashboard__box-title">Checklist</div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{this.state.checklistProgress.toFixed(0)}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.checklistProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.foll</Trans></button>
                                </div>
                            </>
                        :
                            <>
                                <div className={this.state.step >= 1 ? "dashboard__box active" : "dashboard__box"}>
                                    <div className="dashboard__box-title"><Trans>dashboard.company</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p>Progress</p>
                                            <p>{this.state.companyTypeProgress}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.companyTypeProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.generate</Trans></button>
                                </div>
                                <div className={this.state.step >= 2 ? "dashboard__box active" : "dashboard__box"}>
                                    <div className="dashboard__box-title"><Trans>dashboard.schedule</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{this.state.scheduleACallProgress}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.scheduleACallProgress}
                                        />
                                    </div>
                                    <button><Trans>discuss</Trans></button>
                                </div>
                                <div className={this.state.step >= 3 ? "dashboard__box active" : "dashboard__box"}>
                                    <div className="dashboard__box-title"><Trans>dashboard.make</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{this.state.limitedCompanyProgress}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.limitedCompanyProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.and</Trans></button>
                                </div>
                            </>
                        }
                        <div className="dashboard__next">
                            {ExtendIcon}
                        </div>
                        <div className="dashboard__next">
                            {ExtendIcon}
                        </div>
                        <div className="dashboard__next">
                            {ExtendIcon}
                        </div>
                        <div className="dashboard__next">
                            {ExtendIcon}
                        </div>
                    </div>
                    {this.state.extend === true ?
                        <div className="dashboard__extend-content">
                            <div className="dashboard__row">
                                <div className={this.state.step >= 4 ? "dashboard__box secondary active" : "dashboard__box secondary"}>
                                    <div className="dashboard__box-title"><Trans>menu.survey</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{parseInt(this.state.establishmentFlowProgress)}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.establishmentFlowProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.setup</Trans></button>
                                </div>
                                <div className={this.state.step >= 5 ? "dashboard__box secondary active" : "dashboard__box secondary"}>
                                    <div className="dashboard__box-title"><Trans>dashboard.schedule</Trans></div>
                                    <div className="dashboard__progress high">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{this.state.scheduleACallEstablishmentProgress}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.scheduleACallEstablishmentProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.setup</Trans></button>
                                </div>
                                <div className={this.state.step >= 6 ? "dashboard__box filesContent active" : "dashboard__box filesContent"}>
                                    <div className="dashboard__box-title">Checklist</div>
                                    <div className="dashboard__progress hogh">
                                        <div className="dashboard__progress-text">
                                            <p><Trans>dashboard.progress</Trans></p>
                                            <p>{this.state.checklistProgress}%</p>
                                        </div>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.checklistProgress}
                                        />
                                    </div>
                                    <button><Trans>dashboard.foll</Trans></button>
                                </div>
                                <div className="dashboard__next">
                                    {ExtendIcon}
                                </div>
                                <div className="dashboard__next">
                                    {ExtendIcon}
                                </div>
                                <div className="dashboard__next">
                                    {ExtendIcon}
                                </div>
                                <div className="dashboard__next">
                                    {ExtendIcon}
                                </div>
                            </div>
                        </div>
                    :
                        <></>}
                    <div className={this.state.extend === true ? "dashboard__extend is-active" : "dashboard__extend"} onClick={() => this.handleExtend()}>
                        {ExtendIcon}
                    </div>
                    <div className="dashboard__header">{this.state.step === 6 ? 'Checklist' : <Trans>dashboard.limited</Trans>}</div>
                    <p>{this.state.step === 6 ? <Trans>dashboard.follow</Trans> : <Trans>dashboard.check</Trans>}</p>
                    <div className="dashboard__list-title">
                        <div className="text"></div>
                        <div className="text"></div>
                        <div className="text"></div>
                        <div className="text">Assignee</div>
                        <div className="text">Progress</div>
                        <div className="text">Tasks</div>
                        <div className="text">Latest activity</div>
                    </div>
                    {this.state.step <= 5 ?
                        <>
                            {this.state.checkpointData.map((data) => (
                                <div className="dashboard__list">
                                    <div className={((data.userProgress/data.requiredProgress) * 100) > 80 ? "dashboard__item unknown high" : (data.userProgress/data.requiredProgress) * 100 > 10 ? "dashboard__item unknown medium" : "dashboard__item unknown low"}>{ListIcon}</div>
                                    <div className="dashboard__item">{data.name}</div>
                                    {data.name === 'Generate output' ?
                                        <div className="dashboard__item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            {data.description}
                                            <Output type={'userDashboard'} />
                                        </div>
                                    :
                                        <div className="dashboard__item">{data.description}</div>
                                    }
                                    <div className="dashboard__item">
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} />
                                    </div>
                                    <div className={(data.userProgress/data.requiredProgress) * 100 > 80 ? "dashboard__item high" : (data.userProgress/data.requiredProgress) * 100 > 10 ? "dashboard__item medium" : "dashboard__item low"}>
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(data.userProgress/data.requiredProgress) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {data.userProgress}/{data.requiredProgress}</div>
                                    <div className="dashboard__item">{data.lastActivityDate}</div>
                                </div>
                            ))}
                        </>
                        :
                        <>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">{localStorage.getItem('i18nextLng') === 'en' ? 'Files' : 'Documenten'}</div>
                                    <div className="dashboard__item">These documents need to be signed in order to establish your company</div>
                                    <div className="dashboard__item">
                                        <img src={logo} alt={'logo'} className="logo" />
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} className="avatar" />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(this.state.filesChecklistProgress/8) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.filesChecklistProgress}/8</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[0] !== undefined ? this.state.lastActivityDate[0] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend unchecked">
                                    <div className="row" style={{alignItems: 'flex-start'}}>
                                        <div className="box">
                                            <div className="text award">Composition assignment</div>
                                            <FormControlLabel
                                                label={this.state.filesChecklist[0].name}
                                                control={
                                                    <Checkbox color={'#4A5BCF'}
                                                              checked={this.state.filesChecklist[0].checked} />
                                                }/>
                                            <FormControlLabel label={this.state.filesChecklist[1].name}
                                                              control={
                                                                  <Checkbox color={'#4A5BCF'}
                                                                            checked={this.state.filesChecklist[1].checked} />
                                                              }/>
                                            <div className={this.state.userFiles && this.state.userFiles[1].fileUri !== null && this.state.filesChecklist[0].checked === true ? "actions" : "actions blocked"}>
                                                <div className="icon d-none">
                                                    {editAdminIcon}
                                                </div>
                                                <div className="icon d-none">
                                                    {fullScreen}
                                                </div>
                                                <div className="icon" onClick={() => this.open(this.state.userFiles[1].fileUri, this.state.userFiles[1].fileUri.split('/')[this.state.userFiles[1].fileUri.split('/').length - 1])}>
                                                    {download}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text award">Assignment letter</div>
                                            <FormControlLabel label={this.state.filesChecklist[2].name}
                                                              control={
                                                                  <Checkbox color={'#4A5BCF'}
                                                                            checked={this.state.filesChecklist[2].checked}
                                                                            />
                                                              }/>
                                            <FormControlLabel label={this.state.filesChecklist[3].name}
                                                              control={
                                                                  <Checkbox color={'#4A5BCF'}
                                                                            checked={this.state.filesChecklist[3].checked}
                                                                            />
                                                              }/>
                                            <div className={this.state.userFiles && this.state.userFiles[3].fileUri !== null && this.state.filesChecklist[2].checked === true ? "actions" : "actions blocked"}>
                                                <div className="icon d-none">
                                                    {editAdminIcon}
                                                </div>
                                                <div className="icon d-none">
                                                    {fullScreen}
                                                </div>
                                                <div className="icon" onClick={() => this.open(this.state.userFiles[3].fileUri, this.state.userFiles[3].fileUri.split('/')[this.state.userFiles[3].fileUri.split('/').length - 1])}>
                                                    {download}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text award">Power of attorney</div>
                                            <FormControlLabel label={this.state.filesChecklist[4].name}
                                                              control={
                                                                  <Checkbox color={'#4A5BCF'}
                                                                            checked={this.state.filesChecklist[4].checked}
                                                                            />
                                                              }/>
                                            <FormControlLabel label={this.state.filesChecklist[5].name}
                                                              control={
                                                                  <Checkbox color={'#4A5BCF'}
                                                                            checked={this.state.filesChecklist[5].checked}
                                                                            />
                                                              }/>
                                            <div className={this.state.userFiles && this.state.userFiles[2].fileUri !== null && this.state.filesChecklist[4].checked === true ? "actions" : "actions blocked"}>
                                                <div className="icon d-none">
                                                    {editAdminIcon}
                                                </div>
                                                <div className="icon d-none">
                                                    {fullScreen}
                                                </div>
                                                <div className="icon" onClick={() => this.open(this.state.userFiles[2].fileUri, this.state.userFiles[2].fileUri.split('/')[this.state.userFiles[2].fileUri.split('/').length - 1])}>
                                                    {download}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text award">Deed of incorporation</div>
                                            <FormControlLabel label={this.state.filesChecklist[6].name}
                                                              control={
                                                                  <Checkbox color={'#4A5BCF'}
                                                                            checked={this.state.filesChecklist[6].checked}
                                                                            />
                                                              }/>
                                            <FormControlLabel label={this.state.filesChecklist[7].name} control={
                                                <Checkbox color={'#4A5BCF'}
                                                          checked={this.state.filesChecklist[7].checked}
                                                          />
                                            }/>
                                            <div className={this.state.userFiles && this.state.userFiles[4].fileUri !== null && this.state.filesChecklist[6].checked === true ? "actions" : "actions blocked"}>
                                                <div className="icon d-none">
                                                    {editAdminIcon}
                                                </div>
                                                <div className="icon d-none">
                                                    {fullScreen}
                                                </div>
                                                <div className="icon" onClick={() => this.open(this.state.userFiles[4].fileUri, this.state.userFiles[4].fileUri.split('/')[this.state.userFiles[4].fileUri.split('/').length - 1])}>
                                                    {download}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">{localStorage.getItem('i18nextLng') === 'en' ? 'Financial plan' : 'Financieel plan'}</div>
                                    <div className="dashboard__item">Generate a strong financial plan based on your input</div>
                                    <div className="dashboard__item">
                                        <img src={logo} alt={'logo'} className="logo" />
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} className="avatar" />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(this.state.financialChecklistProgress/3) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.financialChecklistProgress}/3</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[1] !== undefined ? this.state.lastActivityDate[1] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend unchecked">
                                    <div className="row start">
                                        <div className="box">
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.financialChecklist[0].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.financialChecklist[0].descriptionUser, 'financial', 1)}>{this.state.financialChecklist[0].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.financialChecklist[1].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.financialChecklist[1].descriptionUser, 'financial', 1)}>{this.state.financialChecklist[1].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.financialChecklist[2].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.financialChecklist[2].descriptionUser, 'financial')}>{this.state.financialChecklist[2].name}</div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text header">Description</div>
                                            <div className="text">
                                                {this.state.financialDescription}
                                            </div>
                                        </div>
                                        {this.state.administrationStep === 1 ?
                                            <div className="box">
                                                <div className="text header">Financial plan</div>
                                                <div className="text">View, download and sign your financial plan here.</div>
                                                <div className={this.state.userFiles && this.state.userFiles[0].fileUri ? "actions" : "actions blocked"}>
                                                    <div className="icon d-none">
                                                        {editAdminIcon}
                                                    </div>
                                                    <div className="icon d-none">
                                                        {fullScreen}
                                                    </div>
                                                    <div className="icon" onClick={() => this.open(this.state.userFiles[0].fileUri, this.state.userFiles[0].fileUri.split('/')[this.state.userFiles[0].fileUri.split('/').length - 1])}>
                                                        {download}
                                                    </div>
                                                </div>
                                            </div>
                                        : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">Bank</div>
                                    <div className="dashboard__item">Opening a professional bank account</div>
                                    <div className="dashboard__item">
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(this.state.bankChecklistProgress/3) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.bankChecklistProgress}/3</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[2] !== undefined ? this.state.lastActivityDate[2] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend">
                                    <div className="row start">
                                        <div className="box">
                                            <div className={this.state.extendBank === 1 ? "row checkbox active" : "row checkbox"}>
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.bankChecklist[0].checked} />
                                                }/>
                                                <div className="text label" onClick={(e) => this.handleExtendBank(e, 1)}>{this.state.bankChecklist[0].name}</div>
                                            </div>
                                            <div className={this.state.extendBank === 2 ? "row checkbox active" : "row checkbox"}>
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.bankChecklist[1].checked} />
                                                }/>
                                                <div className="text label" onClick={(e) => this.handleExtendBank(e, 2)}>{this.state.bankChecklist[1].name}</div>
                                            </div>
                                            <div className={this.state.extendBank === 3 ? "row checkbox active" : "row checkbox"}>
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.bankChecklist[2].checked} />
                                                }/>
                                                <div className="text label" onClick={(e) => this.handleExtendBank(e, 3)}>{this.state.bankChecklist[2].name}</div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text header">Description</div>
                                            <div className="text">
                                                {this.state.bankDescription}
                                            </div>
                                        </div>
                                        {this.state.extendBank === 1 ?
                                            <div className="buttons visible">
                                                {this.state.userBank.ibanNumber === null ?
                                                    <>
                                                        <div className="row">
                                                            <a href="https://www.kbc.be/ondernemen/nl/product/betalen-en-betaald-worden/zakelijke-rekeningen/open-een-zakelijke-rekening-bij-kbc.html" target="_blank" className="btn btn--primary">Open account via
                                                                our partner
                                                            </a>
                                                            <div className="btn btn--primary"
                                                                 onClick={(e) => this.handleIban(e)}>Enter IBAN
                                                            </div>
                                                        </div>
                                                        <div className="row d-none">
                                                            <div className="box">
                                                                <div className="container__body-input">
                                                                    <p className="container__body-text">IBAN</p>
                                                                    <TextField label={''} variant={'outlined'}
                                                                               id={'iban'}
                                                                               size={'small'} type={'text'}
                                                                               placeholder={'BEXX XXXX XXXX XXXX'}/>
                                                                </div>
                                                            </div>
                                                            <div className="btn btn--primary"
                                                                 onClick={() => this.confirmBank(1)}>Confirm
                                                            </div>
                                                        </div>
                                                    </>
                                                :
                                                    <>
                                                        <div className="box">
                                                            <div className="text header">Your IBAN number:</div>
                                                            <div className="text">
                                                                {this.state.userBank.ibanNumber.replaceAll('"', '')}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            :
                                            ''
                                        }
                                        {this.state.extendBank === 2 ?
                                            <>
                                                <div className="box">
                                                    <div className="text header">Confirmation</div>
                                                    {this.state.userBank.isBlock !== true ?
                                                        <div className="text">By pressing the button on the right, you confirm that you blocked the starting capital on the professional account.</div>
                                                    :
                                                        <div className="text">Blocked the starting capital on the professional account is confirmed</div>
                                                    }
                                                </div>
                                                {this.state.userBank.isBlock !== true ?
                                                    <div className="btn btn--primary" onClick={() => this.confirmBank(2)}>Confirm</div>
                                                : ''}
                                            </>
                                        :
                                            ''
                                        }
                                        {this.state.extendBank === 3 ?
                                            <>
                                                <label htmlFor={'uploadBank'} className="dashboard__upload">
                                                    {this.state.bankFile === '' && this.state.userBank.certificateUri === null ?
                                                        <>
                                                            {upload}
                                                            <div className="box">
                                                                <div className="text bold">Choose a file or</div>
                                                                <div className="text">Drag and Drop it here</div>
                                                                <div className="text">Max. file size: 5 mb</div>
                                                            </div>
                                                        </>
                                                    :
                                                        <>
                                                            {pdfFile}
                                                            <div className="textUpload">bank_certificate.pdf</div>
                                                        </>
                                                    }
                                                </label>
                                                <input id={`uploadBank`} type="file" accept="application/pdf" onChange={(e) => this.handleDocumentChange(e)} style={{ display: 'none' }} />
                                            </>
                                        :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">{localStorage.getItem('i18nextLng') === 'en' ? 'Notary' : 'Notaris'}</div>
                                    <div className="dashboard__item">Official setup of your company</div>
                                    <div className="dashboard__item">
                                        <img src={logo} alt={'logo'} className="logo" />
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} className="avatar" />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(this.state.notaryChecklistProgress/6) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.notaryChecklistProgress}/6</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[3] !== undefined ? this.state.lastActivityDate[3] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend">
                                    <div className="row start">
                                        <div className="box">
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.notaryChecklist[0].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.notaryChecklist[0].descriptionUser, 'notary')}>{this.state.notaryChecklist[0].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.notaryChecklist[1].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.notaryChecklist[1].descriptionUser, 'notary')}>{this.state.notaryChecklist[1].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.notaryChecklist[2].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.notaryChecklist[2].descriptionUser, 'notary')}>{this.state.notaryChecklist[2].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.notaryChecklist[3].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.notaryChecklist[3].descriptionUser, 'notary')}>{this.state.notaryChecklist[3].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.notaryChecklist[4].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.notaryChecklist[4].descriptionUser, 'notary')}>{this.state.notaryChecklist[4].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.notaryChecklist[5].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.notaryChecklist[5].descriptionUser, 'notary')}>{this.state.notaryChecklist[5].name}</div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text header">Description</div>
                                            <div className="text">
                                                {this.state.notaryDescription}
                                            </div>
                                        </div>
                                        <div className={this.state.userFiles && this.state.userFiles[3].fileUri !== null && this.state.filesChecklist[3].checked === true ? "box" : "box d-none"}>
                                            <div className="text header">Deed of incorporation</div>
                                            <div className="actions d-none">
                                                <div className="icon d-none">
                                                    {editAdminIcon}
                                                </div>
                                                <div className="icon d-none">
                                                    {fullScreen}
                                                </div>
                                                <div className="icon" onClick={() => this.open(this.state.userFiles[3].fileUri, this.state.userFiles[3].split('/')[this.state.userFiles[3].fileUri.split('/').length - 1])}>
                                                    {download}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">{localStorage.getItem('i18nextLng') === 'en' ? 'Administration' : 'Administratie'}</div>
                                    <div className="dashboard__item">Enterprise counter, mandates, VAT activation </div>
                                    <div className="dashboard__item">
                                        <img src={logo} alt={'avatar'} className="oneLogo" />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(this.state.administrationChecklistProgress/3) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.administrationChecklistProgress}/3</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[4] !== undefined ? this.state.lastActivityDate[4] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend">
                                    <div className="row start">
                                        <div className="box">
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.administrationChecklist[0].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.administrationChecklist[0].descriptionUser, 'administration', 1)}>{this.state.administrationChecklist[0].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.administrationChecklist[1].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.administrationChecklist[1].descriptionUser, 'administration', 2)}>{this.state.administrationChecklist[1].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.administrationChecklist[2].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.administrationChecklist[2].descriptionUser, 'administration')}>{this.state.administrationChecklist[2].name}</div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text header">Description</div>
                                            <div className="text">
                                                {this.state.administrationDescription}
                                            </div>
                                        </div>
                                        {this.state.administrationStep === 1 ?
                                            <div className="box">
                                                <div className="text header">Company number</div>
                                                <div className="text">
                                                    {this.state.companyData.companyNumber !== null && this.state.companyData.companyNumber !== '' ?
                                                        <><b>{this.state.companyData.companyNumber}</b> has been assigned to you as your company number.</>
                                                    :
                                                        <></>
                                                    }
                                                </div>
                                                <div className={this.state.overviewDocument !== null && this.state.overviewDocument[0] && this.state.overviewDocument[0].fileUri !== undefined && this.state.overviewDocument[0].fileUri !== null ? "btn btn--primary" : "btn btn--primary blocked"} onClick={() => this.open(this.state.overviewDocument[0].fileUri, this.state.overviewDocument[0].fileUri.split('/')[this.state.overviewDocument[0].fileUri('/').length - 1])}>Download overview document</div>
                                            </div>
                                        :
                                            this.state.administrationStep === 2 ?
                                                <div className="box">
                                                    <div className="text header">Manual: signing CSAM mandates</div>
                                                    <div className="text">
                                                        The government has an outlined process, check our manual here!
                                                    </div>
                                                    <div className="actions">
                                                        <div className="icon d-none">
                                                            {fullScreen}
                                                        </div>
                                                        <div className="icon" onClick={() => this.open(`${Endpoint.dashboardAdministration}`, `${Endpoint.dashboardAdministration.split('/')[Endpoint.dashboardAdministration.split('/').length - 1]}`)}>
                                                            {download}
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">{localStorage.getItem('i18nextLng') === 'en' ? 'Social security' : 'Sociale zekerheid'}</div>
                                    <div className="dashboard__item">We make sure you are registered with a social security firm </div>
                                    <div className="dashboard__item">
                                        <img src={logo} alt={'logo'} className="logo" />
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} className="avatar" />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={this.state.socialChecklistProgress * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.socialChecklistProgress}/1</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[5] !== undefined ? this.state.lastActivityDate[5] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend">
                                    <div className="row start">
                                        <div className="box">
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.socialChecklist[0].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.socialChecklist[0].descriptionUser, 'social')}>{this.state.socialChecklist[0].name}</div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text header">Description</div>
                                            <div className="text">
                                                {this.state.socialDescription}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__checklist">
                                <div className="dashboard__list">
                                    <div className="dashboard__item" onClick={(e) => e.currentTarget.closest('.dashboard__checklist').classList.toggle('active')}>{checklistArrow}</div>
                                    <div className="dashboard__item">{localStorage.getItem('i18nextLng') === 'en' ? 'Remuneration' : 'Bezoldiging'}</div>
                                    <div className="dashboard__item">After your confirmation, our team sets up your company director’s remuneration</div>
                                    <div className="dashboard__item">
                                        <img src={sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : avatar} alt={'avatar'} />
                                    </div>
                                    <div className="dashboard__item high">
                                        <LinearProgress
                                            variant={'determinate'}
                                            value={(this.state.remunerationChecklistProgress/2) * 100}
                                        />
                                    </div>
                                    <div className="dashboard__item">{secondListIcon} {this.state.remunerationChecklistProgress}/2</div>
                                    <div className="dashboard__item">{this.state.lastActivityDate[6] !== undefined ? this.state.lastActivityDate[6] : '-'}</div>
                                </div>
                                <div className="dashboard__checklist-extend">
                                    <div className="row start">
                                        <div className="box">
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.remunerationChecklist[0].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.remunerationChecklist[0].descriptionUser, 'remuneration')}>{this.state.remunerationChecklist[0].name}</div>
                                            </div>
                                            <div className="row checkbox">
                                                <FormControlLabel label={''} control={
                                                    <Checkbox color={'#4A5BCF'} checked={this.state.remunerationChecklist[1].checked} />
                                                }/>
                                                <div className="text label" onClick={() => this.handleNotary(this.state.remunerationChecklist[1].descriptionUser, 'remuneration')}>{this.state.remunerationChecklist[1].name}</div>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="text header">Description</div>
                                            <div className="text">
                                                {this.state.remunerationDescription}
                                            </div>
                                        </div>
                                        <div className="box d-none">
                                            <div className="text header">Remuneration proposition</div>
                                            <div className="actions d-none">
                                                <div className="icon">
                                                    {editAdminIcon}
                                                </div>
                                                <div className="icon">
                                                    {fullScreen}
                                                </div>
                                                <div className="icon">
                                                    {download}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {this.state.step === 1 &&
                        <Link to={'company'} className="btn btn--primary">Continue survey</Link>
                    }
                </div>
                <Confirm active={this.state.confirmDashboard} message={this.state.message} handleConfirm={this.handleConfirm} handleRemove={this.handleSave} />
            </div>
        )
    }
}

export default withTranslation()(withCookies(Dashboard));