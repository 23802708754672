import React, {useState, useEffect} from "react";
import {TextField} from "@material-ui/core";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";

function Step5(props) {
    const [soleIncomeTax, setSoleIncomeTax] = useState(null);
    const [soleSocialContribution, setSoleSocialContribution] = useState(null);
    const [soleAvgTaxRate, setSoleAvgTaxRate] = useState(null);
    const [soleTotalTax, setSoleTotalTax] = useState(null);
    const [soleMonthNetIncome, setSoleMonthNetIncome] = useState(null);
    const [ltdIncomeTax, setLtdIncomeTax] = useState(null);
    const [ltdSocialContribution, setLtdSocialContribution] = useState(null);
    const [ltdCorporateTax, setLtdCorporateTax] = useState(null);
    const [ltdAvgTaxRate, setLtdAvgTaxRate] = useState(null);
    const [ltdTotalTax, setLtdTotalTax] = useState(null);
    const [ltdMonthNetIncome, setLtdMonthNetIncome] = useState(null);

    useEffect(() => {
        setSoleIncomeTax(props.data !== null ? props.data.soleIncomeTax: 0);
        setSoleSocialContribution(props.data ? props.data.soleSocialContribution: 0);
        setSoleAvgTaxRate(props.data ? props.data.soleAvgTaxRate: 0);
        setSoleTotalTax(props.data ? props.data.soleTotalTax: 0);
        setSoleMonthNetIncome(props.data ? props.data.soleMonthNetIncome: 0);
        setLtdIncomeTax(props.data ? props.data.ltdIncomeTax: 0);
        setLtdSocialContribution(props.data ? props.data.ltdSocialContribution: 0);
        setLtdCorporateTax(props.data ? props.data.ltdCorporateTax: 0);
        setLtdAvgTaxRate(props.data ? props.data.ltdAvgTaxRate: 0);
        setLtdTotalTax(props.data ? props.data.ltdTotalTax: 0);
        setLtdMonthNetIncome(props.data ? props.data.ltdMonthNetIncome: 0);
        props.handleCompanyData();
        props.handleSurvey();
    }, []);

    const save = () => {
        let body = {
            soleIncomeTax,
            soleSocialContribution,
            soleAvgTaxRate,
            soleTotalTax,
            soleMonthNetIncome,
            ltdIncomeTax,
            ltdSocialContribution,
            ltdCorporateTax,
            ltdAvgTaxRate,
            ltdTotalTax,
            ltdMonthNetIncome
        }
        props.handleLoading(true);

        Connect(`${Endpoint.outputData}/${sessionStorage.getItem('userID')}`, 'PUT', body).then(response => {
            props.handleCompanyData();
            props.handleSurvey();
        });
    }

    return (
        <div className="container__body">
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">Sole personal income tax</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={soleIncomeTax} onChange={(e) => setSoleIncomeTax(e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">Sole social contributions</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={soleSocialContribution} onChange={(e) => setSoleSocialContribution(e.target.value)} />
                </div>
            </div>
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">Sole average tax rate</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={soleAvgTaxRate} onChange={(e) => setSoleAvgTaxRate(e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">Sole total taxes</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={soleTotalTax} onChange={(e) => setSoleTotalTax(e.target.value)} />
                </div>
            </div>
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">Sole monthly net income</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={soleMonthNetIncome} onChange={(e) => setSoleMonthNetIncome(e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">Limited income tax</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={ltdIncomeTax} onChange={(e) => setLtdIncomeTax(e.target.value)} />
                </div>
            </div>
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">Limited social contributions</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={ltdSocialContribution} onChange={(e) => setLtdSocialContribution(e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">Limited corporate tax</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={ltdCorporateTax} onChange={(e) => setLtdCorporateTax(e.target.value)} />
                </div>
            </div>
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">Limited average tax rate</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={ltdAvgTaxRate} onChange={(e) => setLtdAvgTaxRate(e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">Limited total taxes</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={ltdTotalTax} onChange={(e) => setLtdTotalTax(e.target.value)} />
                </div>
            </div>
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">Limited monthly net income</p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={ltdMonthNetIncome} onChange={(e) => setLtdMonthNetIncome(e.target.value)} />
                </div>
                <div className="container__body-input" style={{alignItems: 'flex-end'}}>
                    <button className="btn btn--primary" style={{marginTop: 25, width: 220}} onClick={save}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default Step5;