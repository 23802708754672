import React, { useState, useEffect } from 'react';
import Role from './role';
import * as Endpoint from "../../configFile";
import CircularProgress from "@material-ui/core/CircularProgress";
import requestBehavior from "../requestBehavior/requestBehavior";
import AddRole from './addRole';

const Permission = (props) => {
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const update = (data) => {
        setItems((prevItems) => [...prevItems, data]);
    }

    const handleDelete = (deleted) => {
        setItems((prevItems) => prevItems.filter((item) => item.position !== deleted));
    }

    useEffect(() => {
        if (props.role !== 'admin') {
            setError(true);
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(Endpoint.getAllRoles, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
                    },
                });

                if (!response.ok) {
                    throw response;
                }

                const responseData = await response.json();
                setItems(responseData);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
                setIsLoaded(true);
            }
        };

        fetchData();
    }, [props.role]);

    const user = JSON.parse(localStorage.getItem('user'));

    if (error !== null) {
        return requestBehavior(error);
    } else {
        return (
            <>
                <div className="container__title container-justifySpace add__role">
                    {user.permissions.editPermissions && <AddRole isMobile={props.isMobile} update={update} />}
                </div>
                {isLoaded && items && items.length > 0
                    ? items.map((item, index) => (
                        <Role
                            data={item}
                            key={index}
                            canEdit={user.permissions.editPermissions}
                            canDelete={user.permissions.deletePermissions}
                            isMobile={props.isMobile}
                            onDelete={handleDelete}
                        />
                    ))
                    : <CircularProgress classes={{ root: props.classes.root }} />
                }
            </>
        );
    }
}

export default Permission;
