import React, { useState, useEffect } from "react";
import './customSelect.scss';
import { arrowDropdown } from "../login/loginConstants";

function CustomSelect(props) {
    const [defaultSelectText, setDefaultSelectText] = useState("");
    const [showOptionList, setShowOptionList] = useState(false);

    useEffect(() => {
        setDefaultSelectText(props.defaultText);
    }, [props.defaultText]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text") &&
            !e.target.closest('.custom-select-container')
        ) {
            setShowOptionList(false);
        }
    };

    const handleListDisplay = () => {
        setShowOptionList(!showOptionList);
    };

    const handleOptionClick = (e) => {
        const selectedValue = e.target.getAttribute('data-value');
        const selectedName = e.target.getAttribute('data-name');
        const selectedPid = e.target.getAttribute('data-pid');
        let selectedKey = e.target.getAttribute('data-key');
        if (e.target.getAttribute('data-mail') !== null) {
            selectedKey = e.target.getAttribute('data-mail');
        }

        setDefaultSelectText(selectedName);
        setShowOptionList(false);

        if (props.type === 'add') {
            props.handleData(true, props.pid, selectedValue, props.editType, props.editDescription, props.editAmount);
        } else if (props.type === 'revenue') {
            props.handleData(props.type, [{ id: parseInt(selectedPid), text: selectedValue, textKey: selectedKey }]);
        } else {
            props.handleData(props.type, [{ id: parseInt(selectedPid), text: selectedName, textKey: selectedKey }]);
        }
    };

    return (
        <div className={props.disabled === true ? "custom-select-container disabled" : showOptionList ? "custom-select-container active" : "custom-select-container"} style={showOptionList === true ? { height: `${(props.optionsList.length * 42) + 51}px` } : { height: `38px` }}>
            <div
                className={showOptionList ? "selected-text active" : "selected-text"}
                onClick={handleListDisplay}
                data-value={props.defaultSelectValue && props.defaultSelectValue}
                id={props.id}
            >
                {props.disabled === true ? props.defaultText : props.defaultSelectText ? props.defaultSelectText : defaultSelectText !== null ? props.type === 'user' ? `Client: ${defaultSelectText}` : defaultSelectText : 'Please select an option'}
            </div>
            {showOptionList && (
                <ul className="select-options">
                    {props.optionsList.map(option => {
                        return (
                            <li
                                className="custom-select-option"
                                data-value={option.value}
                                data-name={option.name}
                                data-pid={option.id}
                                data-key={option.textKey}
                                data-mail={option.mail}
                                key={option.id}
                                onClick={handleOptionClick}
                            >
                                {option.name}
                            </li>
                        );
                    })}
                </ul>
            )}
            <div className="arrow" onClick={handleListDisplay}>
                {arrowDropdown}
            </div>
        </div>
    );
}

export default CustomSelect;
