import React from "react";
import './confirm.scss';
import {Trans} from "react-i18next";

function Confirm(props) {
    return (
        <div className={props.active === true ? "confirm active" : "confirm"}>
            <div className="confirm__content">
                <div className={props.only === true ? "confirm__content-header only" : "confirm__content-header"}>{props.message}</div>
                <div className={props.only === true ? "row center" : "row"}>
                    {props.only === true ?
                        <>
                            <button className="btn btn--primary" onClick={() => props.handleConfirm(false)}>Close</button>
                        </>
                        :
                        <>
                            <button className="btn btn--secondary" onClick={() => props.handleConfirm(false)}><Trans>establishmentFlow.no</Trans></button>
                            <button className="btn btn--primary" onClick={() => props.handleRemove(sessionStorage.getItem('indexDelete'))}><Trans>establishmentFlow.yes</Trans></button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Confirm;
