import React from "react";
import './_error.scss';
import Logo from "../../assets/icon/logo.svg";
import Magnifier from "../../assets/404-loupe.svg";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

const error404 = () => {
    const getRole = localStorage.getItem('role');
    let homeLink = '/login';
    if(getRole === 'admin'){
        homeLink = '/clients'
    } else if(getRole === 'user'){
        homeLink = '/company'
    }

    return(
        <div className="share-screen">
            <div className="share-screen__color-side">
                <img src={Logo} alt="logo Bizantium" className="logo"/>
                <div className="share-screen__block">
                    <h2 className="share-screen__header"><Trans>error.sorry</Trans></h2>
                    <h4 className="share-screen__text"><Trans>error.try</Trans></h4>
                    <Link className="btn--primary" to={homeLink}><Trans>error.home</Trans></Link>
                </div>
            </div>
            <div className="share-screen__white-side">
                <div className="share-screen__picture">
                    <h1 className="share-screen__picture--text">4</h1>
                    <img src={Magnifier} alt="Magnifier" className="share-screen__picture--image"/>
                    <h1 className="share-screen__picture--text">4</h1>
                </div>

                <h4 className="share-screen__text"><Trans>error.page-not-found</Trans></h4>
            </div>
        </div>
    )
}

export default error404;