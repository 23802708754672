import React, { Component } from "react";
import "./mobility.scss";
import { closeList, editAdminIcon, whitePlus } from "../login/loginConstants";
import { FormControl, FormControlLabel, Radio, TextField, InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { calendar, InfoIcon } from "../survey/surveyConstant";
import Tooltip from "@material-ui/core/Tooltip";
import car from "../../assets/car.png";
import tram from "../../assets/tram.png";
import train from "../../assets/train.png";
import bicycle from "../../assets/bicycle.png";
import bike from "../../assets/motorcycle.png";
import taxi from "../../assets/taxi.png";
import other from "../../assets/other.png";
import scooter from "../../assets/scooter.png";
import { Connect } from "../connect/connect";
import RadioGroup from "@material-ui/core/RadioGroup";
import * as Endpoint from "../../configFile";
import Confirm from "../confirm/confirm";
import DatePicker from "react-datepicker";
import CustomSelect from "../customSelect/customSelect";
import { Range } from "react-range";
import { Trans } from "react-i18next";
import { transformDate } from "../admin/adminEstablishment";
import nl from "date-fns/locale/nl";

class Mobility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      data: [],
      car: "Leasing car",
      motorcycle: "",
      publicTransport: "",
      bicycle: "",
      scooter: "",
      confirm: false,
      startDate: null,
      selectFuel: [
        {
          id: 1,
          name: localStorage.getItem("i18nextLng") === "en" ? "Gasoline" : "Benzine",
          value: localStorage.getItem("i18nextLng") === "en" ? "Gasoline" : "Benzine",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "Diesel" : "Diesel",
          value: localStorage.getItem("i18nextLng") === "en" ? "Diesel" : "Diesel",
        },
        {
          id: 3,
          name: localStorage.getItem("i18nextLng") === "en" ? "Hybrid" : "Hybride",
          value: localStorage.getItem("i18nextLng") === "en" ? "Hybrid" : "Hybride",
        },
        {
          id: 4,
          name: localStorage.getItem("i18nextLng") === "en" ? "Electric" : "Elektrisch",
          value: localStorage.getItem("i18nextLng") === "en" ? "Electric" : "Elektrisch",
        },
        {
          id: 5,
          name: localStorage.getItem("i18nextLng") === "en" ? "Hydrogen" : "Waterstof",
          value: localStorage.getItem("i18nextLng") === "en" ? "Hydrogen" : "Waterstof",
        },
      ],
      selectMotorFuel: [
        {
          id: 1,
          name: localStorage.getItem("i18nextLng") === "en" ? "Gasoline" : "Benzine",
          value: localStorage.getItem("i18nextLng") === "en" ? "Gasoline" : "Benzine",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "Electric" : "Elektrisch",
          value: localStorage.getItem("i18nextLng") === "en" ? "Electric" : "Elektrisch",
        },
      ],
      selectPublicTransport: [
        {
          id: 1,
          name: localStorage.getItem("i18nextLng") === "en" ? "Bus/Tram" : "Bus/Tram",
          value: localStorage.getItem("i18nextLng") === "en" ? "Bus/Tram" : "Belg",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "Train" : "Trein",
          value: localStorage.getItem("i18nextLng") === "en" ? "Train" : "Trein",
        },
        {
          id: 3,
          name: localStorage.getItem("i18nextLng") === "en" ? "Metro" : "Belg",
          value: localStorage.getItem("i18nextLng") === "en" ? "Metro" : "Belg",
        },
        {
          id: 4,
          name: localStorage.getItem("i18nextLng") === "en" ? "Other" : "Ander",
          value: localStorage.getItem("i18nextLng") === "en" ? "Other" : "Ander",
        },
      ],
      selectTaxi: [
        {
          id: 1,
          name: localStorage.getItem("i18nextLng") === "en" ? "Taxi" : "Taxi",
          value: localStorage.getItem("i18nextLng") === "en" ? "Taxi" : "Taxi",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "Uber" : "Uber",
          value: localStorage.getItem("i18nextLng") === "en" ? "Uber" : "Uber",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "Other" : "Ander",
          value: localStorage.getItem("i18nextLng") === "en" ? "Other" : "Ander",
        },
      ],
      selectBicycle: [
        {
          id: 1,
          name: localStorage.getItem("i18nextLng") === "en" ? "Regular bike" : "Gewone fiets",
          value: localStorage.getItem("i18nextLng") === "en" ? "Regular bike" : "Gewone fiets",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "e-bike" : "e-fiets",
          value: localStorage.getItem("i18nextLng") === "en" ? "e-bike" : "e-fiets",
        },
        {
          id: 2,
          name: localStorage.getItem("i18nextLng") === "en" ? "speed pedelec" : "speed-pedelec",
          value: localStorage.getItem("i18nextLng") === "en" ? "speed pedelec" : "speed-pedelec",
        },
      ],
      expensiveCar: [0],
      expensiveMotorcycle: [0],
      editId: 0,
      costId: 0,
      cost2Id: 0,
      publicType: "Bus/Tram",
      defaultLeaseCar: localStorage.getItem("i18nextLng") === "en" ? "I lease this car" : "Ik lease deze auto",
      defaultLeaseMotorcycle: localStorage.getItem("i18nextLng") === "en" ? "I lease this motorcycle" : "Ik lease deze motorfiets",
      defaultPublicTransport: localStorage.getItem("i18nextLng") === "en" ? "Pay per month" : "Betaal per maand",
      defaultTypePublic: "Bus/Tram",
      defaultTypeBicycle: localStorage.getItem("i18nextLng") === "en" ? "I will buy it" : "ik zal het kopen",
      defaultTypeScooter: localStorage.getItem("i18nextLng") === "en" ? "I will buy it" : "ik zal het kopen",
      leasingCarPrice: 1375,
      carPrice: 1375,
      motorcyclePrice: 1240,
      publicTransportPrice: 49,
      taxiPrice: 49,
      errorMessage: "",
      otherAmount: "",
      scooterPrice: "",
      scooterYearly: "",
      bicyclePrice: "",
      bicycleYearly: "",
      editPrice: false,
      defaultGasoline: "Gasoline",
    };
  }

  componentDidMount() {
    this.getRes();
    const date = new Date();
    Connect(`${Endpoint.taxMachine}`, "GET").then((response) => {
      if (response.companyCar !== null) {
        this.setState({
          leasingCarPrice: response.companyCar,
          carPrice: response.companyCar,
        });
      }
    });
    let body = {
      checkpointCategory: JSON.parse(sessionStorage.getItem("checkpointCategoryEstablishment"))[
        sessionStorage.getItem("legalForm") === "LIMITED_COMPANY" ? 5 : 2
      ],
      completed: 1,
      required: 1,
      isChange: true,
    };
    Connect(`${Endpoint.putProgressSurvey}`, "PUT", body).then((response) => {
      console.log(response);
    });
  }

  getRes = () => {
    Connect(`${Endpoint.getUserTransport}`, "GET").then((response) => {
      this.setState({
        data: response,
      });
      this.clear();
    });
  };

  handleStep = (value) => {
    this.setState({
      step: value,
      expensiveCar: [0],
      expensiveMotorcycle: [0],
      editId: 0,
      costId: 0,
      cost2Id: 0,
      publicType: "Bus/Tram",
      car: "Leasing car",
      motorcycle: "Leasing motorcycle",
      publicTransport: "Pay per month",
      bicycle: "",
      scooter: "",
      startDate: null,
      errorMessage: "",
    });
    this.clear();
  };

  clear = () => {
    if (document.querySelector(".mobility__form") && document.querySelector(".mobility__form").querySelector('input[name="carName"]') !== null) {
      document.querySelector(".mobility__form").querySelector('input[name="carName"]').value = "";
    }
    if (document.querySelector(".mobility__form") && document.querySelector(".mobility__form").querySelector('input[name="motorName"]') !== null) {
      document.querySelector(".mobility__form").querySelector('input[name="motorName"]').value = "";
    }
    if (document.querySelector(".mobility__form") && document.querySelector(".mobility__form").querySelector('input[name="bicycleName"]') !== null) {
      document.querySelector(".mobility__form").querySelector('input[name="bicycleName"]').value = "";
    }
    if (document.querySelector(".mobility__form") && document.querySelector(".mobility__form").querySelector('input[name="scooterName"]') !== null) {
      document.querySelector(".mobility__form").querySelector('input[name="scooterName"]').value = "";
    }
    if (document.querySelector(".mobility__form") && document.querySelector(".mobility__form").querySelector('input[name="otherName"]') !== null) {
      document.querySelector(".mobility__form").querySelector('input[name="otherName"]').value = "";
    }
  };

  handleConfirm = (value) => {
    this.setState({
      confirm: value,
    });
  };

  confirmRemove = (pid) => {
    this.handleConfirm(true);
    sessionStorage.setItem("indexDelete", pid);
  };

  remove = () => {
    this.handleConfirm(false);
    Connect(`${Endpoint.getUserTransport}/${sessionStorage.getItem("indexDelete")}`, "DELETE").then((response) => {
      this.getRes();
    });
  };

  save = (e) => {
    let type = "";
    let name = "";
    let engine = "";
    let leasing = "Other";
    let owner = false;
    let amount = 0;
    let amount2 = 0;
    let expensiveScale = null;
    let subscriptionType = null;
    if (this.state.step === 1) {
      type = "CAR";
      name = e.currentTarget.closest(".mobility__form").querySelector('input[name="carName"]').value;
      engine = e.currentTarget.closest(".mobility__form").querySelector("#carEngine").innerText;
      leasing = this.state.car;
      amount = document.querySelector("#carCost").getAttribute("data-value");
      expensiveScale = this.state.expensiveCar[0] + 1;
    }
    if (this.state.step === 2) {
      type = "MOTORCYCLE";
      name = e.currentTarget.closest(".mobility__form").querySelector('input[name="motorName"]').value;
      engine = e.currentTarget.closest(".mobility__form").querySelector("#motorEngine").innerText;
      leasing = this.state.motorcycle;
      amount = document.querySelector("#motorCost").getAttribute("data-value");
      expensiveScale = this.state.expensiveMotorcycle[0] + 1;
    }
    if (this.state.step === 3) {
      type = "PUBLIC_TRANSPORT";
      name = e.currentTarget.closest(".mobility__form").querySelector("#public").innerText;
      leasing = `${e.currentTarget.closest(".mobility__form").querySelector("#public").innerText} - ${this.state.publicTransport
        .replaceAll("Bus/Tram - ", "")
        .replaceAll("Train - ", "")
        .replaceAll("Metro - ", "")
        .replaceAll("Other - ", "")}`;
      if (this.state.publicTransport === "Pay per month") amount = document.querySelector("#publicCost").getAttribute("data-value");
      if (this.state.publicTransport === "Pay per quarter")
        amount = (+document.querySelector("#publicCost").getAttribute("data-value") / 3).toString();
      if (this.state.publicTransport === "Pay per year")
        amount = (+document.querySelector("#publicCost").getAttribute("data-value") / 12).toString().replaceAll(".", ",");
    }
    if (this.state.step === 4) {
      type = "TAXI";
      name = e.currentTarget.closest(".mobility__form").querySelector("#taxiType").innerText;
      amount = document.querySelector("#taxiCost").getAttribute("data-value");
      leasing = e.currentTarget.closest(".mobility__form").querySelector("#taxiType").innerText;
    }
    if (this.state.step === 5) {
      type = "BICYCLE";
      name = e.currentTarget.closest(".mobility__form").querySelector('input[name="bicycleName"]').value;
      amount2 = e.currentTarget.closest(".mobility__form").querySelector('input[name="bicyclePrice"]').value.replaceAll(".", "");
      amount = (
        parseInt(e.currentTarget.closest(".mobility__form").querySelector('input[name="bicycleYearly"]').value.replaceAll(".", "")) / 12
      ).toFixed(0);
      leasing = this.state.bicycle === "I will buy it" ? "Own bicycle" : "Leasing bicycle";
    }
    if (this.state.step === 6) {
      type = "E_SCOOTER";
      name = e.currentTarget.closest(".mobility__form").querySelector('input[name="scooterName"]').value;
      amount2 = e.currentTarget.closest(".mobility__form").querySelector('input[name="scooterPrice"]').value.replaceAll(".", "");
      amount = (
        parseInt(e.currentTarget.closest(".mobility__form").querySelector('input[name="scooterYearly"]').value.replaceAll(".", "")) / 12
      ).toFixed(0);
      leasing = this.state.scooter === "I will buy it" ? "Own e-scooter" : "Leasing e-scooter";
    }
    if (this.state.step === 7) {
      type = "OTHER";
      name = e.currentTarget.closest(".mobility__form").querySelector('input[name="otherName"]').value;
      amount = e.currentTarget.closest(".mobility__form").querySelector('input[name="otherAmount"]').value.replaceAll(".", "");
    }
    let costs = [
      {
        costType: "TRANSPORTATION",
        description: leasing,
        amount: parseInt(amount.replaceAll(".", "")),
        isPeriodic: true,
        costPeriod: "PER_MONTH",
      },
    ];
    if (amount2 !== 0) {
      costs = [
        {
          costType: "TRANSPORTATION",
          description: leasing,
          amount: parseInt(amount.replaceAll(".", "")),
          isPeriodic: true,
          costPeriod: "PER_MONTH",
        },
        {
          costType: "TRANSPORTATION",
          description: leasing,
          amount: parseInt(amount2),
          isPeriodic: false,
          costPeriod: null,
        },
      ];
    }
    let body = {
      name: name,
      description: "test car",
      costs,
      transportType: type,
      expensiveScale: expensiveScale,
      purchaseDate: this.state.startDate,
      isOwner: leasing !== "Leasing car",
      subtype: engine,
      subscriptionType,
    };
    if (document.querySelector(".mobility__form").getAttribute("data-id") !== null) {
      if (amount2 !== 0) {
        costs = [
          {
            id: parseInt(this.state.costId),
            costType: "TRANSPORTATION",
            description: leasing,
            amount: parseInt(amount.replaceAll(".", "")),
            isPeriodic: true,
            costPeriod: "PER_MONTH",
          },
          {
            id: parseInt(this.state.cost2Id),
            costType: "TRANSPORTATION",
            description: leasing,
            amount: parseInt(amount2),
            isPeriodic: false,
            costPeriod: null,
          },
        ];
      } else {
        costs = [
          {
            id: parseInt(this.state.costId),
            costType: "TRANSPORTATION",
            description: leasing,
            amount: parseInt(amount.replaceAll(".", "")),
            isPeriodic: true,
            costPeriod: "PER_MONTH",
          },
        ];
      }
      body = {
        id: parseInt(document.querySelector(".mobility__form").getAttribute("data-id")),
        name: name,
        description: name,
        costs,
        transportType: type,
        expensiveScale: expensiveScale,
        purchaseDate: this.state.startDate,
        isOwner: owner,
        subtype: engine,
        subscriptionType,
      };
      Connect(`${Endpoint.getUserTransport}`, "PUT", body).then((response) => {
        if (response.status === 404 || response === "Błąd serwera") {
          this.setState({
            errorMessage: "There was a problem adding the transport. Please try later",
          });
        } else {
          this.setState({
            expensiveCar: [0],
            expensiveMotorcycle: [0],
            editId: 0,
            costId: 0,
            cost2Id: 0,
            publicType: "Bus/Tram",
            car: "Leasing car",
            motorcycle: "Leasing motorcycle",
            // publicTransport: "Pay per month",
            bicycle: "",
            scooter: "",
            startDate: null,
            otherAmount: "",
            scooterPrice: "",
            scooterYearly: "",
            bicyclePrice: "",
            bicycleYearly: "",
            editPrice: false,
            carPrice: 349,
            motorcyclePrice: 1240,
            // publicTransportPrice: 49,
            taxiPrice: 49,
          });
          this.getRes();
          document.querySelector(".mobility__form").removeAttribute("data-id");
        }
      });
    } else {
      Connect(`${Endpoint.getUserTransport}`, "POST", body).then((response) => {
        if (response.status === 404 || response === "Błąd serwera") {
          this.setState({
            errorMessage: "There was a problem adding the transport. Please try later",
          });
        } else {
          this.setState({
            expensiveCar: [0],
            expensiveMotorcycle: [0],
            editId: 0,
            costId: 0,
            cost2Id: 0,
            publicType: "Bus/Tram",
            car: "Leasing car",
            motorcycle: "Leasing motorcycle",
            // publicTransport: "Pay per month",
            bicycle: "",
            scooter: "",
            startDate: null,
            otherAmount: "",
            scooterPrice: "",
            scooterYearly: "",
            bicyclePrice: "",
            bicycleYearly: "",
            editPrice: false,
            carPrice: 349,
            motorcyclePrice: 1240,
            // publicTransportPrice: 49,
            taxiPrice: 49,
          });
          this.getRes();
        }
      });
    }
  };

  priceCar = (value) => {
    if (value === "Own car") {
      this.setState({
        carPrice: 340,
      });
    } else {
      this.setState({
        carPrice: this.state.leasingCarPrice,
      });
    }
  };

  statusCar = (value, recalculate = true) => {
    this.setState({
      car: value,
    });
    if (recalculate === true) {
      this.priceCar(value);
    }
  };

  priceMotor = (value) => {
    if (value === "Own motorcycle") {
      this.setState({
        motorcyclePrice: 340,
      });
    } else {
      this.setState({
        motorcyclePrice: 1240,
      });
    }
  };

  statusMotor = (value, recalculate = true) => {
    this.setState({
      motorcycle: value,
    });
    if (recalculate === true) {
      this.priceMotor(value);
    }
  };

  pricePublic = (value) => {
    if (value === "Pay per month") {
      this.setState({
        publicTransportPrice: 49,
      });
    } else if (value === "Pay per quarter") {
      this.setState({
        publicTransportPrice: 135,
      });
    } else {
      this.setState({
        publicTransportPrice: 351,
      });
    }
  };

  statusPublic = (value, recalculate = true) => {
    this.setState({
      publicTransport: value,
    });
    if (recalculate === true) {
      this.pricePublic(value);
    }
  };

  statusBicycle = (value) => {
    this.setState({
      bicycle: value,
    });
  };

  statusScooter = (value) => {
    this.setState({
      scooter: value,
    });
  };

  edit = (id, category, name, engine, expensiveScale, costPid, cost, description, purchaseDate, cost2Pid = 0, cost2 = 0) => {
    let step = 0;
    if (category === "CAR") {
      step = 1;
    } else if (category === "MOTORCYCLE") {
      step = 2;
    } else if (category === "PUBLIC_TRANSPORT") {
      step = 3;
    } else if (category === "TAXI") {
      step = 4;
    } else if (category === "BICYCLE") {
      step = 5;
    } else if (category === "E_SCOOTER") {
      step = 6;
    } else if (category === "OTHER") {
      step = 7;
    }
    this.setState({
      step,
      expensiveCar: expensiveScale > 0 ? [parseInt(expensiveScale - 1)] : [0],
      expensiveMotorcycle: expensiveScale > 0 ? [parseInt(expensiveScale - 1)] : [0],
      editId: id,
      costId: costPid,
      cost2Id: cost2Pid,
      car: description,
      motorcycle: description,
      publicTransport: description,
      bicycle: description === "Leasing bicycle" ? "I will lease it" : "I will buy it",
      scooter: description === "Leasing e-scooter" ? "I will lease it" : "I will buy it",
      defaultLeaseCar:
        description === "Own car" ? "I own this car" : localStorage.getItem("i18nextLng") === "en" ? "I lease this car" : "Ik lease deze auto",
      defaultLeaseMotorcycle:
        description === "Own motorcycle"
          ? localStorage.getItem("i18nextLng") === "en"
            ? "I own this motorcycle"
            : "Ik ben eigenaar van deze motorfiets"
          : localStorage.getItem("i18nextLng") === "en"
          ? "I lease this motorcycle"
          : "Ik lease deze motorfiets",
      defaultPublicTransport: description
        .replaceAll("Bus/Tram - ", "")
        .replaceAll("Train - ", "")
        .replaceAll("Metro - ", "")
        .replaceAll("Other - ", ""),
      defaultTypePublic: name,
      defaultTypeBicycle: description === "Leasing bicycle" ? "I will lease it" : "I will buy it",
      defaultTypeScooter: description === "Leasing e-scooter" ? "I will lease it" : "I will buy it",
      date: purchaseDate,
      carPrice: this.addCommas(this.removeNonNumeric(cost)),
      motorcyclePrice: this.addCommas(this.removeNonNumeric(cost)),
      publicTransportPrice: this.addCommas(this.removeNonNumeric(cost)),
      taxiPrice: this.addCommas(this.removeNonNumeric(cost)),
      otherAmount: this.addCommas(this.removeNonNumeric(cost)),
      scooterPrice: this.addCommas(this.removeNonNumeric(cost2)),
      scooterYearly: this.addCommas(this.removeNonNumeric((cost * 12).toFixed(0))),
      bicyclePrice: this.addCommas(this.removeNonNumeric(cost2)),
      bicycleYearly: this.addCommas(this.removeNonNumeric((cost * 12).toFixed(0))),
      defaultGasoline: engine,
    });
    setTimeout(() => {
      if (step === 1) {
        document.querySelector('input[name="carName"]').value = name;
        this.statusCar(this.state.car, false);
        document.querySelector("#dateStart").value = purchaseDate;
      } else if (step === 2) {
        document.querySelector('input[name="motorName"]').value = name;
        document.querySelector("#motorEngine").innerText = engine;
        this.statusMotor(this.state.motorcycle, false);
        document.querySelector("#dateStart").value = purchaseDate;
      } else if (step === 3) {
        this.statusPublic(this.state.publicTransport, false);
      } else if (step === 4) {
        document.querySelector("#taxiType").innerText = name;
      } else if (step === 5) {
        document.querySelector('input[name="bicycleName"]').value = name;
        this.statusBicycle(this.state.bicycle);
      } else if (step === 6) {
        document.querySelector('input[name="scooterName"]').value = name;
        this.statusScooter(this.state.scooter);
      } else if (step === 7) {
        document.querySelector('input[name="otherName"]').value = name;
      }
      document.querySelector(".mobility__form").setAttribute("data-id", id);
    }, 100);
  };

  handleData = (type, value) => {
    this.setState({
      publicType: value[0].text,
    });
  };

  handleDate = (date) => {
    this.setState({
      startDate: date,
    });
  };

  addCommas = (num) => {
    if (num.length > 1) {
      if (num[0] === "0") {
        num = num.slice(1);
      }
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  render() {
    return (
      <div className='mobility'>
        <div className='container__body'>
          <div className='container__body-header'>
            <Trans>establishmentFlow.travel</Trans>
          </div>
          <div className='container__body-box'>
            <div className={this.state.step === 1 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(1)}>
              {whitePlus}
              <Trans>establishmentFlow.car</Trans>
            </div>
            <div className={this.state.step === 2 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(2)}>
              {whitePlus}
              <Trans>establishmentFlow.motor</Trans>
            </div>
            <div className={this.state.step === 3 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(3)}>
              {whitePlus}
              <Trans>establishmentFlow.public</Trans>
            </div>
            <div className={this.state.step === 4 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(4)}>
              {whitePlus}
              <Trans>establishmentFlow.taxi</Trans>
            </div>
            <div className={this.state.step === 5 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(5)}>
              {whitePlus}
              <Trans>establishmentFlow.bicycle</Trans>
            </div>
            <div className={this.state.step === 6 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(6)}>
              {whitePlus}
              <Trans>establishmentFlow.scooter</Trans>
            </div>
            <div className={this.state.step === 7 ? "btn btn--primary active" : "btn btn--primary"} onClick={() => this.handleStep(7)}>
              {whitePlus}
              <Trans>establishmentFlow.other</Trans>
            </div>
          </div>
          {this.state.step === 0 ? (
            <div className='mobility__intro'>
              <Trans>establishmentFlow.plan</Trans>
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 1 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>
                <Trans>establishmentFlow.addCar</Trans>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    <Trans>establishmentFlow.scaleCar</Trans>
                    <Tooltip title={<Trans>establishmentFlow.tooltip7</Trans>} placement={"right"}>
                      <IconButton aria-label='delete'>{InfoIcon}</IconButton>
                    </Tooltip>
                  </p>
                  <div className='structure__range'>
                    <Range
                      step={1}
                      min={0}
                      max={4}
                      values={this.state.expensiveCar}
                      onChange={(expensiveCar) => this.setState({ expensiveCar })}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#C4D0E5",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "18px",
                            width: "18px",
                            backgroundColor: "#4A5BCF",
                            borderRadius: 18,
                          }}
                          className='structure__range-pointer'
                        >
                          <div className='structure__range-percentage'>
                            <p>{parseInt(this.state.expensiveCar) + 1}</p>
                          </div>
                        </div>
                      )}
                    />
                    <div className='structure__range-legend'>
                      <p>1</p>
                      <p>2</p>
                      <p>3</p>
                      <p>4</p>
                      <p>5</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Initial purchase date" : "Initiële aankoopdatum"}
                  </p>
                  <DatePicker
                    id='dateStart'
                    autoComplete='off'
                    selected={this.state.startDate}
                    onChange={(date) => this.handleDate(date)}
                    placeholderText={localStorage.getItem("i18nextLng") === "en" ? "Select a date" : "\n" + "Selecteer een datum"}
                    locale={localStorage.getItem("i18nextLng") !== "en" && nl}
                    dateFormat='dd/MM/yyyy'
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <div className='calendarIcon'>{calendar}</div>
                </div>
                <div className='container__body-select'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Type of car" : "Type auto"}</p>
                  <CustomSelect
                    defaultText={this.state.defaultGasoline}
                    optionsList={this.state.selectFuel}
                    handleData={this.handleData}
                    type={"revenue"}
                    id={"carEngine"}
                  />
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Name this car" : "Geef deze auto een naam"}</p>
                  <TextField label={""} variant={"outlined"} size={"small"} placeholder={"..."} name={"carName"} />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en"
                      ? "Do you own the car, or lease it?"
                      : "Bent u eigenaar van de auto of leaset u deze?"}
                  </p>
                  <FormControl>
                    <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='car' defaultValue={this.state.defaultLeaseCar}>
                      <div className='structure__checkbox'>
                        <div className='container__body-radio' onClick={() => this.statusCar("Leasing car")}>
                          <FormControlLabel
                            value={localStorage.getItem("i18nextLng") === "en" ? "I lease this car" : "Ik lease deze auto"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I lease this car" : "Ik lease deze auto"}
                            className={"container__body-text"}
                          />
                        </div>
                        <div className='container__body-radio' onClick={() => this.statusCar("Own car")}>
                          <FormControlLabel value={"I own this car"} control={<Radio />} label='I own this car' className='container__body-text' />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className='mobility__cost'>
                <div className='mobility__cost-header'>
                  <Trans>establishmentFlow.totalCost</Trans>
                </div>
                <div className='row'>
                  <div className='mobility__cost-content'>
                    {this.state.editPrice === true ? (
                      <>
                        <div className='text' id='carCost' data-value={this.state.carPrice}>
                          €
                        </div>
                        <input
                          type='text'
                          value={this.state.carPrice && this.addCommas(this.removeNonNumeric(this.state.carPrice))}
                          onChange={(e) => this.setState({ carPrice: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                        />
                      </>
                    ) : (
                      <div className='text' id='carCost' data-value={this.state.carPrice}>
                        €{this.state.carPrice && this.addCommas(this.removeNonNumeric(this.state.carPrice))}
                      </div>
                    )}
                    <div className='text award'>
                      <Trans>companyType.month</Trans>
                    </div>
                  </div>
                  {this.state.editPrice === false && (
                    <div className='btn btn--error' onClick={() => this.setState({ editPrice: true })}>
                      <Trans>establishmentFlow.override</Trans>
                    </div>
                  )}
                </div>
                <div className='saveRow'>
                  <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                    {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                  </div>
                  <div className='error-message'>{this.state.errorMessage}</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 2 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>
                {localStorage.getItem("i18nextLng") === "en" ? "Add a motorcycle" : "Voeg een motorfiets toe"}
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en"
                      ? "On a scale of 1 to 5, how expensive is the motorcycle? *"
                      : "Hoe duur is de motorfiets op een schaal van 1 tot 5? *"}
                    <Tooltip title={<Trans>establishmentFlow.tooltip7</Trans>} placement={"right"}>
                      <IconButton aria-label='delete'>{InfoIcon}</IconButton>
                    </Tooltip>
                  </p>
                  <div className='structure__range'>
                    <Range
                      step={1}
                      min={0}
                      max={4}
                      values={this.state.expensiveMotorcycle}
                      onChange={(expensiveMotorcycle) => this.setState({ expensiveMotorcycle })}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#C4D0E5",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "18px",
                            width: "18px",
                            backgroundColor: "#4A5BCF",
                            borderRadius: 18,
                          }}
                          className='structure__range-pointer'
                        >
                          <div className='structure__range-percentage'>
                            <p>{parseInt(this.state.expensiveMotorcycle) + 1}</p>
                          </div>
                        </div>
                      )}
                    />
                    <div className='structure__range-legend'>
                      <p>1</p>
                      <p>2</p>
                      <p>3</p>
                      <p>4</p>
                      <p>5</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Initial purchase date" : "Initiële aankoopdatum"}
                  </p>
                  <DatePicker
                    id='dateStart'
                    autoComplete='off'
                    selected={this.state.startDate}
                    onChange={(date) => this.handleDate(date)}
                    placeholderText={localStorage.getItem("i18nextLng") === "en" ? "Select a date" : "\n" + "Selecteer een datum"}
                    locale={localStorage.getItem("i18nextLng") !== "en" && nl}
                    dateFormat='dd/MM/yyyy'
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <div className='calendarIcon'>{calendar}</div>
                </div>
                <div className='container__body-select'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Type of motocycle" : "Type motorfiets"}</p>
                  <CustomSelect
                    defaultText={this.state.defaultGasoline}
                    optionsList={this.state.selectMotorFuel}
                    handleData={this.handleData}
                    type={"revenue"}
                    id={"motorEngine"}
                  />
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Name this motocycle" : "Noem deze motorfiets"}
                  </p>
                  <TextField label={""} variant={"outlined"} size={"small"} placeholder={"..."} name={"motorName"} />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en"
                      ? "Do you own the motocycle, or lease it?"
                      : "Bent u eigenaar van de motorfiets of leaset u deze?"}
                  </p>
                  <FormControl>
                    <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='motorcycle' defaultValue={this.state.defaultLeaseMotorcycle}>
                      <div className='structure__checkbox'>
                        <div className='container__body-radio' onClick={() => this.statusMotor("Leasing motorcycle")}>
                          <FormControlLabel
                            value={localStorage.getItem("i18nextLng") === "en" ? "I lease this motorcycle" : "Ik lease deze motorfiets"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I lease this motorcycle" : "Ik lease deze motorfiets"}
                            className={"container__body-text"}
                          />
                        </div>
                        <div className='container__body-radio' onClick={() => this.statusMotor("Own motorcycle")}>
                          <FormControlLabel
                            value={localStorage.getItem("i18nextLng") === "en" ? "I own this motorcycle" : "Ik ben eigenaar van deze motorfiets"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I own this motorcycle" : "Ik ben eigenaar van deze motorfiets"}
                            className='container__body-text'
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className='mobility__cost'>
                <div className='mobility__cost-header'>
                  <Trans>establishmentFlow.totalCost</Trans>
                </div>
                <div className='row'>
                  <div className='mobility__cost-content'>
                    {this.state.editPrice === true ? (
                      <>
                        <div className='text' id='motorCost' data-value={this.state.motorcyclePrice}>
                          €
                        </div>
                        <input
                          type='text'
                          value={this.state.motorcyclePrice && this.addCommas(this.removeNonNumeric(this.state.motorcyclePrice))}
                          onChange={(e) => this.setState({ motorcyclePrice: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                        />
                      </>
                    ) : (
                      <div className='text' id='motorCost' data-value={this.state.motorcyclePrice}>
                        €{this.state.motorcyclePrice && this.addCommas(this.removeNonNumeric(this.state.motorcyclePrice))}
                      </div>
                    )}
                    <div className='text award'>{localStorage.getItem("i18nextLng") === "en" ? "per month" : "per maand"}</div>
                  </div>
                  {this.state.editPrice === false && (
                    <div className='btn btn--error' onClick={() => this.setState({ editPrice: true })}>
                      <Trans>establishmentFlow.override</Trans>
                    </div>
                  )}
                </div>
                <div className='saveRow'>
                  <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                    {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                  </div>
                  <div className='error-message'>{this.state.errorMessage}</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 3 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>{localStorage.getItem("i18nextLng") === "en" ? "Public transport" : "Openbaar vervoer"}</div>
              <div className='container__body-box'>
                <div className='container__body-select'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Type of public transport" : "Soort openbaar vervoer"}
                  </p>
                  <CustomSelect
                    defaultText={this.state.defaultTypePublic}
                    optionsList={this.state.selectPublicTransport}
                    handleData={this.handleData}
                    type={"revenue"}
                    id={"public"}
                  />
                </div>
              </div>
              {this.state.publicType === "Bus/Tram" ? (
                <div className='container__body-box'>
                  <div className='container__body-input' style={{ width: "100%" }}>
                    <p className='container__body-text'>
                      {localStorage.getItem("i18nextLng") === "en" ? "Bus and tram subscription" : "Bus- en tramabonnement"}
                    </p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        name='publicTransport'
                        defaultValue={this.state.defaultPublicTransport}
                      >
                        <div className='structure__checkbox'>
                          <img className='tram' src={this.state.publicType === "Bus/Tram" ? tram : train} />
                          <div className='container__body-radio' onClick={() => this.statusPublic("Pay per month")}>
                            <FormControlLabel
                              value={"Pay per month"}
                              control={<Radio />}
                              label={localStorage.getItem("i18nextLng") === "en" ? "Pay per month" : "Betaal per maand"}
                              className={"container__body-text"}
                            />
                          </div>
                          <div className='container__body-radio' onClick={() => this.statusPublic("Pay per quarter")}>
                            <FormControlLabel
                              value={"Pay per quarter"}
                              control={<Radio />}
                              label={localStorage.getItem("i18nextLng") === "en" ? "Pay per quarter" : "Betaal per kwartaal"}
                              className='container__body-text'
                            />
                          </div>
                          <div className='container__body-radio' onClick={() => this.statusPublic("Pay per year")}>
                            <FormControlLabel
                              value={"Pay per year"}
                              control={<Radio />}
                              label={localStorage.getItem("i18nextLng") === "en" ? "Pay per year" : "Betaal per jaar"}
                              className={"container__body-text"}
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              ) : (
                <>
                  <div className='container__body-box'>
                    <div className='container__body-input' style={{ width: "100%" }}>
                      <p className='container__body-text'>
                        {this.state.publicType} {localStorage.getItem("i18nextLng") === "en" ? "subscription" : "abonnement"}
                      </p>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='publicTransport'
                          defaultValue={this.state.defaultPublicTransport}
                        >
                          <div className='structure__checkbox'>
                            <img className='tram' src={tram} />
                            <div className='mobility__cost-content'>
                              <div className='text' id={"publicCost"} data-value={this.state.publicTransport === "Pay per year" ? 1200 : 100}>
                                €{this.state.publicTransport === "Pay per year" ? "1.200" : "100"}
                              </div>
                              <div className='text award'>
                                / {this.state.publicType} {localStorage.getItem("i18nextLng") === "en" ? "month" : "maand"}
                              </div>
                            </div>
                            <div className='container__body-radio' onClick={() => this.statusPublic("Pay per month")}>
                              <FormControlLabel
                                value={"Pay per month"}
                                control={<Radio />}
                                label={localStorage.getItem("i18nextLng") === "en" ? "Pay per month" : "Betaal per maand"}
                                className='container__body-text'
                              />
                            </div>
                            <div className='container__body-radio' onClick={() => this.statusPublic("Pay per year")}>
                              <FormControlLabel value={"Pay per year"} control={<Radio />} label='Pay per year' className={"container__body-text"} />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className='mobility__cost'>
                    <div className='saveRow'>
                      <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                        {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                      </div>
                      <div className='error-message'>{this.state.errorMessage}</div>
                    </div>
                  </div>
                </>
              )}
              {this.state.publicType === "Bus/Tram" ? (
                <div className='mobility__cost'>
                  <div className='row'>
                    <div className='mobility__cost-content'>
                      {this.state.editPrice === true ? (
                        <>
                          <div className='text' id='publicCost' data-value={this.state.publicTransportPrice}>
                            €
                          </div>
                          <input
                            type='text'
                            value={this.state.publicTransportPrice && this.addCommas(this.removeNonNumeric(this.state.publicTransportPrice))}
                            onChange={(e) => this.setState({ publicTransportPrice: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                          />
                        </>
                      ) : (
                        <div className='text' id='publicCost' data-value={this.state.publicTransportPrice}>
                          €{this.state.publicTransportPrice && this.addCommas(this.removeNonNumeric(this.state.publicTransportPrice))}
                        </div>
                      )}
                      <div className='text award'>
                        {this.state.publicTransport === "Pay per month" && <Trans>companyType.month</Trans>}
                        {this.state.publicTransport === "Pay per quarter" && <Trans>companyType.quarter</Trans>}
                        {this.state.publicTransport === "Pay per year" && <Trans>companyType.year</Trans>}
                      </div>
                    </div>

                    {this.state.editPrice === false && (
                      <div className='btn btn--error' onClick={() => this.setState({ editPrice: true })}>
                        <Trans>establishmentFlow.override</Trans>
                      </div>
                    )}
                  </div>
                  <div className='saveRow'>
                    <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                      {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                    </div>
                    <div className='error-message'>{this.state.errorMessage}</div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 4 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>Taxi</div>
              <div className='container__body-box'>
                <div className='container__body-select'>
                  <CustomSelect
                    defaultText={"Taxi"}
                    optionsList={this.state.selectTaxi}
                    handleData={this.handleData}
                    type={"revenue"}
                    id={"taxiType"}
                  />
                </div>
              </div>
              <div className='mobility__cost'>
                {/*<div className="mobility__cost-header">Total Cost of Ownership</div>*/}
                <div className='row'>
                  <div className='mobility__cost-content'>
                    {this.state.editPrice === true ? (
                      <>
                        <div className='text' id='taxiCost' data-value={this.state.taxiPrice}>
                          €
                        </div>
                        <input
                          type='text'
                          value={this.addCommas(this.removeNonNumeric(this.state.taxiPrice))}
                          onChange={(e) => this.setState({ taxiPrice: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                        />
                      </>
                    ) : (
                      <div className='text' id='taxiCost' data-value={this.state.taxiPrice}>
                        €{this.addCommas(this.removeNonNumeric(this.state.taxiPrice))}
                      </div>
                    )}
                    <div className='text award'>
                      <Trans>companyType.month</Trans>
                    </div>
                  </div>
                  {this.state.editPrice === false && (
                    <div className='btn btn--error' onClick={() => this.setState({ editPrice: true })}>
                      <Trans>establishmentFlow.override</Trans>
                    </div>
                  )}
                </div>
                <div className='saveRow'>
                  <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                    {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                  </div>
                  <div className='error-message'>{this.state.errorMessage}</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 5 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>{localStorage.getItem("i18nextLng") === "en" ? "Bicycle" : "Fiets"}</div>
              <div className='container__body-box'>
                <div className='container__body-select'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Type of bicycle" : "Type fiets"}</p>
                  <CustomSelect
                    defaultText={"Regular bike"}
                    optionsList={this.state.selectBicycle}
                    handleData={this.handleData}
                    type={"revenue"}
                    id={"bicycleType"}
                  />
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Will you buy this bicycle, or lease it?" : "Koopt u deze fiets of leaset u hem?"}
                  </p>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      name='publicTransport'
                      defaultValue={this.state.defaultTypeBicycle}
                      value={this.state.bicycle}
                    >
                      <div className='structure__checkbox'>
                        <div className='container__body-radio' onClick={() => this.setState({ bicycle: "I will buy it" })}>
                          <FormControlLabel
                            value={"I will buy it"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I will buy it" : "ik zal het kopen"}
                            className={"container__body-text"}
                          />
                        </div>
                        <div className='container__body-radio' onClick={() => this.setState({ bicycle: "I will lease it" })}>
                          <FormControlLabel
                            value={"I will lease it"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I will lease it" : "Ik ga het leasen"}
                            className='container__body-text'
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Name this bicycle" : "Geef deze fiets een naam"}
                  </p>
                  <TextField label={""} variant={"outlined"} size={"small"} type={"text"} placeholder={"..."} name={"bicycleName"} />
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Initial purchase price" : "Initiële aankoopprijs"}
                  </p>
                  <TextField
                    InputProps={{
                      startAdornment: this.state.bicyclePrice ? (
                        <InputAdornment variant='standard' disableTypography position='start'>
                          €
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"text"}
                    placeholder={"€..."}
                    name={"bicyclePrice"}
                    value={this.state.bicyclePrice}
                    onChange={(e) => this.setState({ bicyclePrice: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                  />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Yearly maintenance" : "Jaarlijks onderhoud"}</p>
                  <TextField
                    InputProps={{
                      startAdornment: this.state.bicycleYearly ? (
                        <InputAdornment variant='standard' disableTypography position='start'>
                          €
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"text"}
                    placeholder={"€..."}
                    name={"bicycleYearly"}
                    value={this.state.bicycleYearly}
                    onChange={(e) => this.setState({ bicycleYearly: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                  />
                </div>
              </div>
              <div className='mobility__cost'>
                <div className='saveRow'>
                  <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                    {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                  </div>
                  <div className='error-message'>{this.state.errorMessage}</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 6 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>{localStorage.getItem("i18nextLng") === "en" ? "e-scooter" : "e-step"}</div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en"
                      ? "Will you buy this e-scooter, or lease it?"
                      : "Koopt u deze e-scooter of leaset u hem?"}
                  </p>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      name='publicTransport'
                      defaultValue={this.state.defaultTypeScooter}
                      value={this.state.scooter}
                    >
                      <div className='structure__checkbox'>
                        <div className='container__body-radio' onClick={() => this.setState({ scooter: "I will buy it" })}>
                          <FormControlLabel
                            value={"I will buy it"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I will buy it" : "ik zal het kopen"}
                            className={"container__body-text"}
                          />
                        </div>
                        <div className='container__body-radio' onClick={() => this.setState({ scooter: "I will lease it" })}>
                          <FormControlLabel
                            value={"I will lease it"}
                            control={<Radio />}
                            label={localStorage.getItem("i18nextLng") === "en" ? "I will lease it" : "Ik ga het leasen"}
                            className='container__body-text'
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Name this brand" : "Noem dit merk"}</p>
                  <TextField label={""} variant={"outlined"} size={"small"} type={"text"} placeholder={"..."} name={"scooterName"} />
                </div>
              </div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Initial purchase price" : "Initiële aankoopprijs"}
                  </p>
                  <TextField
                    InputProps={{
                      startAdornment: this.state.scooterPrice ? (
                        <InputAdornment variant='standard' disableTypography position='start'>
                          €
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"text"}
                    placeholder={"€..."}
                    name={"scooterPrice"}
                    value={this.state.scooterPrice}
                    onChange={(e) => this.setState({ scooterPrice: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                  />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Yearly maintenance" : "Jaarlijks onderhoud"}</p>
                  <TextField
                    InputProps={{
                      startAdornment: this.state.scooterYearly ? (
                        <InputAdornment variant='standard' disableTypography position='start'>
                          €
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"text"}
                    placeholder={"€..."}
                    name={"scooterYearly"}
                    value={this.state.scooterYearly}
                    onChange={(e) => this.setState({ scooterYearly: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                  />
                </div>
              </div>
              <div className='mobility__cost'>
                <div className='saveRow'>
                  <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                    {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                  </div>
                  <div className='error-message'>{this.state.errorMessage}</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.step === 7 ? (
            <div className='mobility__form'>
              <div className='mobility__form-header'>{localStorage.getItem("i18nextLng") === "en" ? "Other" : "Ander"}</div>
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    {localStorage.getItem("i18nextLng") === "en" ? "Name other mobility" : "Noem andere mobiliteit"}
                  </p>
                  <TextField label={""} variant={"outlined"} size={"small"} type={"text"} placeholder={"..."} name={"otherName"} />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>{localStorage.getItem("i18nextLng") === "en" ? "Amount" : "Hoeveelheid"}</p>
                  <TextField
                    InputProps={{
                      startAdornment: this.state.otherAmount ? (
                        <InputAdornment variant='standard' disableTypography position='start'>
                          €
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"text"}
                    placeholder={"€..."}
                    name={"otherAmount"}
                    value={this.state.otherAmount}
                    onChange={(e) => this.setState({ otherAmount: this.addCommas(this.removeNonNumeric(e.target.value)) })}
                  />
                </div>
              </div>
              <div className='mobility__cost'>
                <div className='saveRow'>
                  <div className='btn btn--primary' onClick={(e) => this.save(e)}>
                    {this.state.editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                  </div>
                  <div className='error-message'>{this.state.errorMessage}</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='container__sidebar'>
          <div className='container__sidebar-header'>
            <Trans>establishmentFlow.overview</Trans>
          </div>
          {this.state.data.map((data) => (
            <div className={data.id === this.state.editId ? "container__sidebar-box is-active" : "container__sidebar-box"}>
              <div className='row'>
                <img
                  alt=''
                  src={
                    data.transportType === "PUBLIC_TRANSPORT"
                      ? tram
                      : data.transportType === "MOTORCYCLE"
                      ? bike
                      : data.transportType === "BICYCLE"
                      ? bicycle
                      : data.transportType === "TAXI"
                      ? taxi
                      : data.transportType === "E_SCOOTER"
                      ? scooter
                      : data.transportType === "OTHER"
                      ? other
                      : car
                  }
                />
                <div className='box'>
                  <div className='text award'>{data.name}</div>
                  <div className='text'>{data.subtype}</div>
                </div>
              </div>
              <div className='row'>
                <div className='box'>
                  <div className='text light'>{data.costs[0] && data.costs[0].description}</div>
                </div>
                <div className='box'>
                  <div className='text'>
                    {data.costs[0] && data.costs[0].description === "Pay per year"
                      ? "Cost per year"
                      : data.costs[0] && data.costs[0].description === "Pay per quarter"
                      ? "Cost per quarter"
                      : "Cost per month"}
                  </div>
                  <div className='text award'>
                    €
                    {data.costs.length > 3
                      ? this.addCommas(
                          this.removeNonNumeric(data.costs[0].amount + data.costs[1].amount + data.costs[2].amount + data.costs[3].amount)
                        )
                      : data.costs.length > 2
                      ? this.addCommas(this.removeNonNumeric(data.costs[0].amount + data.costs[1].amount + data.costs[2].amount))
                      : data.costs.length > 2
                      ? this.addCommas(this.removeNonNumeric(data.costs[0].amount + data.costs[1].amount))
                      : data.costs[0] && this.addCommas(this.removeNonNumeric(data.costs[0].amount))}
                  </div>
                </div>
              </div>
              {data.isSurveyAnswer !== true ? (
                <div className='events'>
                  <div
                    className='edit'
                    onClick={() =>
                      this.edit(
                        data.id,
                        data.transportType,
                        data.name,
                        data.subtype,
                        data.expensiveScale,
                        data.costs[0] && data.costs[0].id,
                        data.costs[0] && data.costs[0].amount,
                        data.costs[0] && data.costs[0].description,
                        data.purchaseDate &&
                          transformDate(data.purchaseDate.substring(8, 10), data.purchaseDate.substring(5, 7), data.purchaseDate.substring(0, 4)),
                        data.costs[1] && data.costs[1].id,
                        data.costs[1] && data.costs[1].amount
                      )
                    }
                  >
                    {editAdminIcon}
                  </div>
                  <div className='close' onClick={(e) => this.confirmRemove(data.id)}>
                    {closeList}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        <Confirm
          active={this.state.confirm}
          handleConfirm={this.handleConfirm}
          handleRemove={this.remove}
          message={
            localStorage.getItem("i18nextLng") === "en"
              ? "Are you sure you want to delete transport?"
              : "Weet u zeker dat u het transport wilt verwijderen?"
          }
          only={false}
        />
      </div>
    );
  }
}

export default Mobility;
