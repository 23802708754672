import React, { useEffect, useState } from "react";
import { usePDF, Document, Page, Font, View, Text, StyleSheet, Image, Svg, Circle } from "@react-pdf/renderer";
import Logo from "../../assets/icon/logo.png";
import source from "../../assets/fonts/Inter-Bold.ttf";
import avatar from "../../assets/avatar.png";
import notary from "../../assets/notary.png";
import grow from "../../assets/icon/grow.png";
import update from "../../assets/update.png";
import arrowToRight from "../../assets/arrowToRight.png";
import { Trans } from "react-i18next";
import { adminInfoIcon, download, fullScreen, print } from "../login/loginConstants";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { transformDate } from "../admin/adminEstablishment";
import { convertToBase64 } from "../admin/adminFiles";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import loadingFile from "../../assets/loading.svg";

// Register font
Font.register({ family: "Inter", src: source });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  pageStart: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  pageCenter: {
    alignItems: "center",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    backgroundColor: "#4A5BCF",
    height: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
  },
  headerText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 600,
  },
  logo: {
    objectFit: "contain",
    width: 225,
    height: 150,
  },
  logoText: {
    fontSize: 24,
    color: "#0A004B",
    width: 250,
  },
  bold: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  footer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerBox: {
    backgroundColor: "#4A5BCF",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "73%",
    padding: "0 20px",
  },
  footerText: {
    color: "#FFF",
    fontSize: 10,
    fontWeight: 600,
  },
  footerTextBlack: {
    color: "#000",
    marginBottom: 18,
    marginLeft: 20,
  },
  footerLogo: {
    width: 128,
    height: 32,
    objectFit: "contain",
    marginRight: 12,
  },
  textColorPrimary: {
    color: "#0A004B",
  },
  signature: {
    position: "absolute",
    left: 0,
    bottom: 60,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  signatureText: {
    color: "#000",
    fontSize: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "row",
  },
  marginBottom30: {
    marginBottom: 30,
  },
  marginTop15: {
    marginTop: 15,
  },
  widthFull: {
    width: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
  width80: {
    width: "80px",
  },
  width120: {
    width: "120px",
  },
  margin32: {
    margin: "0 32px",
  },
  marginRight75: {
    marginRight: 75,
    width: 130,
  },
  tableHeader: {
    marginTop: 70,
    marginLeft: 40,
    marginBottom: 10,
    fontSize: 20,
  },
  marginLeft5: {
    marginLeft: 5,
  },
  tableSubHeader: {
    fontSize: 16,
    marginLeft: 40,
  },
  tableCost: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: 520,
    height: 188,
    border: "1px solid #CFDBD5",
    borderRadius: 9,
    position: "relative",
    marginLeft: 40,
    marginTop: 15,
  },
  tableText: {
    fontSize: 16,
    fontWeight: 600,
    margin: "10px 70px",
  },
  table: {
    border: "1px solid #CFDBD5",
    padding: "8px 12px",
    marginBottom: 5,
    marginLeft: 40,
    borderRadius: 6,
    marginTop: 5,
    marginRight: 40,
  },
  loansPreview: {
    padding: "0 18px",
    marginBottom: 0,
    marginLeft: 40,
    marginTop: 10,
    marginRight: 40,
    display: "flex",
    flexDirection: "row",
  },
  marginBottom10: {
    marginBottom: 6,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    height: 36,
    width: 36,
    borderRadius: 36,
  },
  marginRight38: {
    marginRight: 38,
  },
  tableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 225,
    flexDirection: "row",
    borderBottom: "1px solid #CFDBD5",
    padding: "8px 0",
  },
  tableRowOut: {
    border: 0,
  },
  tableDescription: {
    fontSize: 10,
    height: 16,
  },
  height85: {
    height: "auto",
  },
  tableLi: {
    marginLeft: 10,
  },
  tableDot: {
    height: 3,
    width: 3,
    backgroundColor: "#000",
    borderRadius: 3,
    marginBottom: 4,
  },
  margin3: {
    margin: "3px 0",
  },
  visualText: {
    color: "#FFF",
    fontSize: 10,
  },
  visual: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 28,
  },
  visualBlue: {
    backgroundColor: "#0052FF",
  },
  visualPurple: {
    backgroundColor: "#9800FF",
  },
  visualPing: {
    backgroundColor: "#F20089",
  },
  visualMargin: {
    marginLeft: 20,
  },
});

export const OutputFlow = (props) => {
  const [today, setToday] = useState("");
  const [month] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);

  useEffect(() => {
    const date = new Date();
    const today = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }`;
    setToday(today);
  });

  const addCommas = (num) => {
    if (num && num.length > 1) {
      if (num[0] === "0") {
        num = num.slice(1);
      }
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const removeNonNumeric = (num) => num !== null && num !== undefined && num.toString().replace(/[^0-9]/g, "");

  const upload = (file) => {
    let body = {
      companyId: parseInt(`${sessionStorage.getItem("userID")}`),
      documentType: "FINANCIAL_PLAN",
      file: `[Financial_plan_${sessionStorage.getItem("userID")}.pdf]${file}`,
    };
    Connect(`${Endpoint.uploadAdminFiles}`, "POST", body).then((response) => {
      props.handleFiles();
    });
  };

  const uploadFile = async (file) => {
    if (file) {
      const base64 = await convertToBase64(file);
      upload(base64);
    }
  };

  const createDocument = () => {
    return (
      <Document>
        <Page size='A4' style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          {props.LogoCompany && <Image style={styles.logo} source={props.LogoCompany} />}
          <Text style={styles.logoText}>Establishment plan of</Text>
          <Text style={[styles.logoText, styles.bold]}>
            {props.companyData &&
              props.companyData.activityDetail &&
              props.companyData.activityDetail.company.name &&
              props.companyData.activityDetail.company &&
              props.companyData.activityDetail.company.name}
          </Text>
          <View style={styles.signature}>
            <View style={[styles.box, styles.margin32]}>
              <Text style={styles.signatureText}>/// Report generated on {today}</Text>
            </View>
            <View style={[styles.box, styles.margin32]}>
              <Text style={styles.signatureText}>Contacts:</Text>
              <Text style={styles.signatureText}>
                {props.companyData && props.companyData.user.name} {props.companyData && props.companyData.user.surname}
              </Text>
              <Text style={styles.signatureText}>{props.companyData && props.companyData.user.mail}</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Image style={styles.footerLogo} source={Logo} />
            <View style={styles.footerBox}>
              <Text style={styles.footerText}>BE0686.793.751</Text>
              <Text style={styles.footerText}>-</Text>
              <Text style={styles.footerText}>Terlinden 178, 1785 Merchtem</Text>
              <Text style={styles.footerText}>-</Text>
              <Text style={styles.footerText}>support@bizantium.be</Text>
            </View>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          <Text style={[styles.tableHeader, styles.bold]}>Table of contents</Text>
          <View style={styles.row}>
            <Text style={styles.tableText}>General information</Text>
            <Text style={styles.tableText}>1</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.tableText}>Company activities</Text>
            <Text style={styles.tableText}>2</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.tableText}>Sources of funding</Text>
            <Text style={styles.tableText}>3</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.tableText}>Revenue streams</Text>
            <Text style={styles.tableText}>4</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.tableText}>Cost structure</Text>
            <Text style={styles.tableText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.tableText}>Loans</Text>
            <Text style={styles.tableText}>6</Text>
          </View>
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          <View style={[styles.row, styles.pageStart]}>
            <Text style={[styles.tableHeader, styles.bold]}>General information</Text>
            <Text style={[styles.tableHeader, styles.marginLeft5]}>- {props.companyData && props.companyData.name && props.companyData.name}</Text>
          </View>
          <Text style={[styles.tableSubHeader, styles.bold]}>Company information</Text>
          <View style={[styles.table, styles.flexRow]}>
            <View style={[styles.box, styles.marginRight38]}>
              <View style={styles.tableRow}>
                <Text style={styles.tableDescription}>Common name</Text>
                <Text style={[styles.tableDescription, styles.bold]}>{props.companyData && props.companyData.name && props.companyData.name}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableDescription}>Company type</Text>
                <Text style={[styles.tableDescription, styles.bold]}>
                  {props.companyData &&
                  props.companyData.activityDetail &&
                  props.companyData.activityDetail.company &&
                  props.companyData.activityDetail.company.legalForm === "LIMITED_COMPANY"
                    ? "Limited company"
                    : "Sole proprietorship"}
                </Text>
              </View>
              <View style={[styles.tableRow, styles.tableRowOut]}>
                <Text style={styles.tableDescription}>Nationality</Text>
                <Text style={[styles.tableDescription, styles.bold]}>Belgian</Text>
              </View>
            </View>
            <View style={[styles.box]}>
              <View style={styles.tableRow}>
                <Text style={styles.tableDescription}>Registered office</Text>
                <Text style={[styles.tableDescription, styles.bold]}>
                  {props.companyData && props.companyData.registrationCity && props.companyData.registrationCity}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableDescription}>Operating address</Text>
                <Text style={[styles.tableDescription, styles.bold]}>
                  {props.companyData && props.companyData.operationStreet}{" "}
                  {props.companyData && props.companyData.operationHouseNumber && props.companyData.operationHouseNumber}
                </Text>
              </View>
            </View>
          </View>
          {props.companyData &&
            props.companyData.activityDetail &&
            props.companyData.activityDetail.company &&
            props.companyData.activityDetail.company.legalForm === "LIMITED_COMPANY" && (
              <>
                <Text style={[styles.tableSubHeader, styles.bold]}>Shareholders</Text>
                <View style={[styles.row, { padding: "0 40px", margin: "5px 0" }]}>
                  {props.companyData &&
                    props.companyData.shareholders &&
                    props.companyData.shareholders.map((data, index) =>
                      index < 2 ? (
                        <View
                          style={{
                            border: "1px solid #CFDBD5",
                            padding: 15,
                            width: 253,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Image style={styles.avatar} source={avatar} />
                          <View style={[styles.box, { marginLeft: 15 }]}>
                            <Text style={[styles.tableDescription, styles.bold]}>{data.fullName}</Text>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: 168,
                              }}
                            >
                              <Text style={[styles.tableDescription]}>Residing in</Text>
                              <Text style={[styles.tableDescription, styles.bold]}>
                                {data.street} {data.houseNumber}
                              </Text>
                            </View>
                          </View>
                        </View>
                      ) : (
                        <></>
                      )
                    )}
                </View>
              </>
            )}
          <View style={[styles.row, { padding: "0 40px", margin: "5px 0" }]}>
            {props.companyData &&
              props.companyData.shareholders &&
              props.companyData.shareholders.map((data, index) =>
                index > 1 ? (
                  <View style={{ border: "1px solid #CFDBD5", padding: "10px 15px", width: 253, display: "flex", flexDirection: "row" }}>
                    <Image style={styles.avatar} source={avatar} />
                    <View style={[styles.box, { marginLeft: 15 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>{data.fullName}</Text>
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 168 }}>
                        <Text style={[styles.tableDescription]}>Residing in</Text>
                        <Text style={[styles.tableDescription, styles.bold]}>
                          {data.street} {data.houseNumber}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )
              )}
          </View>
          <Text style={[styles.tableSubHeader, styles.bold]}>Identity governing body</Text>
          <View style={[styles.row, { padding: "0 40px", margin: "5px 0" }]}>
            {props.companyData &&
              props.companyData.shareholders &&
              props.companyData.shareholders.map((data, index) =>
                index < 2 ? (
                  <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 253, display: "flex", flexDirection: "row" }}>
                    <Image style={styles.avatar} source={avatar} />
                    <View style={[styles.box, { marginLeft: 15 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>{data.fullName}</Text>
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 168 }}>
                        <Text style={[styles.tableDescription]}>Residing in</Text>
                        <Text style={[styles.tableDescription, styles.bold]}>
                          {data.street} {data.houseNumber}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )
              )}
          </View>
          <View style={[styles.row, { padding: "0 40px", margin: "5px 0" }]}>
            {props.companyData &&
              props.companyData.shareholders &&
              props.companyData.shareholders.map((data, index) =>
                index > 1 ? (
                  <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 253, display: "flex", flexDirection: "row" }}>
                    <Image style={styles.avatar} source={avatar} />
                    <View style={[styles.box, { marginLeft: 15 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>{data.fullName}</Text>
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 168 }}>
                        <Text style={[styles.tableDescription]}>Residing in</Text>
                        <Text style={[styles.tableDescription, styles.bold]}>
                          {data.street} {data.houseNumber}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )
              )}
          </View>
          <Text style={[styles.tableSubHeader, styles.bold]}>Notary</Text>
          <View style={[styles.row, { border: "1px solid #CFDBD5", padding: 15, width: 516, marginLeft: 40, marginTop: 5, marginBottom: 10 }]}>
            <View style={[styles.box, { flexDirection: "row", alignItems: "center" }]}>
              <Image style={{ width: 88, height: 33 }} source={notary} />
              <Text style={[styles.tableDescription, styles.bold, { marginLeft: 15 }]}>Podevyn & Tack BVBA</Text>
            </View>
            <View style={[styles.box, { flexDirection: "row", alignItems: "center" }]}>
              <Text style={[styles.tableDescription, { marginRight: 20 }]}>Located in</Text>
              <Text style={[styles.tableDescription, styles.bold]}>Spiegellaan 27, 1785 Merchtem</Text>
            </View>
          </View>
          <Text style={[styles.tableSubHeader, styles.bold]}>Financial year & General assembly</Text>
          <View style={[styles.row, { padding: "0 40px", margin: "10px 0" }]}>
            <View style={{ border: "1px solid #CFDBD5", padding: "5px 15px", width: 253, display: "flex", flexDirection: "row" }}>
              <View style={styles.row}>
                <View style={styles.box}>
                  <Text style={[styles.tableDescription]}></Text>
                  <Text style={[styles.tableDescription]}>Period 1</Text>
                  <Text style={[styles.tableDescription]}>Period 2</Text>
                  <Text style={[styles.tableDescription]}>Period 3</Text>
                </View>
                <View style={styles.box}>
                  <Text style={[styles.tableDescription]}>From</Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData &&
                      props.financialData.periodOneFrom &&
                      transformDate(
                        props.financialData.periodOneFrom.substring(8, 10),
                        props.financialData.periodOneFrom.substring(5, 7),
                        props.financialData.periodOneFrom.substring(0, 4)
                      )}
                  </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData &&
                      props.financialData.periodTwoFrom &&
                      transformDate(
                        props.financialData.periodTwoFrom.substring(8, 10),
                        props.financialData.periodTwoFrom.substring(5, 7),
                        props.financialData.periodTwoFrom.substring(0, 4)
                      )}
                  </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData &&
                      props.financialData.periodThreeFrom &&
                      transformDate(
                        props.financialData.periodThreeFrom.substring(8, 10),
                        props.financialData.periodThreeFrom.substring(5, 7),
                        props.financialData.periodThreeFrom.substring(0, 4)
                      )}
                  </Text>
                </View>
                <View style={styles.box}>
                  <Text style={[styles.tableDescription]}>To</Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData &&
                      props.financialData.periodOneTo &&
                      transformDate(
                        props.financialData.periodOneTo.substring(8, 10),
                        props.financialData.periodOneTo.substring(5, 7),
                        props.financialData.periodOneTo.substring(0, 4)
                      )}
                  </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData &&
                      props.financialData.periodTwoTo &&
                      transformDate(
                        props.financialData.periodTwoTo.substring(8, 10),
                        props.financialData.periodTwoTo.substring(5, 7),
                        props.financialData.periodTwoTo.substring(0, 4)
                      )}
                  </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData &&
                      props.financialData.periodThreeTo &&
                      transformDate(
                        props.financialData.periodThreeTo.substring(8, 10),
                        props.financialData.periodThreeTo.substring(5, 7),
                        props.financialData.periodThreeTo.substring(0, 4)
                      )}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ border: "1px solid #CFDBD5", padding: "5px 15px", width: 253, display: "flex", flexDirection: "row" }}>
              <View style={styles.box}>
                <Text style={[styles.tableDescription, styles.bold]}>General assembly</Text>
                <View style={styles.row}>
                  <Text style={[styles.tableDescription, { marginRight: 15 }]}>When: </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData && props.financialData.genAssemblyWhen && props.financialData.genAssemblyWhen}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.tableDescription, { marginRight: 15 }]}>Time: </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData && props.financialData.genAssemblyTime && props.financialData.genAssemblyTime}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.tableDescription, { marginRight: 15 }]}>First: </Text>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    {props.financialData && props.financialData.genAssemblyFirst && props.financialData.genAssemblyFirst}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          <Text style={[styles.tableHeader, styles.bold]}>Company activities</Text>
          <Text style={[styles.tableSubHeader, styles.bold, { marginTop: 10 }]}>Executive summary</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableDescription}>Sector</Text>
              <Text style={[styles.tableDescription, styles.bold]}>{props.activities && props.activities.sector && props.activities.sector}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableDescription}>Type of activity</Text>
              <Text style={[styles.tableDescription, styles.bold]}>
                {props.activities && props.activities.activityType && props.activities.activityType}
              </Text>
            </View>
            <View style={[styles.tableRow, styles.tableRowOut, { marginTop: 20 }]}>
              <Text style={styles.tableDescription}>Occupation</Text>
              <Text style={[styles.tableDescription, styles.bold]}>
                {props.form && props.form.occupation && props.form.occupation[0] && props.form.occupation[0].text}
              </Text>
            </View>
          </View>
          <View style={[styles.row, styles.pageStart, { marginTop: 25 }]}>
            <Text style={[styles.tableSubHeader]}>RVO Consultancy</Text>
            <Text style={[styles.tableSubHeader, styles.bold, styles.marginLeft5]}>has the following main activities</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.row, styles.pageStart, styles.pageCenter, styles.margin3]}>
              <View style={styles.tableDot} />
              <Text style={[styles.tableDescription, styles.tableLi]}>Other information technology and computer services;</Text>
            </View>
          </View>
          <Text style={[styles.tableSubHeader, styles.bold, { marginTop: 25 }]}>Business description</Text>
          <View style={styles.table}>
            <Text style={[styles.tableDescription, styles.height85]}>
              {props.activities && props.activities.businessDescription && props.activities.businessDescription}
            </Text>
          </View>
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          {props.companyData &&
          props.companyData.activityDetail &&
          props.companyData.activityDetail.company &&
          props.companyData.activityDetail.company.legalForm === "LIMITED_COMPANY" ? (
            <>
              <Text style={[styles.tableHeader, styles.bold]}>Sources of funding</Text>
              <Text style={[styles.tableSubHeader, styles.bold]}>Overview</Text>
              <View style={[styles.table, styles.flexRow]}>
                <View style={[styles.box, styles.marginRight38]}>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableDescription}>Starting capital</Text>
                    <Text style={[styles.tableDescription, styles.bold]}>
                      €{props.companyData && props.companyData.startingCapital && addCommas(removeNonNumeric(props.companyData.startingCapital))}
                    </Text>
                  </View>
                  <View style={[styles.tableRow, styles.tableRowOut]}>
                    <Text style={styles.tableDescription}>R/C</Text>
                    <Text style={[styles.tableDescription, styles.bold]}>/</Text>
                  </View>
                </View>
                <View style={styles.box}>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableDescription}>Bank financing</Text>
                    <Text style={[styles.tableDescription, styles.bold]}>/</Text>
                  </View>
                  <View style={[styles.tableRow, styles.tableRowOut]}>
                    <Text style={styles.tableDescription}>Cash in account</Text>
                    <Text style={[styles.tableDescription, styles.bold]}>/</Text>
                  </View>
                </View>
              </View>
              <Text style={[styles.tableSubHeader, styles.bold]}>Composition of starting funds</Text>
              {props.companyData &&
                props.companyData.shareholders &&
                props.companyData.shareholders.map((data) => (
                  <View style={[styles.table, styles.flexRow, styles.marginBottom10]}>
                    <Image style={styles.avatar} source={avatar} />
                    <View style={[styles.box, styles.margin32, { width: 158 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>{data.fullName}</Text>
                      <Text style={styles.tableDescription}>
                        {data.sharesAmount} shares - {data.total}% of total
                      </Text>
                    </View>
                    <View style={[styles.box, { width: 90 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>Per share</Text>
                      <Text style={styles.tableDescription}>
                        €
                        {(
                          ((data.total / 100) *
                            parseInt(
                              props.companyData &&
                                props.companyData.startingCapital &&
                                addCommas(removeNonNumeric(props.companyData.startingCapital)).replaceAll(".", "")
                            )) /
                          data.sharesAmount
                        ).toFixed(0)}
                      </Text>
                    </View>
                    <View style={[styles.box, { width: 70 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>Total</Text>
                      <Text style={styles.tableDescription}>
                        €
                        {(
                          (data.total / 100) *
                          parseInt(
                            props.companyData &&
                              props.companyData.startingCapital &&
                              addCommas(removeNonNumeric(props.companyData.startingCapital)).replaceAll(".", "")
                          )
                        ).toFixed(0)}
                      </Text>
                    </View>
                    <View style={[styles.box, { width: 70 }]}>
                      <Text style={[styles.tableDescription, styles.bold]}>Paid-up</Text>
                      <Text style={styles.tableDescription}>
                        €
                        {(
                          (data.total / 100) *
                          parseInt(
                            props.companyData &&
                              props.companyData.startingCapital &&
                              addCommas(removeNonNumeric(props.companyData.startingCapital)).replaceAll(".", "")
                          )
                        ).toFixed(0)}
                      </Text>
                    </View>
                  </View>
                ))}
            </>
          ) : (
            <Text style={[styles.tableHeader, styles.bold]}></Text>
          )}
          <Text style={[styles.tableSubHeader, styles.bold]}>Fixed assets</Text>
          {props.companyData &&
            props.companyData.assets &&
            props.companyData.assets.map((data) =>
              data.isFixed === true ? (
                <View style={[styles.table, styles.flexRow]}>
                  <View style={[styles.box, styles.marginRight38]}>
                    <View style={styles.tableRow}>
                      fit
                      <Text style={styles.tableDescription}>Name</Text>
                      <Text style={[styles.tableDescription, styles.bold]}>Fixed asset: {data.name}</Text>
                    </View>
                    <View style={[styles.tableRow, styles.tableRowOut]}>
                      <Text style={styles.tableDescription}>Starting date</Text>
                      <Text style={[styles.tableDescription, styles.bold]}>
                        {data.startDate &&
                          transformDate(data.startDate.substring(8, 10), data.startDate.substring(5, 7), data.startDate.substring(0, 4))}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.box}>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableDescription}>Useful life</Text>
                      <Text style={[styles.tableDescription, styles.bold]}>{data.lifeMonths} months</Text>
                    </View>
                    <View style={[styles.tableRow, styles.tableRowOut]}>
                      <Text style={styles.tableDescription}>Monthly depreciation</Text>
                      <Text style={[styles.tableDescription, styles.bold]}>€{addCommas(removeNonNumeric(data.value))}</Text>
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )
            )}
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          <Text style={[styles.tableHeader, styles.bold]}>Revenue streams</Text>
          {props.companyData && props.companyData.revenueStreams ? (
            <>
              <View style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Text style={[styles.tableSubHeader, styles.bold, { width: 230 }]}>Operating income</Text>
                <View style={{ display: "flex", flexDirection: "column", width: 90 }}>
                  <Text style={{ fontSize: 9, marginBottom: 1 }}>
                    {props.financialData &&
                      props.financialData.periodOneFrom &&
                      transformDate(
                        props.financialData.periodOneFrom.substring(8, 10),
                        props.financialData.periodOneFrom.substring(5, 7),
                        props.financialData.periodOneFrom.substring(0, 4)
                      )}
                  </Text>
                  <Text style={{ fontSize: 9 }}>
                    {props.financialData &&
                      props.financialData.periodOneTo &&
                      transformDate(
                        props.financialData.periodOneTo.substring(8, 10),
                        props.financialData.periodOneTo.substring(5, 7),
                        props.financialData.periodOneTo.substring(0, 4)
                      )}
                  </Text>
                </View>
                <View style={{ display: "flex", flexDirection: "column", width: 90 }}>
                  <Text style={{ fontSize: 9, marginBottom: 1 }}>
                    {props.financialData &&
                      props.financialData.periodTwoFrom &&
                      transformDate(
                        props.financialData.periodTwoFrom.substring(8, 10),
                        props.financialData.periodTwoFrom.substring(5, 7),
                        props.financialData.periodTwoFrom.substring(0, 4)
                      )}
                  </Text>
                  <Text style={{ fontSize: 9 }}>
                    {props.financialData &&
                      props.financialData.periodTwoTo &&
                      transformDate(
                        props.financialData.periodTwoTo.substring(8, 10),
                        props.financialData.periodTwoTo.substring(5, 7),
                        props.financialData.periodTwoTo.substring(0, 4)
                      )}
                  </Text>
                </View>
                <View style={{ display: "flex", flexDirection: "column", width: 90 }}>
                  <Text style={{ fontSize: 9, marginBottom: 1 }}>
                    {props.financialData &&
                      props.financialData.periodThreeFrom &&
                      transformDate(
                        props.financialData.periodThreeFrom.substring(8, 10),
                        props.financialData.periodThreeFrom.substring(5, 7),
                        props.financialData.periodThreeFrom.substring(0, 4)
                      )}
                  </Text>
                  <Text style={{ fontSize: 9 }}>
                    {props.financialData &&
                      props.financialData.periodThreeTo &&
                      transformDate(
                        props.financialData.periodThreeTo.substring(8, 10),
                        props.financialData.periodThreeTo.substring(5, 7),
                        props.financialData.periodThreeTo.substring(0, 4)
                      )}
                  </Text>
                </View>
              </View>
              {props.companyData &&
                props.companyData.revenueStreams &&
                props.companyData.revenueStreams.map((data, index) =>
                  index < 3 ? (
                    <View style={[styles.table, { borderRadius: 15, marginBottom: 10 }]}>
                      <View style={[styles.tableRow, styles.widthFull, { padding: 0 }]}>
                        <View style={[styles.box, { width: 220 }]}>
                          <Text style={[styles.tableDescription, styles.bold]}>
                            {data.revenueStreamType && data.revenueStreamType === "SIMPLE"
                              ? `Simple Revenue: `
                              : data.revenueStreamType === "SERVICE"
                              ? "Service Revenue: "
                              : data.revenueStreamType === "TRANSACTIONAL"
                              ? "Transactional revenue: "
                              : data.revenueStreamType === "SELLING_GOODS"
                              ? "Selling goods: "
                              : data.revenueStreamType === "ONE_OFF"
                              ? "One-off revenue: "
                              : ""}
                            {data.name && data.name}
                          </Text>
                        </View>
                        <View style={[styles.box, { width: 90 }]}>
                          <Text style={[styles.tableDescription, styles.bold]}>FY 2024</Text>
                        </View>
                        <View style={[styles.box, { width: 90 }]}>
                          <Text style={[styles.tableDescription, styles.bold]}>FY 2025</Text>
                        </View>
                        <View style={[styles.box, { width: 90 }]}>
                          <Text style={[styles.tableDescription, styles.bold]}>FY 2026</Text>
                        </View>
                      </View>
                      <View style={[styles.tableRow, styles.tableRowOut, styles.widthFull]}>
                        <View style={[styles.box, { width: 220 }]}>
                          <Text style={[styles.tableDescription, styles.bold]}>Description</Text>
                          <Text style={styles.tableDescription}>{data.description && data.description}</Text>
                        </View>
                        <View style={[styles.box, { width: 90 }]}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 10,
                              minHeight: 18,
                            }}
                          ></View>
                          <Text style={[styles.tableDescription, styles.bold]}>
                            €{data.revenueYearOne !== null ? addCommas(removeNonNumeric(data.revenueYearOne)) : 0}
                          </Text>
                        </View>
                        <View style={[styles.box, { width: 90 }]}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 10,
                              minHeight: 18,
                            }}
                          >
                            {data.varianceNextYear !== null && (
                              <>
                                <Image source={grow} style={{ width: 14, height: 8, marginRight: 7 }} />
                                <Text
                                  style={{
                                    fontFamily: "Inter",
                                    fontSize: 10,
                                  }}
                                >
                                  +{data.varianceNextYear}%
                                </Text>
                              </>
                            )}
                          </View>
                          <Text style={[styles.tableDescription, styles.bold]}>
                            €{data.revenueYearTwo !== null ? addCommas(removeNonNumeric(data.revenueYearTwo)) : 0}
                          </Text>
                        </View>
                        <View style={[styles.box, { width: 90 }]}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 10,
                              minHeight: 18,
                            }}
                          >
                            {data.varianceThirdYear !== null && (
                              <>
                                <Image source={grow} style={{ width: 14, height: 8, marginRight: 7 }} />
                                <Text
                                  style={{
                                    fontFamily: "Inter",
                                    fontSize: 10,
                                  }}
                                >
                                  +{data.varianceThirdYear}%
                                </Text>
                              </>
                            )}
                          </View>
                          <Text style={[styles.tableDescription, styles.bold]}>
                            €{data.revenueYearThree !== null ? addCommas(removeNonNumeric(data.revenueYearThree)) : 0}
                          </Text>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )
                )}
              <View style={{ display: "flex", alignItems: "flex-start", flexDirection: "row", justifyContent: "flex-end", width: 544 }}>
                <Text style={[styles.tableSubHeader, styles.bold, { marginBottom: 5 }]}>Total revenue</Text>
                <View style={[styles.box, { borderTop: "2px solid #0A004B", width: 90, marginLeft: 30, paddingTop: 10 }]}>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    €
                    {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 1
                      ? addCommas(removeNonNumeric(props.companyData.revenueStreams[0].revenueYearOne))
                      : props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 2
                      ? addCommas(
                          removeNonNumeric(props.companyData.revenueStreams[0].revenueYearOne + props.companyData.revenueStreams[1].revenueYearOne)
                        )
                      : props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 3
                      ? addCommas(
                          removeNonNumeric(
                            props.companyData.revenueStreams[0].revenueYearOne +
                              props.companyData.revenueStreams[1].revenueYearOne +
                              props.companyData.revenueStreams[2].revenueYearOne
                          )
                        )
                      : 0}
                  </Text>
                </View>
                <View style={[styles.box, { borderTop: "2px solid #0A004B", width: 90, paddingTop: 10 }]}>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    €
                    {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 1
                      ? addCommas(removeNonNumeric(props.companyData.revenueStreams[0].revenueYearTwo))
                      : props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 2
                      ? addCommas(
                          removeNonNumeric(props.companyData.revenueStreams[0].revenueYearTwo + props.companyData.revenueStreams[1].revenueYearTwo)
                        )
                      : props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 3
                      ? addCommas(
                          removeNonNumeric(
                            props.companyData.revenueStreams[0].revenueYearTwo +
                              props.companyData.revenueStreams[1].revenueYearTwo +
                              props.companyData.revenueStreams[2].revenueYearTwo
                          )
                        )
                      : 0}
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 10,
                      minHeight: 18,
                    }}
                  >
                    <Image source={grow} style={{ width: 14, height: 8, marginRight: 7 }} />
                    <Text
                      style={{
                        fontFamily: "Inter",
                        fontSize: 10,
                      }}
                    >
                      +
                      {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 1
                        ? props.companyData.revenueStreams[0].varianceNextYear
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 2 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear !== null
                        ? ((props.companyData.revenueStreams[0].varianceNextYear + props.companyData.revenueStreams[1].varianceNextYear) / 2).toFixed(
                            0
                          )
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 2 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear === null
                        ? props.companyData.revenueStreams[0].varianceNextYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 2 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear !== null
                        ? props.companyData.revenueStreams[1].varianceNextYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear !== null
                        ? (
                            (props.companyData.revenueStreams[0].varianceNextYear +
                              props.companyData.revenueStreams[1].varianceNextYear +
                              props.companyData.revenueStreams[2].varianceNextYear) /
                            3
                          ).toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear === null
                        ? ((props.companyData.revenueStreams[0].varianceNextYear + props.companyData.revenueStreams[1].varianceNextYear) / 2).toFixed(
                            0
                          )
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear === null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear !== null
                        ? ((props.companyData.revenueStreams[0].varianceNextYear + props.companyData.revenueStreams[2].varianceNextYear) / 2).toFixed(
                            0
                          )
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear !== null
                        ? ((props.companyData.revenueStreams[1].varianceNextYear + props.companyData.revenueStreams[2].varianceNextYear) / 2).toFixed(
                            0
                          )
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear === null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear === null
                        ? props.companyData.revenueStreams[0].varianceNextYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear === null
                        ? props.companyData.revenueStreams[1].varianceNextYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceNextYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNextYear === null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceNextYear !== null
                        ? props.companyData.revenueStreams[2].varianceNextYear.toFixed(0)
                        : 0}
                      %
                    </Text>
                  </View>
                </View>
                <View style={[styles.box, { borderTop: "2px solid #0A004B", width: 90, paddingTop: 10 }]}>
                  <Text style={[styles.tableDescription, styles.bold]}>
                    €
                    {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 1
                      ? addCommas(removeNonNumeric(props.companyData.revenueStreams[0].revenueYearThree))
                      : props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 2
                      ? addCommas(
                          removeNonNumeric(
                            props.companyData.revenueStreams[0].revenueYearThree + props.companyData.revenueStreams[1].revenueYearThree
                          )
                        )
                      : props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 3
                      ? addCommas(
                          removeNonNumeric(
                            props.companyData.revenueStreams[0].revenueYearThree +
                              props.companyData.revenueStreams[1].revenueYearThree +
                              props.companyData.revenueStreams[2].revenueYearThree
                          )
                        )
                      : 0}
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 10,
                      minHeight: 18,
                    }}
                  >
                    <Image source={grow} style={{ width: 14, height: 8, marginRight: 7 }} />
                    <Text
                      style={{
                        fontFamily: "Inter",
                        fontSize: 10,
                      }}
                    >
                      +
                      {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length === 1
                        ? props.companyData.revenueStreams[0].varianceThirdYear
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 2 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear !== null
                        ? (
                            (props.companyData.revenueStreams[0].varianceThirdYear + props.companyData.revenueStreams[1].varianceThirdYear) /
                            2
                          ).toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 2 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear === null
                        ? props.companyData.revenueStreams[0].varianceThirdYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 2 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear !== null
                        ? props.companyData.revenueStreams[1].varianceThirdYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear !== null
                        ? (
                            (props.companyData.revenueStreams[0].varianceThirdYear +
                              props.companyData.revenueStreams[1].varianceThirdYear +
                              props.companyData.revenueStreams[2].varianceThirdYear) /
                            3
                          ).toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear === null
                        ? (
                            (props.companyData.revenueStreams[0].varianceThirdYear + props.companyData.revenueStreams[1].varianceThirdYear) /
                            2
                          ).toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear === null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear !== null
                        ? (
                            (props.companyData.revenueStreams[0].varianceThirdYear + props.companyData.revenueStreams[2].varianceThirdYear) /
                            2
                          ).toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear !== null
                        ? (
                            (props.companyData.revenueStreams[1].varianceThirdYear + props.companyData.revenueStreams[2].varianceThirdYear) /
                            2
                          ).toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear !== null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear === null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear === null
                        ? props.companyData.revenueStreams[0].varianceThirdYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceNexvarianceThirdYeartYear !== null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear === null
                        ? props.companyData.revenueStreams[1].varianceThirdYear.toFixed(0)
                        : props.companyData &&
                          props.companyData.revenueStreams &&
                          props.companyData.revenueStreams.length === 3 &&
                          props.companyData.revenueStreams[0] &&
                          props.companyData.revenueStreams[0].varianceThirdYear === null &&
                          props.companyData.revenueStreams[1] &&
                          props.companyData.revenueStreams[1].varianceThirdYear === null &&
                          props.companyData.revenueStreams[2] &&
                          props.companyData.revenueStreams[2].varianceThirdYear !== null
                        ? props.companyData.revenueStreams[2].varianceThirdYear.toFixed(0)
                        : 0}
                      %
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={[styles.tableSubHeader, styles.bold]}>Visual representation</Text>
              {props.companyData && props.companyData.revenueStreams[0] && (
                <View style={styles.table}>
                  <View style={[styles.row, styles.marginBottom30, styles.marginTop15]}>
                    <Text style={[styles.tableDescription]}>FY 2024</Text>
                    <View style={[styles.visual, styles.visualBlue, styles.visualMargin, { width: 144 }]}>
                      <Text style={styles.visualText}>
                        €
                        {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams[0].revenueYearOne !== null
                          ? addCommas(removeNonNumeric(props.companyData.revenueStreams[0] && props.companyData.revenueStreams[0].revenueYearOne))
                          : 0}
                      </Text>
                    </View>
                    {props.companyData && props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].revenueYearOne !== null && (
                      <View style={[styles.visual, styles.visualPurple, { width: 144 }]}>
                        <Text style={styles.visualText}>
                          €
                          {props.companyData &&
                            props.companyData.revenueStreams &&
                            addCommas(removeNonNumeric(props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].revenueYearOne))}
                        </Text>
                      </View>
                    )}
                    {props.companyData && props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].revenueYearOne !== null && (
                      <View style={[styles.visual, styles.visualPing, { width: 144 }]}>
                        <Text style={styles.visualText}>
                          €
                          {props.companyData &&
                            props.companyData.revenueStreams &&
                            addCommas(removeNonNumeric(props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].revenueYearOne))}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View style={[styles.row, styles.marginBottom30]}>
                    <Text style={[styles.tableDescription]}>FY 2025</Text>
                    <View style={[styles.visual, styles.visualBlue, styles.visualMargin, { width: 144 }]}>
                      <Text style={styles.visualText}>
                        €
                        {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams[0].revenueYearTwo !== null
                          ? addCommas(removeNonNumeric(props.companyData.revenueStreams[0] && props.companyData.revenueStreams[0].revenueYearTwo))
                          : 0}
                      </Text>
                    </View>
                    {props.companyData && props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].revenueYearTwo !== null && (
                      <View style={[styles.visual, styles.visualPurple, { width: 144 }]}>
                        <Text style={styles.visualText}>
                          €
                          {props.companyData &&
                            props.companyData.revenueStreams &&
                            addCommas(removeNonNumeric(props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].revenueYearTwo))}
                        </Text>
                      </View>
                    )}
                    {props.companyData && props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].revenueYearTwo !== null && (
                      <View style={[styles.visual, styles.visualPing, { width: 144 }]}>
                        <Text style={styles.visualText}>
                          €
                          {props.companyData &&
                            props.companyData.revenueStreams &&
                            addCommas(removeNonNumeric(props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].revenueYearTwo))}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View style={[styles.row, styles.marginBottom30]}>
                    <Text style={[styles.tableDescription]}>FY 2026</Text>
                    <View style={[styles.visual, styles.visualBlue, styles.visualMargin, { width: 144 }]}>
                      <Text style={styles.visualText}>
                        €
                        {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams[0].revenueYearThree !== null
                          ? addCommas(removeNonNumeric(props.companyData.revenueStreams[0] && props.companyData.revenueStreams[0].revenueYearThree))
                          : 0}
                      </Text>
                    </View>
                    {props.companyData && props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].revenueYearThree !== null && (
                      <View style={[styles.visual, styles.visualPurple, { width: 144 }]}>
                        <Text style={styles.visualText}>
                          €
                          {props.companyData &&
                            props.companyData.revenueStreams &&
                            addCommas(removeNonNumeric(props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].revenueYearThree))}
                        </Text>
                      </View>
                    )}
                    {props.companyData && props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].revenueYearThree !== null && (
                      <View style={[styles.visual, styles.visualPing, { width: 144 }]}>
                        <Text style={styles.visualText}>
                          €
                          {props.companyData &&
                            props.companyData.revenueStreams &&
                            addCommas(removeNonNumeric(props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].revenueYearThree))}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", marginLeft: 55 }}>
                    {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length > 0 ? (
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 25 }}>
                        <View style={{ backgroundColor: "#0052FF", height: 6, width: 6, borderRadius: 25 }}></View>
                        <Text style={{ fontSize: 10, marginLeft: 10 }}>
                          {props.companyData && props.companyData.revenueStreams[0] && props.companyData.revenueStreams[0].name}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                    {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length > 1 ? (
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 25 }}>
                        <View style={{ backgroundColor: "#9800FF", height: 6, width: 6, borderRadius: 25 }}></View>
                        <Text style={{ fontSize: 10, marginLeft: 10 }}>
                          {props.companyData && props.companyData.revenueStreams[1] && props.companyData.revenueStreams[1].name}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                    {props.companyData && props.companyData.revenueStreams && props.companyData.revenueStreams.length > 2 ? (
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 25 }}>
                        <View style={{ backgroundColor: "#F20089", height: 6, width: 6, borderRadius: 25 }}></View>
                        <Text style={{ fontSize: 10, marginLeft: 10 }}>
                          {props.companyData && props.companyData.revenueStreams[2] && props.companyData.revenueStreams[2].name}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              )}
            </>
          ) : (
            <></>
          )}
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          <Text style={[styles.tableHeader, styles.bold]}>Cost structure</Text>
          <Text style={[styles.tableSubHeader, styles.bold]}>Summary</Text>
          <View style={styles.tableCost}>
            {props.graphSummary !== undefined && (
              <Svg width='200' height='200' viewBox='-25 -25 250 250'>
                <Circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={props.graphSummary && props.graphSummary[0] ? props.graphSummary[0][0] : "#000000"}
                  strokeWidth={14}
                  strokeDasharray={"565"}
                />
                <Circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={props.graphSummary && props.graphSummary[1] ? props.graphSummary[1][0] : "#000000"}
                  strokeWidth={14}
                  strokeDasharray={props.graphSummary && props.graphSummary[0] ? `${((100 - props.graphSummary[0][1]) / 100) * 564}` : "0"}
                />
                <Circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={props.graphSummary && props.graphSummary[2] ? props.graphSummary[2][0] : "#000000"}
                  strokeWidth={14}
                  strokeDasharray={
                    props.graphSummary && props.graphSummary[1]
                      ? `${((100 - (props.graphSummary[1][1] + props.graphSummary[0][1])) / 100) * 564}`
                      : "0"
                  }
                />
                <Circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={props.graphSummary && props.graphSummary[3] ? props.graphSummary[3][0] : "#000000"}
                  strokeWidth={14}
                  strokeDasharray={
                    props.graphSummary && props.graphSummary[1]
                      ? `${((100 - (props.graphSummary[2][1] + props.graphSummary[1][1] + props.graphSummary[0][1])) / 100) * 564}`
                      : "0"
                  }
                />
                <Circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={props.graphSummary && props.graphSummary[4] ? props.graphSummary[4][0] : "#000000"}
                  strokeWidth={14}
                  strokeDasharray={
                    props.graphSummary && props.graphSummary[1]
                      ? `${
                          ((100 - (props.graphSummary[3][1] + props.graphSummary[2][1] + props.graphSummary[1][1] + props.graphSummary[0][1])) /
                            100) *
                          564
                        }`
                      : "0"
                  }
                />
                <Circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={props.graphSummary && props.graphSummary[5] ? props.graphSummary[5][0] : "#000000"}
                  strokeWidth={14}
                  strokeDasharray={
                    props.graphSummary && props.graphSummary[1]
                      ? `${
                          ((100 -
                            (props.graphSummary[4][1] +
                              props.graphSummary[3][1] +
                              props.graphSummary[2][1] +
                              props.graphSummary[1][1] +
                              props.graphSummary[0][1])) /
                            100) *
                            564 <
                          280
                            ? 280
                            : ((100 -
                                (props.graphSummary[4][1] +
                                  props.graphSummary[3][1] +
                                  props.graphSummary[2][1] +
                                  props.graphSummary[1][1] +
                                  props.graphSummary[0][1])) /
                                100) *
                              564
                        }`
                      : "0"
                  }
                />
              </Svg>
            )}
            <View style={{ position: "absolute", left: 50, top: 70 }}>
              <Text
                style={{ fontFamily: "Inter", fontWeight: "bold", fontSize: 20, color: "#2E1754", width: 100, marginBottom: 3, textAlign: "center" }}
              >
                €{props.summary}
              </Text>
              <Text style={{ fontSize: 10, color: "#747A80", width: 100, textAlign: "center" }}>Projected yearly costs</Text>
            </View>
            <View style={{ width: 300, marginLeft: 10 }}>
              <View style={{ display: "row", flexDirection: "row" }}>
                <View style={{ width: 6, marginRight: 15 }}></View>
                <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontFamily: "Inter" }}></Text>
                <Text style={{ width: 60, color: "#747A80", fontSize: 9 }}>One-off</Text>
                <Text style={{ width: 70, color: "#747A80", fontSize: 9 }}>Per month</Text>
                <Text style={{ width: 50, color: "#747A80", fontSize: 9 }}>Per year</Text>
              </View>
              {props.data &&
                props.data.map((data) => (
                  <View style={{ display: "row", flexDirection: "row", marginTop: 12 }}>
                    <View
                      style={{
                        width: 6,
                        marginRight: 10,
                        borderRadius: 25,
                        height: 6,
                        backgroundColor:
                          data[1] === "red"
                            ? "#E94F37"
                            : data[1] === "green"
                            ? "#23E33E"
                            : data[1] === "yellow"
                            ? "#F4C952"
                            : data[1] === "pink"
                            ? "#F20089"
                            : data[1] === "purple"
                            ? "#9800FF"
                            : "#0052FF",
                        marginTop: 3,
                      }}
                    ></View>
                    <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>{data[0]}</Text>
                    <Text style={{ width: 60, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>
                      {data[5] !== "0" ? `€${data[5]}` : ""}
                    </Text>
                    <Text style={{ width: 70, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>
                      {data[6] !== "0" ? `€${data[6]}` : ""}
                    </Text>
                    <Text style={{ width: 50, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>
                      {data[7] !== "0" ? `€${data[7]}` : ""}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
          <View style={[styles.row, { padding: "0 40px", margin: "10px 0", width: 600 }]}>
            <View
              style={{
                border: "1px solid #CFDBD5",
                padding: 15,
                width: 255,
                display: "flex",
                flexDirection: "row",
                borderRadius: 8,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Image source={update} style={{ marginRight: 5, width: 20 }}></Image>
                <Text style={{ fontSize: 11, color: "#0A004B" }}>Total recurring costs</Text>
              </View>
              <Text style={{ fontSize: 10, color: "#0A004B", fontFamily: "Inter" }}>€{props.summary}</Text>
            </View>
            <View
              style={{
                border: "1px solid #CFDBD5",
                padding: 15,
                width: 255,
                display: "flex",
                flexDirection: "row",
                borderRadius: 8,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Image source={arrowToRight} style={{ marginRight: 5, width: 20 }}></Image>
                <Text style={{ fontSize: 11, color: "#0A004B" }}>One-off costs</Text>
              </View>
              <Text style={{ fontSize: 10, color: "#0A004B", fontFamily: "Inter" }}>€{props.oneOffSummary}</Text>
            </View>
          </View>
          <Text style={[styles.tableSubHeader, styles.bold]}>In detail</Text>
          <View style={[styles.row, { padding: "0 40px", margin: "10px 0", width: 600, alignItems: "flex-start" }]}>
            <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 255, borderRadius: 8 }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: "#23E33E", marginTop: 3 }}></View>
                  <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>General & Admin</Text>
                </View>
                <Text style={{ color: "#747A80", fontSize: 9 }}>Per month</Text>
              </View>
              {props.data &&
                props.data[4] &&
                props.data[4][4].map(
                  (extendData, index) =>
                    index < 5 &&
                    extendData[2] === "PER_MONTH" && (
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                        <Text style={{ fontSize: 9, color: "#0A004B", marginLeft: 25 }}>{extendData[0]}</Text>
                        <Text style={{ fontSize: 9, color: "#0A004B", fontFamily: "Inter" }}>{`€${
                          extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                        }`}</Text>
                      </View>
                    )
                )}
            </View>
            <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 255, borderRadius: 8 }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: "#F4C952", marginTop: 3 }}></View>
                  <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>Office expenses</Text>
                </View>
                <Text style={{ color: "#747A80", fontSize: 9 }}>Per month</Text>
              </View>
              {props.data &&
                props.data[3] &&
                props.data[3][4].map(
                  (extendData, index) =>
                    index < 5 &&
                    extendData[2] === "PER_MONTH" && (
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                        <Text style={{ fontSize: 9, color: "#0A004B", marginLeft: 25, maxWidth: "170px" }}>{extendData[0]}</Text>
                        <Text style={{ fontSize: 9, color: "#0A004B", fontFamily: "Inter" }}>{`€${
                          extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                        }`}</Text>
                      </View>
                    )
                )}
            </View>
          </View>
          <View style={[styles.row, { padding: "0 40px", margin: "10px 0", width: 600, alignItems: "flex-start" }]}>
            <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 255, borderRadius: 8 }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: "#F20089", marginTop: 3 }}></View>
                  <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>Transportation costs</Text>
                </View>
                <Text style={{ color: "#747A80", fontSize: 9 }}>Per month</Text>
              </View>
              {props.data &&
                props.data[2] &&
                props.data[2][4].map(
                  (extendData, index) =>
                    index < 6 &&
                    extendData[2] === "PER_MONTH" && (
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                        <Text style={{ fontSize: 9, color: "#0A004B", marginLeft: 25 }}>{extendData[0]}</Text>
                        <Text style={{ fontSize: 9, color: "#0A004B", fontFamily: "Inter" }}>{`€${
                          extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                        }`}</Text>
                      </View>
                    )
                )}
            </View>
            <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 255, borderRadius: 8 }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: "#0052FF", marginTop: 3 }}></View>
                  <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>Establishment costs</Text>
                </View>
                <Text style={{ color: "#747A80", fontSize: 9 }}>One-off</Text>
              </View>
              {props.data &&
                props.data[0] &&
                props.data[0][4].map(
                  (extendData, index) =>
                    index < 6 &&
                    extendData[2] !== "PER_MONTH" && (
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                        <Text style={{ fontSize: 9, color: "#0A004B", marginLeft: 25 }}>{extendData[0]}</Text>
                        <Text style={{ fontSize: 9, color: "#0A004B", fontFamily: "Inter" }}>{`€${
                          extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                        }`}</Text>
                      </View>
                    )
                )}
            </View>
          </View>
          <View style={[styles.row, { padding: "0 40px", margin: "10px 0", width: 600, alignItems: "flex-start" }]}>
            <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 255, borderRadius: 8 }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: "#9800FF", marginTop: 3 }}></View>
                  <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>Professional services</Text>
                </View>
                <Text style={{ color: "#747A80", fontSize: 9 }}>Per month</Text>
              </View>
              {props.data &&
                props.data[1] &&
                props.data[1][4].map(
                  (extendData, index) =>
                    index < 2 &&
                    extendData[2] === "PER_MONTH" && (
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                        <Text style={{ fontSize: 9, color: "#0A004B", marginLeft: 25 }}>{extendData[0]}</Text>
                        <Text style={{ fontSize: 9, color: "#0A004B", fontFamily: "Inter" }}>{`€${
                          extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                        }`}</Text>
                      </View>
                    )
                )}
            </View>
            <View style={{ border: "1px solid #CFDBD5", padding: 15, width: 255, borderRadius: 8 }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: "#E94F37", marginTop: 3 }}></View>
                  <Text style={{ width: 130, color: "#0A004B", fontSize: 10, fontWeight: "bold", fontFamily: "Inter" }}>Other</Text>
                </View>
                <Text style={{ color: "#747A80", fontSize: 9 }}>Per month</Text>
              </View>
              {props.data &&
                props.data[5] &&
                props.data[5][4].map(
                  (extendData, index) =>
                    index < 2 &&
                    extendData[2] === "PER_MONTH" && (
                      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                        <Text style={{ fontSize: 9, color: "#0A004B", marginLeft: 25 }}>{extendData[0]}</Text>
                        <Text style={{ fontSize: 9, color: "#0A004B", fontFamily: "Inter" }}>{`€${
                          extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                        }`}</Text>
                      </View>
                    )
                )}
            </View>
          </View>
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
        <Page size='A4' style={[styles.page, styles.pageStart]}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Establishment plan</Text>
          </View>
          <Text style={[styles.tableHeader, styles.bold]}>Loans</Text>
          <View style={[styles.loansPreview]}>
            <View style={[styles.box, styles.width80]}>
              <Text style={[styles.tableDescription]}>Starting date</Text>
            </View>
            <View style={[styles.box, styles.width80]}>
              <Text style={[styles.tableDescription]}>Capital</Text>
            </View>
            <View style={[styles.box, styles.width80]}>
              <Text style={[styles.tableDescription]}>Duration</Text>
            </View>
            <View style={[styles.box, styles.width80, { width: 110 }]}>
              <Text style={[styles.tableDescription]}>Annual charge %</Text>
            </View>
            <View style={[styles.box, styles.width120]}>
              <Text style={[styles.tableDescription]}>Linked asset</Text>
            </View>
          </View>
          {props.companyData &&
            props.companyData.loans &&
            props.companyData.loans.map((data) => (
              <View style={[styles.table, styles.marginBottom10]}>
                <View style={[styles.tableRow, styles.tableRowOut, styles.widthFull, { padding: "8px 5px" }]}>
                  <View style={[styles.box, styles.width80]}>
                    <Text style={[styles.tableDescription, styles.textColorPrimary]}>
                      {data.startDate &&
                        transformDate(data.startDate.substring(8, 10), data.startDate.substring(5, 7), data.startDate.substring(0, 4))}
                    </Text>
                  </View>
                  <View style={[styles.box, styles.width80]}>
                    <Text style={[styles.tableDescription, styles.bold, styles.textColorPrimary]}>€{addCommas(removeNonNumeric(data.capital))}</Text>
                  </View>
                  <View style={[styles.box, styles.width80]}>
                    <Text style={[styles.tableDescription, styles.textColorPrimary]}>3 years</Text>
                  </View>
                  <View style={[styles.box, styles.width80, { width: 110 }]}>
                    <Text style={[styles.tableDescription, styles.textColorPrimary]}>{data.annualChargePercentage}%</Text>
                  </View>
                  <View style={[styles.box, styles.width120]}>
                    <Text style={[styles.tableDescription, styles.textColorPrimary]}>None</Text>
                  </View>
                </View>
              </View>
            ))}
          <View style={[styles.footer, styles.pageStart]}>
            <Text style={[styles.footerText, styles.footerTextBlack]}>© Bizantium 2024</Text>
          </View>
        </Page>
      </Document>
    );
  };

  const documentPDF = createDocument();
  const [instance, updateInstance] = usePDF({ document: documentPDF });

  if (instance.url !== null) {
    updateInstance(documentPDF);
  }

  useEffect(() => {
    // Update pdf
    setTimeout(() => {
      updateInstance(documentPDF);
    }, 1000);
  });

  const regenerate = () => {
    let file = new File([instance.blob], "financialplan.pdf");
    uploadFile(file).then((r) => console.log(r));
  };

  return (
    <>
      <div className='admin__output'>
        <div className='admin__output-icon'>{adminInfoIcon}</div>
        <div className='admin__output-icon d-none'>{fullScreen}</div>
        <a href={instance.url} rel='noopener noreferrer' target='_blank'>
          <div className='admin__output-icon'>{download}</div>
        </a>
        <a href={instance.url} rel='noopener noreferrer' target='_blank'>
          <div className='admin__output-icon'>{print}</div>
        </a>
      </div>
      <div className='admin__buttons'>
        <a
          href={instance.url}
          rel='noopener noreferrer'
          target='_blank'
          onClick={() => regenerate()}
          className={props.loading === true ? "blocked" : ""}
        >
          <div className='btn btn--primary output'>
            {props.loading === true ? <img className='loading' src={loadingFile} alt={"loading"} /> : <Trans>admin.regenerate</Trans>}
          </div>
        </a>
        <div className='btn btn--primary d-none'>
          <Trans>admin.send</Trans>
        </div>
      </div>
    </>
  );
};
