import React, { useEffect } from 'react';
import * as Endpoint from '../../configFile';

function LoginGoogle() {
    useEffect(() => {
        const token = window.location.href.split('?token=')[1];

        const path = localStorage.getItem('redirect');

        console.log(path);

        fetch(Endpoint.loginGoogleALlData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response;
            })
            .then(res => res.json())
            .then(data => {
                localStorage.setItem('login', 'true');
                localStorage.setItem('role', data.user.role);
                localStorage.setItem('token', token);
                localStorage.setItem('unseen', data.unseen);
                localStorage.setItem('unseenShow', 'false');
                localStorage.setItem('user', JSON.stringify(data.user));
                if (path != null && path !== 'null' && path !== '') {
                    localStorage.removeItem('redirect');
                    window.location.href = path;
                } else {
                    data.user.role === 'admin' ? (window.location.href = '/clients') : (window.location.href = '/company');
                }
            })
            .catch(error => {
                console.log('Error:', error);
            });
    }, []);

    return true;
}

export default LoginGoogle;
