import React, { useState } from "react";
import './cost.scss';
import { FormControl, FormControlLabel, Radio, TextField, InputAdornment } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import CustomSelect from "../customSelect/customSelect";
import {Trans} from "react-i18next";

function Add(props) {
    const [selectList] = useState([
        { id: 1, name: <Trans>companyType.typeCost1</Trans>, value: 'ESTABLISHMENT' },
        { id: 2, name: <Trans>companyType.typeCost2</Trans>, value: 'PRO_SERVICES' },
        { id: 3, name: <Trans>companyType.typeCost3</Trans>, value: 'TRANSPORTATION' },
        { id: 4, name: <Trans>companyType.typeCost4</Trans>, value: 'OFFICE' },
        { id: 5, name: <Trans>companyType.typeCost5</Trans>, value: 'GENERAL' },
        { id: 5, name: <Trans>companyType.typeCost6</Trans>, value: 'OTHER' }
    ]);

    const handleRadio = (value) => {
        sessionStorage.setItem('addCostType', `${value}`);
        props.handleActive(true, props.pid, props.editCategory, value, props.editDescription, props.editAmount);
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === "0") {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    return (
        <div className={props.active === true ? "cost__wrapper active" : "cost__wrapper"}>
            <div className="cost__content">
                <div className="cost__content-header">{props.pid === null ? localStorage.getItem('i18nextLng') === 'en' ? 'Add a cost' : 'Kost toevoegen' : `Edit cost - ${props.editDescription}`}</div>
                <div className={`container__body-select ${props.question === true ? 'd-none' : ''}`}>
                    <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Which type of cost do you want to add?' : 'Welk type kosten wilt u toevoegen?'}</p>
                    <CustomSelect
                        defaultText={props.editCategory === null ? <Trans>companyType.typeCost1</Trans> : props.editCategory === 'ESTABLISHMENT' ? <Trans>companyType.typeCost1</Trans> : props.editCategory === 'PRO_SERVICES' ? <Trans>companyType.typeCost2</Trans> : props.editCategory === 'TRANSPORTATION' ? <Trans>companyType.typeCost3</Trans> : props.editCategory === 'OFFICE' ? <Trans>companyType.typeCost4</Trans> : props.editCategory === 'GENERAL' ? <Trans>companyType.typeCost5</Trans> : props.editCategory === 'OTHER' ? <Trans>companyType.typeCost6</Trans> : ''}
                        defaultValue={props.editCategory === null ? 'ESTABLISHMENT' : props.editCategory}
                        defaultSelectText={props.defaultSelectText}
                        defaultSelectValue={props.defaultSelectValue}
                        optionsList={selectList}
                        type={'add'}
                        handleData={props.handleActive}
                        pid={props.pid}
                        editType={props.editType}
                        editDescription={props.editDescription}
                        editAmount={props.editAmount}
                    />
                </div>
                <div className={`container__body-input ${props.question === true ? 'd-none' : ''}`}>
                    <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Is this a one-off, or a monthly recurring cost?' : 'Zijn dit eenmalige of maandelijks terugkerende kosten?'}</p>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="costType"
                            value={props.pid === null ? sessionStorage.getItem('addCostType') : props.editType === 'PER_MONTH' ? 'PER_MONTH' : 'One-off'}
                            onChange={(e) => handleRadio(e.target.value)}
                        >
                            <div className="structure__checkbox">
                                <div className="container__body-radio">
                                    <FormControlLabel value="PER_MONTH" control={<Radio />} label={localStorage.getItem('i18nextLng') === 'en' ? 'Monthly recurring' : 'Maandelijks terugkerend'} />
                                </div>
                                <div className="container__body-radio">
                                    <FormControlLabel value="One-off" control={<Radio />} label={localStorage.getItem('i18nextLng') === 'en' ? 'One-off' : 'Eenmalig'} />
                                </div>
                            </div>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={`container__body-input ${props.question === true ? 'd-none' : ''}`}>
                    <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Cost description' : 'Beschrijving van de kosten'}</p>
                    <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={props.editDescription === null ? '' : props.editDescription} onChange={(e) => props.handleActive(true, props.pid, props.editCategory, props.editType, e.target.value, props.editAmount)} />
                </div>
                <div className="container__body-input">
                    <p id="assetsValue" className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? sessionStorage.getItem('addCostType') === 'PER_MONTH' ? 'Amount per month' : 'Amount' : sessionStorage.getItem('addCostType') === 'PER_MONTH' ? 'Bedrag per maand' : 'Hoeveelheid'}</p>
                    <TextField InputProps={{
            startAdornment: props.editAmount  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={props.editAmount === null ? '' : props.editAmount} onChange={(e) => props.handleActive(true, props.pid, props.editCategory, props.editType, props.editDescription, addCommas(removeNonNumeric(e.target.value)), props.question)} />
                </div>
                <div className="row">
                    <button className="btn btn--secondary" onClick={() => props.handleActive(false)}><Trans>basic.cancel</Trans></button>
                    <button className="btn btn--primary" onClick={() => props.handleSave(false)}><Trans>basic.save-2</Trans></button>
                </div>
            </div>
        </div>
    );
}

export default Add;
