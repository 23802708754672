import React, { useState, useEffect } from "react";
import { paginationArrow } from "../login/loginConstants";
import './pagination.scss';

function Pagination(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        let content = [];
        for (let i = 1; i < props.sum + 1; i++) {
            content.push(i);
        }
        setData(content);
    }, [props.sum]);

    return (
        <div className="pagination">
            <div className={props.active > 1 ? "pagination__number pagination__arrow" : "pagination__number pagination__arrow hidden"} onClick={() => props.handlePage(props.active - 1)}>{paginationArrow}</div>
            {data.map((pageNumber) => (
                <div className={pageNumber === props.active ? "pagination__number active" : "pagination__number"} onClick={() => props.handlePage(pageNumber)}>{pageNumber}</div>
            ))}
            <div className={props.active < props.sum ? "pagination__number pagination__arrow" : "pagination__number pagination__arrow hidden"} onClick={() => props.handlePage(props.active + 1)}>{paginationArrow}</div>
        </div>
    );
}

export default Pagination;
