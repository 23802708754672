// display table in user module, comment table with user and save only administrator

import React, {useState} from "react";
import {NavLink, Route, Switch, useRouteMatch} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useStylesCirclePrimary} from "../support/supportConstant";
import {deleteUser} from '../../configFile';
import './_users.scss';
import AddUser from "./addUser";
import * as Constant from './userConstant';
import Permission from "./permission";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {makeStyles} from "@material-ui/core/styles";
import {Trans} from "react-i18next";

const useStylesTab = makeStyles(() => ({
    root: {
        backgroundColor: 'white',
        boxShadow: 'none',
        color: '#D6D6D6',
        marginBottom: '25px'
    },
    button:{
        textTransform: 'capitalize',
        fontFamily: 'Inter, sans-serif',
        fontSize: '15px',
        padding: '10px 20px 10px 0'
    },
    buttonMobile:{
        textTransform: 'capitalize',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        padding: '10px 10px 10px 0'
    },
    buttonSelected: {
        color: '#444445',
        fontWeight: '600',
    },
    indicator: {
        backgroundColor: 'transparent',
    }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>{children}</Box>
            )}
        </div>
    );
}

const Users = (props) => {
    let {path, url} = useRouteMatch();
    const classes = useStylesCirclePrimary();
    const classesTab = useStylesTab();
    const loggedRole = props.role;
    const isMobile = props.isMobile;
    const user = JSON.parse(localStorage.getItem('user'));
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const administratorTable = () => {
        return(
            <>
                <div className="container__title container-justifySpace">
                    {!isMobile && <h2><Trans>users.admin</Trans></h2>}
                    {loggedRole === "admin" && user.permissions.editUsers &&
                    <AddUser update={props.update} isEdit={false} isMobile={isMobile}/>}
                </div>
            </>
        )
    }

    /*
    const usersTable = () => {
        return(<>
            <div className="container__title container-justifySpace">
                {!isMobile && <h2>Users</h2>}
            </div>
           </>)
    }
     */

    if (isMobile) {
        return (
            <>
                <h2 className="header__title"><Trans>menu.users</Trans></h2>
                <div className="container__body container--users">
                    <AppBar position="static" classes={{root: classesTab.root}}>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
                              classes={{indicator: classesTab.indicator}}>
                            {[<Trans>users.admin</Trans>, //'Users',
                                 <Trans>users.permission</Trans>].map((tab, index) =>
                                (tab !==<Trans>users.permission</Trans> || user.permissions.displayPermissions) &&
                                <Tab key={index} label={tab} id={`simple-tab-${index}`}
                                     aria-controls={`simple-tabPanel-${index}`}
                                     classes={{
                                         root: isMobile ? classesTab.buttonMobile : classesTab.button,
                                         selected: classesTab.buttonSelected
                                     }}/>
                            )}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        {props.isLoaded
                            ? administratorTable()
                            : <CircularProgress classes={{root: classes.root}}/>
                        }
                    </TabPanel>
                    {/*
                    <TabPanel value={value} index={1}>
                        {props.isLoaded
                            ? usersTable()
                            : <CircularProgress classes={{root: classes.root}}/>
                        }
                    </TabPanel>
                    */}
                    <TabPanel value={value} index={2}>
                        <Permission role={loggedRole} classes={classes} isMobile={isMobile}/>
                    </TabPanel>
                </div>
            </>
        )
    } else {
        return (
            <>
                <h2 className="header__title"><Trans>menu.users</Trans></h2>

                <div className="support--buttons">
                    <NavLink exact to={`${url}`} className="btn--shadow"><Trans>users.users</Trans></NavLink>
                    {user.permissions.displayPermissions && <NavLink exact to={`${url}/permissions`} className="btn--shadow"><Trans>users.permissions</Trans></NavLink>}
                </div>

                <Switch>
                    <>
                        <div className="container__body container--users">
                            {props.isLoaded
                                ? <>
                                    <Route exact path={path}>
                                        {administratorTable()}
                                        {/*usersTable()*/}
                                    </Route>

                                    <Route path={`${path}/permissions`}>
                                        <Permission role={loggedRole} classes={classes} isMobile={isMobile}/>
                                    </Route>
                                </>
                                : <CircularProgress classes={{root: classes.root}}/>
                            }
                        </div>
                    </>
                </Switch>
            </>
        )
    }
}

export default Users;