import React, { useEffect } from "react";
import './rememberMe.scss';
import Checkbox from "../checkbox/checkbox";

function RememberMe(props) {
    const label = localStorage.getItem('i18nextLng') === 'en' ? 'Remember me' : 'Onthoud me';

    useEffect(() => {
        if (localStorage.checkbox && localStorage.email !== "") {
            props.handleChecked(true);
        }
    }, [props]);

    const onChangeCheckbox = (event) => {
        props.handleChecked(event.target.checked);
    };

    return (
        <div className="rememberMe">
            <Checkbox handleChangeCheckbox={onChangeCheckbox} isChecked={props.isChecked} label={label} />
        </div>
    );
}

export default RememberMe;
