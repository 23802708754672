import React, { useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon } from "../survey/surveyConstant";
import { TextField } from "@material-ui/core";
import CustomSwitch from "../switch/switch";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import CustomSelect from "../customSelect/customSelect";
import { Trans } from "react-i18next";
import LimitedCompany from "./limitedCompany";

const CompanyDetails = () => {
  const [operatingAddress, setOperatingAddress] = useState(false);
  const [limitedCompany, setLimitedCompany] = useState(false);
  const [data, setData] = useState({
    operationCity: sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).operationCity : "",
    operationHouseNumber:
      sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).operationHouseNumber : "",
    operationPostalCode:
      sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).operationPostalCode : "",
    operationStreet: sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).operationStreet : "",
    operationRegion: sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).operationRegion : "",
    registrationCity: sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).registrationCity : "",
    registrationHouseNumber:
      sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).registrationHouseNumber : "",
    registrationPostalCode:
      sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).registrationPostalCode : "",
    registrationStreet:
      sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).registrationStreet : "",
    registrationRegion:
      sessionStorage.getItem("companyDetails") !== null ? JSON.parse(sessionStorage.getItem("companyDetails")).registrationRegion : "",
  });
  const selectList = [
    {
      id: 1,
      name: localStorage.getItem("i18nextLng") === "en" ? "Flanders" : "Vlaanderen",
      value: localStorage.getItem("i18nextLng") === "en" ? "Flanders" : "Vlaanderen",
    },
    {
      id: 2,
      name: localStorage.getItem("i18nextLng") === "en" ? "Brussels" : "Brussel",
      value: localStorage.getItem("i18nextLng") === "en" ? "Brussels" : "Brussel",
    },
    {
      id: 3,
      name: localStorage.getItem("i18nextLng") === "en" ? "Wallonia" : "Wallonië",
      value: localStorage.getItem("i18nextLng") === "en" ? "Wallonia" : "Wallonië",
    },
  ];

  useEffect(() => {
    Connect(`${Endpoint.companyDetails}`, "GET").then((response) => {
      const updatedData = {
        operationCity: response.operationCity,
        operationHouseNumber: response.operationHouseNumber,
        operationPostalCode: response.operationPostalCode,
        operationStreet: response.operationStreet,
        operationRegion: response.operationRegion,
        registrationCity: response.registrationCity,
        registrationHouseNumber: response.registrationHouseNumber,
        registrationPostalCode: response.registrationPostalCode,
        registrationStreet: response.registrationStreet,
        registrationRegion: response.registrationRegion,
      };
      setData(updatedData);
      sessionStorage.setItem("financialCalendar", JSON.stringify(response.financialCalendar));
      sessionStorage.setItem("companyDetails", JSON.stringify(updatedData));
      if (response.legalForm === null) {
        setLimitedCompany(true);
      }
    });
    let body = {
      checkpointCategory: JSON.parse(sessionStorage.getItem("checkpointCategoryEstablishment"))[0],
      completed: 1,
      required: 1,
      isChange: true,
    };
    Connect(`${Endpoint.putProgressSurvey}`, "PUT", body).then((response) => {
      console.log(response);
    });
  }, []);

  const alpha = (value) => {
    let res = value.replace(/[^A-Za-z -]/g, "");
    return res;
  };

  const alphaAndNumbers = (value) => {
    let res = value.replace(/[^A-Za-z0-9]/g, "");
    return res;
  };

  const handleData = (type, value) => {
    let currentData = JSON.parse(sessionStorage.getItem("companyDetails"));
    if (type === "operationCity") {
      currentData.operationCity = alpha(value);
    }
    if (type === "operationHouseNumber") {
      currentData.operationHouseNumber = alphaAndNumbers(value);
    }
    if (type === "operationPostalCode") {
      currentData.operationPostalCode = value;
    }
    if (type === "operationStreet") {
      currentData.operationStreet = value;
    }
    if (type === "operationRegion") {
      currentData.operationRegion = value[0].text;
    }
    if (type === "registrationCity") {
      currentData.registrationCity = alpha(value);
    }
    if (type === "registrationHouseNumber") {
      currentData.registrationHouseNumber = alphaAndNumbers(value);
    }
    if (type === "registrationPostalCode") {
      currentData.registrationPostalCode = value;
    }
    if (type === "registrationStreet") {
      currentData.registrationStreet = value;
    }
    if (type === "registrationRegion") {
      currentData.registrationRegion = value[0].text;
    }
    setData(currentData);
    sessionStorage.setItem("companyDetails", JSON.stringify(currentData));
  };

  const handleOperatingAddress = (value) => {
    if (value === true) {
      const updatedData = {
        registrationCity: data.registrationCity,
        registrationHouseNumber: data.registrationHouseNumber,
        registrationPostalCode: data.registrationPostalCode,
        registrationStreet: data.registrationStreet,
        registrationRegion: data.registrationRegion,
        operationCity: data.registrationCity,
        operationHouseNumber: data.registrationHouseNumber,
        operationPostalCode: data.registrationPostalCode,
        operationStreet: data.registrationStreet,
        operationRegion: data.registrationRegion,
      };
      setData(updatedData);
      setTimeout(() => {
        sessionStorage.setItem("companyDetails", JSON.stringify(updatedData));
      }, 10);
    } else {
      setTimeout(() => {
        sessionStorage.setItem("companyDetails", JSON.stringify(data));
      }, 10);
    }
    setOperatingAddress(value);
  };

  const handleLimitedCompany = (value) => {
    let body = {
      legalForm: value,
    };
    Connect(`${Endpoint.companyDetails}`, "PATCH", body).then((response) => {
      setLimitedCompany(false);
    });
  };

  return (
    <>
      <div className='container__body establishmentFlow companyDetails'>
        <div className='container__body-header'>
          <Trans>establishmentFlow.office</Trans>
          <Tooltip title={<Trans>establishmentFlow.tooltip</Trans>} placement={"right"}>
            <IconButton aria-label='delete'>{InfoIcon}</IconButton>
          </Tooltip>
        </div>
        <div className='container__body-box'>
          <div className='container__body-input'>
            <p className='container__body-text'>
              <Trans>establishmentFlow.street</Trans>
            </p>
            <TextField
              label={""}
              variant={"outlined"}
              size={"small"}
              placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your street name" : "Jouw straat"}
              value={data.registrationStreet}
              onChange={(e) => handleData("registrationStreet", e.target.value)}
            />
          </div>
          <div className='container__body-input'>
            <p className='container__body-text'>
              <Trans>establishmentFlow.house</Trans>
            </p>
            <TextField
              label={""}
              variant={"outlined"}
              size={"small"}
              placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your address cont." : "Jouw huisnummer"}
              value={data.registrationHouseNumber}
              onChange={(e) => handleData("registrationHouseNumber", e.target.value)}
            />
          </div>
          <div className='container__body-input container__body-switcher' onClick={(e) => handleOperatingAddress(e.target.checked === true)}>
            <p className='container__body-text'>
              <Trans>establishmentFlow.setAddress</Trans>
            </p>
            <CustomSwitch checked={operatingAddress === true ? true : false} />
          </div>
        </div>
        <div className='container__body-box'>
          <div className='container__body-input'>
            <p className='container__body-text'>
              <Trans>establishmentFlow.postalCode</Trans>
            </p>
            <TextField
              label={""}
              variant={"outlined"}
              size={"small"}
              placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your postal code" : "Jouw postcode"}
              value={data.registrationPostalCode}
              onChange={(e) => handleData("registrationPostalCode", e.target.value)}
            />
          </div>
          <div className='container__body-input'>
            <p className='container__body-text'>
              <Trans>establishmentFlow.city</Trans>
            </p>
            <TextField
              label={""}
              variant={"outlined"}
              size={"small"}
              placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your city" : "Jouw stad"}
              value={data.registrationCity}
              onChange={(e) => handleData("registrationCity", e.target.value)}
            />
          </div>
        </div>
        <div className='container__body-box'>
          <div className='container__body-select'>
            <p className='container__body-text'>
              <Trans>establishmentFlow.region</Trans>
            </p>
            <CustomSelect
              defaultText={data.registrationRegion !== null ? data.registrationRegion : "..."}
              optionsList={selectList}
              handleData={handleData}
              type={"registrationRegion"}
            />
          </div>
        </div>
        <div id='operatingAddress'>
          <div className='container__body-header'>
            <Trans>establishmentFlow.address</Trans>
            <Tooltip title={<Trans>establishmentFlow.tooltip2</Trans>} placement={"right"}>
              <IconButton aria-label='delete'>{InfoIcon}</IconButton>
            </Tooltip>
          </div>
          <div className='container__body-box'>
            <div className='container__body-input'>
              <p className='container__body-text'>
                <Trans>establishmentFlow.street</Trans>
              </p>
              <TextField
                label={""}
                variant={"outlined"}
                disabled={operatingAddress === true ? true : false}
                size={"small"}
                placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your address" : "Uw adres"}
                value={data.operationStreet}
                onChange={(e) => handleData("operationStreet", e.target.value)}
              />
            </div>
            <div className='container__body-input'>
              <p className='container__body-text'>
                <Trans>establishmentFlow.house</Trans>
              </p>
              <TextField
                label={""}
                variant={"outlined"}
                disabled={operatingAddress === true ? true : false}
                size={"small"}
                placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your house number" : "Uw huisnummer"}
                value={data.operationHouseNumber}
                onChange={(e) => handleData("operationHouseNumber", e.target.value)}
              />
            </div>
          </div>
          <div className='container__body-box'>
            <div className='container__body-input'>
              <p className='container__body-text'>
                <Trans>establishmentFlow.postalCode</Trans>
              </p>
              <TextField
                label={""}
                variant={"outlined"}
                disabled={operatingAddress === true ? true : false}
                size={"small"}
                placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your postal code" : "Jouw postcode"}
                value={data.operationPostalCode}
                onChange={(e) => handleData("operationPostalCode", e.target.value)}
              />
            </div>
            <div className='container__body-input'>
              <p className='container__body-text'>
                <Trans>establishmentFlow.city</Trans>
              </p>
              <TextField
                label={""}
                variant={"outlined"}
                disabled={operatingAddress === true ? true : false}
                size={"small"}
                placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your city" : "Jouw stad"}
                value={data.operationCity}
                onChange={(e) => handleData("operationCity", e.target.value)}
              />
            </div>
          </div>
          <div className='container__body-box'>
            <div className='container__body-select'>
              <p className='container__body-text'>
                <Trans>establishmentFlow.region</Trans>
              </p>
              <CustomSelect
                defaultText={data.operationRegion !== null ? data.operationRegion : "..."}
                optionsList={selectList}
                handleData={handleData}
                type={"operationRegion"}
                disabled={operatingAddress === true ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
      <LimitedCompany active={limitedCompany} handleSave={handleLimitedCompany} handleLimitedCompany={setLimitedCompany} />
    </>
  );
};

export default CompanyDetails;
