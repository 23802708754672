import React, { Component } from "react";
import "./chekUp.scss";
import { bulletList, closeList, editIcon, trash } from "../login/loginConstants";
import Add from "../cost/add";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import Confirm from "../confirm/confirm";
import { Trans } from "react-i18next";

class CheckUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      confirm: false,
      defaultSelectText: "",
      defaultSelectValue: "",
      pid: null,
      editType: null,
      editCategory: null,
      editDescription: null,
      editAmount: null,
      question: false,
    };
  }

  componentDidMount() {
    document.querySelector(".footer .btn.next").innerText = "Finish";
    this.props.handleCheckup();
    let body = {
      checkpointCategory: JSON.parse(sessionStorage.getItem("checkpointCategoryEstablishment"))[
        sessionStorage.getItem("legalForm") === "LIMITED_COMPANY" ? 8 : 5
      ],
      completed: 1,
      required: 1,
      isChange: true,
    };
    Connect(`${Endpoint.putProgressSurvey}`, "PUT", body).then((response) => {
      console.log(response);
    });
  }

  componentWillUnmount() {
    document.querySelector(".footer .btn.next").innerText = "Next";
  }

  handleAdd = (value, pid = null, editCategory = null, editType = null, editDescription = null, editAmount = null, question = false) => {
    this.setState({
      add: value,
      pid,
      editCategory,
      editType,
      editDescription,
      editAmount,
      defaultSelectText:
        editCategory === null ? null : editCategory === "ESTABLISHMENT" ? (
          <Trans>companyType.typeCost1</Trans>
        ) : editCategory === "PRO_SERVICES" ? (
          <Trans>companyType.typeCost2</Trans>
        ) : editCategory === "TRANSPORTATION" ? (
          <Trans>companyType.typeCost3</Trans>
        ) : editCategory === "OFFICE" ? (
          <Trans>companyType.typeCost4</Trans>
        ) : editCategory === "GENERAL" ? (
          <Trans>companyType.typeCost5</Trans>
        ) : editCategory === "OTHER" ? (
          <Trans>companyType.typeCost6</Trans>
        ) : (
          ""
        ),
      defaultSelectValue: editCategory === null ? "ESTABLISHMENT" : editCategory,
      question,
    });
    if (editType === "PER_MONTH") {
      sessionStorage.setItem("addCostType", "PER_MONTH");
    } else {
      sessionStorage.setItem("addCostType", "One-off");
    }
  };

  save = () => {
    let type = document.querySelector(".cost__content .custom-select-container .selected-text").getAttribute("data-value");
    let description = document.querySelector(".cost__content .container__body-input:nth-child(4) .MuiInputBase-input").value;
    let price = document.querySelector(".cost__content .container__body-input:nth-child(5) .MuiInputBase-input").value.replaceAll(".", "");
    let isPeriodic;
    let costPeriod = null;
    if (sessionStorage.getItem("addCostType") === "One-off") {
      isPeriodic = false;
    } else {
      isPeriodic = true;
      costPeriod = "PER_MONTH";
    }
    document.querySelector(".cost__content .container__body-input:nth-child(4) .MuiInputBase-input").value = "";
    document.querySelector(".cost__content .container__body-input:nth-child(5) .MuiInputBase-input").value = "";
    this.setState({
      defaultSelectValue: "ESTABLISHMENT",
    });
    this.handleAdd(false);
    let body;
    if (this.state.pid === null) {
      body = {
        costType: type,
        description,
        costPeriod,
        amount: parseInt(price),
        isPeriodic,
      };
      Connect(`${Endpoint.postCostUser}`, "POST", body).then((response) => {
        this.props.handleCheckup();
      });
    } else {
      body = {
        id: this.state.pid,
        costType: type,
        description,
        costPeriod,
        amount: parseInt(price),
        isPeriodic,
      };
      Connect(`${Endpoint.postCostUser}`, "PUT", body).then((response) => {
        this.props.handleCheckup();
      });
    }
  };

  handleConfirm = (value) => {
    this.setState({
      confirm: value,
    });
  };

  confirmRemove = (pid, status, type, description, costPeriod, isPeriodic) => {
    this.handleConfirm(true);
    sessionStorage.setItem("indexDelete", pid);
    sessionStorage.setItem("indexDeleteStatus", status);
    sessionStorage.setItem("indexDeleteType", type);
    sessionStorage.setItem("indexDeleteDescription", description);
    sessionStorage.setItem("indexDeleteCostPeriod", costPeriod);
    sessionStorage.setItem("indexDeleteIsPeriodic", isPeriodic);
  };

  removeCost = () => {
    this.handleConfirm(false);
    if (sessionStorage.getItem("indexDeleteStatus") === "null") {
      Connect(`${Endpoint.postCostUser}/${sessionStorage.getItem("indexDelete")}`, "DELETE").then((response) => {
        this.props.handleCheckup();
      });
    } else {
      let body = {
        id: parseInt(sessionStorage.getItem("indexDelete")),
        costType: sessionStorage.getItem("indexDeleteType"),
        description: sessionStorage.getItem("indexDeleteDescription"),
        costPeriod: sessionStorage.getItem("indexDeleteCostPeriod"),
        amount: 0,
        isPeriodic: sessionStorage.getItem("indexDeleteIsPeriodic"),
      };
      Connect(`${Endpoint.postCostUser}`, "PUT", body).then((response) => {
        this.props.handleCheckup();
      });
    }
  };

  dropdown = (e) => {
    e.currentTarget.closest(".editable").classList.toggle("active");
    e.currentTarget.querySelector(".open").classList.toggle("d-none");
    e.currentTarget.querySelector(".close").classList.toggle("d-none");
  };

  render() {
    return (
      <>
        <div className='container__body cost checkUp'>
          <div className='container__body-header'>
            <Trans>establishmentFlow.cost</Trans>
          </div>
          <div className='container__body-text'>
            <Trans>establishmentFlow.checkup</Trans>
          </div>
          <div className='row'>
            <div className='column'>
              <div className='btn btn--primary' onClick={() => this.handleAdd(true)}>
                <Trans>establishmentFlow.addCost</Trans>
              </div>
              {this.props.data.map((data, index) => (
                <div className='container__table editable'>
                  <div className='container__table-row'>
                    <div className='box'>
                      <div className='container__table-item bulletList'></div>
                      <div className='container__table-item point'></div>
                      <div className='container__table-item name'></div>
                    </div>
                    <div className='box'>
                      <div className={`container__table-item one right ${data[5] === "0" ? "d-none" : ""}`}>
                        <Trans>companyType.one</Trans>
                      </div>
                      <div className={`container__table-item one right ${data[6] === "0" ? "d-none" : ""}`}>
                        <Trans>companyType.month</Trans>
                      </div>
                      <div className={`container__table-item one right ${data[6] === "0" ? "d-none" : ""}`}>
                        <Trans>companyType.year</Trans>
                      </div>
                    </div>
                  </div>
                  <div className='container__table-row'>
                    <div className='box'>
                      <div className='container__table-item bulletList' onClick={(e) => this.dropdown(e)}>
                        <div className='open'>{bulletList}</div>
                        <div className='close d-none'>{closeList}</div>
                      </div>
                      <div className='container__table-item point'>
                        <div className={`container__table-circle ${data[1]}`} />
                      </div>
                      <div className='container__table-item name award noWrap'>{data[0]}</div>
                    </div>
                    <div className='box'>
                      <div className={`container__table-item one award right ${data[5] === "0" ? "d-none" : ""}`}>
                        {data[5] !== "0" ? `€${data[5]}` : ""}
                      </div>
                      <div className={`container__table-item one award right ${data[6] === "0" ? "d-none" : ""}`}>
                        {data[6] !== "0" ? `€${data[6]}` : ""}
                      </div>
                      <div className={`container__table-item one award right ${data[7] === "0" ? "d-none" : ""}`}>
                        {data[7] !== "0" ? `€${data[7]}` : ""}
                      </div>
                    </div>
                  </div>
                  <div className='container__table-extend'>
                    {data[4].map((extendData) => (
                      <div className='container__table-row'>
                        <div className='box'>
                          <div
                            className='remove'
                            onClick={() =>
                              this.confirmRemove(
                                extendData[3],
                                extendData[5],
                                extendData[4],
                                extendData[0],
                                extendData[2],
                                extendData[2] === "PER_MONTH" ? "true" : "false"
                              )
                            }
                          >
                            {trash}
                          </div>
                          {extendData[5] === null ? (
                            <div
                              className='edit'
                              onClick={() =>
                                this.handleAdd(
                                  true,
                                  extendData[3],
                                  extendData[4],
                                  extendData[2],
                                  extendData[0],
                                  extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                                )
                              }
                            >
                              {editIcon}
                            </div>
                          ) : (
                            <div
                              className='edit'
                              onClick={() =>
                                this.handleAdd(
                                  true,
                                  extendData[3],
                                  extendData[4],
                                  extendData[2],
                                  extendData[0],
                                  extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1],
                                  true
                                )
                              }
                            >
                              {editIcon}
                            </div>
                          )}
                          <div className='container__table-item bulletList'></div>
                          <div className='container__table-item point'></div>
                          <div className='container__table-item name'>{extendData[0]}</div>
                        </div>
                        <div className='box'>
                          <div className={`container__table-item one right price ${extendData[2] === "PER_MONTH" ? "d-none" : ""}`}>{`${
                            extendData[2] !== "PER_MONTH" ? "€" + extendData[1] : ""
                          }`}</div>
                          <div
                            className={`container__table-item one right price ${
                              extendData[2] !== "PER_MONTH" && this.props.costCategory[index] === false ? "d-none" : ""
                            }`}
                          >{`${extendData[2] === "PER_MONTH" ? "€" + extendData[6] : ""}`}</div>
                          <div
                            className={`container__table-item one right price ${
                              extendData[2] !== "PER_MONTH" && this.props.costCategory[index] === false ? "d-none" : ""
                            }`}
                          >{`${extendData[2] === "PER_MONTH" ? "€" + extendData[1] : ""}`}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className='column'>
              <div className='container__body-progress'>
                <CircularProgressbarWithChildren
                  value={this.props.otherSummary}
                  styles={buildStyles({
                    pathColor: "#E94F37",
                    trailColor: "#eee",
                    strokeLinecap: "butt",
                  })}
                >
                  {/* Foreground path */}
                  <CircularProgressbarWithChildren
                    value={this.props.generalSummary}
                    styles={buildStyles({
                      trailColor: "transparent",
                      strokeLinecap: "butt",
                      pathColor: "#23E33E",
                    })}
                  >
                    <CircularProgressbarWithChildren
                      value={this.props.officeSummary}
                      styles={buildStyles({
                        trailColor: "transparent",
                        strokeLinecap: "butt",
                        pathColor: "#FFBD00",
                      })}
                    >
                      <CircularProgressbarWithChildren
                        value={this.props.transportationSummary}
                        styles={buildStyles({
                          trailColor: "transparent",
                          strokeLinecap: "butt",
                          pathColor: "#F20089",
                        })}
                      >
                        <CircularProgressbarWithChildren
                          value={this.props.professionalSummary}
                          styles={buildStyles({
                            trailColor: "transparent",
                            strokeLinecap: "butt",
                            pathColor: "#9800FF",
                          })}
                        >
                          <CircularProgressbar
                            value={this.props.establishmentSummary}
                            styles={buildStyles({
                              trailColor: "transparent",
                              strokeLinecap: "butt",
                              pathColor: "#0052FF",
                            })}
                          />
                        </CircularProgressbarWithChildren>
                      </CircularProgressbarWithChildren>
                    </CircularProgressbarWithChildren>
                  </CircularProgressbarWithChildren>
                </CircularProgressbarWithChildren>
                <div className='box'>
                  <div className='progress__header'>€{this.props.summary}</div>
                  <div className='progress__description'>
                    <Trans>companyType.cost</Trans> Y1
                  </div>
                </div>
              </div>
              <div className='container__table'>
                <div className='container__table-row'>
                  <div className='container__table-item point'></div>
                  <div className='container__table-item name'></div>
                  <div className='container__table-item one'>
                    <Trans>companyType.one</Trans>
                  </div>
                  <div className='container__table-item month'>
                    <Trans>companyType.month</Trans>
                  </div>
                  <div className='container__table-item year'>
                    <Trans>companyType.year</Trans>
                  </div>
                </div>
                {this.props.data.map((data, i) => (
                  <div className={`container__table-row ${data[3] === 0 ? "d-none" : ""}`}>
                    <div className='container__table-item point'>
                      <div className={`container__table-circle ${data[1]}`} />
                    </div>
                    <div className='container__table-item name'>{data[0]}</div>
                    <div className='container__table-item one award'>{data[5] !== "0" ? `€${data[5]}` : ""}</div>
                    <div className='container__table-item month'>{data[6] !== "0" ? `€${data[6]}` : ""}</div>
                    <div className='container__table-item year'>{data[7] !== "0" ? `€${data[7]}` : ""}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Add
            active={this.state.add}
            pid={this.state.pid}
            editCategory={this.state.editCategory}
            editType={this.state.editType}
            editDescription={this.state.editDescription}
            editAmount={this.state.editAmount}
            defaultSelectText={this.state.defaultSelectText}
            defaultSelectValue={this.state.defaultSelectValue}
            question={this.state.question}
            handleActive={this.handleAdd}
            handleSave={this.save}
          />
          <Confirm
            active={this.state.confirm}
            handleConfirm={this.handleConfirm}
            handleRemove={this.removeCost}
            message={<Trans>establishmentFlow.delete</Trans>}
            only={false}
          />
        </div>
      </>
    );
  }
}

export default CheckUp;
