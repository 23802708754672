// Simple floating message, display

import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import checkMsg from "./messagesBehavior";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Messages = ({status}) => {
    let checkMsgObject = checkMsg(status);

    return (
        <div>
            <Snackbar open={true}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>

                <Alert  severity={checkMsgObject.severity}>
                    {checkMsgObject.msg}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Messages;
