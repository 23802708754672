import React, { useState, useEffect } from "react";
import { TextareaAutosize, TextField, IconButton, Tooltip } from "@material-ui/core";
import CustomSelect from "../customSelect/customSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendar, InfoIcon } from "../survey/surveyConstant";
import { Trans } from "react-i18next";
import nl from "date-fns/locale/nl";

function Step2(props) {
  const [selectList] = useState([
    {
      id: 524,
      name: localStorage.getItem("i18nextLng") === "en" ? "Main occupation" : "Hoofdberoep",
      value: localStorage.getItem("i18nextLng") === "en" ? "Main occupation" : "Hoofdberoep",
      textKey: "question-option-main-occ",
    },
    {
      id: 523,
      name: localStorage.getItem("i18nextLng") === "en" ? "Secondary occupation" : "bijberoep",
      value: localStorage.getItem("i18nextLng") === "en" ? "Secondary occupation" : "bijberoep",
      textKey: "question-option-sec-occ",
    },
  ]);

  const [today, setToday] = useState("");
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    const date = new Date();
    const today = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }`;
    setToday(today);

    const initialStartDate = props.data.dateStart || "";
    if (initialStartDate) {
      setStartDate(new Date(initialStartDate));
    }
  }, [props.data.dateStart]);

  const handleDate = (date) => {
    props.handleData(
      "dateStart",
      `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      }`
    );
    setStartDate(date);
  };

  return (
    <div className='container__body container__body--hauto'>
      <div className='container__body-header'>
        <Trans>companyType.about</Trans>
      </div>
      <div className='container__body-box'>
        <div
          className={
            props.validateForm.dateStart !== undefined && props.validateForm.dateStart === "false"
              ? "container__body-input is-error"
              : "container__body-input"
          }
        >
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[0].questionText}{" "}
            <span className={props.validateForm.dateStart !== undefined && props.validateForm.dateStart === "false" ? "require is-error" : "require"}>
              *
            </span>
          </p>
          <DatePicker
            id='dateStart'
            selected={startDate}
            onChange={handleDate}
            minDate={new Date(today)}
            placeholderText={localStorage.getItem("i18nextLng") === "en" ? "Select a date" : "\n" + "Selecteer een datum"}
            locale={localStorage.getItem("i18nextLng") !== "en" && nl}
            dateFormat='dd/MM/yyyy'
            onKeyDown={(e) => e.preventDefault()}
          />
          <div className='calendarIcon'>{calendar}</div>
        </div>
        <div
          className={
            props.validateForm.companyName !== undefined && props.validateForm.companyName === "false"
              ? "container__body-input is-error"
              : "container__body-input"
          }
        >
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[1].questionText}{" "}
            <span
              className={props.validateForm.companyName !== undefined && props.validateForm.companyName === "false" ? "require is-error" : "require"}
            >
              *
            </span>
            <Tooltip title={<Trans>companyType.tooltip3</Trans>} placement={"right"}>
              <IconButton aria-label='delete'>{InfoIcon}</IconButton>
            </Tooltip>
          </p>
          <TextField
            label={""}
            variant={"outlined"}
            size={"small"}
            value={props.data.companyName}
            onChange={(e) => props.handleData("companyName", e.target.value)}
          />
        </div>
      </div>
      <div className='container__body-box'>
        <div
          className={
            props.validateForm.occupation !== undefined && props.validateForm.occupation === "false"
              ? "container__body-select is-error"
              : "container__body-select"
          }
        >
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[2].questionText}{" "}
            <span
              className={props.validateForm.occupation !== undefined && props.validateForm.occupation === "false" ? "require is-error" : "require"}
            >
              *
            </span>
          </p>
          <CustomSelect
            defaultText={
              sessionStorage.getItem("companyType") &&
              JSON.parse(sessionStorage.getItem("companyType")).occupation[0] &&
              JSON.parse(sessionStorage.getItem("companyType")).occupation[0].text
            }
            optionsList={selectList}
            handleData={props.handleData}
            type={"occupation"}
          />
        </div>
      </div>
      <div className='container__body-box'>
        <div
          className={
            props.validateForm.companyActivities !== undefined && props.validateForm.companyActivities === "false"
              ? "container__body-input is-error"
              : "container__body-input"
          }
        >
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[3].questionText}{" "}
            <span
              className={
                props.validateForm.companyActivities !== undefined && props.validateForm.companyActivities === "false"
                  ? "require is-error"
                  : "require"
              }
            >
              *
            </span>
          </p>
          <TextField
            label={""}
            variant={"outlined"}
            size={"small"}
            value={props.data.companyActivities}
            onChange={(e) => props.handleData("companyActivities", e.target.value)}
          />
        </div>
      </div>
      <div className='container__body-box'>
        <div className='container__body-textarea'>
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[4].questionText}
          </p>
          <TextareaAutosize
            minRows={5}
            maxRows={5}
            value={props.data.companyActivitiesDescription}
            onChange={(e) => props.handleData("companyActivitiesDescription", e.target.value)}
          />
        </div>
      </div>
      <div className='container__body-box'>
        <div
          className={
            props.validateForm.postalCode !== undefined && props.validateForm.postalCode === "false"
              ? "container__body-input is-error"
              : "container__body-input"
          }
        >
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[5].questionText}{" "}
            <span
              className={props.validateForm.postalCode !== undefined && props.validateForm.postalCode === "false" ? "require is-error" : "require"}
            >
              *
            </span>
            <Tooltip title={<Trans>companyType.tooltip4</Trans>} placement={"right"}>
              <IconButton aria-label='delete'>{InfoIcon}</IconButton>
            </Tooltip>
          </p>
          <TextField
            label={""}
            variant={"outlined"}
            size={"small"}
            value={props.data.postalCode}
            onChange={(e) => props.handleData("postalCode", e.target.value)}
          />
        </div>
        <div className='container__body-input'>
          <p className='container__body-text'>
            {sessionStorage.getItem("companyTypeData") &&
              JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[1].steps[0].questions[6].questionText}
          </p>
          <TextField
            label={""}
            variant={"outlined"}
            size={"small"}
            pattern='^[a-zA-Z]'
            value={props.data.city}
            onChange={(e) => props.handleData("city", e.target.value)}
            autoComplete={"new-password"}
          />
        </div>
      </div>
      {(props.validateForm.dateStart !== undefined && props.validateForm.dateStart === "false") ||
      (props.validateForm.companyName !== undefined && props.validateForm.companyName === "false") ||
      (props.validateForm.occupation !== undefined && props.validateForm.occupation === "false") ||
      (props.validateForm.companyActivities !== undefined && props.validateForm.companyActivities === "false") ||
      (props.validateForm.postalCode !== undefined && props.validateForm.postalCode === "false") ? (
        <p className='errorMessage'>
          <Trans>companyType.valid</Trans>
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Step2;
