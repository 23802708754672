import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import Menu from "../menu/menu";
import "./_main-container.scss";
import error404 from "../error/error404";
import { Link } from "react-router-dom";
import getPersonData from "../settings/getPersonData";
import GetUserData from "../users/getUserData";
import Login from "../login/login";
import Password from "../settings/password";
import ActivateUser from "../login/activateUser";
import ResetPassword from "../login/resetPassword";
import LoginGoogle from "../login/loginGoogle";
import SignUp from "../login/signUp";
import { CookiesProvider } from "react-cookie";
import * as Endpoint from "../../configFile";
import { Trans, withTranslation } from "react-i18next";
import { SelectCustom } from "../select/select";
import EstablishmentFlow from "../establishmentFlow/establishmentFlow";
import CompanyType from "../companyType/companyType";
import Dashboard from "../dashboard/dashboard";
import Support from "../support/support";
import Admin from "../admin/admin";
import Parameters from "../parameters/parameters";
import Profile from "../profile/profile";
import Files from "../files/files";
import Cookies from "universal-cookie";
import AdminAllClients from "../admin/adminAllClients";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("login") === "true" || localStorage.getItem("login") === "true" ? (
          children(props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const UserPrivateRoute = ({ children }) => {
  return (
    <Route
      render={(props) =>
        sessionStorage.getItem("login") === "true" || localStorage.getItem("login") === "true" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const languageOptions = [
  { value: "en", label: "en" },
  { value: "nl", label: "nl" },
];

function MainContainer(props) {
  console.log(sessionStorage.getItem("login") === "true", localStorage.getItem("login") === "true");
  const [userOptionIsActive, setUserOptionIsActive] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [login, setLogin] = useState(localStorage.getItem("login"));
  const [width, setWidth] = useState(window.innerWidth);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [language, setLanguage] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).language !== null
      ? JSON.parse(localStorage.getItem("user")).language
      : { label: "en", value: "en" }
  );

  const nodeRef = useRef(null);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleClick = () => {
    if (!userOptionIsActive) {
      document.addEventListener("click", handleOutsideClick, false);
    } else {
      document.removeEventListener("click", handleOutsideClick, false);
    }

    setUserOptionIsActive(!userOptionIsActive);
  };

  const handleOutsideClick = (e) => {
    if (!nodeRef.current.contains(e.target)) {
      handleClick();
    }
  };

  const changeLanguage = (event) => {
    let newLang = event.value;
    props.i18n.changeLanguage(newLang);
    setLanguage(event);

    const cookies = new Cookies();

    fetch(Endpoint.changeLanguage, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
      },
      body: event.label,
    })
      .then((resp) => {
        if (!resp.ok) throw resp;
        return resp.json();
      })
      .then((data) => {
        localStorage.setItem("user", JSON.stringify(data));
      });
  };

  const withRoot = (Component, type, props) => {
    const isMobile = width <= 768;
    let userData = JSON.parse(localStorage.getItem("user"));

    if (userData === null) {
      userData = { id: "", name: "", surname: "" };
    }

    return (
      <main className='container'>
        <Menu role={role} permissions={user ? user.permissions : null} isMobile={isMobile} component={type} />
        <section className='container__content'>
          <header className='header'>
            <div ref={nodeRef}>
              <button className='user-info' onClick={handleClick}>
                <h2 className='user-info__name' id={userData.id}>
                  {userData.name} {userData.surname !== null ? userData.surname : ""}
                </h2>
                {userData.picture === null ? (
                  <div className='user-info__avatar avatar--image' />
                ) : (
                  <div className='user-info__avatar'>
                    <div className='picture' style={{ backgroundImage: `url(${userData.picture})` }} />
                  </div>
                )}
              </button>
              {userOptionIsActive && (
                <div className='user-option'>
                  <ul className='user-option__list'>
                    <li className='user-option__item'>
                      <Link to='/settings' onClick={handleClick}>
                        <Trans>menu.profile</Trans>
                      </Link>
                    </li>
                    <li className={`user-option__item ${userData.provider !== "local" && "d-none"}`}>
                      <Link to='/password' onClick={handleClick}>
                        <Trans>menu.password</Trans>
                      </Link>
                    </li>
                    <li className='user-option__item'>
                      <Link to='/login'>
                        <Trans>menu.log-out</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className='change-language'>
              <SelectCustom selectedValue={language} isEdit={true} options={languageOptions} onChange={(event) => changeLanguage(event)} />
            </div>
          </header>
          <Component {...props} typeSettings={type} isMobile={isMobile} role={role} onlyRead={false} />
        </section>
      </main>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <CookiesProvider>
      <Router basename={"/bizantium/"}>
        <Switch>
          <PrivateRoute path='/users'>{(props) => withRoot(GetUserData, "users & permissions", props)}</PrivateRoute>
          <PrivateRoute path='/settings' exact>
            {(props) => withRoot(getPersonData, "settings", props)}
          </PrivateRoute>
          <PrivateRoute path='/upgradePlan' exact>
            {(props) => withRoot(getPersonData, "upgradePlan", props)}
          </PrivateRoute>
          <PrivateRoute path='/password' exact>
            {(props) => withRoot(Password, "password", props)}
          </PrivateRoute>
          <UserPrivateRoute path='/files' exact>
            {<Files />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/profile' exact>
            {<Profile />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/parameters' exact>
            {<Parameters />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/admin' exact>
            {<Admin />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/all-clients' exact>
            {<AdminAllClients />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/support' exact>
            {<Support />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/dashboard' exact>
            {<Dashboard />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/company' exact>
            {<CompanyType />}
          </UserPrivateRoute>
          <UserPrivateRoute path='/establishment-flow' exact>
            {<EstablishmentFlow />}
          </UserPrivateRoute>
          <Route path='/login' exact>
            {<Login />}
          </Route>
          <Route path='/activate:key?'>{<ActivateUser />}</Route>
          <Route path='/reset:key?'>{<ResetPassword />}</Route>
          <Route path='/signup' exact>
            {<SignUp />}
          </Route>
          <Route path='/loginGoogle'>{<LoginGoogle />}</Route>
          <Route path='/' exact>
            {<Login />}
          </Route>
          <Route component={error404} />
        </Switch>
      </Router>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        transition={Slide}
        theme={"colored"}
      />
    </CookiesProvider>
  );
}

export default withTranslation()(MainContainer);
