import React from "react";
import {Trans} from 'react-i18next';

export const tableHeaderUser = [
    { id: 'name', label: <Trans>profile.name</Trans>, mobile: true},
    { id: 'phone', label: <Trans>profile.phone</Trans>, mobile: false },
    { id: 'email', label: <Trans>profile.email</Trans>, mobile: false },
    { id: 'position', label: <Trans>profile.position</Trans>, mobile: true },
    { id: 'option', label: '', typeOption: ['edit', 'delete'], mobile: false}
];

export const addUserSelectOption = [
    { value: 'admin', label: 'Administrators'},
    { value: 'user', label: 'Users'},
];

export const roleStart = {
    position: '',
    displayPermissions: false,
    editPermissions: false,
    deletePermissions: false,

    //Users
    displayUsers: false,
    editUsers: false,
    deleteUsers: false,

    //Clients
    displayClients: false,
    editClients: false,
    deleteClients: false,

    //Survey
    displaySurvey: false,
    editSurvey: false,
    deleteSurvey: false,

    //FAQ
    displayFAQ: false,
    editFAQ: false,
    deleteFAQ: false,

    //Help
    displayHelp: false,
    editHelp: false,
    deleteHelp: false,

    //Files
    displayFiles: false,
    editFiles: false,
    deleteFiles: false,
};