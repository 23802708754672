import React, { useState, useEffect, useRef } from 'react';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import './admin.scss';
import AdminEstablishment from "./adminEstablishment";
import AdminFaq from "./adminFaq";
import AdminFiles from "./adminFiles";
import AdminChecklist from "./adminChecklist";
import AdminCompanyTypeSurvey from "./adminCompanyTypeSurvey";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import {initLoader, removeLoader} from "../loader/loader";

const Admin = (props) => {
    const [step, setStep] = useState(1);
    const [quickstart, setQuickstart] = useState(false);
    const [filesData, setFilesData] = useState([]);
    const [shareholdersFilesData, setShareholdersFilesData] = useState([]);
    const [supportData, setSupportData] = useState([]);
    const [assigmentDate, setAssigmentDate] = useState('');
    const [incomeTax, setIncomeTax] = useState('');
    const [bookkeepingFee, setBookkeepingFee] = useState('');
    const [enterprise, setEnterprise] = useState('');
    const [necessaryId, setNecessaryId] = useState(0);
    const [checklistData, setChecklistData] = useState([]);
    const [activitiesData, setActivitiesData] = useState([]);
    const [outputData, setOutputData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [form, setForm] = useState({
        name: JSON.parse(localStorage.getItem('user')).name,
        surname: JSON.parse(localStorage.getItem('user')).surname,
        email: JSON.parse(localStorage.getItem('user')).email,
        phone: JSON.parse(localStorage.getItem('user')).phone,
        nationality: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).nationality,
        dateStart: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).dateStart,
        companyName: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyName,
        occupation: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).occupation,
        companyActivities: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivities,
        companyActivitiesDescription: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivitiesDescription,
        postalCode: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).postalCode,
        city: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).city,
    });
    const [companyTypeData, setCompanyTypeData] = useState([]);
    const [financialData, setFinancialData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [deleteDocument, setDeleteDocument] = useState(0);
    const [documentUrl, setDocumentUrl] = useState('');
    const [isSignVisible, setIsSignVisible] = useState(false);
    const [signStatus, setSignStatus] = useState(false);
    const [LogoCompany, setLogoCompany] = useState('');
    const [selectData, setSelectData] = useState([]);
    const [nData, setNData] = useState([]);
    const [graphSummary, setGraphSummary] = useState([]);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingFlow, setLoadingFlow] = useState(true);
    const [oneOffSummary, setOneOffSummary] = useState(0);

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const updateStep = (value) => {
        setStep(value);
    }

    const formRef = useRef();

    useEffect(() => {
        initLoader(document.querySelector('.container__body'));
        survey();
        setTimeout(() => {
            files();
            checklist();
            establishmentFlow();
            support();
            output();
        }, 1500);

    }, []);

    const output = () => {
        setLoadingFlow(true);
        Connect(`${Endpoint.getClientsData}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            fetchAndSetImage(response.logoUri, 1);
            let summarySharesAmount = 0;
            response.shareholders.map((data) => {
                summarySharesAmount += data.sharesAmount;
            })
            response.shareholders.map((data) => {
                data.total = data.sharesAmount !== null ? ((parseInt(data.sharesAmount)/summarySharesAmount) * 100).toFixed(2) : 0
            })
            setOutputData(response.ctOutputData);
            setCompanyData(response);
        });
        Connect(`${Endpoint.getAdminCostsUser}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            let costData = [
                ['Establishment costs', '', 'One-off', 0, [], 0, 0],
                ['Professional services', 'purple', 'Per year', 0, [], 0, 0],
                ['Transportation costs', 'pink', 'Per year', 0, [], 0, 0],
                ['Office expenses', 'yellow', 'Per year', 0, [], 0, 0],
                ['General & Admin', 'green', 'Per year', 0, [], 0, 0],
                ['Other', 'red', 'Per year', 0, [], 0, 0]
            ];
            let summary = 0;
            let oneOffSummary = 0;
            let establishment = 0;
            let professional = 0;
            let transportation = 0;
            let office = 0;
            let general = 0;
            let other = 0;
            response.map((data) => {
                summary += data.isPeriodic === true ? data.amount * 12 : data.amount;
                oneOffSummary += data.isPeriodic !== true ? data.amount : 0;
                if (data.costType === 'ESTABLISHMENT') {
                    establishment += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[0][6] += data.amount;
                    } else {
                        costData[0][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[0][4].push(array);
                }
                if (data.costType === 'PRO_SERVICES') {
                    professional += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[1][6] += data.amount;
                    } else {
                        costData[1][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[1][4].push(array);
                }
                if (data.costType === 'TRANSPORTATION') {
                    transportation += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[2][6] += data.amount;
                    } else {
                        costData[2][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[2][4].push(array);
                }
                if (data.costType === 'OFFICE') {
                    office += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[3][6] += data.amount;
                    } else {
                        costData[3][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[3][4].push(array);
                }
                if (data.costType === 'GENERAL') {
                    general += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[4][6] += data.amount;
                    } else {
                        costData[4][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[4][4].push(array);
                }
                if (data.costType === 'OTHER') {
                    other += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[5][6] += data.amount;
                    } else {
                        costData[5][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[5][4].push(array);
                }
            })
            costData[0][3] = establishment;
            costData[1][3] = professional;
            costData[2][3] = transportation;
            costData[3][3] = office;
            costData[4][3] = general;
            costData[5][3] = other;
            let array = [establishment, professional, transportation, office, general, other];
            array.sort((a, b) => a - b);

            const colorMapping = {
                [establishment]: '#0052FF',
                [professional]: '#0052FF',
                [transportation]: '#0052FF',
                [office]: '#0052FF',
                [general]: '#0052FF',
                [other]: '#0052FF',
                [costData[1][3]]: '#9800FF',
                [costData[2][3]]: '#F20089',
                [costData[3][3]]: '#FFBD00',
                [costData[4][3]]: '#23E33E',
                [costData[5][3]]: '#E94F37',
            };

            let graphSummary = array.map((data, index) => [colorMapping[data], data]);
            graphSummary[0][1] = graphSummary[0][1]/summary * 100;
            graphSummary[1][1] = graphSummary[1][1]/summary * 100;
            graphSummary[2][1] = graphSummary[2][1]/summary * 100;
            graphSummary[3][1] = graphSummary[3][1]/summary * 100;
            graphSummary[4][1] = graphSummary[4][1]/summary * 100;
            graphSummary[5][1] = graphSummary[5][1]/summary * 100;
            for (let i = 0; i <= 5; i++) {
                costData[i][3] = addCommas(removeNonNumeric(costData[i][3].toFixed(0)));
                costData[i][4].map((data) => {
                    data[1] = addCommas(removeNonNumeric(data[1].toFixed(0)));
                    data[6] = addCommas(removeNonNumeric(data[6].toFixed(0)));
                })
                costData[i][7] = addCommas(removeNonNumeric((costData[i][6]*12).toFixed(0)));
                costData[i][5] = addCommas(removeNonNumeric(costData[i][5].toFixed(0)));
                costData[i][6] = addCommas(removeNonNumeric(costData[i][6].toFixed(0)));
            }
            sessionStorage.setItem('costData', JSON.stringify(costData));
            summary = addCommas(removeNonNumeric(summary.toFixed(0)));
            oneOffSummary = addCommas(removeNonNumeric(oneOffSummary.toFixed(0)));
            setSummary(summary);
            setData(costData);
            setGraphSummary(graphSummary);
            setOneOffSummary(oneOffSummary);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
            setTimeout(() => {
                setLoadingFlow(false);
            }, 5000);
        })
    }

    const support = () => {
        Connect(`${Endpoint.getFAQs}`, 'GET').then(response => {
            setSupportData(response);
        });
    }

    const survey = () => {
        Connect(`${Endpoint.getAdminSurvey}/company-type/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            const newForm = {
                name: response.surveyPages[0].steps[0].questions[0].answers[0]
                    && response.surveyPages[0].steps[0].questions[0].answers[0].textAnswer,
                surname: response.surveyPages[0].steps[0].questions[1].answers[0]
                    && response.surveyPages[0].steps[0].questions[1].answers[0].textAnswer,
                email: sessionStorage.getItem('userEmail'),
                phone: response.surveyPages[0].steps[0].questions[3].answers[0]
                    && response.surveyPages[0].steps[0].questions[3].answers[0].textAnswer,
                nationality: createArray(response.surveyPages[0].steps[0].questions[4].answers[0] && response.surveyPages[0].steps[0].questions[4].answers[0].questionOptions),
                dateStart: response.surveyPages[1].steps[0].questions[0].answers[0]
                    && response.surveyPages[1].steps[0].questions[0].answers[0].textAnswer,
                companyName: response.surveyPages[1].steps[0].questions[1].answers[0]
                    && response.surveyPages[1].steps[0].questions[1].answers[0].textAnswer,
                occupation: createArray(response.surveyPages[1].steps[0].questions[2].answers[0] && response.surveyPages[1].steps[0].questions[2].answers[0].questionOptions),
                companyActivities: response.surveyPages[1].steps[0].questions[3].answers[0]
                    && response.surveyPages[1].steps[0].questions[3].answers[0].textAnswer,
                companyActivitiesDescription: response.surveyPages[1].steps[0].questions[4].answers[0]
                    && response.surveyPages[1].steps[0].questions[4].answers[0].textAnswer,
                postalCode: response.surveyPages[1].steps[0].questions[5].answers[0]
                    && response.surveyPages[1].steps[0].questions[5].answers[0].textAnswer,
                city: response.surveyPages[1].steps[0].questions[6].answers[0]
                    && response.surveyPages[1].steps[0].questions[6].answers[0].textAnswer,
                revenue: response.surveyPages[2].steps[0].questions[0].answers[0] && response.surveyPages[2].steps[0].questions[0].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[0].answers[0].numericAnswer)),
                expectNextYear: createArray(response.surveyPages[2].steps[0].questions[1].answers[0] && response.surveyPages[2].steps[0].questions[1].answers[0].questionOptions),
                revenueNextYear: response.surveyPages[2].steps[0].questions[2].answers[0] && response.surveyPages[2].steps[0].questions[2].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[2].answers[0].numericAnswer)),
                expectThirdYear: createArray(response.surveyPages[2].steps[0].questions[3].answers[0] && response.surveyPages[2].steps[0].questions[3].answers[0].questionOptions),
                revenueThirdYear: response.surveyPages[2].steps[0].questions[4].answers[0] && response.surveyPages[2].steps[0].questions[4].answers[0].numericAnswer !== null ? addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[4].answers[0].numericAnswer)) : '',
                transport: createArray(response.surveyPages[3].steps[0].questions[0].answers[0] && response.surveyPages[3].steps[0].questions[0].answers[0].questionOptions),
                transportCar: createArray(response.surveyPages[3].steps[0].questions[1].answers[0] && response.surveyPages[3].steps[0].questions[1].answers[0].questionOptions),
                transportMotorcycle: createArray(response.surveyPages[3].steps[0].questions[2].answers[0] && response.surveyPages[3].steps[0].questions[2].answers[0].questionOptions),
                transportPublic: createArray(response.surveyPages[3].steps[0].questions[3].answers[0] && response.surveyPages[3].steps[0].questions[3].answers[0].questionOptions),
                transportBicycle: createArray(response.surveyPages[3].steps[0].questions[4].answers[0] && response.surveyPages[3].steps[0].questions[4].answers[0].questionOptions),
                transportTaxi: response.surveyPages[3].steps[0].questions[5].answers[0] && response.surveyPages[3].steps[0].questions[5].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[3].steps[0].questions[5].answers[0].numericAnswer)),
                usuallyWork: createArray(response.surveyPages[3].steps[1].questions[0].answers[0] && response.surveyPages[3].steps[1].questions[0].answers[0].questionOptions),
                officeSpace: response.surveyPages[3].steps[1].questions[1].answers[0] && response.surveyPages[3].steps[1].questions[1].answers[0].numericAnswer !== null
                    ? addCommas(removeNonNumeric(response.surveyPages[3].steps[1].questions[1].answers[0].numericAnswer)) : 1,
                officeSpaceRent: response.surveyPages[3].steps[1].questions[2].answers[0] && response.surveyPages[3].steps[1].questions[2].answers[0].numericAnswer !== null
                    ? addCommas(removeNonNumeric(response.surveyPages[3].steps[1].questions[2].answers[0].numericAnswer)) : 1,
                usedHome: response.surveyPages[3].steps[1].questions[3].answers[0] && response.surveyPages[3].steps[1].questions[3].answers[0].numericAnswer !== null ? response.surveyPages[3].steps[1].questions[3].answers[0].numericAnswer : 0,
                applicableSituation: createArray(response.surveyPages[3].steps[1].questions[4].answers[0] && response.surveyPages[3].steps[1].questions[4].answers[0].questionOptions),
                homeOffice: createArray(response.surveyPages[3].steps[1].questions[5].answers[0] && response.surveyPages[3].steps[1].questions[5].answers[0].questionOptions),
                restaurants: createArray(response.surveyPages[3].steps[2].questions[0].answers[0] && response.surveyPages[3].steps[2].questions[0].answers[0].questionOptions),
                pensionSaving: createArray(response.surveyPages[3].steps[2].questions[1].answers[0] && response.surveyPages[3].steps[2].questions[1].answers[0].questionOptions),
                goodsToSell: createArray(response.surveyPages[3].steps[2].questions[2].answers[0] && response.surveyPages[3].steps[2].questions[2].answers[0].questionOptions),
                buyingGoods: response.surveyPages[3].steps[2].questions[3].answers[0] && response.surveyPages[3].steps[2].questions[3].answers[0].numericAnswer,
                otherCategories: createArray(response.surveyPages[3].steps[3].questions[0].answers[0] && response.surveyPages[3].steps[3].questions[0].answers[0].questionOptions),
            }
            setForm(newForm);
            formRef.current = newForm;
            setTimeout(() => {
                sessionStorage.setItem('companyType', JSON.stringify(newForm));
                sessionStorage.setItem('companyTypeData', JSON.stringify(response));
                setCompanyTypeData(response);
                removeLoader(document.querySelector('.container__body'));
            }, 1);
        });
    }

    const establishmentFlow = () => {
        if (step !== 1) {
            initLoader(document.querySelector('.admin'));
        }
        Connect(`${Endpoint.getFinancialCalendar}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            setFinancialData(response);
        });
        Connect(`${Endpoint.getCompanyActivities}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            setActivitiesData(response);
        })
        Connect(`${Endpoint.mainActivities}`, 'GET').then(response => {
            setSelectData(response.map(data => {
                return { id: 1, name: data.templateName, value: data.templateName }
            }));
            setNData(response);
        })
    }

    const files = () => {
        Connect(`${Endpoint.getAdminFiles}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            if (response.status !== 404) {
                setFilesData(response);
            }
        })
        Connect(`${Endpoint.shareholdersAdminFiles}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            setShareholdersFilesData(response);
        })
        Connect(`${Endpoint.necessaryValues}/${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            setNecessaryId(response && response.id);
            setAssigmentDate(response && response.assignmentStartDate);
            setIncomeTax(response && response.incomeTaxStartDate);
            setBookkeepingFee(response && response.bookkeepingFee);
            setEnterprise(response && response.enterpriseNumber);
        })
    }

    const checklist = () => {
        Connect(`${Endpoint.getChecklist}?companyId=${sessionStorage.getItem('userID')}`, 'GET').then(response => {
            setChecklistData(response);
        });
    }

    const fetchAndSetImage = (photoUrl, index) => {
        const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
        const headers = {
            'Authorization': token ? `Bearer ${token}` : '',
        };

        fetch(photoUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Network response was not ok: ${res.status}`);
                }
                return res.blob();
            })
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob);
                if (index === 1) {
                    setLogoCompany(imageUrl);
                }
                // setTimeout(() => {
                    removeLoader(document.querySelector('.admin'));
                // }, 500);

            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const createArray = (element) => {
        let array = [];
        if (element) {
            element.forEach((data) => {
                array.push({
                    id: data.id,
                    text: data.text
                });
            });
        }
        return array;
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const updateForm = (param, value) => {
        setForm({
            ...form,
            [param]: value
        });
        setTimeout(() => {
            sessionStorage.setItem('companyType', JSON.stringify({
                ...form,
                [param]: value
            }));
        }, 1);
    }

    return (
        <div className="container">
            <Navbar currentStep={step} handleQuickstart={handleQuickstart} handleStep={updateStep} name={'Admin panel'} survey={true} desc={<Trans>admin.description</Trans>} handleFiles={files} handleChecklist={checklist} handleEstablishmentFlow={establishmentFlow} handleSurvey={survey} handleSupport={support} handleOutput={output} handleEditable={setEditable} handleFullScreen={setFullScreen} handlePdfUrl={setPdfUrl} handleDeleteDocument={setDeleteDocument} handleDocumentUrl={setDocumentUrl} handleIsSignVisible={setIsSignVisible} handleSignStatus={setSignStatus} {...props} />
            <Menu role={'admin'} permissions={null} isMobile={false} component={'support'}/>
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <div className="admin">
                {step === 1 ?
                    <AdminCompanyTypeSurvey form={form} handleForm={updateForm} companyTypeData={companyTypeData} outputData={outputData} handleCompanyData={output} handleSurvey={survey} graphSummary={graphSummary} data={data} summary={summary} loading={loading} handleLoading={setLoading} {...props} />
                    :
                    <></>
                }
                {step === 2 ?
                    <AdminEstablishment activitiesData={activitiesData} handleData={setActivitiesData} financialData={financialData} handleFinancialData={setFinancialData} companyData={companyData} oneOffSummary={oneOffSummary} handleCompanyData={output} LogoCompany={LogoCompany} selectData={selectData} nData={nData} graphSummary={graphSummary} data={data} summary={summary} handleFiles={files} form={form} loading={loadingFlow} {...props} />
                    :
                    <></>
                }
                {step === 3 ?
                    <AdminFiles data={filesData} shareholdersData={shareholdersFilesData} assigmentDate={assigmentDate} handleAssigmentDate={setAssigmentDate} incomeTax={incomeTax} handleIncomeTax={setIncomeTax} bookkeepingFee={bookkeepingFee} handleBookkeepingFee={setBookkeepingFee} enterprise={enterprise} handleEnterprise={setEnterprise} necessaryId={necessaryId} companyData={companyData} handleData={files} editable={editable} handleEditable={setEditable} fullScreen={fullScreen} handleFullScreen={setFullScreen} pdfUrl={pdfUrl} handlePdfUrl={setPdfUrl} deleteDocument={deleteDocument} handleDeleteDocument={setDeleteDocument} documentUrl={documentUrl} handleDocumentUrl={setDocumentUrl} isSignVisible={isSignVisible} handleIsSignVisible={setIsSignVisible} signStatus={signStatus} handleSignStatus={setSignStatus} {...props} />
                    :
                    <></>
                }
                {step === 4 ?
                    <AdminChecklist data={checklistData} handleData={checklist} {...props} />
                    :
                    <></>
                }
                {step === 5 ?
                    <AdminFaq data={supportData} handleData={support} {...props} />
                    :
                    <></>
                }
            </div>
        </div>
    );
}

export default withTranslation()(withCookies(Admin));
