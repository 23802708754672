import React, { useState, useEffect } from "react";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import './establishmentFlow.scss';
import ShareholderStructure from "./shareholderStructure";
import CompanyDetails from "./companyDetails";
import Revenue from "./revenue";
import Remuneration from "./remuneration";
import Assets from "./assets";
import Loans from "./loans";
import PersonalSituation from "./personalSituation";
import CheckUp from "./checkUp";
import Mobility from "./mobility";
import Finish from "./finish";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import Quickstart from "../quickstart/quickstart";
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import {initLoader, removeLoader} from "../loader/loader";

const EstablishmentFlow = (props) => {
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(0);
    const [quickstart, setQuickstart] = useState(false);
    const [validateForm, setValidateForm] = useState([]);
    const [form] = useState({});
    const [shareholderData, setShareholderData] = useState({
        id: 0,
        fullName: '',
        sharesAmount: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: ''
    });
    const [summary, setSummary] = useState(0);
    const [data, setData] = useState( [
        [<Trans>companyType.typeCost1</Trans>, '', 'One-off', 0, [], 0, 0],
        [<Trans>companyType.typeCost2</Trans>, 'purple', 'Per year', 0, [], 0, 0],
        [<Trans>companyType.typeCost3</Trans>, 'pink', 'Per year', 0, [], 0, 0],
        [<Trans>companyType.typeCost4</Trans>, 'yellow', 'Per year', 0, [], 0, 0],
        [<Trans>companyType.typeCost5</Trans>, 'green', 'Per year', 0, [], 0, 0],
        [<Trans>companyType.typeCost6</Trans>, 'red', 'Per year', 0, [], 0, 0]
    ]);
    const [establishmentSummary, setEstablishmentSummary] = useState(0);
    const [professionalSummary, setProfessionalSummary] = useState(0);
    const [transportationSummary, setTransportationSummary] = useState(0);
    const [officeSummary, setOfficeSummary] = useState(0);
    const [generalSummary, setGeneralSummary] = useState(0);
    const [otherSummary, setOtherSummary] = useState(0);
    const [costCategory, setCostCategory] = useState([false, false, false, false, false, false]);
    const [graphSummary, setGraphSummary] = useState([]);
    const [selectList, setSelectList] = useState([
        { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue' : 'Simpele inkomsten', value: 'SIMPLE' },
        { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Service revenue' : 'Omzet uit diensten', value: 'SERVICE' },
        { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'Transactional revenue' : 'Transactionele inkomsten', value: 'TRANSACTIONAL' },
        { id: 4, name: localStorage.getItem('i18nextLng') === 'en' ? 'Selling goods' : 'Goederen verkopen', value: 'SELLING_GOODS' },
        { id: 5, name: localStorage.getItem('i18nextLng') === 'en' ? 'One-off revenue' : 'Eenmalige omzet', value: 'ONE_OFF' }
    ]);
    const [selectYear, setSelectYear] = useState([
        { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1', value: localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1' },
        { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2', value: localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2' },
        { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3', value: localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3' }
    ]);
    const [defaultSelect, setDefaultSelect] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue' : 'Simpele inkomsten');

    useEffect(() => {
        initLoader(document.querySelector('.container__box'));
        setTimeout(() => {
            removeLoader(document.querySelector('.container__box'));
        }, 1500);
        Connect(`${Endpoint.getUserSurvey}/company-type`, 'GET').then(response => {
            sessionStorage.setItem('companyTypeId', JSON.stringify(response && response.id));

        });

        return () => {
            sessionStorage.removeItem('companyTypeId');
        }
    }, []);

    const handleRevenue = () => {
        setSelectList([
            { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue' : 'Simpele inkomsten', value: 'SIMPLE' },
            { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Service revenue' : 'Omzet uit diensten', value: 'SERVICE' },
            { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'Transactional revenue' : 'Transactionele inkomsten', value: 'TRANSACTIONAL' },
            { id: 4, name: localStorage.getItem('i18nextLng') === 'en' ? 'Selling goods' : 'Goederen verkopen', value: 'SELLING_GOODS' },
            { id: 5, name: localStorage.getItem('i18nextLng') === 'en' ? 'One-off revenue' : 'Eenmalige omzet', value: 'ONE_OFF' }
        ]);
        setSelectYear([
            { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1', value: localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1' },
            { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2', value: localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2' },
            { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3', value: localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3' }
        ]);
        setDefaultSelect(localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue' : 'Simpele inkomsten');
    }

    const updateStep = (value) => {
        if (value > 0 && value < 11) {
            let newProgress = 0;
            if (value === 2) {
                newProgress = 12
            }
            if (value === 3) {
                newProgress = 25
            }
            if (value === 4) {
                newProgress = 50
            }
            if (value === 5) {
                newProgress = 60
            }
            if (value === 6) {
                newProgress = 70
            }
            if (value === 7) {
                newProgress = 78
            }
            if (value === 8) {
                newProgress = 85
            }
            if (value === 9) {
                newProgress = 92
            }
            if (value === 10) {
                newProgress = 100
            }
            setStep(value);
            setProgress(newProgress);
        }
    }

    const handleValidate = (value) => {
        setValidateForm(value);
    }

    const handleQuickstart = (value) => {
        setQuickstart(value)
    }

    const handleShareholderData = (id, fullName, sharesAmount, street, houseNumber, postalCode, city) => {
        setShareholderData({
            id,
            fullName,
            sharesAmount,
            street,
            houseNumber,
            postalCode,
            city
        })
    }

    const saveFetchShareholder = (id, fullName, sharesAmount, street, houseNumber, postalCode, city) => {
        let body = {
            id,
            fullName,
            sharesAmount,
            street,
            houseNumber,
            postalCode,
            city
        }
        if (body.id !== 0) {
            Connect(`${Endpoint.getShareholders}`, 'PUT', body).then(response => {
            })
        }
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const checkup = () => {
        // sessionStorage.setItem('costData', JSON.stringify(data));
        Connect(`${Endpoint.getCostsUser}`, 'GET').then(response => {
            let costData = [
                [<Trans>companyType.typeCost1</Trans>, '', 'One-off', 0, [], 0, 0],
                [<Trans>companyType.typeCost2</Trans>, 'purple', 'Per year', 0, [], 0, 0],
                [<Trans>companyType.typeCost3</Trans>, 'pink', 'Per year', 0, [], 0, 0],
                [<Trans>companyType.typeCost4</Trans>, 'yellow', 'Per year', 0, [], 0, 0],
                [<Trans>companyType.typeCost5</Trans>, 'green', 'Per year', 0, [], 0, 0],
                [<Trans>companyType.typeCost6</Trans>, 'red', 'Per year', 0, [], 0, 0]
            ];
            let summary = 0;
            let establishment = 0;
            let professional = 0;
            let transportation = 0;
            let office = 0;
            let general = 0;
            let other = 0;
            let costCategory = [false, false, false, false, false, false];
            response.map((data) => {
                summary += data.isPeriodic === true ? data.amount * 12 : data.amount;
                if (data.costType === 'ESTABLISHMENT') {
                    establishment += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[0][6] += data.amount;
                        costCategory[0] = true;
                    } else {
                        costData[0][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[0][4].push(array);
                }
                if (data.costType === 'PRO_SERVICES') {
                    professional += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[1][6] += data.amount;
                        costCategory[1] = true;
                    } else {
                        costData[1][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[1][4].push(array);
                }
                if (data.costType === 'TRANSPORTATION') {
                    transportation += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[2][6] += data.amount;
                        costCategory[2] = true;
                    } else {
                        costData[2][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[2][4].push(array);
                }
                if (data.costType === 'OFFICE') {
                    office += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[3][6] += data.amount;
                        costCategory[3] = true;
                    } else {
                        costData[3][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[3][4].push(array);
                }
                if (data.costType === 'GENERAL') {
                    general += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[4][6] += data.amount;
                        costCategory[4] = true;
                    } else {
                        costData[4][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[4][4].push(array);
                }
                if (data.costType === 'OTHER') {
                    other += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[5][6] += data.amount;
                        costCategory[5] = true;
                    } else {
                        costData[5][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[5][4].push(array);
                }
            })
            costData[0][3] = establishment;
            costData[1][3] = professional;
            costData[2][3] = transportation;
            costData[3][3] = office;
            costData[4][3] = general;
            costData[5][3] = other;
            let array = [establishment, professional, transportation, office, general, other];
            array.sort((a, b) => a - b);

            const colorMapping = {
                [establishment]: '#0052FF',
                [professional]: '#0052FF',
                [transportation]: '#0052FF',
                [office]: '#0052FF',
                [general]: '#0052FF',
                [other]: '#0052FF',
                [costData[1][3]]: '#9800FF',
                [costData[2][3]]: '#F20089',
                [costData[3][3]]: '#FFBD00',
                [costData[4][3]]: '#23E33E',
                [costData[5][3]]: '#E94F37',
            };

            let graphSummary = array.map((data, index) => [colorMapping[data], data]);
            graphSummary[0][1] = graphSummary[0][1]/summary * 100;
            graphSummary[1][1] = graphSummary[1][1]/summary * 100;
            graphSummary[2][1] = graphSummary[2][1]/summary * 100;
            graphSummary[3][1] = graphSummary[3][1]/summary * 100;
            graphSummary[4][1] = graphSummary[4][1]/summary * 100;
            graphSummary[5][1] = graphSummary[5][1]/summary * 100;

            let establishmentSummary = (costData[0][3]/summary) * 100;
            let professionalSummary = ((costData[1][3]/summary) * 100) + establishmentSummary;
            let transportationSummary = ((costData[2][3]/summary) * 100) + professionalSummary;
            let officeSummary = ((costData[3][3]/summary) * 100) + transportationSummary;
            let generalSummary = ((costData[4][3]/summary) * 100) + officeSummary;
            let otherSummary = ((costData[5][3]/summary) * 100) + generalSummary;
            for (let i = 0; i <= 5; i++) {
                costData[i][3] = addCommas(removeNonNumeric(costData[i][3].toFixed(0)));
                costData[i][4].map((data) => {
                    data[1] = addCommas(removeNonNumeric(data[1].toFixed(0)));
                    data[6] = addCommas(removeNonNumeric(data[6].toFixed(0)));
                })
                costData[i][7] = addCommas(removeNonNumeric((costData[i][6]*12).toFixed(0)));
                costData[i][5] = addCommas(removeNonNumeric(costData[i][5].toFixed(0)));
                costData[i][6] = addCommas(removeNonNumeric(costData[i][6].toFixed(0)));
            }
            sessionStorage.setItem('costData', JSON.stringify(costData));
            summary = addCommas(removeNonNumeric(summary.toFixed(0)));
            setSummary(summary);
            setData(costData);
            setEstablishmentSummary(establishmentSummary);
            setProfessionalSummary(professionalSummary);
            setTransportationSummary(transportationSummary);
            setOfficeSummary(officeSummary);
            setGeneralSummary(generalSummary);
            setOtherSummary(otherSummary);
            setCostCategory(costCategory);
            setGraphSummary(graphSummary);
        })
    }

    return (
        <main className="container">
            <Navbar currentStep={step} progress={progress} handleQuickstart={setQuickstart} survey={true} name={'Establishment flow'} desc={localStorage.getItem('i18nextLng') === 'en' ? `Go through the ${sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? '8' : '5'} steps in the establishment flow. Feel free to start where you want - your progress is saved continually.` : `Doorloop de ${sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? '8' : '5'} stappen in de oprichtingsflow. Voel je vrij om te beginnen waar je wilt - je voortgang wordt voortdurend opgeslagen.`} progressName={'Establishment flow'} {...props} handleCheckup={checkup} handleRevenue={handleRevenue} />
            <Menu role={'user'} permissions={null} isMobile={false} component={'survey'} />
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <section className="container__content">
                <div className="container__box">
                    {step === 1 ?
                        <CompanyDetails />
                        : <></>}
                    {step === 2 ?
                        <ShareholderStructure validate={validateForm} saveFetchShareholder={saveFetchShareholder} handleShareholderData={handleShareholderData} />
                        : <></>}
                    {step === 3 ?
                        <PersonalSituation />
                        : <></>}
                    {step === 4 ?
                        <Revenue handleRevenue={handleRevenue} selectList={selectList} selectYear={selectYear} defaultSelect={defaultSelect} handleSelectList={setSelectList} handleDefaultSelect={setDefaultSelect} />
                        : <></>}
                    {step === 5 ?
                        <Remuneration validate={validateForm} />
                        : <></>}
                    {step === 6 ?
                        <Mobility />
                        : <></>}
                    {step === 7 ?
                        <Assets />
                        : <></>}
                    {step === 8 ?
                        <Loans />
                        : <></>}
                    {step === 9 ?
                        <CheckUp summary={summary} data={data} establishmentSummary={establishmentSummary} professionalSummary={professionalSummary} transportationSummary={transportationSummary} officeSummary={officeSummary} generalSummary={generalSummary} otherSummary={otherSummary} costCategory={costCategory} graphSummary={graphSummary} handleCheckup={checkup} />
                        : <></>}
                    {step === 10 ?
                        <Finish />
                        : <></>}
                </div>
            </section>
            <Footer handleUpdateStep={updateStep} currentStep={step} data={form} saveFetchShareholder={saveFetchShareholder} shareholderData={shareholderData} validateForm={validateForm} handleValidate={handleValidate} type={'establishment'} admin={false} />
        </main>
    )
}

export default withTranslation()(withCookies(EstablishmentFlow));
