import React, {useState, useEffect, useRef} from "react";
import './companyType.scss';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Footer from "../footer/footer";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Quickstart from "../quickstart/quickstart";
import Ready from "./ready";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import {initLoader, removeLoader} from "../loader/loader";

const CompanyType = (props) => {
    const [step, setStep] = useState(sessionStorage.getItem('companyTypeStep') ? parseInt(sessionStorage.getItem('companyTypeStep')) : 1);
    const [progress, setProgress] = useState(0);
    const [quickstart, setQuickstart] = useState(false);
    const [form, setForm] = useState({
            name: '',
            surname: '',
            email: '',
            phone: '',
            nationality: [],
            dateStart: '',
            companyName: '',
            occupation: [],
            companyActivities: '',
            companyActivitiesDescription: '',
            postalCode: '',
            city: '',
            revenue: '',
            expectNextYear: [],
            revenueNextYear: '',
            expectThirdYear: [],
            revenueThirdYear: '',
            transport: [],
            transportCar: [],
            transportPublic: [],
            transportBicycle: [],
            transportMotorcycle: [],
            transportTaxi: '',
            usuallyWork: [],
            officeSpace: '',
            officeSpaceRent: '',
            usedHome: 0,
            applicableSituation: [],
            homeOffice: [],
            restaurants: [],
            pensionSaving: [],
            goodsToSell: [],
            buyingGoods: '',
            otherCategories: []
        });
    const [currentForm, setCurrentForm] = useState({ownOffice: false, rentOffice: false, ownHome: false, coWork: false})
    const [validateForm, setValidateForm] = useState([]);
    const [companyTypeData, setCompanyTypeData] = useState([]);
    const [isChangePersonal, setIsChangePersonal] = useState(false);
    const [isChangeCompany, setIsChangeCompany] = useState(false);
    const [isChangeRevenue, setIsChangeRevenue] = useState(false);
    const [isChangeCost, setIsChangeCost] = useState(false);

    const formRef = useRef();

    useEffect(() => {
        initLoader(document.querySelector('.container__content'));
        getRes();
        return () => {
            sessionStorage.removeItem('companyType');
            sessionStorage.removeItem('companyDetails');
            sessionStorage.removeItem('companyTypeData');
            sessionStorage.removeItem('financialCalendar');
        }
    }, []);

    const getRes = () => {
        Connect(`${Endpoint.getUserSurvey}/company-type`, 'GET').then(response => {
            const newForm = {
                name: response.surveyPages[0].steps[0].questions[0].answers[0]
                    && response.surveyPages[0].steps[0].questions[0].answers[0].textAnswer,
                surname: response.surveyPages[0].steps[0].questions[1].answers[0]
                    && response.surveyPages[0].steps[0].questions[1].answers[0].textAnswer,
                email: JSON.parse(localStorage.getItem('user')).email,
                phone: response.surveyPages[0].steps[0].questions[3].answers[0]
                    && response.surveyPages[0].steps[0].questions[3].answers[0].textAnswer,
                nationality: createArray(response.surveyPages[0].steps[0].questions[4].answers[0] && response.surveyPages[0].steps[0].questions[4].answers[0].questionOptions),
                dateStart: response.surveyPages[1].steps[0].questions[0].answers[0]
                    && response.surveyPages[1].steps[0].questions[0].answers[0].textAnswer,
                companyName: response.surveyPages[1].steps[0].questions[1].answers[0]
                    && response.surveyPages[1].steps[0].questions[1].answers[0].textAnswer,
                occupation: createArray(response.surveyPages[1].steps[0].questions[2].answers[0] && response.surveyPages[1].steps[0].questions[2].answers[0].questionOptions),
                companyActivities: response.surveyPages[1].steps[0].questions[3].answers[0]
                    && response.surveyPages[1].steps[0].questions[3].answers[0].textAnswer,
                companyActivitiesDescription: response.surveyPages[1].steps[0].questions[4].answers[0]
                    && response.surveyPages[1].steps[0].questions[4].answers[0].textAnswer,
                postalCode: response.surveyPages[1].steps[0].questions[5].answers[0]
                    && response.surveyPages[1].steps[0].questions[5].answers[0].textAnswer,
                city: response.surveyPages[1].steps[0].questions[6].answers[0]
                    && response.surveyPages[1].steps[0].questions[6].answers[0].textAnswer,
                revenue: response.surveyPages[2].steps[0].questions[0].answers[0] && response.surveyPages[2].steps[0].questions[0].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[0].answers[0].numericAnswer)),
                expectNextYear: createArray(response.surveyPages[2].steps[0].questions[1].answers[0] && response.surveyPages[2].steps[0].questions[1].answers[0].questionOptions),
                revenueNextYear: response.surveyPages[2].steps[0].questions[2].answers[0] && response.surveyPages[2].steps[0].questions[2].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[2].answers[0].numericAnswer)),
                expectThirdYear: createArray(response.surveyPages[2].steps[0].questions[3].answers[0] && response.surveyPages[2].steps[0].questions[3].answers[0].questionOptions),
                revenueThirdYear: response.surveyPages[2].steps[0].questions[4].answers[0] && response.surveyPages[2].steps[0].questions[4].answers[0].numericAnswer !== null ? addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[4].answers[0].numericAnswer)) : '',
                transport: createArray(response.surveyPages[3].steps[0].questions[0].answers[0] && response.surveyPages[3].steps[0].questions[0].answers[0].questionOptions),
                transportCar: createArray(response.surveyPages[3].steps[0].questions[1].answers[0] && response.surveyPages[3].steps[0].questions[1].answers[0].questionOptions),
                transportMotorcycle: createArray(response.surveyPages[3].steps[0].questions[2].answers[0] && response.surveyPages[3].steps[0].questions[2].answers[0].questionOptions),
                transportPublic: createArray(response.surveyPages[3].steps[0].questions[3].answers[0] && response.surveyPages[3].steps[0].questions[3].answers[0].questionOptions),
                transportBicycle: createArray(response.surveyPages[3].steps[0].questions[4].answers[0] && response.surveyPages[3].steps[0].questions[4].answers[0].questionOptions),
                transportTaxi: response.surveyPages[3].steps[0].questions[5].answers[0] && response.surveyPages[3].steps[0].questions[5].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[3].steps[0].questions[5].answers[0].numericAnswer)),
                usuallyWork: createArray(response.surveyPages[3].steps[1].questions[0].answers[0] && response.surveyPages[3].steps[1].questions[0].answers[0].questionOptions),
                officeSpace: response.surveyPages[3].steps[1].questions[1].answers[0] && response.surveyPages[3].steps[1].questions[1].answers[0].numericAnswer !== null
                    ? addCommas(removeNonNumeric(response.surveyPages[3].steps[1].questions[1].answers[0].numericAnswer)) : null,
                officeSpaceRent: response.surveyPages[3].steps[1].questions[2].answers[0] && response.surveyPages[3].steps[1].questions[2].answers[0].numericAnswer !== null
                    ? addCommas(removeNonNumeric(response.surveyPages[3].steps[1].questions[2].answers[0].numericAnswer)) : null,
                usedHome: response.surveyPages[3].steps[1].questions[3].answers[0] && response.surveyPages[3].steps[1].questions[3].answers[0].numericAnswer !== null ? response.surveyPages[3].steps[1].questions[3].answers[0].numericAnswer : 0,
                applicableSituation: createArray(response.surveyPages[3].steps[1].questions[4].answers[0] && response.surveyPages[3].steps[1].questions[4].answers[0].questionOptions),
                homeOffice: createArray(response.surveyPages[3].steps[1].questions[5].answers[0] && response.surveyPages[3].steps[1].questions[5].answers[0].questionOptions),
                restaurants: createArray(response.surveyPages[3].steps[2].questions[0].answers[0] && response.surveyPages[3].steps[2].questions[0].answers[0].questionOptions),
                pensionSaving: createArray(response.surveyPages[3].steps[2].questions[1].answers[0] && response.surveyPages[3].steps[2].questions[1].answers[0].questionOptions),
                goodsToSell: createArray(response.surveyPages[3].steps[2].questions[2].answers[0] && response.surveyPages[3].steps[2].questions[2].answers[0].questionOptions),
                buyingGoods: response.surveyPages[3].steps[2].questions[3].answers[0] && response.surveyPages[3].steps[2].questions[3].answers[0].numericAnswer,
                otherCategories: createArray(response.surveyPages[3].steps[3].questions[0].answers[0] && response.surveyPages[3].steps[3].questions[0].answers[0].questionOptions),
            }
            setForm(newForm);
            formRef.current = newForm;
            setTimeout(() => {

                sessionStorage.setItem('companyType', JSON.stringify(newForm));
                sessionStorage.setItem('companyTypeData', JSON.stringify(response));
                setCompanyTypeData(response);
                removeLoader(document.querySelector('.container__content'));
            }, 1);
        });
    }

    const createArray = (element) => {
        let array = [];
        if (element) {
            element.forEach((data) => {
                array.push({
                    id: data.id,
                    text: data.text,
                    textKey: data.textKey,
                });
            });
        }
        return array;
    }

    const save = (values, pid, required = 0, page = 0) => {
        Connect(`${Endpoint.postUserAnswer}/${pid}`, 'POST', values).then(response => {
            if (response.length > 0) {
                let body = {
                    checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategory'))[page],
                    completed: required,
                    required,
                    isChange: page === 0 ? isChangePersonal : page === 1 ? isChangeCompany : page === 2 ? isChangeRevenue : isChangeCost
                };
                Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
                    console.log(response);
                });
            }
        });
    }

    const updateStep = (value, leave) => {
        if (value > 0 && value < 6) {
            let progress = 0;
            if (value === 2) {
                progress = 25;
            }
            if (value === 3) {
                progress = 50;
            }
            if (value === 4) {
                progress = 75;
            }
            if (value === 5) {
                progress = 100;
            }
            setStep(value);
            setProgress(progress);
            if (!leave && value <= 5) {
                sessionStorage.setItem('companyTypeStep', value);
            }
        }
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const alpha = (value) => {
        let res = value.replace(/[^A-Za-z -]/g, "");
        return res;
    }


    const updateForm = (param, value, currentFormState) => {

        if(currentFormState){
            let obj = currentForm;
            obj = {...obj, ...currentFormState}
            setCurrentForm(obj)
        }
        if (param === 'name' || param === 'surname' || param === 'phone' || param === 'nationality') {
            setIsChangePersonal(true);
        }
        if (param === 'dateStart' || param === 'companyName' || param === 'occupation' || param === 'companyActivities' || param === 'companyActivitiesDescription' || param === 'postalCode' || param === 'city') {
            setIsChangeCompany(true)
        }
        if (param === 'revenue' || param === 'revenueNextYear' || param === 'revenueThirdYear') {
            setIsChangeRevenue(true);
        }
        if (param === 'transport' || param === 'transportCar' || param === 'transportPublic' || param === 'transportBicycle' || param === 'transportMotorcycle' || param === 'transportTaxi' || param === 'usuallyWork' || param === 'officeSpace' || param === 'officeSpaceRent' || param === 'usedHome' || param === 'applicableSituation' || param === 'homeOffice' || param === 'restaurants' || param === 'pensionSaving' || param === 'goodsToSell' || param === 'buyingGoods' || param === 'otherCategories') {
            setIsChangeCost(true);
        }
        const newForm = {
            name: param === 'name' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).name,
            surname: param === 'surname' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).surname,
            email: JSON.parse(localStorage.getItem('user')).email,
            phone: param === 'phone' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).phone,
            nationality: param === 'nationality' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).nationality,
            dateStart: param === 'dateStart' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).dateStart,
            companyName: param === 'companyName' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyName,
            occupation: param === 'occupation' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).occupation,
            companyActivities: param === 'companyActivities' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivities,
            companyActivitiesDescription: param === 'companyActivitiesDescription' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivitiesDescription,
            postalCode: param === 'postalCode' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).postalCode,
            city: param === 'city' ? alpha(value) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).city,
            revenue: param === 'revenue' ? addCommas(removeNonNumeric(value)) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenue,
            expectNextYear: param === 'expectNextYear' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear,
            revenueNextYear: param === 'revenueNextYear' ? addCommas(removeNonNumeric(value)) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear,
            expectThirdYear: param === 'expectThirdYear' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear,
            revenueThirdYear: param === 'revenueThirdYear' ? addCommas(removeNonNumeric(value)) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueThirdYear,
            transport: param === 'transport' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).transport,
            transportCar: param === 'transportCar' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).transportCar,
            transportPublic: param === 'transportPublic' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).transportPublic,
            transportBicycle: param === 'transportBicycle' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).transportBicycle,
            transportMotorcycle: param === 'transportMotorcycle' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).transportMotorcycle,
            transportTaxi: param === 'transportTaxi' ? addCommas(removeNonNumeric(value)) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).transportTaxi,
            usuallyWork: param === 'usuallyWork' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).usuallyWork,
            officeSpace: param === 'officeSpace' ? addCommas(removeNonNumeric(value)) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).officeSpace,
            officeSpaceRent: param === 'officeSpaceRent' ? addCommas(removeNonNumeric(value)) : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).officeSpaceRent,
            usedHome: param === 'usedHome' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).usedHome,
            applicableSituation : param === 'applicableSituation' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).applicableSituation,
            homeOffice: param === 'homeOffice' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).homeOffice,
            restaurants: param === 'restaurants' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).restaurants,
            pensionSaving: param === 'pensionSaving' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).pensionSaving,
            goodsToSell: param === 'goodsToSell' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).goodsToSell,
            buyingGoods: param === 'buyingGoods' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).buyingGoods,
            otherCategories: param === 'otherCategories' ? value : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).otherCategories
        }
        setForm(newForm);
        sessionStorage.setItem('companyType', JSON.stringify(newForm));
    }

    const handleQuickstart = (value) => {
        setQuickstart(value)
    }

    const handleValidate = (value) => {
        setValidateForm(value)
    }


    return (
        <main className="container">
            <Navbar currentStep={step} progress={progress} handleQuickstart={handleQuickstart} survey={true} name={`Company type`} desc={<Trans>companyType.subtitle</Trans>} progressName={<Trans>companyType.survey</Trans>} handleCompanyType={getRes} {...props} />
            <Menu role={'user'} permissions={null} isMobile={false} component={'company'}/>
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <section className="container__content">
                {form && form.email !== '' &&
                    <div className="container__box">
                        {step === 1 ?
                            <Step1 data={form} handleData={updateForm} validateForm={validateForm}/>
                        : <></>}
                        {step === 2 ?
                            <Step2 data={form} handleData={updateForm} validateForm={validateForm}/>
                        : <></>}
                        {step === 3 && sessionStorage.getItem('companyType') ?
                            <Step3 data={form} handleData={updateForm} validateForm={validateForm}/>
                        : <></>}
                        {step === 4 ?
                            <Step4 data={form} handleData={updateForm} validateForm={validateForm}
                                   companyTypeData={companyTypeData} type={'user'} isChangeCost={isChangeCost} />
                        : <></>}
                        {step === 5 ?
                            <Ready/>
                        : <></>}
                    </div>
                }
            </section>
            <Footer handleUpdateStep={updateStep} currentStep={step} data={form} validateForm={{...validateForm, checkboxState: currentForm}} handleValidate={handleValidate} type={'company'} admin={false} save={save} />
        </main>
    )
}

export default withTranslation()(withCookies(CompanyType));