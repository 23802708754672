import React, { useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { calendar, InfoIcon } from "../survey/surveyConstant";
import { editIcon, removeIcon } from "../login/loginConstants";
import { TextField, InputAdornment } from "@material-ui/core";
import Confirm from "../confirm/confirm";
import CustomSwitch from "../switch/switch";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import DatePicker from "react-datepicker";
import { Trans } from "react-i18next";
import { transformDate } from "../admin/adminEstablishment";
import nl from "date-fns/locale/nl";

const Assets = () => {
  const [data, setData] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [balanceFixed, setBalanceFixed] = useState(false);
  const [companyReport, setCompanyReport] = useState(false);
  const [assetsValue, setAssetsValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [today, setToday] = useState("");
  const [editId, setEditId] = useState(0);

  useEffect(() => {
    let date = new Date();
    const today = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }`;
    setToday(today);
    getRes();
    let body = {
      checkpointCategory: JSON.parse(sessionStorage.getItem("checkpointCategoryEstablishment"))[
        sessionStorage.getItem("legalForm") === "LIMITED_COMPANY" ? 6 : 3
      ],
      completed: 1,
      required: 1,
      isChange: true,
    };
    Connect(`${Endpoint.putProgressSurvey}`, "PUT", body).then((response) => {
      // console.log(response);
    });
  }, []);

  const getRes = () => {
    Connect(`${Endpoint.getUserAssets}`, "GET").then((response) => {
      setData(response);
      // document.querySelector('input[name="assetsValue"]').value = null;
      document.querySelector('input[name="nameAsset"]').value = null;
      document.querySelector('input[name="months"]').value = null;
      // document.querySelector('#dateStart').value = null;
      document.querySelector("#firstYear").innerText = "€0";
      setBalanceFixed(false);
      setCompanyReport(false);
      sessionStorage.removeItem("startDate");
      setAssetsValue("");
      setStartDate("");
    });
  };

  const handleDate = (date) => {
    setStartDate(date);
    sessionStorage.setItem("startDate", date);
  };

  const handleConfirm = (value) => {
    setConfirm(value);
  };

  const confirmRemove = (index, pid) => {
    handleConfirm(true);
    sessionStorage.setItem("indexDelete", pid);
  };

  const recalculate = () => {
    let useful = document.querySelector('input[name="months"]').value;
    let value = parseInt(document.querySelector('input[name="assetsValue"]').value.replaceAll(".", ""));
    if (useful !== null && useful < value) {
      value = value / useful;
    }
    if (isNaN(value) === true || isNaN(useful) === true || !isFinite(value)) {
      value = 0;
    }
    document.querySelector("#firstYear").setAttribute("data-value", value.toFixed(0));
    document.querySelector("#firstYear").innerText = `€${addCommas(removeNonNumeric(value.toFixed(0)))}`;
  };

  const handleAssetsValue = (e, value) => {
    setAssetsValue(addCommas(removeNonNumeric(value)));
    setTimeout(() => {
      recalculate(e);
    }, 100);
  };

  const remove = (index) => {
    let currentData = data.slice();
    handleConfirm(false);
    Connect(`${Endpoint.getUserAssets}/${sessionStorage.getItem("indexDelete")}`, "DELETE").then((response) => {
      getRes();
    });
    currentData.splice(index, 1);
    setData(currentData);
    localStorage.setItem("assets", JSON.stringify(currentData));
  };

  const edit = (id, value, lifeMonths, startDate, name, isFixed, hasReport) => {
    setEditId(id);
    setTimeout(() => {
      document.querySelector('input[name="assetsValue"]').value = addCommas(removeNonNumeric(value));
      document.querySelector('input[name="nameAsset"]').value = name;
      document.querySelector('input[name="months"]').value = lifeMonths;
      document.querySelector("#dateStart").value = startDate;
      setBalanceFixed(isFixed);
      setCompanyReport(hasReport);
      setAssetsValue(addCommas(removeNonNumeric(value)));
      // setStartDate(startDate);
    }, 100);
    setTimeout(() => {
      document.querySelector('input[name="assetsValue"]').value = addCommas(removeNonNumeric(value));
      document.querySelector("#dateStart").value = startDate;
      setAssetsValue(addCommas(removeNonNumeric(value)));
    }, 300);
    setTimeout(() => {
      document.querySelector('input[name="assetsValue"]').value = addCommas(removeNonNumeric(value));
      document.querySelector("#dateStart").value = startDate;
      setAssetsValue(addCommas(removeNonNumeric(value)));
      recalculate();
    }, 500);
  };

  const save = () => {
    let currentData = data.slice();
    let assetsValue = document.querySelector('input[name="assetsValue"]').value;
    let nameAsset = document.querySelector('input[name="nameAsset"]').value;
    let months = document.querySelector('input[name="months"]').value;
    let date = startDate;
    let array = {
      name: nameAsset,
      value: parseInt(assetsValue.replaceAll(".", "")),
      lifeMonths: months,
      startDate: date,
      isFixed: balanceFixed,
      hasReport: companyReport,
    };
    currentData.push(array);
    localStorage.setItem("assets", JSON.stringify(currentData));
    let body = {
      name: nameAsset,
      value: parseInt(assetsValue.replaceAll(".", "")),
      lifeMonths: months,
      startDate: date,
      isFixed: balanceFixed,
      hasReport: companyReport,
    };
    if (editId !== 0) {
      body = {
        id: parseInt(editId),
        name: nameAsset,
        value: parseInt(assetsValue.replaceAll(".", "")),
        lifeMonths: months,
        startDate: date,
        isFixed: balanceFixed,
        hasReport: companyReport,
      };
      Connect(`${Endpoint.getUserAssets}`, "PUT", body).then((response) => {
        getRes();
        setEditId(0);
      });
    } else {
      Connect(`${Endpoint.getUserAssets}`, "POST", body).then((response) => {
        getRes();
      });
    }
  };

  const addCommas = (num) => {
    if (num.length > 1) {
      if (num[0] === "0") {
        num = num.slice(1);
      }
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  return (
    <>
      <div className='container__body establishmentFlow revenue'>
        <div className='container__body-header'>
          <Trans>establishmentFlow.assets</Trans>
          <Tooltip title='These will be displayed in your initial financial statements' placement={"right"}>
            <IconButton aria-label='delete'>{InfoIcon}</IconButton>
          </Tooltip>
        </div>
        {data.map((data, index) => (
          <div className='establishmentFlow__box' key={index}>
            <div className='row space-between'>
              <div className='header'>
                {data.isFixed === true ? "Fixed asset: " : ""}
                {data.name}
              </div>
              <div className='box'>
                <div
                  className='edit'
                  onClick={() =>
                    edit(
                      data.id,
                      data.value,
                      data.lifeMonths,
                      data.startDate &&
                        transformDate(data.startDate.substring(8, 10), data.startDate.substring(5, 7), data.startDate.substring(0, 4)),
                      data.name,
                      data.isFixed,
                      data.hasReport
                    )
                  }
                >
                  {editIcon}
                </div>
                <div className='remove' onClick={() => confirmRemove(index, data.id)}>
                  {removeIcon}
                </div>
              </div>
            </div>
            <div className='row space-between'>
              <p>
                <Trans>establishmentFlow.description</Trans>
              </p>
              <p>
                <Trans>establishmentFlow.value</Trans>
              </p>
              <p>
                <Trans>establishmentFlow.amortization</Trans>
              </p>
            </div>
            <div className='row space-between'>
              <p className='award'>{data.name}</p>
              <p className='price'>€{addCommas(removeNonNumeric(data.value))}</p>
              <p className='price'>€{addCommas(removeNonNumeric((data.value / data.lifeMonths).toFixed(0)))}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='container__sidebar revenue' data-id={editId}>
        <div className='container__body-input'>
          <p id='assetsValue' className='container__body-text'>
            <Trans>establishmentFlow.assetsValue</Trans>
          </p>
          <TextField
            label={""}
            variant={"outlined"}
            size={"small"}
            placeholder={"€..."}
            value={assetsValue}
            onChange={(e) => handleAssetsValue(e, e.target.value)}
            name={"assetsValue"}
            InputProps={{
              startAdornment: assetsValue ? (
                <InputAdornment variant='standard' disableTypography position='start'>
                  €
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        </div>
        <div className='container__body-input'>
          <p id='usefulLife' className='container__body-text'>
            <Trans>establishmentFlow.useful</Trans>
          </p>
          <TextField label={""} variant={"outlined"} size={"small"} placeholder={"..."} name={"months"} onChange={() => recalculate()} />
        </div>
        <div className='container__body-input'>
          <p id='startingDate' className='container__body-text'>
            <Trans>establishmentFlow.startDate</Trans>
          </p>
          <DatePicker
            id='dateStart'
            placeholderText={localStorage.getItem("i18nextLng") === "en" ? "Select a date" : "\n" + "Selecteer een datum"}
            locale={localStorage.getItem("i18nextLng") !== "en" && nl}
            selected={startDate}
            onChange={(date) => handleDate(date)}
            minDate={new Date(today)}
            dateFormat='dd/MM/yyyy'
            autoComplete='off'
            onKeyDown={(e) => e.preventDefault()}
          />
          <div className='calendarIcon'>{calendar}</div>
        </div>
        <div className='container__body-input'>
          <p id='nameAsset' className='container__body-text'>
            <Trans>establishmentFlow.nameAsset</Trans>
          </p>
          <TextField label={""} variant={"outlined"} size={"small"} placeholder={"..."} name={"nameAsset"} />
        </div>
        <div className='container__body-input container__body-switcher'>
          <p className='container__body-text'>
            <Trans>establishmentFlow.balance</Trans>
          </p>
          <CustomSwitch checked={balanceFixed} onChange={(e) => setBalanceFixed(e.target.checked)} />
        </div>
        <div className='container__body-input container__body-switcher'>
          <p className='container__body-text'>
            <Trans>establishmentFlow.report</Trans>
            <Tooltip
              title='To bring assets into the company as capital, you are legally obligated to acquire an estimation of value from an independent auditor. Otherwise, you will need to register these assets as investments.'
              placement={"right"}
            >
              <IconButton aria-label='delete'>{InfoIcon}</IconButton>
            </Tooltip>
          </p>
          <CustomSwitch checked={companyReport} onChange={(e) => setCompanyReport(e.target.checked)} />
        </div>
        <div className='container__body-input d-none'>
          <p className='container__body-text'>
            <Trans>establishmentFlow.deprecation</Trans>
          </p>
        </div>
        <div className='summary'>
          <div className='box'>
            <div id='firstYear' className='summary__header' data-value={0}>
              €0
            </div>
            <div className='summary__subheader'>
              <Trans>establishmentFlow.deprecation</Trans>
            </div>
          </div>
        </div>
        <div className='row'>
          <button className='btn btn--primary' onClick={save}>
            {editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
          </button>
        </div>
      </div>
      <Confirm active={confirm} handleConfirm={handleConfirm} handleRemove={remove} message={<Trans>establishmentFlow.delete</Trans>} only={false} />
    </>
  );
};

export default Assets;
