import React from "react";
import './_load-container.scss';
import './_itemsLoader.scss';
import './_icon.scss';

export const initLoader = (container) => {
    let loadContainer = document.querySelector(".load-container");
    let clone = loadContainer.cloneNode(true);
    clone.classList.remove("d-none");
    clone.classList.add("is-active");
    container.classList.add("is-loading");
    container.append(clone);
};
export const removeLoader = (container) => {
    let element = document.querySelector(".load-container.is-active");
    if (element !== null) element.remove();
    container && container.classList.remove("is-loading");
};

function Loader() {
    return (
        <div className="load-container d-none">
            <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
        </div>
    )
}

export default Loader;