// select dropdown, library react-select : https://react-select.com/home

import React from 'react';
import Select, { components } from 'react-select';
import {customStyles} from "./selectStyle";

// change icon in select
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 0.913209L7.30696 8.22017L14.6139 0.913209L13.7007 0L7.30696 6.39375L0.913209 0L0 0.913209Z"
                    fill={props.isDisabled ? "#FFF" : "#444445"}/>
            </svg>
        </components.DropdownIndicator>
    );
};

const SelectCustom = (props) => {

    return <>
        {props.isEdit ?
            <Select
                isMulti={props.isMulti}
                value={props.selectedValue && props.selectedValue.value === '' ? '' : props.selectedValue}
                styles={customStyles}
                options={props.options}
                placeholder={"Select"}
                isDisabled={props.readOnly}
                name="select"
                // menuIsOpen={true}
                // isClearable
                components={{DropdownIndicator}}
                onChange={props.onChange}
                classNamePrefix="react-select"
                onMouseEnter={event => console.log(event)}
                menuPosition={'fixed'}
                // menuPortalTarget={document.body}
                onBlur={event => event.preventDefault()}
                setValue
            />
            :
            <Select
                isMulti={props.isMulti}
                styles={customStyles}
                options={props.options}
                placeholder={"Select"}
                name="select"
                components={{DropdownIndicator}}
                onChange={props.onChange}
                classNamePrefix="react-select"
            />
        }

    </>
};

export {SelectCustom}