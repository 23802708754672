import React from 'react';
import MainContainer from "./components/mainContainer/mainContainer";
import {useEffect} from 'react'

function App() {

  useEffect(()=>{
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
  });
  },[])
  return <MainContainer />
}

export default App;
