// Main menu, display for desktop and mobile

import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import * as Constant from './menuConstant';
import "./_menu.scss";
import Logo from "../../assets/icon/logo.svg";
import {Trans} from 'react-i18next';
import {likeNavbar} from "../login/loginConstants";

const Menu = (props) => {
    const [open, setOpen] = useState(false);

    const validatePermissions = (item) =>{
        if(props.role === 'user' || item === 'Log out' || props.role === 'admin'){
            return true;
        }
        switch (item) {
            case 'Clients':
                return props.permissions.displayClients;
            case 'Survey':
                return props.permissions.displaySurvey;
            case 'Users & Permissions':
                return props.permissions.displayUsers || props.permissions.displayPermissions;
            case 'Support':
                return props.permissions.displayHelp || props.permissions.displayFAQ;
            default: console.log('Error permission')
        }
    };

    const getTranslation = (name) =>{
        switch (name) {
            case 'My company':
                return <Trans>menu.company</Trans>
            case 'Clients':
                return <Trans>menu.clients</Trans>
            case 'Survey':
                return <Trans>menu.survey</Trans>
            case 'Files':
                return <Trans>menu.users</Trans>
            case 'Finance':
                return <Trans>menu.finance</Trans>
            case 'My profile':
                return <Trans>menu.profile</Trans>
            case 'Support':
                return <Trans>menu.support</Trans>
            case 'Integrations':
                return 'Integrations';
            case 'Admin panel':
                return <Trans>menu.panel</Trans>;
            case 'Parameters':
                return 'Parameters';
            case 'All clients':
                return <Trans>menu.all</Trans>;
            default: console.log('Unrecognized')
        }
    };

    const handleClickMenuHamburger = () => {
        setOpen(!open);

        const container = document.querySelector('.container');
        if(props.isMobile) {
            !open ? container.classList.add('mobile-open') : container.classList.remove('mobile-open')
        }
    }

    const menuList = Constant.MenuList.map( (elem) => {
        const isButtonUpgradePlan = elem.name === 'Upgrade your plan',
            isMobile = props.isMobile;
        return <React.Fragment key={elem.name}>

            {elem.role.includes(props.role) && validatePermissions(elem.name) && (elem.display.includes('always') || elem.display.includes(isMobile && 'mobile')) &&
               <li className={`menu__item${ isButtonUpgradePlan ? ' item--button' : ''}`}>
                   <NavLink exact={elem.path === '/'} className={isButtonUpgradePlan ? 'btn--white' : elem.name.toLowerCase() === 'survey' ? 'menu__link activeted' : 'menu__link'} to={elem.path} onClick={handleClickMenuHamburger}>
                       {!isButtonUpgradePlan && <i className="menu__icon">{elem.icon}</i>}
                       {getTranslation(elem.name)}
                       {/*<span className="unseen-quest">{exclamation}</span>*/}
                   </NavLink>
               </li>
            }
       </React.Fragment>
    });



    return (
        <>
            <button className={open ? 'menu__hamburger open' : 'menu__hamburger'} onClick={handleClickMenuHamburger}><span /><span /><span /></button>

            <div className={open ? 'menu open' : 'menu'}>
                <img className="menu__logo" src={Logo} alt="Logo Bizantium"/>
                <nav className="menu__nav">
                    <ul className="menu__list">
                        {menuList}
                    </ul>
                </nav>
                <div className="menu__like d-none">
                    {likeNavbar}
                </div>
                <div className="menu__copyright">
                    <span className="menu__copyright-header">© Bizantium, 2024</span>
                    <span className="menu__copyright-description">Bizantium is een all-in-one tool die startende ondernemers op weg helpt door hun onderneming volledig digitaal op te richten.</span>
                </div>
            </div>

            {props.isMobile && (
                <div className="menu--floating">
                    <ul className="menu__list">
                        {Constant.MenuList.map( menuItem => {

                            if(menuItem.name === 'Support' && props.role === 'user') return true;

                            return menuItem.role.includes(props.role) && menuItem.floatingMenu &&
                            <NavLink exact to={menuItem.path}
                                     className={props.component === menuItem.name.toLowerCase() ? 'active' : ''}
                                     key={menuItem.name}
                                     type={menuItem.name.toLowerCase()}>
                                {menuItem.icon}
                            </NavLink>})
                        }
                    </ul>
                </div>
            )}
        </>
    )
};

export default Menu;