import React from "react";
import {Trans} from 'react-i18next';

export const settingsForm = [
    {name: <Trans>profile.name</Trans>, attr: 'name', state: 'name', required: true},
    {name: <Trans>profile.surname</Trans>, attr: 'surname', state: 'surname', required: true},
    {name: <Trans>profile.email</Trans>, attr: 'email', state: 'email', required: true},
    {name: <Trans>profile.phone</Trans>, attr: 'phone', state: 'phone', required: false},
    {name: <Trans>profile.function</Trans>, attr: 'function', state: 'function', required: false},
];

export const iconYes = (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.85575 19L0.450195 11.4073L3.82503 7.94723L7.85575 12.092L19.6379 0L23.0127 3.4601L7.85575 19Z" fill="#3FDB99"/>
    </svg>
)

export const iconNo = (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4502 2.14275L13.3072 0L7.9502 5.35725L2.59295 0L0.450195 2.14275L5.8072 7.5L0.450195 12.8573L2.59295 15L7.9502 9.64275L13.3072 15L15.4502 12.8573L10.0927 7.5L15.4502 2.14275Z" fill="#FB6868"/>
    </svg>
)