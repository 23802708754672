import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export const iconArrow = (
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0.913209L7.30696 8.22017L14.6139 0.913209L13.7007 0L7.30696 6.39375L0.913209 0L0 0.913209Z" fill="#37517E" fillOpacity="0.4"/>
    </svg>
);

export const iconEdit = (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.77952 2.79102H2.72878C2.27028 2.79102 1.83056 2.96905 1.50635 3.28596C1.18214 3.60286 1 4.03268 1 4.48085V16.3097C1 16.7579 1.18214 17.1877 1.50635 17.5046C1.83056 17.8215 2.27028 17.9996 2.72878 17.9996H14.8303C15.2888 17.9996 15.7285 17.8215 16.0527 17.5046C16.3769 17.1877 16.559 16.7579 16.559 16.3097V10.3953" stroke="#37517E" strokeOpacity="0.3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.2626 1.52497C15.6065 1.18884 16.0729 1 16.5592 1C17.0455 1 17.5119 1.18884 17.8558 1.52497C18.1996 1.8611 18.3928 2.31699 18.3928 2.79235C18.3928 3.26771 18.1996 3.72361 17.8558 4.05974L9.64407 12.0865L6.18652 12.9314L7.05091 9.55174L15.2626 1.52497Z" stroke="#37517E" strokeOpacity="0.3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export const iconDelete = (
    <svg width="17" height="20" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.13542 1.62H4.95833C5.05573 1.62 5.13542 1.539 5.13542 1.44V1.62H11.8646V1.44C11.8646 1.539 11.9443 1.62 12.0417 1.62H11.8646V3.24H13.4583V1.44C13.4583 0.64575 12.823 0 12.0417 0H4.95833C4.17695 0 3.54167 0.64575 3.54167 1.44V3.24H5.13542V1.62ZM16.2917 3.24H0.708333C0.316536 3.24 0 3.56175 0 3.96V4.68C0 4.779 0.0796875 4.86 0.177083 4.86H1.51406L2.06081 16.6275C2.09622 17.3948 2.72044 18 3.47526 18H13.5247C14.2818 18 14.9038 17.397 14.9392 16.6275L15.4859 4.86H16.8229C16.9203 4.86 17 4.779 17 4.68V3.96C17 3.56175 16.6835 3.24 16.2917 3.24ZM13.3543 16.38H3.6457L3.11003 4.86H13.89L13.3543 16.38Z" fill="#37517E" fillOpacity="0.3"/>
    </svg>
)

export const useStylesCirclePrimary = makeStyles(() => ({
    root: {
        display: 'block',
        margin: '0 auto',
        color: '#37517E',
    }
}));

export const useStylesModal = makeStyles(() => ({
    root: {
        padding: 0,
        marginBottom: '25px',
        color: '#37517E',
        '&>h2':{
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
        }
    },
    content:{
        fontSize: '16px',
        color: '#444445',
    },
    contentMobile:{
        fontSize: '15px',
        color: '#444445',
    },
    block: {
        padding: '0',
    },
    paper:{
        padding: '15px 30px',
    },
    paperMobile:{
        padding: '10px',
        margin: '10px',
        width: '100%',
    },
    action:{
        justifyContent: 'space-between',
        marginTop: '40px'
    },
    actionMobile:{
        justifyContent: 'center',
        marginTop: '40px'
    }
}));

export const exclamation = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" width="17" height="20">
        <g>
            <g>
                <path d="M503.839,395.379l-195.7-338.962C297.257,37.569,277.766,26.315,256,26.315c-21.765,0-41.257,11.254-52.139,30.102
                    L8.162,395.378c-10.883,18.85-10.883,41.356,0,60.205c10.883,18.849,30.373,30.102,52.139,30.102h391.398
                    c21.765,0,41.256-11.254,52.14-30.101C514.722,436.734,514.722,414.228,503.839,395.379z M477.861,440.586
                    c-5.461,9.458-15.241,15.104-26.162,15.104H60.301c-10.922,0-20.702-5.646-26.162-15.104c-5.46-9.458-5.46-20.75,0-30.208
                    L229.84,71.416c5.46-9.458,15.24-15.104,26.161-15.104c10.92,0,20.701,5.646,26.161,15.104l195.7,338.962
                    C483.321,419.836,483.321,431.128,477.861,440.586z"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="241.001" y="176.01" width="29.996" height="149.982"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M256,355.99c-11.027,0-19.998,8.971-19.998,19.998s8.971,19.998,19.998,19.998c11.026,0,19.998-8.971,19.998-19.998
                    S267.027,355.99,256,355.99z"/>
            </g>
        </g>
        <g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g />
    </svg>)