import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Endpoint from "../../configFile";
import * as Constant from './userConstant';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Messages from "../messages/messages";
import Role from "./role";
import validate from "../validate/validate";
import Cookies from "universal-cookie";
import {Trans} from "react-i18next";

export const useStylesModal = makeStyles(() => ({
    root: {
        padding: 0,
        marginBottom: '25px',
        color: '#37517E',
        '&>h2':{
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
        }
    },
    content:{
        fontSize: '16px',
        color: '#444445',
    },
    block: {
        padding: '0',
    },
    paper:{
        padding: '15px 30px',
    },
    paperMobile:{
        padding: '10px',
        margin: '10px'
    },
    action:{
        justifyContent: 'space-between',
        marginTop: '40px'
    },
    actionMobile:{
        justifyContent: 'center',
        marginTop: '10px'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddRole = (props) => {
    const [open, setOpen] = useState(false);
    const classes = useStylesModal();
    const [states, setStates] = React.useState(0);
    const [position, setPosition] = React.useState();

    const [data, setData] = useState(Constant.roleStart);

    const handlePosition = e =>{
        setPosition(e.target.value);
        setData({
            ...data,
            position: e.target.value,
        });
    };

    const handleChange = data => {
        setData((prev) => {
            return {
                ...data,
                position: prev.position,
            }
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () =>{
        if(validate() !== 1) {
            setStates(1)
            setTimeout( () => setStates(0),3000);
            return true;
        }
        setOpen(false);
        const cookies = new Cookies();

        fetch( Endpoint.createRole, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN')
            },
            body: JSON.stringify(data),
        })
            .then(response => {if(!response.ok) {
                throw response
            }
                return response
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                props.update(data)

                setStates(2)
                setTimeout( () => setStates(0),3000);
            })
            .catch((error) => {
                setStates(3)
                setTimeout( () => setStates(0),3000);
                console.log('Error:', error);
            });
    }

    const handleClickOpen = (e) => {
        setOpen(true);
    };

    return (
        <>
            {props.isMobile ?
                <button className="btn--mobileAdd" onClick={handleClickOpen} />
                : <button className="btn--primary" onClick={handleClickOpen}><Trans>users.add-func</Trans></button>
            }

            <Dialog
                fullWidth={true}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                classes={{paper: props.isMobile? classes.paperMobile : classes.paper}}
            >

                <DialogTitle id="functionAdd" classes={{ root: classes.root }}><Trans>users.new-func</Trans></DialogTitle>

                <DialogContent classes={{root: classes.block}}>

                    <div className='container--users'>

                        <div className="input-group">
                            <label htmlFor="name"><Trans>users.role-name</Trans></label>
                            <input type="text" name="position" id="position" value={position} onChange={handlePosition} required/>
                            <label className="label--error d-none"><Trans>basic.required</Trans></label>
                        </div>

                        <Role data={data} canEdit={true} isAdd={true} onChange={handleChange}/>
                    </div>
                </DialogContent>

                <DialogActions classes={{root: props.isMobile ? classes.actionMobile : classes.action}}>
                    <button onClick={handleClose} className="btn--outline"><Trans>basic.cancel</Trans></button>
                    <button onClick={handleSave} className="btn--primary"><Trans>basic.save-2</Trans></button>
                </DialogActions>
            </Dialog>

            {states !== 0 && <Messages status={states} />}
        </>
    );
}

export default AddRole;