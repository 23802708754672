import React from "react";

export const IconUser = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M5.85714 2H13.7364C14.0911 2 14.4343 2.12568 14.7051 2.35474L19.4687 6.38394C19.8057 6.66895 20 7.08788 20 7.5292V20.0833C20 21.8739 19.9796 22 18.1429 22H5.85714C4.02045 22 4 21.8739 4 20.0833V3.91667C4 2.12612 4.02045 2 5.85714 2Z" fill="#ADAFDC"/>
    <rect x="6" y="11" width="9" height="2" rx="1" fill="#ADAFDC"/>
    <rect x="6" y="15" width="5" height="2" rx="1" fill="#ADAFDC"/>
</svg>

const questionMark = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3295 14.9755C18.6451 15.2911 19.1846 15.0664 19.183 14.6201L19.1699 10.9211V4.60526C19.1699 3.44256 18.2273 2.5 17.0646 2.5H7.24003C6.07732 2.5 5.13477 3.44256 5.13477 4.60526V7.5H10.3332C11.9901 7.5 13.3332 8.84315 13.3332 10.5V13.0263H16.3803L18.3295 14.9755Z" fill="#ADAFDC"/>
    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M1.6543 15V10.8333C1.6543 9.91286 2.40049 9.16667 3.32096 9.16667H9.98763C10.9081 9.16667 11.6543 9.91286 11.6543 10.8333V15C11.6543 15.9205 10.9081 16.6667 9.98763 16.6667H3.41741L2.52182 17.5239C2.20381 17.8282 1.67609 17.6028 1.67609 17.1627V15.2702C1.66175 15.1823 1.6543 15.092 1.6543 15ZM5.00009 12.0833C5.00009 11.8532 5.18664 11.6667 5.41676 11.6667H9.58343C9.81354 11.6667 10.0001 11.8532 10.0001 12.0833C10.0001 12.3135 9.81354 12.5 9.58343 12.5H5.41676C5.18664 12.5 5.00009 12.3135 5.00009 12.0833ZM7.91676 13.3333C7.68664 13.3333 7.50009 13.5199 7.50009 13.75C7.50009 13.9801 7.68664 14.1667 7.91676 14.1667H9.58343C9.81354 14.1667 10.0001 13.9801 10.0001 13.75C10.0001 13.5199 9.81355 13.3333 9.58343 13.3333H7.91676Z" fill="#ADAFDC"/>
</svg>


export const MenuList = [
    {
        name: 'My company',
        role: 'user',
        floatingMenu: true,
        display: 'always',
        path: '/dashboard',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4C2.44772 4 2 4.44772 2 5V7C2 7.55228 2.44771 8 3 8H20C20.5523 8 21 7.55228 21 7V5C21 4.44772 20.5523 4 20 4H3ZM10 10C9.44772 10 9 10.4477 9 11V19C9 19.5523 9.44771 20 10 20H20C20.5523 20 21 19.5523 21 19V11C21 10.4477 20.5523 10 20 10H10Z" fill="#4A5BCF"/>
            <rect opacity="0.3" x="2" y="10" width="5" height="10" rx="1" fill="#4A5BCF"/>
        </svg>
    },
    {
        name: 'Clients',
        role: 'user',
        floatingMenu: true,
        display: 'always',
        path: '/company',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16675 3.66666C4.16675 2.56209 5.06218 1.66666 6.16675 1.66666H13.8334C14.938 1.66666 15.8334 2.56209 15.8334 3.66666V17.5H11.2501V15.1667C11.2501 14.6144 10.8024 14.1667 10.2501 14.1667H9.75008C9.1978 14.1667 8.75008 14.6144 8.75008 15.1667V17.5H4.16675V3.66666ZM6.66675 4.33332C6.66675 3.78104 7.11446 3.33332 7.66675 3.33332H8.16675C8.71903 3.33332 9.16675 3.78104 9.16675 4.33332V4.83332C9.16675 5.38561 8.71903 5.83332 8.16675 5.83332H7.66675C7.11446 5.83332 6.66675 5.38561 6.66675 4.83332V4.33332ZM11.8334 3.33332C11.2811 3.33332 10.8334 3.78104 10.8334 4.33332V4.83332C10.8334 5.38561 11.2811 5.83332 11.8334 5.83332H12.3334C12.8857 5.83332 13.3334 5.38561 13.3334 4.83332V4.33332C13.3334 3.78104 12.8857 3.33332 12.3334 3.33332H11.8334ZM6.66675 7.66666C6.66675 7.11437 7.11446 6.66666 7.66675 6.66666H8.16675C8.71903 6.66666 9.16675 7.11437 9.16675 7.66666V8.16666C9.16675 8.71894 8.71903 9.16666 8.16675 9.16666H7.66675C7.11446 9.16666 6.66675 8.71894 6.66675 8.16666V7.66666ZM7.66675 9.99999C7.11446 9.99999 6.66675 10.4477 6.66675 11V11.5C6.66675 12.0523 7.11446 12.5 7.66675 12.5H8.16675C8.71903 12.5 9.16675 12.0523 9.16675 11.5V11C9.16675 10.4477 8.71903 9.99999 8.16675 9.99999H7.66675ZM10.8334 11C10.8334 10.4477 11.2811 9.99999 11.8334 9.99999H12.3334C12.8857 9.99999 13.3334 10.4477 13.3334 11V11.5C13.3334 12.0523 12.8857 12.5 12.3334 12.5H11.8334C11.2811 12.5 10.8334 12.0523 10.8334 11.5V11Z" fill="#ADAFDC"/>
            <rect x="10.8333" y="6.66666" width="2.5" height="2.5" rx="1" fill="white"/>
            <path opacity="0.3" d="M2.5 19.1567C2.5 18.2417 3.24174 17.5 4.15673 17.5H15.8433C16.7583 17.5 17.5 18.2417 17.5 19.1567C17.5 19.1622 17.4955 19.1667 17.4901 19.1667H2.50994C2.50445 19.1667 2.5 19.1622 2.5 19.1567Z" fill="#ADAFDC"/>
        </svg>
    },
    {
        name: 'Survey',
        role: 'user',
        floatingMenu: true,
        display: 'always',
        path: '/establishment-flow',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66659 2.5H5.33325C4.22868 2.5 3.33325 3.39543 3.33325 4.5V17.1667C3.33325 18.2712 4.22868 19.1667 5.33325 19.1667H14.6666C15.7712 19.1667 16.6666 18.2712 16.6666 17.1667V4.5C16.6666 3.39543 15.7712 2.5 14.6666 2.5H13.3333V2.91667C13.3333 3.60702 12.7736 4.16667 12.0833 4.16667H7.91659C7.22623 4.16667 6.66659 3.60702 6.66659 2.91667V2.5Z" fill="#ADAFDC"/>
            <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M10.8333 1.66668C10.8333 1.20644 10.4602 0.833344 10 0.833344C9.53976 0.833344 9.16667 1.20644 9.16667 1.66668L8 1.66668C7.72386 1.66668 7.5 1.89053 7.5 2.16668V2.83334C7.5 3.10949 7.72386 3.33334 8 3.33334H12C12.2761 3.33334 12.5 3.10949 12.5 2.83334V2.16668C12.5 1.89053 12.2761 1.66668 12 1.66668L10.8333 1.66668Z" fill="#4A5BCF"/>
            <rect x="8.33325" y="7.5" width="5.83333" height="1.66667" rx="0.833333" fill="white"/>
            <rect x="5.83325" y="7.5" width="1.66667" height="1.66667" rx="0.833333" fill="white"/>
            <rect x="5.83325" y="10.8333" width="1.66667" height="1.66667" rx="0.833333" fill="white"/>
            <rect x="8.33325" y="10.8333" width="5.83333" height="1.66667" rx="0.833333" fill="white"/>
            <rect x="5.83325" y="14.1667" width="1.66667" height="1.66667" rx="0.833333" fill="white"/>
            <rect x="8.33325" y="14.1667" width="5.83333" height="1.66667" rx="0.833334" fill="white"/>
        </svg>
    },
    {
        name: 'Files',
        role: 'user',
        floatingMenu: true,
        display: 'always',
        path: '/files',
        icon: IconUser
    },
    // {
    //     name: 'Finance',
    //     role: 'user',
    //     floatingMenu: true,
    //     display: 'always',
    //     path: '/finance',
    //     icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <ellipse opacity="0.3" cx="17.0833" cy="10.4167" rx="1.25" ry="1.25" fill="#4A5BCF"/>
    //         <rect opacity="0.5" x="2.00073" y="4.54053" width="15" height="5.83333" rx="1" transform="rotate(-15 2.00073 4.54053)" fill="#ADAFDC"/>
    //         <path fill-rule="evenodd" clip-rule="evenodd" d="M3.66675 3.33333C2.56218 3.33333 1.66675 4.22876 1.66675 5.33333V14.6667C1.66675 15.7712 2.56218 16.6667 3.66675 16.6667H16.3334C17.438 16.6667 18.3334 15.7712 18.3334 14.6667V13.0526C17.9546 13.2326 17.5307 13.3333 17.0834 13.3333C15.4726 13.3333 14.1667 12.0275 14.1667 10.4167C14.1667 8.80583 15.4726 7.49999 17.0834 7.49999C17.5307 7.49999 17.9546 7.6007 18.3334 7.78068V5.33333C18.3334 4.22876 17.438 3.33333 16.3334 3.33333H3.66675Z" fill="#ADAFDC"/>
    //     </svg>
    // },
    {
        name: 'My profile',
        path: '/settings',
        floatingMenu: true,
        display: 'mobile',
        role: 'user',
        icon: IconUser
    },
    {
        name: 'Support',
        role: 'user',
        floatingMenu: true,
        display: 'always',
        path: '/support',
        icon: questionMark
    },
    // {
    //     name: 'Integrations',
    //     role: 'user',
    //     floatingMenu: true,
    //     display: 'always',
    //     path: '/integrations',
    //     icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path fill-rule="evenodd" clip-rule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#ADAFDC"/>
    //         <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#ADAFDC"/>
    //     </svg>
    // },
    {
        name: 'Admin panel',
        role: 'admin',
        floatingMenu: true,
        display: 'always',
        path: '/admin',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4C2.44772 4 2 4.44772 2 5V7C2 7.55228 2.44771 8 3 8H20C20.5523 8 21 7.55228 21 7V5C21 4.44772 20.5523 4 20 4H3ZM10 10C9.44772 10 9 10.4477 9 11V19C9 19.5523 9.44771 20 10 20H20C20.5523 20 21 19.5523 21 19V11C21 10.4477 20.5523 10 20 10H10Z" fill="#4A5BCF"/>
            <rect opacity="0.3" x="2" y="10" width="5" height="10" rx="1" fill="#4A5BCF"/>
        </svg>
    },
    {
        name: 'Parameters',
        role: 'admin',
        floatingMenu: true,
        display: 'always',
        path: '/parameters',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C4.79086 3 3 4.79086 3 7C3 9.20914 4.79086 11 7 11H17C19.2091 11 21 9.20914 21 7C21 4.79086 19.2091 3 17 3H7ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z" fill="#ADAFDC"/>
            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M7 13C4.79086 13 3 14.7909 3 17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17C21 14.7909 19.2091 13 17 13H7ZM17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19Z" fill="#ADAFDC"/>
        </svg>
    },
    // {
    //     name: 'All clients',
    //     role: 'admin',
    //     floatingMenu: true,
    //     display: 'always',
    //     path: '/all-clients',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    //         <path opacity="0.3" d="M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z" fill="#ADAFDC"/>
    //         <path d="M3.00065 20.1992C3.38826 15.4265 7.26191 13 11.9833 13C16.7712 13 20.7049 15.2932 20.9979 20.2C21.0096 20.3955 20.9979 21 20.2467 21C16.5411 21 11.0347 21 3.7275 21C3.47671 21 2.97954 20.4592 3.00065 20.1992Z" fill="#ADAFDC"/>
    //     </svg>
    // }
    // {
    //     name: 'Upgrade your plan',
    //     role: 'user',
    //     floatingMenu: false,
    //     display: 'mobile',
    //     path: '/upgradePlan',
    // },
];


