import React, { useState, useEffect } from 'react';
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import {editAdminIcon, uploadImage} from "../login/loginConstants";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon } from "../survey/surveyConstant";
import EditProfile from "./editProfile";
import Confirm from "../confirm/confirm";
import {Trans} from "react-i18next";
import {initLoader, removeLoader} from "../loader/loader";
import {transformDate} from "../admin/adminEstablishment";
import { toast } from 'react-toastify';

function ProfileDetails() {
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [header, setHeader] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Change' : 'Wijzig');
    const [inputName, setInputName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [index, setIndex] = useState(0);
    const [confirmSave, setConfirmSave] = useState(false);
    const [type, setType] = useState('');
    const [file, setFile] = useState('');
    const [file2, setFile2] = useState('');
    const allowedTypes = ["image/jpeg", "image/png"];

    useEffect(() => {
        initLoader(document.querySelector('.profile'));
        getUserProfile();
    }, []);

    const fetchAndSetImage = (photoUrl, index) => {
        const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
        const headers = {
            'Authorization': token ? `Bearer ${token}` : '',
        };

        fetch(photoUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Network response was not ok: ${res.status}`);
                }
                return res.blob();
            })
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob);
                if (index === 1) {
                    setFile(imageUrl);
                } else {
                    setFile2(imageUrl);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const getUserProfile = () => {
        Connect(`${Endpoint.userProfile}`, 'GET').then(response => {
            setData(response);
            if (response.logoUri !== null) {
                fetchAndSetImage(response.logoUri, 1);
            }
            if (response.avatarUri !== null) {
                fetchAndSetImage(response.avatarUri, 2);
                let user = JSON.parse(localStorage.getItem('user'));
                user.avatarUri = response.avatarUri;
                localStorage.setItem('user', JSON.stringify(user));
            }
            removeLoader(document.querySelector('.profile'));
        });
    }

    const handleConfirm = (value) => {
        setConfirmSave(value);
    }

    const handleSave = () => {
        handleConfirm(false);
        save();
    }

    const handleEdit = (open, name, value, idx, type) => {
        setEdit(open);
        setType(type ? type :'')
        setInputName(name);
        setInputValue(value ? value : "");
        setIndex(idx);
        setHeader(localStorage.getItem('i18nextLng') === 'en' ? 'Change' : 'Wijzig');
    }

    const update = () => {
        let tempArr = data;
        if (index === 'firstName') {
            tempArr.firstName = inputValue;
        } else if (index === 'lastName') {
            tempArr.lastName = inputValue;
        } else if (index === 'gender') {
            tempArr.gender = inputValue;
        } else if (index === 'dateOfBirth') {
            tempArr.dateOfBirth = inputValue;
        } else if (index === 'phone') {
            tempArr.phone = inputValue;
        } else if (index === 'address') {
            tempArr.address = inputValue;
        }
        setData(tempArr);
        setEdit(false);
    }

    const save = () => {
        let body = {
            userId: JSON.parse(localStorage.getItem('user')).id,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            dateOfBirth: data.dateOfBirth,
            phone: data.phone,
            address: data.address,
            logoFile: data.logoUri,
            avatarFile: data.avatarUri
        }
        Connect(`${Endpoint.userProfile}`, 'PUT', body).then(response => {
            getUserProfile();
        });
    }

    const handleDocumentChange = async (e, pid) => {
        const file = e.target.files[0];
        let tempArr = data;
        if (!allowedTypes.includes(file?.type)) {
            toast.error(localStorage.getItem("i18nextLng") === "en" ? "Only JPEG and PNG images are allowed." : "Alleen JPEG- en PNG-afbeeldingen zijn toegestaan.")
            return;
        }
        if (file?.size > 1000000) {
            toast.error(localStorage.getItem("i18nextLng") === "en" ? "Max file size is 1mb." : "De maximale bestandsgrootte is 1mb.", );
            return;
        }
        if (file) {
            const base64Image = await convertToBase64(file);
            if (pid === 1) {
                setFile(`[biz-user-logo.jpg]${base64Image}`);
                tempArr.logoUri = `[biz-user-logo.jpg]${base64Image}`;
            } else {
                setFile2(`[biz-user-avatar.jpg]${base64Image}`);
                tempArr.avatarUri = `[biz-user-avatar.jpg]${base64Image}`;
            }
            setData(tempArr);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    return (
        <>
            <div className="profile">
                <div className="row margin">
                    <div className="box">
                        <div className="profile__header"><Trans>profile.account</Trans></div>
                        <div className="profile__box">
                            <div className="row">
                                <div className="profile__input half">
                                    <div className="text light"><Trans>profile.name</Trans></div>
                                    <div className="text">{data.firstName}</div>
                                    <div className="edit" onClick={() => handleEdit(true, <Trans>profile.name</Trans>, data.firstName, 'firstName')}>
                                        {editAdminIcon}
                                    </div>
                                </div>
                                <div className="profile__input half">
                                    <div className="text light"><Trans>profile.surname</Trans></div>
                                    <div className="text">{data.lastName}</div>
                                    <div className="edit" onClick={() => handleEdit(true, <Trans>profile.surname</Trans>, data.lastName, 'lastName')}>
                                        {editAdminIcon}
                                    </div>
                                </div>
                            </div>
                            <div className="profile__input main">
                                <div className="text light"><Trans>profile.gender</Trans></div>
                                <div className="text">{data.gender}</div>
                                <div className="edit" onClick={() => handleEdit(true, <Trans>profile.gender</Trans>, data.gender, 'gender')}>
                                    {editAdminIcon}
                                </div>
                            </div>
                            <div className="profile__input main">
                                <div className="text light"><Trans>profile.date</Trans></div>
                                <div className="text">{data.dateOfBirth && transformDate(data.dateOfBirth.substring(8,10), data.dateOfBirth.substring(5,7), data.dateOfBirth.substring(0,4))}</div>
                                <div className="edit" onClick={() => handleEdit(true, <Trans>profile.date</Trans>, data.dateOfBirth, 'dateOfBirth')}>
                                    {editAdminIcon}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="profile__header"><Trans>profile.logo</Trans></div>
                        <label htmlFor={`logo`}>
                            <div className="profile__upload" style={{backgroundImage: `url(${file.replace('[biz-user-logo.jpg]', '')})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                {file === '' ? uploadImage : ''}
                            </div>
                            <div className="profile__upload-buttom">Upload your logo</div>
                        </label>
                        <input id={`logo`} type="file" accept="image/*" onChange={(e) => handleDocumentChange(e, 1)} style={{ display: 'none' }} />
                    </div>
                    <div className="box">
                        <div className="profile__header"><Trans>profile.picture</Trans></div>
                        <label htmlFor={`picture`}>
                            <div className="profile__upload" style={{backgroundImage: `url(${file2.replace('[biz-user-avatar.jpg]', '')})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                {file2 === '' ? uploadImage : ''}
                            </div>
                            <div className="profile__upload-buttom">Upload your profile picture</div>
                        </label>
                        <input id={`picture`} type="file" accept="image/*" onChange={(e) => handleDocumentChange(e, 2)} style={{ display: 'none' }} />
                    </div>
                </div>
                <div className="row margin">
                    <div className="box long">
                        <div className="profile__header"><Trans>profile.contact</Trans></div>
                        <div className="profile__box long">
                            <div className="profile__input long">
                                <div className="text light"><Trans>profile.email</Trans></div>
                                <div className="text">{data.email}</div>
                                <div className="info">
                                    <Tooltip title={localStorage.getItem('i18nextLng') === 'en' ? 'Want to change the email address you use to log in? Please contact our support team!' : 'Wilt u het e-mailadres waarmee u inlogt wijzigen? Neem contact op met ons ondersteuningsteam!'} placement={'right'}>
                                        <IconButton aria-label="delete">
                                            {InfoIcon}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="profile__input long">
                                <div className="text light"><Trans>profile.phone</Trans></div>
                                <div className="text">{data.phone}</div>
                                <div className="edit" onClick={() => handleEdit(true, <Trans>profile.phone</Trans>, data.phone, 'phone', 'phone')}>
                                    {editAdminIcon}
                                </div>
                            </div>
                            <div className="profile__input long">
                                <div className="text light"><Trans>profile.address</Trans></div>
                                <div className="text">{data.address}</div>
                                <div className="edit" onClick={() => handleEdit(true, <Trans>profile.address</Trans>, data.address, 'address')}>
                                    {editAdminIcon}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="btn" onClick={() => handleConfirm(true)}><Trans>profile.update</Trans></div>
                </div>
            </div>
            <EditProfile active={edit} handleActive={handleEdit} handleSave={update} header={header} inputName={inputName} inputValue={inputValue} index={index} type={type} />
            <Confirm active={confirmSave} message={localStorage.getItem('i18nextLng') === 'en' ? 'Whether to save changes?' : 'Of de wijzigingen moeten worden opgeslagen?'} handleConfirm={handleConfirm} handleRemove={handleSave} />
        
        </>
    )
}

export default ProfileDetails;
