import React, { useState } from 'react';
import {editAdminIcon} from "../login/loginConstants";
import Popup from "../popup/popup";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import {TextField} from "@material-ui/core";
import {Trans} from "react-i18next";
import {OutputFlow} from "../establishmentFlow/outputFlow";

export const transformDate = (day, month, year) => {
    return `${day}/${month}/${year}`;
}

export const transformSaveDate = (day, month, year) => {
    return `${year}-${month}-${day}`;
}

function AdminEstablishment(props) {
    const [add, setAdd] = useState(false);
    const [addActive, setAddCompany] = useState(false);
    const [companyNumber, setCompanyNumber] = useState('');
    const [type, setType] = useState('');

    const handleAdd = (value, type) => {
        setAdd(value);
        setType(type);
    }

    const save = (value, type) => {
        handleAdd(value);
        if (type === 'activities') {
            let body = {
                mainActivities: props.activitiesData.mainActivities,
                businessDescription: props.activitiesData.businessDescription,
                sector: props.activitiesData.sector,
                activityType: props.activitiesData.activityType
            }
            Connect(`${Endpoint.getCompanyActivities}/${sessionStorage.getItem('userID')}`, 'PUT', body).then(response => {
                props.handleFiles();
            })
        } else {
            let body = {
                genAssemblyFirst: props.financialData.genAssemblyFirst,
                genAssemblyTime: props.financialData.genAssemblyTime,
                genAssemblyWhen: props.financialData.genAssemblyWhen,
                periodOneFrom: props.financialData.periodOneFrom,
                periodOneTo: props.financialData.periodOneTo,
                periodThreeFrom: props.financialData.periodThreeFrom,
                periodThreeTo: props.financialData.periodThreeTo,
                periodTwoFrom: props.financialData.periodTwoFrom,
                periodTwoTo: props.financialData.periodTwoTo,
            }
            Connect(`${Endpoint.getFinancialCalendar}/${sessionStorage.getItem('userID')}`, 'PUT', body).then(response => {
                props.handleFiles();
            })
        }
    }

    const saveCompanyNumber = () => {
        setAddCompany(false);
        let body = {
            id: parseInt(sessionStorage.getItem('userID')),
            companyNumber
        }
        Connect(`${Endpoint.companyDetails}`, 'PATCH', body).then(response => {
            props.handleCompanyData();
            props.handleFiles();
            setTimeout(() => {
                setCompanyNumber('');
            }, 300);

        })
    }

    return (
        <>
            <div className="admin__content">
                <div className="admin__header">
                    <Trans>adminEstablishment.header1</Trans>
                    <div className="edit" onClick={() => handleAdd(true, 'financial')}>{editAdminIcon}</div>
                    <div className={props.financialData.genAssemblyFirst !== null && props.financialData.genAssemblyTime !== null && props.financialData.genAssemblyWhen !== null && props.financialData.periodOneFrom !== null && props.financialData.periodOneTo !== null && props.financialData.periodThreeFrom !== null && props.financialData.periodThreeTo !== null && props.financialData.periodTwoFrom !== null && props.financialData.periodTwoTo !== null ? "btn complete" : "btn pending"}>{props.financialData.genAssemblyFirst !== null && props.financialData.genAssemblyTime !== null && props.financialData.genAssemblyWhen !== null && props.financialData.periodOneFrom !== null && props.financialData.periodOneTo !== null && props.financialData.periodThreeFrom !== null && props.financialData.periodThreeTo !== null && props.financialData.periodTwoFrom !== null && props.financialData.periodTwoTo !== null ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                </div>
                <div className="admin__header company">
                    <Trans>adminEstablishment.header3</Trans>
                    <div className="edit" onClick={() => setAddCompany(true)}>{editAdminIcon}</div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <div className="row">
                                <div className="admin__text"></div>
                                <div className="admin__text"><Trans>admin.from</Trans></div>
                                <div className="admin__text"><Trans>admin.to</Trans></div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.period</Trans> 1</div>
                                <div className="admin__text award">{props.financialData.periodOneFrom && transformDate(props.financialData.periodOneFrom.substring(8,10), props.financialData.periodOneFrom.substring(5,7), props.financialData.periodOneFrom.substring(0,4))}</div>
                                <div className="admin__text award">{props.financialData.periodOneTo && transformDate(props.financialData.periodOneTo.substring(8,10), props.financialData.periodOneTo.substring(5,7), props.financialData.periodOneTo.substring(0,4))}</div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.period</Trans> 2</div>
                                <div className="admin__text award">{props.financialData.periodTwoFrom && transformDate(props.financialData.periodTwoFrom.substring(8,10), props.financialData.periodTwoFrom.substring(5,7), props.financialData.periodTwoFrom.substring(0,4))}</div>
                                <div className="admin__text award">{props.financialData.periodTwoTo && transformDate(props.financialData.periodTwoTo.substring(8,10), props.financialData.periodTwoTo.substring(5,7), props.financialData.periodTwoTo.substring(0,4))}</div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.period</Trans> 3</div>
                                <div className="admin__text award">{props.financialData.periodThreeFrom && transformDate(props.financialData.periodThreeFrom.substring(8,10), props.financialData.periodThreeFrom.substring(5,7), props.financialData.periodThreeFrom.substring(0,4))}</div>
                                <div className="admin__text award">{props.financialData.periodThreeTo && transformDate(props.financialData.periodThreeTo.substring(8,10), props.financialData.periodThreeTo.substring(5,7), props.financialData.periodThreeTo.substring(0,4))}</div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="row">
                                <div className="admin__text award"><Trans>admin.general</Trans></div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.when</Trans></div>
                                <div className="admin__text award">{props.financialData.genAssemblyWhen}</div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.time</Trans></div>
                                <div className="admin__text award">{props.financialData.genAssemblyTime}</div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.first</Trans></div>
                                <div className="admin__text award">{props.financialData.genAssemblyFirst}</div>
                            </div>
                        </div>
                        {(companyNumber.length > 0) || (props.companyData && props.companyData.companyNumber !== null && props.companyData.companyNumber !== '') ?
                            <div className="box">
                                <div className="row">
                                    <div className="admin__text">{companyNumber.length > 0 ? companyNumber : props.companyData && props.companyData.companyNumber}</div>
                                </div>
                            </div>
                        : ''}
                    </div>
                </div>
            </div>
            <div className="admin__content">
                <div className="admin__header">
                    <Trans>adminEstablishment.header2</Trans>
                    <div className="edit" onClick={() => handleAdd(true, 'activities')}>{editAdminIcon}</div>
                    <div className={props.activitiesData.sector !== null && props.activitiesData.activityType !== null && props.activitiesData.businessDescription !== null && props.activitiesData.mainActivities !== null ? "btn complete" : "btn pending"}>{props.activitiesData.sector !== null && props.activitiesData.activityType !== null && props.activitiesData.businessDescription !== null && props.activitiesData.mainActivities !== null ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <div className="row">
                                <div className="admin__text"><Trans>admin.sector</Trans></div>
                                <div className="admin__text award">{props.activitiesData.sector}</div>
                            </div>
                            <div className="row">
                                <div className="admin__text"><Trans>admin.activity</Trans></div>
                                <div className="admin__text award">{props.activitiesData.activityType}</div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="admin__text small">{props.activitiesData.businessDescription}</div>
                        </div>
                        <div className="box">
                            <div className="admin__text small">
                                {props.activitiesData.mainActivities && props.activitiesData.mainActivities.split(';').map((activity, index) => (
                                    <React.Fragment key={index}>
                                        {activity}
                                        {index < props.activitiesData.mainActivities.split(';').length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin__content">
                <div className="admin__header"> <Trans>adminEstablishment.header4</Trans></div>
                <OutputFlow financialData={props.financialData} companyData={props.companyData} activities={props.activitiesData} LogoCompany={props.LogoCompany} establishmentSummary={props.establishmentSummary} professionalSummary={props.professionalSummary} transportationSummary={props.transportationSummary} officeSummary={props.officeSummary} generalSummary={props.generalSummary} otherSummary={props.otherSummary} data={props.data} summary={props.summary} handleFiles={props.handleFiles} form={props.form} graphSummary={props.graphSummary} oneOffSummary={props.oneOffSummary} loading={props.loading} />
            </div>
            <div className={addActive === true ? "addCompany__wrapper active" : "addCompany__wrapper"}>
                <div className="addCompany__content">
                    <div className="addCompany__content-header">Add Company number</div>
                    <div className="row space">
                        <div className="text">Value</div>
                        <TextField label={''} variant={'outlined'} size={'small'} type={'text'} value={companyNumber}
                                   placeholder={'...'} onChange={(e) => setCompanyNumber(e.target.value)} />
                    </div>
                    <div className="row">
                        <button className="btn btn--primary" onClick={() => saveCompanyNumber()}>Save</button>
                    </div>
                </div>
            </div>
            <Popup active={add} handleActive={handleAdd} handleSave={save} data={props.activitiesData} handleData={props.handleData} type={type} selectData={props.selectData} nData={props.nData}
                   financialData={props.financialData} handleFinancialData={props.handleFinancialData}
            />
        </>
    );
}

export default AdminEstablishment;
