import React, { useState } from 'react';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import './profile.scss';
import ProfileDetails from "./profileDetails";
import Security from "./security";
import ProfileNotifications from "./profileNotifications";
import {withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";

const Profile = (props) => {
    const [quickstart, setQuickstart] = useState(false);
    const [step, setStep] = useState(1);

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const updateStep = (value) => {
        setStep(value);
    }

    return (
        <div className="container">
            <Navbar currentStep={step} handleQuickstart={handleQuickstart} handleStep={updateStep} name={'Profile'} survey={true} {...props} />
            <Menu role={'user'} permissions={null} isMobile={false} component={'company'} />
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            {step === 1 ?
                <ProfileDetails />
                : ''}
            {step === 2 ?
                <Security />
                : ''}
            {step === 3 ?
                <ProfileNotifications />
                : ''}
        </div>
    );
}

export default withTranslation()(withCookies(Profile));