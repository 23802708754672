import React, { useState, useEffect } from "react";
import Settings from "./settings";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Endpoint from '../../configFile';
import UpgradePlan from "./upgradePlan";
import requestBehavior from "../requestBehavior/requestBehavior";

function GetPersonData(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch(props.onlyRead ? `${Endpoint.getPersonData}/${props.idPerson}` : Endpoint.getPersonData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response;
            })
            .then(res => res.json())
            .then(response => {
                setIsLoaded(true);
                setItems(response);
            })
            .catch((error) => {
                console.log('Error:', error);
                setIsLoaded(true);
                setError(error);
            });
    }, [props]);

    const { classes, isMobile, onlyRead } = props;

    if (error !== null) {
        return requestBehavior(error);
    } else if (!isLoaded) {
        return <div className={props.idPerson === undefined ? 'container__body container--settings' : ''}><CircularProgress classes={{ root: classes.root }} /></div>;
    } else {
        return props.typeSettings === 'settings' ? <Settings request={onlyRead ? items : items.user} isMobile={isMobile} onlyRead={onlyRead} /> : <UpgradePlan request={items} isMobile={isMobile} />;
    }
}

export default GetPersonData;
