import Switch from '@material-ui/core/Switch';
import {withStyles} from "@material-ui/core/styles";

const CustomSmallSwitch = withStyles(() => ({
    root: {
        width: 25,
        height: 13,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: '#301164;',
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#FFF',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#4A5BCF',
            },
        },
    },
    thumb: {
        width: 9,
        height: 9,
        boxShadow: 'none',
    },
    track: {
        borderRadius: '15px',
        opacity: 1,
        backgroundColor: '#FFF',
    },
    checked: {},
}))(Switch);

export default CustomSmallSwitch;

