import React, { useState, useEffect } from 'react';
import * as Endpoint from '../../configFile';
import Users from "./users";
import requestBehavior from "../requestBehavior/requestBehavior";

const GetUserData = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState({});
    const [role] = useState(props.role);

    const updateData = (newItem, isEdit) => {
        if (isEdit) {
            setItems((prevItems) => {
                let updatedElem = prevItems.find((elem) => elem.id === newItem.id);
                updatedElem.name = newItem.name;
                updatedElem.surname = newItem.surname;
                updatedElem.avatar = newItem.avatar;
                updatedElem.email = newItem.email;
                updatedElem.phone = newItem.phone;
                updatedElem.position = newItem.position;
                updatedElem.role = newItem.role;
                return [...prevItems];
            });
        } else {
            setItems((prevItems) => [...prevItems, newItem]);
        }
    };

    useEffect(() => {
        if (role !== 'admin') {
            setError(true);
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(Endpoint.getUsersData, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
                    },
                });

                if (!response.ok) {
                    throw response;
                }

                const responseData = await response.json();
                setItems(responseData.content);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
                setIsLoaded(true);
            }
        };

        fetchData();
    }, [role]);

    if (error !== null) {
        return requestBehavior(error);
    } else {
        return <Users request={items} isLoaded={isLoaded} update={updateData} role={role} isMobile={props.isMobile} />;
    }
};

export default GetUserData;
