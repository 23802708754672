import React, { Component } from "react";
import { FormControlLabel, Radio, TextField, FormControl } from "@material-ui/core";
import CustomSwitch from "../switch/switch";
import { avatar, smile } from "../login/loginConstants";
import Pagination from "../pagination/pagination";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon, removeIcon } from "../survey/surveyConstant";
import Tooltip from "@material-ui/core/Tooltip";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import RadioGroup from "@material-ui/core/RadioGroup";
import Confirm from "../confirm/confirm";
import { Trans } from "react-i18next";
import { InputAdornment } from "@material-ui/core";

class ShareholderStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      appendShareHolders: [false, false],
      shareholders: [],
      localShareholders: sessionStorage.getItem("shareholders") !== null ? JSON.parse(sessionStorage.getItem("shareholders")) : [],
      addShareholderId: 0,
      addShareholderId2: 0,
      addShareholderId3: 0,
      addShareholderName: "",
      addShareholderName2: "",
      addShareholderName3: "",
      addShareholderPhoto1: null,
      addShareholderPhoto2: null,
      addShareholderPhoto3: null,
      addShareholderPhotoMy: null,
      addShareholderAmount: "",
      addShareholderAmount2: "",
      addShareholderAmount3: "",
      addShareholderAmountMy: null,
      addShareholderStreet: "",
      addShareholderStreet2: "",
      addShareholderStreet3: "",
      addShareholderNumber: "",
      addShareholderNumber2: "",
      addShareholderNumber3: "",
      addShareholderPostalCode: "",
      addShareholderPostalCode2: "",
      addShareholderPostalCode3: "",
      addShareholderCity: "",
      addShareholderCity2: "",
      addShareholderCity3: "",
      shareholderName: "",
      shareholderAmount: "",
      shareHolderStreet: "",
      shareHolderNumber: "",
      shareHolderPostalCode: "",
      shareHolderCity: "",
      shareHolderPid: 0,
      confirm: false,
      otherShareholders: false,
      startingCapital: null,
      image: "",
    };
  }

  componentDidMount() {
    this.getRes();
    let body = {
      checkpointCategory: JSON.parse(sessionStorage.getItem("checkpointCategoryEstablishment"))[1],
      completed: 1,
      required: 1,
      isChange: true,
    };
    Connect(`${Endpoint.putProgressSurvey}`, "PUT", body).then((response) => {
      console.log(response);
    });
  }

  componentWillUnmount() {
    this.localSave("outside");
    this.props.saveFetchShareholder(
      this.state.shareHolderPid,
      this.state.shareholderName,
      this.state.shareholderAmount,
      this.state.shareHolderStreet,
      this.state.shareHolderNumber,
      this.state.shareHolderPostalCode,
      this.state.shareHolderCity
    );
    this.sendFetchStartingCapital();
  }

  fetchAndSetImage = (photoUrl, shareholderIndex) => {
    const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;
    const headers = {
      Authorization: token ? `Bearer ${token}` : "",
    };

    fetch(photoUrl, {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Network response was not ok: ${res.status}`);
        }
        return res.blob();
      })
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        const stateKey = `addShareholderPhoto${shareholderIndex}`;
        this.setState({
          [stateKey]: imageUrl,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getRes = () => {
    Connect(`${Endpoint.getShareholders}`, "GET").then((response) => {
      let summarySharesAmount = 0;
      this.setState({
        addShareholderId: 0,
        addShareholderId2: 0,
        addShareholderId3: 0,
        addShareholderName: "",
        addShareholderName2: "",
        addShareholderName3: "",
        addShareholderPhoto1: null,
        addShareholderPhoto2: null,
        addShareholderPhoto3: null,
        addShareholderAmount: "",
        addShareholderAmount2: "",
        addShareholderAmount3: "",
        addShareholderStreet: "",
        addShareholderStreet2: "",
        addShareholderStreet3: "",
        addShareholderNumber: "",
        addShareholderNumber2: "",
        addShareholderNumber3: "",
        addShareholderPostalCode: "",
        addShareholderPostalCode2: "",
        addShareholderPostalCode3: "",
        addShareholderCity: "",
        addShareholderCity2: "",
        addShareholderCity3: "",
      });
      if (response.shareholders.length > 0) {
        if (response.shareholders[0].photoUri !== null) {
          const photoUrl = response.shareholders[0].photoUri;
          const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;
          const headers = {
            Authorization: token ? `Bearer ${token}` : "",
          };
          this.fetchAndSetImage(photoUrl, "My");
        }
      }
      if (response.shareholders.length > 1) {
        this.setState({
          otherShareholders: true,
          addShareholderId: response.shareholders[1].id,
          addShareholderName: response.shareholders[1].fullName,
          addShareholderAmount: response.shareholders[1].sharesAmount,
          addShareholderStreet: response.shareholders[1].street,
          addShareholderNumber: response.shareholders[1].houseNumber,
          addShareholderPostalCode: response.shareholders[1].postalCode,
          addShareholderCity: response.shareholders[1].city,
        });
        if (response.shareholders[1].photoUri !== null) {
          const photoUrl = response.shareholders[1].photoUri;
          const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;
          const headers = {
            Authorization: token ? `Bearer ${token}` : "",
          };
          this.fetchAndSetImage(photoUrl, 1);
        }
      }
      if (response.shareholders.length > 2) {
        this.setState({
          addShareholderId2: response.shareholders[2].id,
          addShareholderName2: response.shareholders[2].fullName,
          addShareholderAmount2: response.shareholders[2].sharesAmount,
          addShareholderStreet2: response.shareholders[2].street,
          addShareholderNumber2: response.shareholders[2].houseNumber,
          addShareholderPostalCode2: response.shareholders[2].postalCode,
          addShareholderCity2: response.shareholders[2].city,
        });
        if (response.shareholders[2].photoUri !== null) {
          const photoUrl = response.shareholders[2].photoUri;
          const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;
          const headers = {
            Authorization: token ? `Bearer ${token}` : "",
          };
          this.fetchAndSetImage(photoUrl, 2);
        }
      }
      if (response.shareholders.length > 3) {
        this.setState({
          addShareholderId3: response.shareholders[3].id,
          addShareholderName3: response.shareholders[3].fullName,
          addShareholderAmount3: response.shareholders[3].sharesAmount,
          addShareholderStreet3: response.shareholders[3].street,
          addShareholderNumber3: response.shareholders[3].houseNumber,
          addShareholderPostalCode3: response.shareholders[3].postalCode,
          addShareholderCity3: response.shareholders[3].city,
        });
        if (response.shareholders[3].photoUri !== null) {
          const photoUrl = response.shareholders[3].photoUri;
          const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;
          const headers = {
            Authorization: token ? `Bearer ${token}` : "",
          };
          this.fetchAndSetImage(photoUrl, 3);
        }
      }
      if (response.shareholders.length === 3) {
        this.setState({
          appendShareHolders: [true, false],
        });
      }
      if (response.shareholders.length === 4) {
        this.setState({
          appendShareHolders: [true, true],
        });
      }
      response.shareholders.map((data) => {
        summarySharesAmount += data.sharesAmount;
      });
      response.shareholders.map((data) => {
        data.total = data.sharesAmount !== null ? ((parseInt(data.sharesAmount) / summarySharesAmount) * 100).toFixed(2) : 0;
      });
      this.setState({
        shareholders: response.shareholders,
        addShareholderAmountMy: response.shareholders[0].sharesAmount,
        startingCapital: response.startingCapital ? this.addCommas(this.removeNonNumeric(response.startingCapital)) : 0,
      });
    });
  };

  sendFetchStartingCapital = () => {
    let bodyCapital = {
      startingCapital: this.state.startingCapital && parseInt(this.state.startingCapital.replaceAll(".", "")),
    };
    Connect(`${Endpoint.companyDetails}`, "PATCH", bodyCapital).then((response) => {});
  };

  handleStartingCapital = (value) => {
    this.setState({
      startingCapital: this.addCommas(this.removeNonNumeric(value)),
    });
  };

  handlePage = (value) => {
    if (value === 2) {
      this.sendFetchStartingCapital();
      this.localSave();
      document.querySelector(".container__sidebar-element:nth-child(2)").click();
    }
    this.setState({
      page: value,
    });
  };

  handleImageChange = async (e, pid) => {
    const file = e.target.files[0];

    if (file) {
      const base64Image = await this.convertToBase64(file);
      this.uploadAvatar(pid, base64Image);
      this.setState({
        image: base64Image,
      });
    }
  };

  convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  uploadAvatar = (id, file) => {
    let body = {
      id,
      photoFile: `[photo.jpg]${file}`,
    };
    Connect(`${Endpoint.avatarShareholder}`, "PATCH", body).then((response) => {
      this.getRes();
    });
  };

  handleAppendShareHolders = (value) => {
    this.setState({
      appendShareHolders: value,
    });
  };

  addCommas = (num) => {
    if (num.length > 1) {
      if (num[0] === "0") {
        num = num.slice(1);
      }
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  yourAmount = (value) => {
    this.setState({
      addShareholderAmountMy: value,
    });
    let body = {
      id: this.state.shareholders[0].id,
      fullName: this.state.shareholders[0].fullName,
      sharesAmount: value,
    };
    Connect(`${Endpoint.getShareholders}`, "PUT", body).then((response) => {
      this.getRes();
    });
  };

  localSave = (type = "normal") => {
    let body = {
      fullName: this.state.addShareholderName,
      sharesAmount: this.state.addShareholderAmount,
    };
    let body2 = {
      fullName: this.state.addShareholderName2,
      sharesAmount: this.state.addShareholderAmount2,
    };
    let body3 = {
      fullName: this.state.addShareholderName3,
      sharesAmount: this.state.addShareholderAmount3,
    };
    if (this.state.addShareholderId !== 0) {
      body = {
        id: this.state.addShareholderId,
        fullName: this.state.addShareholderName,
        sharesAmount: this.state.addShareholderAmount,
        street: this.state.addShareholderStreet,
        city: this.state.addShareholderCity,
        houseNumber: this.state.addShareholderNumber,
        postalCode: this.state.addShareholderPostalCode,
      };
    }
    if (this.state.addShareholderId2 !== 0) {
      body2 = {
        id: this.state.addShareholderId2,
        fullName: this.state.addShareholderName2,
        sharesAmount: this.state.addShareholderAmount2,
        street: this.state.addShareholderStreet2,
        city: this.state.addShareholderCity2,
        houseNumber: this.state.addShareholderNumber2,
        postalCode: this.state.addShareholderPostalCode2,
      };
    }
    if (this.state.addShareholderId3 !== 0) {
      body3 = {
        id: this.state.addShareholderId3,
        fullName: this.state.addShareholderName3,
        sharesAmount: this.state.addShareholderAmount3,
        street: this.state.addShareholderStreet3,
        city: this.state.addShareholderCity3,
        houseNumber: this.state.addShareholderNumber3,
        postalCode: this.state.addShareholderPostalCode3,
      };
    }
    let array = [];
    if (body.fullName !== "") {
      array.push(body);
    }
    if (body2.fullName !== "") {
      array.push(body2);
    }
    if (body3.fullName !== "") {
      array.push(body3);
    }
    this.setState({
      localShareholders: array,
    });
    if (type === "normal") {
      if (array && array[0] && array[0].fullName !== "") {
        Connect(`${Endpoint.getShareholders}`, this.state.addShareholderId !== 0 ? "PUT" : "POST", array[0]).then((response) => {
          this.getRes();
        });
      }
      if (array && array[1] && array[1].fullName !== "") {
        Connect(`${Endpoint.getShareholders}`, this.state.addShareholderId2 !== 0 ? "PUT" : "POST", array[1]).then((response) => {
          this.getRes();
        });
      }
      if (array && array[2] && array[2].fullName !== "") {
        Connect(`${Endpoint.getShareholders}`, this.state.addShareholderId3 !== 0 ? "PUT" : "POST", array[2]).then((response) => {
          this.getRes();
        });
      }
    } else {
      if (array && array[0] && array[0].street !== "" && array[0].street !== null) {
        Connect(`${Endpoint.getShareholders}`, this.state.addShareholderId !== 0 ? "PUT" : "POST", array[0]).then((response) => {
          this.getRes();
        });
      }
      if (array && array[1] && array[1].street !== "" && array[1].street !== null) {
        Connect(`${Endpoint.getShareholders}`, this.state.addShareholderId2 !== 0 ? "PUT" : "POST", array[1]).then((response) => {
          this.getRes();
        });
      }
      if (array && array[2] && array[2].street !== "" && array[2].street !== null) {
        Connect(`${Endpoint.getShareholders}`, this.state.addShareholderId3 !== 0 ? "PUT" : "POST", array[2]).then((response) => {
          this.getRes();
        });
      }
    }
  };

  saveDataShareholder = (type, value) => {
    if (type === "street") {
      this.setState({
        shareHolderStreet: value,
      });
      this.props.handleShareholderData(
        this.state.shareHolderPid,
        this.state.shareholderName,
        this.state.shareholderAmount,
        value,
        this.state.shareHolderNumber,
        this.state.shareHolderPostalCode,
        this.state.shareHolderCity
      );
    }
    if (type === "houseNumber") {
      this.setState({
        shareHolderNumber: value,
      });
      this.props.handleShareholderData(
        this.state.shareHolderPid,
        this.state.shareholderName,
        this.state.shareholderAmount,
        this.state.shareHolderStreet,
        value,
        this.state.shareHolderPostalCode,
        this.state.shareHolderCity
      );
    }
    if (type === "postalCode") {
      this.setState({
        shareHolderPostalCode: value,
      });
      this.props.handleShareholderData(
        this.state.shareHolderPid,
        this.state.shareholderName,
        this.state.shareholderAmount,
        this.state.shareHolderStreet,
        this.state.shareHolderNumber,
        value,
        this.state.shareHolderCity
      );
    }
    if (type === "city") {
      this.setState({
        shareHolderCity: value,
      });
      this.props.handleShareholderData(
        this.state.shareHolderPid,
        this.state.shareholderName,
        this.state.shareholderAmount,
        this.state.shareHolderStreet,
        this.state.shareHolderNumber,
        this.state.shareHolderPostalCode,
        value
      );
    }
  };

  handleShareholder = (e, pid, target) => {
    if (!e.target.closest(".upload") && !e.target.closest(".remove") && !e.target.closest("label")) {
      e.preventDefault();
      this.props.saveFetchShareholder(
        this.state.shareHolderPid,
        this.state.shareholderName,
        this.state.shareholderAmount,
        this.state.shareHolderStreet,
        this.state.shareHolderNumber,
        this.state.shareHolderPostalCode,
        this.state.shareHolderCity
      );
      let table = document.querySelectorAll(".container__sidebar-element");
      for (let i = 0; i < table.length; i++) {
        table[i].classList.remove("is-active");
      }
      this.setState({
        page: 2,
      });
      Connect(`${Endpoint.getShareholders}/${pid}`, "GET").then((response) => {
        this.setState({
          shareHolderStreet: response.street !== null ? response.street : "",
          shareHolderNumber: response.houseNumber !== null ? response.houseNumber : "",
          shareHolderPostalCode: response.postalCode !== null ? response.postalCode : "",
          shareHolderCity: response.city !== null ? response.city : "",
          shareholderName: response.fullName !== null ? response.fullName : "",
          shareholderAmount: response.sharesAmount !== null ? response.sharesAmount : "",
          shareHolderPid: response.id,
        });
        target.classList.add("is-active");
      });
    }
  };

  handleConfirm = (value) => {
    this.setState({
      confirm: value,
    });
  };

  confirmRemove = (pid) => {
    this.handleConfirm(true);
    sessionStorage.setItem("indexDelete", pid);
  };

  remove = () => {
    this.handleConfirm(false);
    Connect(`${Endpoint.getShareholders}/${sessionStorage.getItem("indexDelete")}`, "DELETE").then((response) => {
      this.getRes();
    });
  };

  render() {
    return (
      <>
        {this.state.page === 1 ? (
          <div className='container__body establishmentFlow shareholders'>
            <div className='container__body-header'>
              <Trans>establishmentFlow.lone</Trans>
            </div>
            <div className='container__body-box'>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.otherShareholder</Trans>
                </p>
                <FormControl>
                  <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='otherShareholders' defaultValue={"No"}>
                    <div className='container__body-radio' onClick={() => this.setState({ otherShareholders: true })}>
                      <FormControlLabel
                        value='Yes'
                        control={<Radio />}
                        label={<Trans>establishmentFlow.yes</Trans>}
                        className='container__body-text'
                        checked={this.state.otherShareholders}
                      />
                    </div>
                    <div
                      className={this.state.shareholders.length > 1 ? "container__body-radio blocked" : "container__body-radio"}
                      onClick={() => this.setState({ otherShareholders: false })}
                    >
                      <FormControlLabel
                        value='No'
                        control={<Radio />}
                        label={<Trans>establishmentFlow.no</Trans>}
                        className='container__body-text'
                        checked={!this.state.otherShareholders}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.amount</Trans>
                </p>
                <TextField
                  id={"startingCapital"}
                  label={""}
                  variant={"outlined"}
                  size={"small"}
                  type={"number"}
                  placeholder={"..."}
                  value={this.state.addShareholderAmountMy}
                  onChange={(e) => this.yourAmount(e.target.value)}
                />
              </div>
            </div>
            {this.state.otherShareholders === true ? (
              <>
                <p className='container__body-subheader'>
                  <Trans>establishmentFlow.fillShareholder</Trans>
                </p>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      <Trans>establishmentFlow.name</Trans>
                    </p>
                    <TextField
                      id='shareHolderName'
                      label={""}
                      variant={"outlined"}
                      size={"small"}
                      placeholder={"..."}
                      value={this.state.addShareholderName}
                      onChange={(e) => this.setState({ addShareholderName: e.target.value })}
                    />
                  </div>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      <Trans>establishmentFlow.amountShares</Trans>
                    </p>
                    <TextField
                      label={""}
                      variant={"outlined"}
                      size={"small"}
                      type={"number"}
                      placeholder={"..."}
                      value={this.state.addShareholderAmount}
                      onChange={(e) => this.setState({ addShareholderAmount: e.target.value })}
                    />
                  </div>
                  <div
                    className='container__body-input container__body-switcher'
                    onClick={(e) => this.handleAppendShareHolders(e.target.checked === true ? [true, false] : [false, false])}
                  >
                    <p className='container__body-text'>
                      <Trans>establishmentFlow.another</Trans>
                    </p>
                    <CustomSwitch checked={this.state.appendShareHolders[0]} />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {this.state.appendShareHolders[0] === true ? (
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    <Trans>establishmentFlow.name</Trans>
                  </p>
                  <TextField
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    placeholder={"..."}
                    value={this.state.addShareholderName2}
                    onChange={(e) => this.setState({ addShareholderName2: e.target.value })}
                  />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    <Trans>establishmentFlow.amountShares</Trans>
                  </p>
                  <TextField
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"number"}
                    placeholder={"..."}
                    value={this.state.addShareholderAmount2}
                    onChange={(e) => this.setState({ addShareholderAmount2: e.target.value })}
                  />
                </div>
                <div
                  className='container__body-input container__body-switcher'
                  onClick={(e) => this.handleAppendShareHolders(e.target.checked === true ? [true, true] : [true, false])}
                >
                  <p className='container__body-text'>
                    <Trans>establishmentFlow.another</Trans>
                  </p>
                  <CustomSwitch checked={this.state.appendShareHolders[1]} />
                </div>
              </div>
            ) : (
              <></>
            )}
            {this.state.appendShareHolders[1] === true ? (
              <div className='container__body-box'>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    <Trans>establishmentFlow.name</Trans>
                  </p>
                  <TextField
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    placeholder={"..."}
                    value={this.state.addShareholderName3}
                    onChange={(e) => this.setState({ addShareholderName3: e.target.value })}
                  />
                </div>
                <div className='container__body-input'>
                  <p className='container__body-text'>
                    <Trans>establishmentFlow.amountShares</Trans>
                  </p>
                  <TextField
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    type={"number"}
                    placeholder={"..."}
                    value={this.state.addShareholderAmount3}
                    onChange={(e) => this.setState({ addShareholderAmount3: e.target.value })}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className='container__body-box'>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.capital</Trans>
                  <Tooltip
                    title="There isn't a minimum amount of starting capital, but we advise you to reserve at least €1.000 to get started."
                    placement={"right"}
                  >
                    <IconButton aria-label='delete'>{InfoIcon}</IconButton>
                  </Tooltip>
                </p>
                <TextField
                  InputProps={{
                    startAdornment: this.state.startingCapital ? (
                      <InputAdornment variant='standard' disableTypography position='start'>
                        €
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                  id={"startingCapital"}
                  label={""}
                  variant={"outlined"}
                  size={"small"}
                  type={"text"}
                  placeholder={"€..."}
                  value={this.state.startingCapital}
                  onChange={(e) => this.handleStartingCapital(e.target.value)}
                />
              </div>
            </div>
            <div className='container__body-box d-none'>
              <div className='container__body-button'>
                <button className='btn btn--primary'>{smile}Verify my identity</button>
                <div className='container__body-signature'>Powered by Veriff</div>
              </div>
            </div>
            <Pagination sum={2} active={this.state.page} handlePage={this.handlePage} />
          </div>
        ) : (
          <></>
        )}
        {this.state.page === 2 ? (
          <div className='container__body establishmentFlow shareholders page2'>
            <div className='container__body-header'>
              <Trans>establishmentFlow.home</Trans>
            </div>
            <div className='container__body-box'>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.street</Trans>
                </p>
                <TextField
                  label={""}
                  variant={"outlined"}
                  size={"small"}
                  autoComplete={"new-password"}
                  placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your street name" : "Jouw straat"}
                  value={this.state.shareHolderStreet}
                  onChange={(e) => this.saveDataShareholder("street", e.target.value)}
                />
              </div>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.house</Trans>
                </p>
                <TextField
                  label={""}
                  variant={"outlined"}
                  size={"small"}
                  autoComplete={"new-password"}
                  placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your address cont." : "Jouw huisnummer"}
                  value={this.state.shareHolderNumber}
                  onChange={(e) => this.saveDataShareholder("houseNumber", e.target.value)}
                />
              </div>
            </div>
            <div className='container__body-box'>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.postalCode</Trans>
                </p>
                <TextField
                  label={""}
                  variant={"outlined"}
                  size={"small"}
                  autoComplete={"new-password"}
                  placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your postal code" : "Jouw postcode"}
                  value={this.state.shareHolderPostalCode}
                  onChange={(e) => this.saveDataShareholder("postalCode", e.target.value)}
                />
              </div>
              <div className='container__body-input'>
                <p className='container__body-text'>
                  <Trans>establishmentFlow.city</Trans>
                </p>
                <TextField
                  label={""}
                  variant={"outlined"}
                  size={"small"}
                  autoComplete={"new-password"}
                  placeholder={localStorage.getItem("i18nextLng") === "en" ? "Your city" : "Jouw stad"}
                  value={this.state.shareHolderCity}
                  onChange={(e) => this.saveDataShareholder("city", e.target.value)}
                />
              </div>
            </div>
            <Pagination sum={2} active={this.state.page} handlePage={this.handlePage} />
          </div>
        ) : (
          <></>
        )}
        <div className='container__sidebar shareholders'>
          <div className='container__sidebar-header'>
            <Trans>establishmentFlow.table</Trans>
          </div>
          {this.state.shareholders.map((data, i) => (
            <div
              className={
                (data.street === null ||
                  data.houseNumber === null ||
                  data.postalCode === null ||
                  data.city === null ||
                  data.street === "" ||
                  data.houseNumber === "" ||
                  data.postalCode === "" ||
                  data.city === "") &&
                this.props.validate === true
                  ? "container__sidebar-element error"
                  : "container__sidebar-element"
              }
              onClick={(e) => this.handleShareholder(e, data.id, e.target.closest(".container__sidebar-element"))}
            >
              <label htmlFor={`avatarInput${i + 1}`} className='avatar'>
                {data.photoUri !== null ? (
                  <img
                    src={
                      i === 1
                        ? this.state.addShareholderPhoto1
                        : i === 2
                        ? this.state.addShareholderPhoto2
                        : i === 3
                        ? this.state.addShareholderPhoto3
                        : this.state.addShareholderPhotoMy
                    }
                    alt=''
                  />
                ) : (
                  avatar
                )}
              </label>
              <input
                id={`avatarInput${i + 1}`}
                type='file'
                className='upload'
                accept='image/*'
                onChange={(e) => this.handleImageChange(e, data.id)}
                style={{ display: "none" }}
              />
              <div className='box'>
                <div className='container__sidebar-name'>{data.fullName}</div>
                <div className='container__sidebar-extend'>
                  <p>
                    {data.sharesAmount !== null ? data.sharesAmount : "0"} <Trans>establishmentFlow.shares</Trans>{" "}
                    <span>
                      - {data.total}% <Trans>establishmentFlow.total</Trans>
                    </span>
                  </p>
                </div>
              </div>
              {data.isMain !== true ? (
                <div className='remove' onClick={() => this.confirmRemove(data.id)}>
                  {removeIcon}
                </div>
              ) : (
                <></>
              )}
              <p className='error-text'>
                <Trans>establishmentFlow.validShareholder</Trans>
              </p>
            </div>
          ))}
        </div>
        <Confirm
          active={this.state.confirm}
          handleConfirm={this.handleConfirm}
          handleRemove={this.remove}
          message={<Trans>establishmentFlow.deleteShareHolder</Trans>}
          only={false}
        />
      </>
    );
  }
}

export default ShareholderStructure;
