import React, { Component } from "react";
import { Checkbox, FormControl, FormControlLabel, Radio, TextField, InputAdornment } from "@material-ui/core";
import "./structure.scss";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon } from "../survey/surveyConstant";
import Tooltip from "@material-ui/core/Tooltip";
import { bulletList, closeList, editIcon, trash } from "../login/loginConstants";
import Add from "../cost/add";
import Pagination from "../pagination/pagination";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import RadioGroup from "@material-ui/core/RadioGroup";
import CustomSelect from "../customSelect/customSelect";
import { Range } from "react-range";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Confirm from "../confirm/confirm";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        [<Trans>companyType.typeCost1</Trans>, "", "One-off", 0, [], 0, 0],
        [<Trans>companyType.typeCost2</Trans>, "purple", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost3</Trans>, "pink", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost4</Trans>, "yellow", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost5</Trans>, "green", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost6</Trans>, "red", "Per year", 0, [], 0, 0],
      ],
      dataWithAll: [
        [<Trans>companyType.typeCost1</Trans>, "", "One-off", 0, [], 0, 0],
        [<Trans>companyType.typeCost2</Trans>, "purple", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost3</Trans>, "pink", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost4</Trans>, "yellow", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost5</Trans>, "green", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost6</Trans>, "red", "Per year", 0, [], 0, 0],
      ],
      add: false,
      summary: 0,
      view: "overview",
      confirm: false,
      page: 1,
      car: false,
      transport: false,
      bicycle: false,
      motorcycle: false,
      taxi: false,
      ownOffice: false,
      rentOffice: false,
      ownHome: false,
      coWork: false,
      goodsToSell: false,
      userCosts: [],
      defaultSelectText: "",
      defaultSelectValue: "",
      pid: null,
      editType: null,
      editCategory: null,
      editDescription: null,
      editAmount: null,
      question: false,
      surveyAllFilled: 4,
      companyTypeData: sessionStorage.getItem("companyTypeData") ? JSON.parse(sessionStorage.getItem("companyTypeData")) : [],
      costs: [],
      situationSelectList: [
        {
          id: 597,
          name: localStorage.getItem("i18nextLng") === "en" ? "I am tenant" : "Ik ben huurder",
          value: localStorage.getItem("i18nextLng") === "en" ? "I am tenant" : "Ik ben huurder",
          textKey: "question-option-tenant",
        },
        {
          id: 599,
          name: localStorage.getItem("i18nextLng") === "en" ? "I am the owner" : "ik ben de eigenaar",
          value: localStorage.getItem("i18nextLng") === "en" ? "I am the owner" : "ik ben de eigenaar",
          textKey: "question-option-owner",
        },
        {
          id: 595,
          name: localStorage.getItem("i18nextLng") === "en" ? "I live in a co-housing" : "Ik woon in een cohousing",
          value: localStorage.getItem("i18nextLng") === "en" ? "I live in a co-housing" : "Ik woon in een cohousing",
          textKey: "question-option-co-housing",
        },
        {
          id: 601,
          name: localStorage.getItem("i18nextLng") === "en" ? "I live with my partner" : "Ik woon samen met mijn partner",
          value: localStorage.getItem("i18nextLng") === "en" ? "I live with my partner" : "Ik woon samen met mijn partner",
          textKey: "question-option-partner",
        },
        {
          id: 603,
          name: localStorage.getItem("i18nextLng") === "en" ? "I still live with my parents" : "ik woon nog bij mijn ouders",
          value: localStorage.getItem("i18nextLng") === "en" ? "I still live with my parents" : "ik woon nog bij mijn ouders",
          textKey: "question-option-parents",
        },
      ],
      officeSelectList: [
        {
          id: 576,
          name: localStorage.getItem("i18nextLng") === "en" ? "In a dedicated room" : "In een speciale ruimte",
          value: localStorage.getItem("i18nextLng") === "en" ? "In a dedicated room" : "In een speciale ruimte",
          textKey: "question-option-home-office-room",
        },
        {
          id: 579,
          name: localStorage.getItem("i18nextLng") === "en" ? "In a separate part of my home" : "In een apart gedeelte van mijn huis",
          value: localStorage.getItem("i18nextLng") === "en" ? "In a separate part of my home" : "In een apart gedeelte van mijn huis",
          textKey: "question-option-home-office-seperate-part",
        },
      ],
      usedHomeValues: [this.props.data.usedHome === null || this.props.data.usedHome === undefined ? 0 : this.props.data.usedHome],
      goodsToSellValues: [this.props.data.buyingGoods === null || this.props.data.buyingGoods === undefined ? 0 : this.props.data.buyingGoods],
      establishmentSummary: 0,
      professionalSummary: 0,
      transportationSummary: 0,
      officeSummary: 0,
      generalSummary: 0,
      otherSummary: 0,
      graphSummary: [],
      validateForm: [],
      costCategory: [false, false, false, false, false, false],
    };
  }

  componentDidMount() {
    this.getRes();
    sessionStorage.setItem("goodsToSellValues", this.props.data.buyingGoods);
    setTimeout(() => {
      this.setState({
        costs: [
          this.createCostElement(0, "Car", 0, 0, 0),
          this.createCostElement(1, "Public transport", 0, 0, 1),
          this.createCostElement(2, "Bicycle", 0, 0, 2),
          this.createCostElement(3, "Motorcycle", 0, 0, 3),
          this.createCostElement(4, "Current car", 0, 1, 0),
          this.createCostElement(5, "Leasing car", 0, 1, 1),
          this.createCostElement(6, "Current motorcycle", 0, 2, 0),
          this.createCostElement(7, "Leasing motorcycle", 0, 2, 1),
          this.createCostElement(8, "Train full", 0, 3, 0),
          this.createCostElement(9, "Train part", 0, 3, 1),
          this.createCostElement(10, "Bus", 0, 3, 2),
          this.createCostElement(11, "E-bike", 0, 4, 0),
          this.createCostElement(12, "Regular bike", 0, 4, 1),
          this.createCostElement(13, "Current one", 0, 4, 2),
          { id: 14, name: "Taxi", value: 0, category: "TRANSPORTATION", periodic: true, isPercent: false, status: true },
          this.createCostElement(15, "Office space", 1, 0, 0),
          this.createCostElement(16, "Rent office space", 1, 0, 1),
          this.createCostElement(17, "Home", 1, 0, 2),
          this.createCostElement(18, "Co-work", 1, 0, 3),
          this.createCostElement(19, "Restaurant", 2, 0, 0),
          this.createCostElement(20, "Pension saving", 2, 1, 0),
          this.createCostElement(21, "Goods to sell", 2, 2, 0),
          this.createCostElement(22, "Internet", 3, 0, 0),
          this.createCostElement(23, "Phone", 3, 0, 1),
          this.createCostElement(24, "Office supplies", 3, 0, 2),
          this.createCostElement(25, "Legal documents", 3, 0, 3),
          this.createCostElement(26, "New laptop", 3, 0, 4),
          this.createCostElement(27, "New phone", 3, 0, 5),
          this.createCostElement(28, "Website", 3, 0, 6),
          this.createCostElement(29, "Office 365", 3, 0, 7),
        ],
      });
    }, 50);
    setTimeout(() => {
      this.checkAnswers();
      sessionStorage.setItem("costs", JSON.stringify(this.state.costs));
      sessionStorage.setItem("addCostType", "PER_MONTH");
      this.props.handleData(null, null, { ownOffice: this.state.costs[15].status, rentOffice: this.state.costs[16].status });
    }, 100);
  }

  checkAnswers = () => {
    let data = JSON.parse(sessionStorage.getItem("companyType"));
    let car = false;
    let transport = false;
    let bicycle = false;
    let motorcycle = false;
    let taxi = false;
    let ownOffice = false;
    let rentOffice = false;
    let ownHome = false;
    let coWork = false;
    let goodsToSell = false;
    let costs = this.state.costs;
    data.transport.map((data) => {
      if (data.text === "Car") {
        car = "true";
        costs[0].status = true;
      }
      if (data.text === "Public transport") {
        transport = "true";
        costs[1].status = true;
      }
      if (data.text === "Bicycle") {
        bicycle = "true";
        costs[2].status = true;
      }
      if (data.text === "Motorcycle") {
        motorcycle = "true";
        costs[3].status = true;
      }
      if (data.text === "Taxi") {
        taxi = "true";
      }
    });
    data.usuallyWork.map((data) => {
      if (data.text === "At my own office space") {
        ownOffice = "true";
        costs[15].status = true;
      }
      if (data.text === "At a rented office space") {
        rentOffice = "true";
        costs[16].status = true;
      }
      if (data.text === "From home") {
        ownHome = "true";
        costs[17].status = true;
      }
      if (data.text === "In a co-work space") {
        coWork = "true";
        costs[18].status = true;
      }
    });
    if (data.transportCar.length > 0 && data.transportCar[0].id === 566) {
      costs[4].status = true;
    }
    if (data.transportCar.length > 0 && data.transportCar[0].id === 567) {
      costs[5].status = true;
    }
    if (data.transportMotorcycle.length > 0 && data.transportMotorcycle[0].id === 548) {
      costs[6].status = true;
    }
    if (data.transportMotorcycle.length > 0 && data.transportMotorcycle[0].id === 547) {
      costs[7].status = true;
    }
    data.transportPublic.map((data) => {
      if (data.id === 552) {
        costs[8].status = true;
      }
      if (data.id === 550) {
        costs[9].status = true;
      }
      if (data.id === 551) {
        costs[10].status = true;
      }
    });
    if (data.transportBicycle.length > 0 && data.transportBicycle[0].id === 572) {
      costs[11].status = true;
    }
    if (data.transportBicycle.length > 0 && data.transportBicycle[0].id === 570) {
      costs[12].status = true;
    }
    if (data.transportBicycle.length > 0 && data.transportBicycle[0].id === 571) {
      costs[13].status = true;
    }
    if (data.transportTaxi !== null && data.transportTaxi !== undefined) {
      costs[14].status = true;
      costs[14].value = parseInt(data.transportTaxi);
    }
    if (data.restaurants.length > 0 && data.restaurants[0].id === 609) {
      costs[19].status = true;
    }
    if (data.pensionSaving.length > 0 && data.pensionSaving[0].id === 612) {
      costs[20].status = true;
    }
    if (data.goodsToSell.length > 0 && data.goodsToSell[0].id === 615) {
      costs[21].status = true;
      goodsToSell = "true";
    }
    data.otherCategories.map((data) => {
      if (data.id === 624) {
        costs[22].status = true;
      }
      if (data.id === 621) {
        costs[23].status = true;
      }
      if (data.id === 623) {
        costs[24].status = true;
      }
      if (data.id === 627) {
        costs[25].status = true;
      }
      if (data.id === 622) {
        costs[26].status = true;
      }
      if (data.id === 626) {
        costs[27].status = true;
      }
      if (data.id === 620) {
        costs[28].status = true;
      }
      if (data.id === 625) {
        costs[29].status = true;
      }
    });
    this.setState({
      car,
      transport,
      bicycle,
      motorcycle,
      taxi,
      costs,
      ownOffice,
      rentOffice,
      ownHome,
      coWork,
      goodsToSell,
    });
  };

  createCostElement = (id, name, step, question, index) => {
    return {
      id: id,
      name: name,
      value: this.summaryPrice(step, question, index),
      category: this.categoryPrice(step, question, index),
      periodic: this.periodicPrice(step, question, index),
      isPercent: this.isPercent(step, question, index),
      status: false,
    };
  };

  getRes = () => {
    sessionStorage.setItem("costData", JSON.stringify(this.state.data));
    Connect(
      `${this.props.type === "user" ? Endpoint.getCostsUser : Endpoint.getAdminCostsUser}/${
        this.props.type === "admin" ? sessionStorage.getItem("userID") : ""
      }`,
      "GET"
    ).then((response) => {
      let costData = [
        [<Trans>companyType.typeCost1</Trans>, "", "One-off", 0, [], 0, 0],
        [<Trans>companyType.typeCost2</Trans>, "purple", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost3</Trans>, "pink", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost4</Trans>, "yellow", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost5</Trans>, "green", "Per year", 0, [], 0, 0],
        [<Trans>companyType.typeCost6</Trans>, "red", "Per year", 0, [], 0, 0],
      ];
      let summary = 0;
      let establishment = 0;
      let professional = 0;
      let transportation = 0;
      let office = 0;
      let general = 0;
      let other = 0;
      let costCategory = [false, false, false, false, false, false];
      response.map((data) => {
        summary += data.isPeriodic === true ? data.amount * 12 : data.amount;
        if (data.costType === "ESTABLISHMENT") {
          establishment += data.isPeriodic === true ? data.amount * 12 : data.amount;
          if (data.costPeriod === "PER_MONTH" || data.isPeriodic === true) {
            costData[0][6] += data.amount;
            costCategory[0] = true;
          } else {
            costData[0][5] += data.amount;
          }
          let array = [
            data.description,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? data.amount * 12 : data.amount,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? "PER_MONTH" : "",
            data.id,
            data.costType,
            data.answerId,
            data.amount,
          ];
          costData[0][4].push(array);
        }
        if (data.costType === "PRO_SERVICES") {
          professional += data.isPeriodic === true ? data.amount * 12 : data.amount;
          if (data.costPeriod === "PER_MONTH" || data.isPeriodic === true) {
            costData[1][6] += data.amount;
            costCategory[1] = true;
          } else {
            costData[1][5] += data.amount;
          }
          let array = [
            data.description,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? data.amount * 12 : data.amount,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? "PER_MONTH" : "",
            data.id,
            data.costType,
            data.answerId,
            data.amount,
          ];
          costData[1][4].push(array);
        }
        if (data.costType === "TRANSPORTATION") {
          transportation += data.isPeriodic === true ? data.amount * 12 : data.amount;
          if (data.costPeriod === "PER_MONTH" || data.isPeriodic === true) {
            costData[2][6] += data.amount;
            costCategory[2] = true;
          } else {
            costData[2][5] += data.amount;
          }
          let array = [
            data.description,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? data.amount * 12 : data.amount,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? "PER_MONTH" : "",
            data.id,
            data.costType,
            data.answerId,
            data.amount,
          ];
          costData[2][4].push(array);
        }
        if (data.costType === "OFFICE") {
          office += data.isPeriodic === true ? data.amount * 12 : data.amount;
          if (data.costPeriod === "PER_MONTH" || data.isPeriodic === true) {
            costData[3][6] += data.amount;
            costCategory[3] = true;
          } else {
            costData[3][5] += data.amount;
          }
          let array = [
            data.description,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? data.amount * 12 : data.amount,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? "PER_MONTH" : "",
            data.id,
            data.costType,
            data.answerId,
            data.amount,
          ];
          costData[3][4].push(array);
        }
        if (data.costType === "GENERAL") {
          general += data.isPeriodic === true ? data.amount * 12 : data.amount;
          if (data.costPeriod === "PER_MONTH" || data.isPeriodic === true) {
            costData[4][6] += data.amount;
            costCategory[4] = true;
          } else {
            costData[4][5] += data.amount;
          }
          let array = [
            data.description,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? data.amount * 12 : data.amount,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? "PER_MONTH" : "",
            data.id,
            data.costType,
            data.answerId,
            data.amount,
          ];
          costData[4][4].push(array);
        }
        if (data.costType === "OTHER") {
          other += data.isPeriodic === true ? data.amount * 12 : data.amount;
          if (data.costPeriod === "PER_MONTH" || data.isPeriodic === true) {
            costData[5][6] += data.amount;
            costCategory[5] = true;
          } else {
            costData[5][5] += data.amount;
          }
          let array = [
            data.description,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? data.amount * 12 : data.amount,
            data.costPeriod === "PER_MONTH" || data.isPeriodic === true ? "PER_MONTH" : "",
            data.id,
            data.costType,
            data.answerId,
            data.amount,
          ];
          costData[5][4].push(array);
        }
      });
      costData[0][3] = establishment;
      costData[1][3] = professional;
      costData[2][3] = transportation;
      costData[3][3] = office;
      costData[4][3] = general;
      costData[5][3] = other;
      let array = [establishment, professional, transportation, office, general, other];
      array.sort((a, b) => a - b);

      const colorMapping = {
        [establishment]: "#0052FF",
        [professional]: "#0052FF",
        [transportation]: "#0052FF",
        [office]: "#0052FF",
        [general]: "#0052FF",
        [other]: "#0052FF",
        [costData[1][3]]: "#9800FF",
        [costData[2][3]]: "#F20089",
        [costData[3][3]]: "#FFBD00",
        [costData[4][3]]: "#23E33E",
        [costData[5][3]]: "#E94F37",
      };

      let graphSummary = array.map((data, index) => [colorMapping[data], data]);
      graphSummary[0][1] = (graphSummary[0][1] / summary) * 100;
      graphSummary[1][1] = (graphSummary[1][1] / summary) * 100;
      graphSummary[2][1] = (graphSummary[2][1] / summary) * 100;
      graphSummary[3][1] = (graphSummary[3][1] / summary) * 100;
      graphSummary[4][1] = (graphSummary[4][1] / summary) * 100;
      graphSummary[5][1] = (graphSummary[5][1] / summary) * 100;

      let establishmentSummary = (costData[0][3] / summary) * 100;
      let professionalSummary = (costData[1][3] / summary) * 100 + establishmentSummary;
      let transportationSummary = (costData[2][3] / summary) * 100 + professionalSummary;
      let officeSummary = (costData[3][3] / summary) * 100 + transportationSummary;
      let generalSummary = (costData[4][3] / summary) * 100 + officeSummary;
      let otherSummary = (costData[5][3] / summary) * 100 + generalSummary;
      for (let i = 0; i <= 5; i++) {
        costData[i][3] = this.addCommas(this.removeNonNumeric(costData[i][3].toFixed(0)));
        costData[i][4].map((data) => {
          data[1] = this.addCommas(this.removeNonNumeric(data[1].toFixed(0)));
          data[6] = this.addCommas(this.removeNonNumeric(data[6].toFixed(0)));
        });
        costData[i][7] = this.addCommas(this.removeNonNumeric((costData[i][6] * 12).toFixed(0)));
        costData[i][5] = this.addCommas(this.removeNonNumeric(costData[i][5].toFixed(0)));
        costData[i][6] = this.addCommas(this.removeNonNumeric(costData[i][6].toFixed(0)));
      }
      sessionStorage.setItem("costData", JSON.stringify(costData));
      summary = this.addCommas(this.removeNonNumeric(summary.toFixed(0)));
      this.setState({
        userCosts: response,
        summary: summary,
        data: costData,
        dataWithAll: costData,
        establishmentSummary,
        professionalSummary,
        transportationSummary,
        officeSummary,
        generalSummary,
        otherSummary,
        costCategory,
        graphSummary,
      });
    });
  };

  addSessionElement = (pid, text, key) => {
    return {
      id: pid,
      text: text,
      textKey: key,
    };
  };

  addCommas = (num) => {
    if (num.length > 1) {
      if (num[0] === "0") {
        num = num.slice(1);
      }
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  handleSessionData = () => {
    let transport = [];
    let office = [];
    if (this.state.car === "true") {
      transport.push(this.addSessionElement(558, "Car", "question-option-car"));
    }
    if (this.state.transport === "true") {
      transport.push(this.addSessionElement(562, "Public transport", "question-option-public"));
    }
    if (this.state.bicycle === "true") {
      transport.push(this.addSessionElement(556, "Bicycle", "question-option-bicycle"));
    }
    if (this.state.motorcycle === "true") {
      transport.push(this.addSessionElement(554, "Motorcycle", "question-option-motorcycle"));
    }
    if (this.state.taxi === "true") {
      transport.push(this.addSessionElement(560, "Taxi", "question-option-taxi"));
    }
    this.props.handleData("transport", transport);
    if (this.state.ownOffice === "true") {
      office.push(this.addSessionElement(590, "At my own office space", "question-option-own-office"));
    }
    if (this.state.rentOffice === "true") {
      office.push(this.addSessionElement(588, "At a rented office space", "question-option-rented-office"));
    }
    if (this.state.ownHome === "true") {
      office.push(this.addSessionElement(586, "From home", "question-option-home-office"));
    }
    if (this.state.coWork === "true") {
      office.push(this.addSessionElement(592, "In a co-work space", "question-option-co-work-office"));
    }
    setTimeout(() => {
      this.props.handleData("usuallyWork", office);
    }, 333);
    setTimeout(() => {
      if (this.state.costs[4].status === true) {
        this.props.handleData("transportCar", [this.addSessionElement(566, "I’ll use my current car", "question-option-own-car")]);
      }
    }, 199);
    setTimeout(() => {
      if (this.state.costs[5].status === true) {
        this.props.handleData("transportCar", [this.addSessionElement(567, "I will be leasing a company car", "question-option-leasing-car")]);
      }
    }, 233);
    setTimeout(() => {
      if (this.state.costs[6].status === true) {
        this.props.handleData("transportMotorcycle", [
          this.addSessionElement(548, "I’ll use my current motorcycle", "question-option-own-motorcycle"),
        ]);
      }
    }, 301);
    setTimeout(() => {
      if (this.state.costs[7].status === true) {
        this.props.handleData("transportMotorcycle", [
          this.addSessionElement(547, "I will be leasing a company motorcycle", "question-option-leasing-motorcycle"),
        ]);
      }
    }, 405);
    setTimeout(() => {
      if (this.state.costs[8].status === true) {
        this.props.handleData("transportPublic", [this.addSessionElement(552, "Train subscription - fulltime", "question-option-train-full")]);
      }
    }, 55);
    setTimeout(() => {
      if (this.state.costs[9].status === true) {
        this.props.handleData("transportPublic", [this.addSessionElement(550, "Train subscription - parttime", "question-option-train-part")]);
      }
    }, 69);
    setTimeout(() => {
      if (this.state.costs[10].status === true) {
        this.props.handleData("transportPublic", [this.addSessionElement(551, "Bus subscription - Omnipass", "question-option-ownipass")]);
      }
    }, 79);
    setTimeout(() => {
      if (this.state.costs[11].status === true) {
        this.props.handleData("transportBicycle", [this.addSessionElement(572, "Yes, an e-bike", "question-option-buy-e-bike")]);
      }
    }, 89);
    setTimeout(() => {
      if (this.state.costs[12].status === true) {
        this.props.handleData("transportBicycle", [this.addSessionElement(570, "Yes, a regular bike", "question-option-buy-regular-bike")]);
      }
    }, 119);
    setTimeout(() => {
      if (this.state.costs[13].status === true) {
        this.props.handleData("transportBicycle", [this.addSessionElement(571, "No, I’ll use my current one", "question-option-current-bike")]);
      }
    }, 105);
    setTimeout(() => {
      if (this.state.costs[19].status === true) {
        this.props.handleData("restaurants", [this.addSessionElement(609, "Yes", "question-option-yes")]);
      } else {
        this.props.handleData("restaurants", [this.addSessionElement(608, "No", "question-option-no")]);
      }
    }, 15);
    setTimeout(() => {
      if (this.state.costs[20].status === true) {
        this.props.handleData("pensionSaving", [this.addSessionElement(612, "Yes", "question-option-yes")]);
      } else {
        this.props.handleData("pensionSaving", [this.addSessionElement(611, "No", "question-option-no")]);
      }
    }, 250);
    setTimeout(() => {
      if (this.state.costs[21].status === true) {
        this.props.handleData("goodsToSell", [this.addSessionElement(615, "Yes", "question-option-yes")]);
      } else {
        this.props.handleData("goodsToSell", [this.addSessionElement(614, "No", "question-option-no")]);
      }
    }, 500);
    setTimeout(() => {
      let otherCategories = [];
      if (this.state.costs[22].status === true) {
        otherCategories.push(this.addSessionElement(624, "Internet subscription", "question-option-internet-subscription"));
      }
      if (this.state.costs[23].status === true) {
        otherCategories.push(this.addSessionElement(621, "Phone subscription", "question-option-phone-subscription"));
      }
      if (this.state.costs[24].status === true) {
        otherCategories.push(this.addSessionElement(623, "Office supplies", "question-option-office-supplies"));
      }
      if (this.state.costs[25].status === true) {
        otherCategories.push(this.addSessionElement(627, "Legal documents (ex. terms and conditions)", "question-option-legal-docs"));
      }
      if (this.state.costs[26].status === true) {
        otherCategories.push(this.addSessionElement(622, "New laptop", '"question-option-new-laptop'));
      }
      if (this.state.costs[27].status === true) {
        otherCategories.push(this.addSessionElement(626, "New phone", "question-option-new-phone"));
      }
      if (this.state.costs[28].status === true) {
        otherCategories.push(this.addSessionElement(620, "Website: domain and hosting", "question-option-website-hosting"));
      }
      if (this.state.costs[29].status === true) {
        otherCategories.push(this.addSessionElement(625, "Office 365 license", "question-option-office-license"));
      }
      this.props.handleData("otherCategories", otherCategories);
    }, 550);
  };

  handleCost = (pid, added = true, revenue = 1) => {
    let costData = this.state.data;
    let costs = this.state.costs;
    let category = 0;
    if (costs[pid].category === "TRANSPORTATION") {
      category = 2;
    }
    if (costs[pid].category === "OFFICE") {
      category = 3;
    }
    if (costs[pid].category === "GENERAL") {
      category = 4;
    }
    for (let i = 0; i <= 5; i++) {
      costData[i][3] = typeof costData[i][3] === "string" ? parseInt(costData[i][3].replaceAll(".", "")) : costData[i][3];
      costData[i][5] = typeof costData[i][5] === "string" ? parseInt(costData[i][5].replaceAll(".", "")) : costData[i][5];
      costData[i][6] = typeof costData[i][6] === "string" ? parseInt(costData[i][6].replaceAll(".", "")) : costData[i][6];
      costData[i][7] = typeof costData[i][7] === "string" ? parseInt(costData[i][7].replaceAll(".", "")) : costData[i][7];
    }
    if (pid !== 20) {
      if (costs[pid].status === true) {
        if (costs[pid].periodic === true) {
          costData[category][6] += costs[pid].value;
          costData[category][7] += costs[pid].value * 12;
          costData[category][3] += costs[pid].value * 12;
        } else {
          costData[category][3] += costs[pid].value;
          costData[category][5] += costs[pid].value;
        }
      } else {
        if (costs[pid].periodic === true) {
          costData[category][6] -= costs[pid].value;
          costData[category][7] -= costs[pid].value;
          costData[category][3] -= costs[pid].value * 12;
        } else {
          costData[category][5] -= costs[pid].value;
          costData[category][3] -= costs[pid].value;
        }
      }
    }
    if (pid === 20) {
      if (costs[pid].status === true) {
        if (costs[pid].periodic === true) {
          costData[category][6] +=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
          costData[category][7] +=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) * 12 <= 46312.8
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) * 12
              : 46312.8;
          costData[category][3] +=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) * 12 <= 46312.8
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) * 12
              : 46312.8;
        } else {
          costData[category][3] +=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
          costData[category][5] +=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
        }
      } else {
        if (costs[pid].periodic === true) {
          costData[category][6] -=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
          costData[category][7] -=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
          costData[category][3] -=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) * 12 <= 46312.8
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) * 12
              : 46312.8;
        } else {
          costData[category][5] -=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
          costData[category][3] -=
            (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", "")) <= 3859.4
              ? (costs[pid].value / 100) * parseInt(this.props.data.revenue.replaceAll(".", ""))
              : 3859.4;
        }
      }
    }
    if (pid === 21 && costs[pid].status === true) {
      if (added === true) {
        costData[4][6] += (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue);
        costData[4][7] += (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue) * 12;
        costData[4][3] += (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue) * 12;
      } else {
        costData[4][6] -= (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue);
        costData[4][7] -= (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue);
        costData[4][3] -= (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue) * 12;
      }
    }
    if (pid === 21 && costs[pid].status === false) {
      costData[4][6] -= (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue);
      costData[4][7] -= (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue);
      costData[4][3] -= (parseInt(this.props.data.revenue.replaceAll(".", "")) / 100) * (isNaN(revenue) ? 0 : revenue) * 12;
    }
    let summary = costData[0][3] + costData[1][3] + costData[2][3] + costData[3][3] + costData[4][3] + costData[5][3];
    let establishmentSummary = (costData[0][3] / summary) * 100;
    let professionalSummary = (costData[1][3] / summary) * 100 + establishmentSummary;
    let transportationSummary = (costData[2][3] / summary) * 100 + professionalSummary;
    let officeSummary = (costData[3][3] / summary) * 100 + transportationSummary;
    let generalSummary = (costData[4][3] / summary) * 100 + officeSummary;
    let otherSummary = (costData[5][3] / summary) * 100 + generalSummary;
    for (let i = 0; i <= 5; i++) {
      costData[i][3] = this.addCommas(this.removeNonNumeric(costData[i][3].toFixed(0)));
      costData[i][7] = this.addCommas(this.removeNonNumeric((costData[i][6] * 12).toFixed(0)));
      costData[i][5] = this.addCommas(this.removeNonNumeric(costData[i][5].toFixed(0)));
      costData[i][6] = this.addCommas(this.removeNonNumeric(costData[i][6].toFixed(0)));
    }
    sessionStorage.setItem("costData", JSON.stringify(costData));
    summary = this.addCommas(this.removeNonNumeric(summary.toFixed(0)));
    this.setState({
      summary: summary,
      data: costData,
      establishmentSummary,
      professionalSummary,
      transportationSummary,
      officeSummary,
      generalSummary,
      otherSummary,
    });
    setTimeout(() => {
      this.handleSessionData();
    }, 300);
  };

  answer = (type, value, number, page, step) => {
    return {
      textAnswer: type === "text" ? value : null,
      numericAnswer: type === "numeric" ? parseInt(value) : null,
      questionOptions: type === "select" ? value : [],
      user: {
        id: JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).id,
      },
      question: {
        id: JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[page].steps[step].questions[number].id,
        questionText: JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[page].steps[step].questions[number].questionText,
        questionTextKey: JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[page].steps[step].questions[number].questionTextKey,
      },
    };
  };

  handlePage = (value) => {
    if (this.state.page === 2 && value !== 1) {
      if (this.state.ownOffice === "true" && (!this.props.data.officeSpace || this.props.data.officeSpace === "")) {
        return toast.error(localStorage.getItem("i18nextLng") === "en" ? "Complete the required fields." : "Vul de verplichte velden in.");
      }
      if (this.state.rentOffice === "true" && (!this.props.data.officeSpaceRent || this.props.data.officeSpaceRent === "")) {
        return toast.error(localStorage.getItem("i18nextLng") === "en" ? "Complete the required fields." : "Vul de verplichte velden in.");
      }
    }
    let validateForm = {
      transportTaxi: !(this.props.data.transportTaxi === "" || this.props.data.transportTaxi === undefined),
    };
    this.setState({
      validateForm,
    });
    if (value === 2 && validateForm.transportTaxi === false && validateForm.transportTaxi === undefined) {
      if (this.state.taxi === "false") {
        this.setState({
          page: value,
        });
        document.querySelector(".container__body:first-child").scrollTo(0, 0);
        this.handleSave();
      }
    } else {
      this.setState({
        page: value,
      });
      document.querySelector(".container__body:first-child").scrollTo(0, 0);
      this.handleSave();
    }
  };

  handleSave = () => {
    let body = [];
    let required = 0;
    let completed = 0;
    if (this.state.page === 1) {
      required += 1;
      completed += 1;
      body = [this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).transport, 0, 3, 0)];
      if (this.state.car === "true") {
        body.push(
          this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).transportCar, 1, 3, 0)
        );
        required += 1;
        completed += 1;
      }
      if (this.state.motorcycle === "true") {
        body.push(
          this.answer(
            "select",
            sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).transportMotorcycle,
            2,
            3,
            0
          )
        );
        required += 1;
        completed += 1;
      }
      if (this.state.transport === "true") {
        body.push(
          this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).transportPublic, 3, 3, 0)
        );
        required += 1;
        completed += 1;
      }
      if (this.state.bicycle === "true") {
        body.push(
          this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).transportBicycle, 4, 3, 0)
        );
        required += 1;
        completed += 1;
      }
      if (this.state.taxi === "true") {
        body.push(
          this.answer("numeric", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).transportTaxi, 5, 3, 0)
        );
        required += 1;
        completed += 1;
      }
    }
    if (this.state.page === 2) {
      body = [this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).usuallyWork, 0, 3, 1)];
      required += 1;
      completed += 1;
      if (this.state.ownOffice === "true") {
        body.push(
          this.answer("numeric", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).officeSpace, 1, 3, 1)
        );
        required += 1;
        completed += 1;
      }
      if (this.state.rentOffice === "true") {
        body.push(
          this.answer("numeric", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).officeSpaceRent, 2, 3, 1)
        );
        required += 1;
        completed += 1;
      }
      if (this.state.ownHome === "true") {
        body.push(this.answer("numeric", this.state.usedHomeValues, 3, 3, 1));
        body.push(
          this.answer(
            "select",
            sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).applicableSituation,
            4,
            3,
            1
          )
        );
        body.push(
          this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).homeOffice, 5, 3, 1)
        );
        required += 1;
        completed += 1;
      }
    }
    if (this.state.page === 3) {
      body = [
        this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).restaurants, 0, 3, 2),
        this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).pensionSaving, 1, 3, 2),
        this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).goodsToSell, 2, 3, 2),
      ];
      required += 1;
      completed += 1;
      if (this.state.goodsToSell === "true") {
        body.push(this.answer("numeric", this.state.goodsToSellValues, 3, 3, 2));
        required += 1;
        completed += 1;
      }
    }
    if (this.state.page === 4) {
      body = [
        this.answer("select", sessionStorage.getItem("companyType") && JSON.parse(sessionStorage.getItem("companyType")).otherCategories, 0, 3, 3),
      ];
      required += 1;
      completed += 1;
    }
    Connect(
      `${Endpoint.postUserAnswer}/${JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[this.state.page - 1].id}`,
      "POST",
      body
    ).then((response) => {
      if (response.length > 0) {
        let body = {
          checkpointCategory: JSON.parse(sessionStorage.getItem("checkpointCategory"))[3],
          completed,
          required,
          isChange: this.props.isChangeCost,
        };
        Connect(`${Endpoint.putProgressSurvey}`, "PUT", body).then((response) => {
          console.log(response);
        });
      }
      this.getRes();
    });
  };

  handleView = (e, value) => {
    this.handleSave();
    e.currentTarget.classList.remove("btn--secondary");
    setTimeout(() => {
      if (value === "overview") {
        document.querySelector(".btn.detail").classList.add("btn--secondary");
      } else {
        document.querySelector(".btn.overview").classList.add("btn--secondary");
      }
      this.setState({
        view: value,
      });
    }, 100);
  };

  handleAdd = (value, pid = null, editCategory = null, editType = null, editDescription = null, editAmount = null, question = false) => {
    this.setState({
      add: value,
      pid,
      editCategory,
      editType,
      editDescription,
      editAmount,
      defaultSelectText:
        editCategory === null ? null : editCategory === "ESTABLISHMENT" ? (
          <Trans>companyType.typeCost1</Trans>
        ) : editCategory === "PRO_SERVICES" ? (
          <Trans>companyType.typeCost2</Trans>
        ) : editCategory === "TRANSPORTATION" ? (
          <Trans>companyType.typeCost3</Trans>
        ) : editCategory === "OFFICE" ? (
          <Trans>companyType.typeCost4</Trans>
        ) : editCategory === "GENERAL" ? (
          <Trans>companyType.typeCost5</Trans>
        ) : editCategory === "OTHER" ? (
          <Trans>companyType.typeCost6</Trans>
        ) : (
          ""
        ),
      defaultSelectValue: editCategory === null ? "ESTABLISHMENT" : editCategory,
      question,
    });
    if (editType === "PER_MONTH") {
      sessionStorage.setItem("addCostType", "PER_MONTH");
    } else {
      sessionStorage.setItem("addCostType", "One-off");
    }
  };

  handleRadio = (value) => {
    let costs = this.state.costs;
    if (value === "I’ll use my current car") {
      let firstload = true;
      if (costs[5].status === true) {
        firstload = false;
      }
      costs[4].status = true;
      costs[5].status = false;
      setTimeout(() => {
        this.handleCost(4);
      }, 50);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(5);
        }, 100);
      }
    }
    if (value === "I will be leasing a company car") {
      costs[5].status = true;
      costs[4].status = false;
      setTimeout(() => {
        this.handleCost(4);
      }, 50);
      setTimeout(() => {
        this.handleCost(5);
      }, 100);
    }
    if (value === "I’ll use my current motorcycle") {
      let firstload = true;
      if (costs[7].status === true) {
        firstload = false;
      }
      costs[6].status = true;
      costs[7].status = false;
      setTimeout(() => {
        this.handleCost(6);
      }, 50);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(7);
        }, 100);
      }
    }
    if (value === "I will be leasing a company motorcycle") {
      costs[7].status = true;
      costs[6].status = false;
      setTimeout(() => {
        this.handleCost(6);
        this.handleCost(7);
      }, 50);
    }
    if (value === "Yes, an e-bike") {
      let firstload = true;
      if (costs[13].status === true) {
        firstload = false;
      }
      let secondload = true;
      if (costs[12].status === true) {
        secondload = false;
      }
      costs[11].status = true;
      costs[12].status = false;
      costs[13].status = false;
      setTimeout(() => {
        this.handleCost(11);
      }, 50);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(13);
        }, 100);
      }
      if (secondload === false) {
        setTimeout(() => {
          this.handleCost(12);
        }, 150);
      }
    }
    if (value === "Yes, a regular bike") {
      let firstload = true;
      if (costs[11].status === true) {
        firstload = false;
      }
      let secondload = true;
      if (costs[13].status === true) {
        secondload = false;
      }
      costs[12].status = true;
      costs[11].status = false;
      costs[13].status = false;
      setTimeout(() => {
        this.handleCost(12);
      }, 50);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(11);
        }, 100);
      }
      if (secondload === false) {
        setTimeout(() => {
          this.handleCost(13);
        }, 150);
      }
    }
    if (value === "No, I’ll use my current one") {
      let firstload = true;
      if (costs[11].status === true) {
        firstload = false;
      }
      let secondload = true;
      if (costs[12].status === true) {
        secondload = false;
      }
      costs[13].status = true;
      costs[11].status = false;
      costs[12].status = false;
      setTimeout(() => {
        this.handleCost(13);
      }, 50);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(11);
        }, 100);
      }
      if (secondload === false) {
        setTimeout(() => {
          this.handleCost(12);
        }, 150);
      }
    }
    this.setState({
      costs: costs,
    });
  };

  handleRestaurant = (value) => {
    let costs = this.state.costs;
    if (value === "Yes") {
      costs[19].status = true;
    } else {
      costs[19].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(19);
    }, 50);
  };

  handlePensionSaving = (value) => {
    let costs = this.state.costs;
    if (value === "Yes") {
      costs[20].status = true;
    } else {
      costs[20].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(20);
    }, 50);
  };

  handleGoodsToSell = (value) => {
    let costs = this.state.costs;
    let goodsToSell = false;
    if (value === "Yes") {
      costs[21].status = true;
      goodsToSell = "true";
    } else {
      costs[21].status = false;
    }
    this.setState({
      goodsToSell,
      costs: costs,
    });
    setTimeout(() => {
      // this.handleCost(21);
      this.handleGoodsToSellValues([0]);
    }, 50);
  };

  handleCheckbox = (index, checked, name) => {
    let costs = this.state.costs;
    if (checked === true) {
      costs[index].status = true;
    } else {
      costs[index].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(index);
    }, 100);
    setTimeout(() => {
      if (this.state.page === 4) {
        this.handleSave();
      }
    }, 1200);
  };

  handleText = (name, value) => {
    //if string value break function
    let regExp = /[a-zA-Z]/g;
    if (regExp.test(value)) return;
    let costs = this.state.costs;
    if (value === null) {
      value = 0;
    }
    if (name === "transportTaxi") {
      costs[14].value = parseInt(value.replace(".", ""));
      setTimeout(() => {
        this.handleCost(14);
      }, 50);
    }
    if (name === "officeSpace") {
      let value = costs[15].value;

      setTimeout(() => {
        this.handleCost(15);
      }, 50);
    }
    if (name === "officeSpaceRent") {
      setTimeout(() => {
        this.handleCost(16);
      }, 50);
    }
    this.setState({
      costs: costs,
    });
    this.props.handleData(name, value);
  };

  updateCar = (value) => {
    this.setState({
      car: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[0].status = true;
      costs[4].status = true;
      setTimeout(() => {
        this.handleCost(0);
      }, 50);
      setTimeout(() => {
        this.handleCost(4);
      }, 100);
    } else {
      let firstload = true;
      if (costs[5].status === true) {
        firstload = false;
      }
      costs[0].status = false;
      costs[4].status = false;
      costs[5].status = false;
      setTimeout(() => {
        this.handleCost(0);
      }, 50);
      setTimeout(() => {
        this.handleCost(4);
      }, 100);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(5);
        }, 150);
      }
    }
    this.setState({
      costs: costs,
    });
  };

  updateTransport = (value) => {
    this.setState({
      transport: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[1].status = true;
      setTimeout(() => {
        this.handleCost(1);
      }, 50);
    } else {
      costs[1].status = false;
      costs[8].status = false;
      costs[9].status = false;
      costs[10].status = false;
      setTimeout(() => {
        this.handleCost(1);
        this.handleCost(8);
        this.handleCost(9);
        this.handleCost(10);
      }, 50);
    }
    this.setState({
      costs: costs,
    });
  };

  updateBicycle = (value) => {
    this.setState({
      bicycle: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[2].status = true;
      setTimeout(() => {
        this.handleCost(2);
      }, 50);
    } else {
      let secondload = true;
      if (costs[12].status === true) {
        secondload = false;
      }
      let thirdload = true;
      if (costs[11].status === true) {
        thirdload = false;
      }
      costs[2].status = false;
      costs[11].status = false;
      costs[12].status = false;
      costs[13].status = false;
      setTimeout(() => {
        this.handleCost(2);
        this.handleCost(11);
      }, 50);
      if (secondload === false) {
        setTimeout(() => {
          this.handleCost(12);
        }, 50);
      }
      if (thirdload === false) {
        setTimeout(() => {
          this.handleCost(13);
        }, 50);
      }
    }
    this.setState({
      costs: costs,
    });
  };

  updateMotorcycle = (value) => {
    this.setState({
      motorcycle: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[3].status = true;
      setTimeout(() => {
        this.handleCost(3);
      }, 50);
    } else {
      let firstload = true;
      if (costs[7].status === true) {
        firstload = false;
      }
      costs[3].status = false;
      costs[6].status = false;
      costs[7].status = false;
      setTimeout(() => {
        this.handleCost(3);
      }, 50);
      setTimeout(() => {
        this.handleCost(6);
      }, 100);
      if (firstload === false) {
        setTimeout(() => {
          this.handleCost(7);
        }, 150);
      }
    }
    this.setState({
      costs: costs,
    });
  };

  updateTaxi = (value) => {
    this.setState({
      taxi: `${value}`,
    });
    setTimeout(() => {
      this.handleCost(14);
    }, 50);
  };

  updateOwnOffice = (value) => {
    this.props.handleData(null, null, { ownOffice: value });
    this.setState({
      ownOffice: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[15].status = true;
    } else {
      costs[15].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(15);
    }, 50);
  };

  updateRentOffice = (value) => {
    this.props.handleData(null, null, { rentOffice: value });
    this.setState({
      rentOffice: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[16].status = true;
    } else {
      costs[16].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(16);
    }, 50);
  };

  updateOwnHome = (value) => {
    this.props.handleData(null, null, { ownHome: value });
    this.setState({
      ownHome: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[17].status = true;
    } else {
      costs[17].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(17);
    }, 50);
  };

  updateCoWork = (value) => {
    this.props.handleData(null, null, { coWork: value });
    this.setState({
      coWork: `${value}`,
    });
    let costs = this.state.costs;
    if (value === true) {
      costs[18].status = true;
    } else {
      costs[18].status = false;
    }
    this.setState({
      costs: costs,
    });
    setTimeout(() => {
      this.handleCost(18);
    }, 50);
  };

  save = () => {
    let type = document.querySelector(".cost__content .custom-select-container .selected-text").getAttribute("data-value");
    let description = document.querySelector(".cost__content .container__body-input:nth-child(4) .MuiInputBase-input").value;
    let price = document.querySelector(".cost__content .container__body-input:nth-child(5) .MuiInputBase-input").value.replaceAll(".", "");
    let isPeriodic;
    let costPeriod = null;
    if (sessionStorage.getItem("addCostType") === "One-off") {
      isPeriodic = false;
    } else {
      isPeriodic = true;
      costPeriod = "PER_MONTH";
    }
    document.querySelector(".cost__content .container__body-input:nth-child(4) .MuiInputBase-input").value = "";
    document.querySelector(".cost__content .container__body-input:nth-child(5) .MuiInputBase-input").value = "";
    this.setState({
      defaultSelectValue: "ESTABLISHMENT",
    });
    this.handleAdd(false);
    let body;
    if (this.state.pid === null) {
      body = {
        costType: type,
        description,
        costPeriod,
        amount: parseInt(price),
        isPeriodic,
      };
      if (this.props.type === "user") {
        Connect(`${Endpoint.postCostUser}`, "POST", body).then((response) => {
          this.getRes();
        });
      } else {
        Connect(`${Endpoint.postAdminPostUser}/${sessionStorage.getItem("userID")}`, "POST", body).then((response) => {
          this.getRes();
        });
      }
    } else {
      body = {
        id: this.state.pid,
        costType: type,
        description,
        costPeriod,
        amount: parseInt(price),
        isPeriodic,
      };
      if (this.props.type === "user") {
        Connect(`${Endpoint.postCostUser}`, "PUT", body).then((response) => {
          this.getRes();
        });
      } else {
        Connect(`${Endpoint.postAdminPostUser}`, "PUT", body).then((response) => {
          this.getRes();
        });
      }
    }
  };

  handleConfirm = (value) => {
    this.setState({
      confirm: value,
    });
  };

  confirmRemove = (pid) => {
    this.handleConfirm(true);
    sessionStorage.setItem("indexDelete", pid);
  };

  removeCost = () => {
    if (this.props.type === "user") {
      Connect(`${Endpoint.postCostUser}/${sessionStorage.getItem("indexDelete")}`, "DELETE").then((response) => {
        this.getRes();
        this.handleConfirm(false);
      });
    } else {
      Connect(`${Endpoint.postAdminPostUser}/${sessionStorage.getItem("indexDelete")}`, "DELETE").then((response) => {
        this.getRes();
        this.handleConfirm(false);
      });
    }
  };

  dropdown = (e) => {
    e.currentTarget.closest(".editable").classList.toggle("active");
    e.currentTarget.querySelector(".open").classList.toggle("d-none");
    e.currentTarget.querySelector(".close").classList.toggle("d-none");
  };

  summaryPrice = (step, question, index) => {
    let sum = 0;
    this.props.companyTypeData &&
      this.props.companyTypeData.surveyPages &&
      this.props.companyTypeData.surveyPages[3].steps[step].questions[question].questionOptions[index].parameters.map((data) => {
        sum += data.numberValue;
      });
    return sum;
  };

  periodicPrice = (step, question, index) => {
    let isPeriodic = false;
    this.props.companyTypeData &&
      this.props.companyTypeData.surveyPages &&
      this.props.companyTypeData.surveyPages[3].steps[step].questions[question].questionOptions[index].parameters.map((data) => {
        isPeriodic = data.isPeriodic;
      });
    return isPeriodic;
  };

  categoryPrice = (step, question, index) => {
    let category = "";
    this.props.companyTypeData &&
      this.props.companyTypeData.surveyPages &&
      this.props.companyTypeData.surveyPages[3].steps[step].questions[question].questionOptions[index].parameters.map((data) => {
        category = data.costType;
      });
    return category;
  };

  isPercent = (step, question, index) => {
    let isPercent = false;
    this.props.companyTypeData &&
      this.props.companyTypeData.surveyPages &&
      this.props.companyTypeData.surveyPages[3].steps[step].questions[question].questionOptions[index].parameters.map((data) => {
        isPercent = data.isPercent;
      });
    return isPercent;
  };

  handleGoodsToSellValues = (goodsToSellValues) => {
    this.setState({
      goodsToSellValues,
    });
    let revenue = 1;
    let added = true;
    if (goodsToSellValues[0] < parseInt(sessionStorage.getItem("goodsToSellValues"))) {
      added = false;
      revenue = parseInt(sessionStorage.getItem("goodsToSellValues")) - goodsToSellValues[0];
    } else {
      revenue = goodsToSellValues[0] - parseInt(sessionStorage.getItem("goodsToSellValues"));
    }
    sessionStorage.setItem("goodsToSellValues", goodsToSellValues[0]);
    this.handleCost(21, added, revenue);
  };

  render() {
    return (
      <div className='structure'>
        {this.state.view === "overview" ? (
          <div className='container__body'>
            <div
              className={`structure__filled ${this.state.page > 0 && this.state.page < 4 ? "progress" : ""} ${
                this.state.page === 4 ? "complete" : ""
              }`}
            >
              <p>
                <Trans>companyType.step</Trans> {this.state.page} <Trans>companyType.of</Trans> {this.state.surveyAllFilled}
              </p>
            </div>
            {this.state.page === 1 ? (
              <>
                <div className='container__body-header'>
                  <Trans>companyType.costs</Trans>
                </div>
                <p className='container__body-subheader'>
                  <Trans>companyType.costText</Trans>
                </p>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      {sessionStorage.getItem("companyTypeData") &&
                        JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[0].questionText}
                    </p>
                    <div className='structure__checkbox'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[0].questionOptions[0].text
                        }
                        data-value={this.summaryPrice(0, 0, 0)}
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateCar(e.target.checked)}
                            checked={this.state.car === "true" ? true : false}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[0].questionOptions[1].text
                        }
                        data-value={this.summaryPrice(0, 0, 1)}
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateTransport(e.target.checked)}
                            checked={this.state.transport === "true" ? true : false}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[0].questionOptions[2].text
                        }
                        data-value={this.summaryPrice(0, 0, 2)}
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateBicycle(e.target.checked)}
                            checked={this.state.bicycle === "true" ? true : false}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[0].questionOptions[3].text
                        }
                        data-value={this.summaryPrice(0, 0, 3)}
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateMotorcycle(e.target.checked)}
                            checked={this.state.motorcycle === "true" ? true : false}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[0].questionOptions[4].text
                        }
                        data-value={this.summaryPrice(0, 0, 4)}
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateTaxi(e.target.checked)}
                            checked={this.state.taxi === "true" ? true : false}
                          />
                        }
                      />
                      {/*<FormControlLabel label="Other" data-value={this.summaryPrice(0, 0, 5)} control={*/}
                      {/*    <Checkbox color={'#4A5BCF'}/>*/}
                      {/*}/>*/}
                    </div>
                  </div>
                </div>
                {this.state.car === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[1].questionText}
                      </p>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='companyCar'
                          defaultValue={this.props.data.transportCar.length > 0 ? this.props.data.transportCar[0].text : ""}
                          onChange={(e) => this.handleRadio(e.target.value)}
                        >
                          <div className='structure__checkbox'>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='I’ll use my current car'
                                data-value={this.summaryPrice(0, 1, 0)}
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[1].questionOptions[0].text
                                }
                              />
                            </div>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='I will be leasing a company car'
                                data-value={this.summaryPrice(0, 1, 1)}
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[1].questionOptions[1].text
                                }
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.motorcycle === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[2].questionText}
                      </p>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='companyMotorcycle'
                          defaultValue={this.props.data.transportMotorcycle.length > 0 ? this.props.data.transportMotorcycle[0].text : ""}
                          onChange={(e) => this.handleRadio(e.target.value)}
                        >
                          <div className='structure__checkbox'>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='I’ll use my current motorcycle'
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[2].questionOptions[0].text
                                }
                              />
                            </div>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='I will be leasing a company motorcycle'
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[2].questionOptions[1].text
                                }
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.transport === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[3].questionText}
                      </p>
                      <div className='structure__checkbox extend'>
                        <FormControlLabel
                          label={
                            sessionStorage.getItem("companyTypeData") &&
                            JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[3].questionOptions[0].text
                          }
                          control={
                            <Checkbox
                              color={"#4A5BCF"}
                              onChange={(e) => this.handleCheckbox(8, e.target.checked, "transportPublic")}
                              checked={this.state.costs[8].status}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            sessionStorage.getItem("companyTypeData") &&
                            JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[3].questionOptions[1].text
                          }
                          control={
                            <Checkbox
                              color={"#4A5BCF"}
                              onChange={(e) => this.handleCheckbox(9, e.target.checked, "transportPublic")}
                              checked={this.state.costs[9].status}
                            />
                          }
                        />
                      </div>
                      <div className='structure__checkbox extend'>
                        <FormControlLabel
                          label={
                            sessionStorage.getItem("companyTypeData") &&
                            JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[3].questionOptions[2].text
                          }
                          control={
                            <Checkbox
                              color={"#4A5BCF"}
                              onChange={(e) => this.handleCheckbox(10, e.target.checked, "transportPublic")}
                              checked={this.state.costs[10].status}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.bicycle === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[4].questionText}{" "}
                        *
                      </p>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='bicycle'
                          defaultValue={this.props.data.transportBicycle.length > 0 ? this.props.data.transportBicycle[0].text : ""}
                          onChange={(e) => this.handleRadio(e.target.value)}
                        >
                          <div className='structure__checkbox'>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='Yes, an e-bike'
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[4].questionOptions[0].text
                                }
                              />
                            </div>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='Yes, a regular bike'
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[4].questionOptions[1].text
                                }
                              />
                            </div>
                            <div className='container__body-radio'>
                              <FormControlLabel
                                value='No, I’ll use my current one'
                                control={<Radio />}
                                label={
                                  sessionStorage.getItem("companyTypeData") &&
                                  JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[4].questionOptions[2].text
                                }
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.taxi === "true" ? (
                  <div className='container__body-box'>
                    <div
                      className={
                        this.state.validateForm.transportTaxi !== undefined && this.state.validateForm.transportTaxi === false
                          ? "container__body-input text is-error"
                          : "container__body-input text"
                      }
                    >
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[0].questions[5].questionText}
                      </p>
                      <TextField
                        InputProps={{
                          startAdornment: this.props.data.transportTaxi ? (
                            <InputAdornment variant='standard' disableTypography position='start'>
                              €
                            </InputAdornment>
                          ) : (
                            <></>
                          ),
                        }}
                        label={""}
                        variant={"outlined"}
                        size={"small"}
                        placeholder={"€..."}
                        value={this.props.data.transportTaxi}
                        onChange={(e) => this.handleText("transportTaxi", e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {this.state.page === 2 ? (
              <>
                <div className='container__body-header'>
                  <Trans>companyType.costs</Trans>
                </div>
                <p className='container__body-subheader'>
                  <Trans>companyType.costText</Trans>
                </p>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      {sessionStorage.getItem("companyTypeData") &&
                        JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[0].questionText}
                    </p>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[0].questionOptions[0].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateOwnOffice(e.target.checked)}
                            checked={this.state.costs[15].status}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[0].questionOptions[1].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.updateRentOffice(e.target.checked)}
                            checked={this.state.costs[16].status}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[0].questionOptions[2].text
                        }
                        control={
                          <Checkbox color={"#4A5BCF"} onChange={(e) => this.updateOwnHome(e.target.checked)} checked={this.state.costs[17].status} />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[0].questionOptions[3].text
                        }
                        control={
                          <Checkbox color={"#4A5BCF"} onChange={(e) => this.updateCoWork(e.target.checked)} checked={this.state.costs[18].status} />
                        }
                      />
                    </div>
                  </div>
                </div>
                {this.state.ownOffice === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input text'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[1].questionText}{" "}
                        *
                      </p>
                      <TextField
                        InputProps={{
                          endAdornment: this.props.data.officeSpace ? (
                            <InputAdornment variant='standard' disableTypography position='start'>
                              m2
                            </InputAdornment>
                          ) : (
                            <></>
                          ),
                        }}
                        label={""}
                        variant={"outlined"}
                        size={"small"}
                        type={"text"}
                        placeholder={"... m2"}
                        value={this.props.data.officeSpace}
                        onChange={(e) => this.handleText("officeSpace", e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.rentOffice === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input text'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[2].questionText}{" "}
                        *
                      </p>
                      <TextField
                        InputProps={{
                          endAdornment: this.props.data.officeSpaceRent ? (
                            <InputAdornment variant='standard' disableTypography position='start'>
                              m2
                            </InputAdornment>
                          ) : (
                            <></>
                          ),
                        }}
                        label={""}
                        variant={"outlined"}
                        size={"small"}
                        type={"text"}
                        placeholder={"... m2"}
                        value={this.props.data.officeSpaceRent}
                        onChange={(e) => this.handleText("officeSpaceRent", e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.ownHome === "true" ? (
                  <>
                    <div className='container__body-box'>
                      <div className='container__body-input text'>
                        <p className='container__body-text'>
                          {sessionStorage.getItem("companyTypeData") &&
                            JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[3].questionText}{" "}
                          *
                        </p>
                        <div className='structure__range'>
                          <Range
                            step={1}
                            min={0}
                            max={100}
                            values={this.state.usedHomeValues}
                            onChange={(usedHomeValues) => this.setState({ usedHomeValues })}
                            renderTrack={({ props, children }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "6px",
                                  width: "100%",
                                  backgroundColor: "#C4D0E5",
                                }}
                              >
                                {children}
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "18px",
                                  width: "18px",
                                  backgroundColor: "#4A5BCF",
                                  borderRadius: 18,
                                }}
                              >
                                <div className='structure__range-percentage'>
                                  <p>{this.state.usedHomeValues}%</p>
                                </div>
                              </div>
                            )}
                          />
                          <div className='structure__range-legend'>
                            <p>0%</p>
                            <p>25%</p>
                            <p>50%</p>
                            <p>75%</p>
                            <p>100%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='container__body-box'>
                      <div className='container__body-select'>
                        <p className='container__body-text'>
                          {sessionStorage.getItem("companyTypeData") &&
                            JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[4].questionText}
                        </p>
                        <CustomSelect
                          defaultText={
                            sessionStorage.getItem("companyType") &&
                            JSON.parse(sessionStorage.getItem("companyType")).applicableSituation[0] &&
                            JSON.parse(sessionStorage.getItem("companyType")).applicableSituation[0].text
                          }
                          optionsList={this.state.situationSelectList}
                          handleData={this.props.handleData}
                          type={"applicableSituation"}
                        />
                      </div>
                    </div>
                    <div className='container__body-box'>
                      <div className='container__body-select'>
                        <p className='container__body-text'>
                          {sessionStorage.getItem("companyTypeData") &&
                            JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[1].questions[5].questionText}
                        </p>
                        <CustomSelect
                          defaultText={
                            sessionStorage.getItem("companyType") &&
                            JSON.parse(sessionStorage.getItem("companyType")).homeOffice[0] &&
                            JSON.parse(sessionStorage.getItem("companyType")).homeOffice[0].text
                          }
                          optionsList={this.state.officeSelectList}
                          handleData={this.props.handleData}
                          type={"homeOffice"}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {this.state.page === 3 ? (
              <>
                <div className='container__body-header'>
                  <Trans>companyType.costs</Trans>
                </div>
                <p className='container__body-subheader'>
                  <Trans>companyType.costText</Trans>
                </p>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      {sessionStorage.getItem("companyTypeData") &&
                        JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[0].questionText}
                      <Tooltip title={<Trans>companyType.tooltip6</Trans>} placement={"right"}>
                        <IconButton aria-label='delete'>{InfoIcon}</IconButton>
                      </Tooltip>
                    </p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        name='restaurants'
                        defaultValue={this.props.data.restaurants.length > 0 ? this.props.data.restaurants[0].text : ""}
                        onChange={(e) => this.handleRestaurant(e.target.value)}
                      >
                        <div className='structure__checkbox'>
                          <div className='container__body-radio'>
                            <FormControlLabel
                              value='Yes'
                              control={<Radio />}
                              label={
                                sessionStorage.getItem("companyTypeData") &&
                                JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[0].questionOptions[0].text
                              }
                            />
                          </div>
                          <div className='container__body-radio'>
                            <FormControlLabel
                              value='No'
                              control={<Radio />}
                              label={
                                sessionStorage.getItem("companyTypeData") &&
                                JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[0].questionOptions[1].text
                              }
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      {sessionStorage.getItem("companyTypeData") &&
                        JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[1].questionText}
                      <Tooltip title={<Trans>companyType.tooltip7</Trans>} placement={"right"}>
                        <IconButton aria-label='delete'>{InfoIcon}</IconButton>
                      </Tooltip>
                    </p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        name='pension'
                        defaultValue={this.props.data.pensionSaving.length > 0 ? this.props.data.pensionSaving[0].text : ""}
                        onChange={(e) => this.handlePensionSaving(e.target.value)}
                      >
                        <div className='structure__checkbox'>
                          <div className='container__body-radio'>
                            <FormControlLabel
                              value='Yes'
                              control={<Radio />}
                              label={
                                sessionStorage.getItem("companyTypeData") &&
                                JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[1].questionOptions[0].text
                              }
                            />
                          </div>
                          <div className='container__body-radio'>
                            <FormControlLabel
                              value='No'
                              control={<Radio />}
                              label={
                                sessionStorage.getItem("companyTypeData") &&
                                JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[1].questionOptions[1].text
                              }
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      {sessionStorage.getItem("companyTypeData") &&
                        JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[2].questionText}
                    </p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        name='goods'
                        defaultValue={this.props.data.goodsToSell.length > 0 ? this.props.data.goodsToSell[0].text : ""}
                        onChange={(e) => this.handleGoodsToSell(e.target.value)}
                      >
                        <div className='structure__checkbox'>
                          <div className='container__body-radio'>
                            <FormControlLabel
                              value='Yes'
                              control={<Radio />}
                              label={
                                sessionStorage.getItem("companyTypeData") &&
                                JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[2].questionOptions[0].text
                              }
                            />
                          </div>
                          <div className='container__body-radio'>
                            <FormControlLabel
                              value='No'
                              control={<Radio />}
                              label={
                                sessionStorage.getItem("companyTypeData") &&
                                JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[2].questionOptions[1].text
                              }
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {this.state.goodsToSell === "true" ? (
                  <div className='container__body-box'>
                    <div className='container__body-input text'>
                      <p className='container__body-text'>
                        {sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[2].questions[3].questionText}{" "}
                        *
                      </p>
                      <div className='structure__range'>
                        <Range
                          step={1}
                          min={0}
                          max={100}
                          values={this.state.goodsToSellValues}
                          onChange={(goodsToSellValues) => this.handleGoodsToSellValues(goodsToSellValues)}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "6px",
                                width: "100%",
                                backgroundColor: "#C4D0E5",
                              }}
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "18px",
                                width: "18px",
                                backgroundColor: "#4A5BCF",
                                borderRadius: 18,
                              }}
                              className='structure__range-pointer'
                            >
                              <div className='structure__range-percentage'>
                                <p>{this.state.goodsToSellValues}%</p>
                              </div>
                            </div>
                          )}
                        />
                        <div className='structure__range-legend'>
                          <p>0%</p>
                          <p>25%</p>
                          <p>50%</p>
                          <p>75%</p>
                          <p>100%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {this.state.page === 4 ? (
              <>
                <div className='container__body-header'>
                  <Trans>companyType.costs</Trans>
                </div>
                <p className='container__body-subheader'>
                  <Trans>companyType.costText</Trans>
                </p>
                <div className='container__body-box'>
                  <div className='container__body-input'>
                    <p className='container__body-text'>
                      {sessionStorage.getItem("companyTypeData") &&
                        JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionText}
                    </p>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[0].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(22, e.target.checked, "officeSpace")}
                            checked={this.state.costs[22].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[1].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(23, e.target.checked, "officeSpace")}
                            checked={this.state.costs[23].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[2].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(24, e.target.checked, "officeSpace")}
                            checked={this.state.costs[24].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[3].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(25, e.target.checked, "officeSpace")}
                            checked={this.state.costs[25].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[4].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(26, e.target.checked, "officeSpace")}
                            checked={this.state.costs[26].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[5].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(27, e.target.checked, "officeSpace")}
                            checked={this.state.costs[27].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[6].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(28, e.target.checked, "officeSpace")}
                            checked={this.state.costs[28].status}
                          />
                        }
                      />
                    </div>
                    <div className='structure__checkbox extend'>
                      <FormControlLabel
                        label={
                          sessionStorage.getItem("companyTypeData") &&
                          JSON.parse(sessionStorage.getItem("companyTypeData")).surveyPages[3].steps[3].questions[0].questionOptions[7].text
                        }
                        control={
                          <Checkbox
                            color={"#4A5BCF"}
                            onChange={(e) => this.handleCheckbox(29, e.target.checked, "officeSpace")}
                            checked={this.state.costs[29].status}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <Pagination sum={4} active={this.state.page} handlePage={this.handlePage} />
          </div>
        ) : (
          <div className='container__body checkUp'>
            <div className='container__body-header'>Add your expected costs based on our best practice models</div>
            <p className='container__body-subheader'>Edit individual costs here. Establishment costs are fixed - you can’t edit these.</p>
            <div className='btn btn--primary' onClick={() => this.handleAdd(true)}>
              <Trans>establishmentFlow.addCost</Trans>
            </div>
            {this.state.data.map((data, index) => (
              <div className='container__table editable'>
                <div className='container__table-row'>
                  <div className='box'>
                    <div className='container__table-item bulletList'></div>
                    <div className='container__table-item point'></div>
                    <div className='container__table-item name'></div>
                  </div>
                  <div className='box'>
                    <div className={`container__table-item one right ${data[5] === "0" ? "d-none" : ""}`}>
                      <Trans>companyType.one</Trans>
                    </div>
                    <div className={`container__table-item one right ${data[6] === "0" ? "d-none" : ""}`}>
                      <Trans>companyType.month</Trans>
                    </div>
                    <div className={`container__table-item one right ${data[6] === "0" ? "d-none" : ""}`}>
                      <Trans>companyType.year</Trans>
                    </div>
                  </div>
                </div>
                <div className='container__table-row'>
                  <div className='box'>
                    <div className='container__table-item bulletList' onClick={(e) => this.dropdown(e)}>
                      <div className='open'>{bulletList}</div>
                      <div className='close d-none'>{closeList}</div>
                    </div>
                    <div className='container__table-item point'>
                      <div className={`container__table-circle ${data[1]}`} />
                    </div>
                    <div className='container__table-item name award noWrap'>{data[0]}</div>
                  </div>
                  <div className='box'>
                    <div className={`container__table-item one award right ${data[5] === "0" ? "d-none" : ""}`}>
                      {data[5] !== "0" ? `€${data[5]}` : ""}
                    </div>
                    <div className={`container__table-item one award right ${data[6] === "0" ? "d-none" : ""}`}>
                      {data[6] !== "0" ? `€${data[6]}` : ""}
                    </div>
                    <div className={`container__table-item one award right ${data[7] === "0" ? "d-none" : ""}`}>
                      {data[7] !== "0" ? `€${data[7]}` : ""}
                    </div>
                  </div>
                </div>
                <div className='container__table-extend'>
                  {data[4].map((extendData) => (
                    <div className='container__table-row'>
                      <div className='box'>
                        {extendData[5] === null ? (
                          <div className='remove' onClick={() => this.confirmRemove(extendData[3])}>
                            {trash}
                          </div>
                        ) : (
                          <></>
                        )}
                        {extendData[5] === null ? (
                          <div
                            className='edit'
                            onClick={() =>
                              this.handleAdd(
                                true,
                                extendData[3],
                                extendData[4],
                                extendData[2],
                                extendData[0],
                                extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1]
                              )
                            }
                          >
                            {editIcon}
                          </div>
                        ) : (
                          <div
                            className='edit'
                            onClick={() =>
                              this.handleAdd(
                                true,
                                extendData[3],
                                extendData[4],
                                extendData[2],
                                extendData[0],
                                extendData[2] === "PER_MONTH" ? extendData[6] : extendData[1],
                                true
                              )
                            }
                          >
                            {editIcon}
                          </div>
                        )}
                        <div className='container__table-item bulletList'></div>
                        <div className='container__table-item point'></div>
                        <div className='container__table-item name'>{extendData[0]}</div>
                      </div>
                      <div className='box'>
                        <div className={`container__table-item one right price ${extendData[2] === "PER_MONTH" ? "d-none" : ""}`}>{`${
                          extendData[2] !== "PER_MONTH" ? "€" + extendData[1] : ""
                        }`}</div>
                        <div
                          className={`container__table-item one right price ${
                            extendData[2] !== "PER_MONTH" && this.state.costCategory[index] === false ? "d-none" : ""
                          }`}
                        >{`${extendData[2] === "PER_MONTH" ? "€" + extendData[6] : ""}`}</div>
                        <div
                          className={`container__table-item one right price ${
                            extendData[2] !== "PER_MONTH" && this.state.costCategory[index] === false ? "d-none" : ""
                          }`}
                        >{`${extendData[2] === "PER_MONTH" ? "€" + extendData[1] : ""}`}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='container__body'>
          <div className='container__body-header'>
            <Trans>companyType.breakdown</Trans>
            <Tooltip title={<Trans>companyType.tooltip8</Trans>} placement={"right"}>
              <IconButton aria-label='delete'>{InfoIcon}</IconButton>
            </Tooltip>
            <div className='container__body-progress'>
              <CircularProgressbarWithChildren
                value={this.state.otherSummary}
                styles={buildStyles({
                  pathColor: "#E94F37",
                  trailColor: "#eee",
                  strokeLinecap: "butt",
                })}
              >
                {/* Foreground path */}
                <CircularProgressbarWithChildren
                  value={this.state.generalSummary}
                  styles={buildStyles({
                    trailColor: "transparent",
                    strokeLinecap: "butt",
                    pathColor: "#23E33E",
                  })}
                >
                  <CircularProgressbarWithChildren
                    value={this.state.officeSummary}
                    styles={buildStyles({
                      trailColor: "transparent",
                      strokeLinecap: "butt",
                      pathColor: "#FFBD00",
                    })}
                  >
                    <CircularProgressbarWithChildren
                      value={this.state.transportationSummary}
                      styles={buildStyles({
                        trailColor: "transparent",
                        strokeLinecap: "butt",
                        pathColor: "#F20089",
                      })}
                    >
                      <CircularProgressbarWithChildren
                        value={this.state.professionalSummary}
                        styles={buildStyles({
                          trailColor: "transparent",
                          strokeLinecap: "butt",
                          pathColor: "#9800FF",
                        })}
                      >
                        <CircularProgressbar
                          value={this.state.establishmentSummary}
                          styles={buildStyles({
                            trailColor: "transparent",
                            strokeLinecap: "butt",
                            pathColor: "#0052FF",
                          })}
                        />
                      </CircularProgressbarWithChildren>
                    </CircularProgressbarWithChildren>
                  </CircularProgressbarWithChildren>
                </CircularProgressbarWithChildren>
              </CircularProgressbarWithChildren>
              <svg width='200' height='200' viewBox='0 0 198 198' className='d-none'>
                <circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={this.state.graphSummary && this.state.graphSummary[0] ? this.state.graphSummary[0][0] : "#000000"}
                  strokeWidth={15}
                  strokeDasharray={565}
                />
                <circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={this.state.graphSummary && this.state.graphSummary[1] ? this.state.graphSummary[1][0] : "#000000"}
                  strokeWidth={15}
                  strokeDasharray={
                    this.state.graphSummary && this.state.graphSummary[0] && isNaN(this.state.graphSummary[0][1]) === false
                      ? `${((100 - this.state.graphSummary[0][1]) / 100) * 565}`
                      : 0
                  }
                />
                <circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={this.state.graphSummary && this.state.graphSummary[2] ? this.state.graphSummary[2][0] : "#000000"}
                  strokeWidth={15}
                  strokeDasharray={
                    this.state.graphSummary && this.state.graphSummary[1] && isNaN(this.state.graphSummary[0][1]) === false
                      ? `${((100 - (this.state.graphSummary[1][1] + this.state.graphSummary[0][1])) / 100) * 564}`
                      : 0
                  }
                />
                <circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={this.state.graphSummary && this.state.graphSummary[3] ? this.state.graphSummary[3][0] : "#000000"}
                  strokeWidth={15}
                  strokeDasharray={
                    this.state.graphSummary && this.state.graphSummary[1] && isNaN(this.state.graphSummary[0][1]) === false
                      ? `${((100 - (this.state.graphSummary[2][1] + this.state.graphSummary[1][1] + this.state.graphSummary[0][1])) / 100) * 564}`
                      : 0
                  }
                />
                <circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={this.state.graphSummary && this.state.graphSummary[4] ? this.state.graphSummary[4][0] : "#000000"}
                  strokeWidth={15}
                  strokeDasharray={
                    this.state.graphSummary && this.state.graphSummary[1] && isNaN(this.state.graphSummary[0][1]) === false
                      ? `${
                          ((100 -
                            (this.state.graphSummary[3][1] +
                              this.state.graphSummary[2][1] +
                              this.state.graphSummary[1][1] +
                              this.state.graphSummary[0][1])) /
                            100) *
                          564
                        }`
                      : 0
                  }
                />
                <circle
                  cx='100'
                  cy='100'
                  r='90'
                  fill='none'
                  stroke={this.state.graphSummary && this.state.graphSummary[5] ? this.state.graphSummary[5][0] : "#000000"}
                  strokeWidth={15}
                  strokeDasharray={
                    this.state.graphSummary && this.state.graphSummary[1] && isNaN(this.state.graphSummary[0][1]) === false
                      ? `${
                          ((100 -
                            (this.state.graphSummary[4][1] +
                              this.state.graphSummary[3][1] +
                              this.state.graphSummary[2][1] +
                              this.state.graphSummary[1][1] +
                              this.state.graphSummary[0][1])) /
                            100) *
                            564 <
                          250
                            ? 250
                            : ((100 -
                                (this.state.graphSummary[4][1] +
                                  this.state.graphSummary[3][1] +
                                  this.state.graphSummary[2][1] +
                                  this.state.graphSummary[1][1] +
                                  this.state.graphSummary[0][1])) /
                                100) *
                              564
                        }`
                      : 0
                  }
                />
              </svg>
              <div className='box'>
                <div className='progress__header'>€{this.state.summary}</div>
                <div className='progress__description'>
                  <Trans>companyType.cost</Trans> Y1
                </div>
              </div>
            </div>
            <div className='container__table'>
              <div className='container__table-row'>
                <div className='container__table-item point'></div>
                <div className='container__table-item name'></div>
                <div className='container__table-item one'>
                  <Trans>companyType.one</Trans>
                </div>
                <div className='container__table-item month'>
                  <Trans>companyType.month</Trans>
                </div>
                <div className='container__table-item year'>
                  <Trans>companyType.year</Trans>
                </div>
              </div>
              {this.state.data.map((data, i) => (
                <div className={`container__table-row ${data[3] === 0 ? "d-none" : ""}`}>
                  <div className='container__table-item point'>
                    <div className={`container__table-circle ${data[1]}`} />
                  </div>
                  <div className='container__table-item name'>{data[0]}</div>
                  <div className='container__table-item one award'>{data[5] !== "0" ? `€${data[5]}` : ""}</div>
                  <div className='container__table-item month'>{data[6] !== "0" ? `€${data[6]}` : ""}</div>
                  <div className='container__table-item year'>{data[7] !== "0" ? `€${data[7]}` : ""}</div>
                </div>
              ))}
            </div>
          </div>
          <div className='container__body-footer'>
            <button className='btn overview btn--primary' onClick={(e) => this.handleView(e, "overview")}>
              <Trans>companyType.overview</Trans>
            </button>
            <button className='btn detail btn--primary btn--secondary' onClick={(e) => this.handleView(e, "detail")}>
              <Trans>companyType.detail</Trans>
            </button>
          </div>
        </div>
        <Add
          active={this.state.add}
          pid={this.state.pid}
          editCategory={this.state.editCategory}
          editType={this.state.editType}
          editDescription={this.state.editDescription}
          editAmount={this.state.editAmount}
          defaultSelectText={this.state.defaultSelectText}
          defaultSelectValue={this.state.defaultSelectValue}
          question={this.state.question}
          handleActive={this.handleAdd}
          handleSave={this.save}
        />
        <Confirm
          active={this.state.confirm}
          handleConfirm={this.handleConfirm}
          handleRemove={this.removeCost}
          message={<Trans>establishmentFlow.delete</Trans>}
          only={false}
        />
      </div>
    );
  }
}

export default Step4;
