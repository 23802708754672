import React, { useState, useEffect, useRef } from 'react';
import Step1 from "../companyType/step1";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import Step2 from "../companyType/step2";
import Step3 from "../companyType/step3";
import Step4 from "../companyType/step4";
import Step5 from "../companyType/step5";

function AdminCompanyTypeSurvey(props) {
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(0);
    const [quickstart, setQuickstart] = useState(false);
    const [validateForm, setValidateForm] = useState([]);

    useEffect(() => {
        return () => {
            // sessionStorage.removeItem('companyType');
            // sessionStorage.removeItem('companyDetails');
            // sessionStorage.removeItem('companyTypeData');
            // sessionStorage.removeItem('financialCalendar');
        }
    }, []);

    const updateStep = (value) => {
        let progress = 0;
        if (value > 0 && value < 6) {
            if (value === 2) progress = 25;
            if (value === 3) progress = 50;
            if (value === 4) progress = 75;
            if (value === 5) progress = 100;
            setStep(value);
            setProgress(progress);
        }
    }

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const handleValidate = (value) => {
        setValidateForm(value);
    }

    const save = (values, pid, required = 0, page = 0) => {
        Connect(`${Endpoint.postAdminAnswer}/${sessionStorage.getItem('userID')}/${pid}`, 'POST', values).then(response => {
        });
    }

    return (
        <div className="admin__content survey">
            <div className="box">
                <Navbar currentStep={step} progress={progress} handleQuickstart={handleQuickstart} survey={true} name={'Admin company type'} desc={'Fill in the survey to generate a personalized simulation that helps you decide the perfect company type for you.'} progressName={'Company type survey'} />
                {step === 1 ?
                    <Step1 data={props.form} handleData={props.handleForm} validateForm={validateForm} />
                :
                    <></>
                }
                {step === 2 ?
                    <Step2 data={props.form} handleData={props.handleForm} validateForm={validateForm}/>
                :
                    <></>
                }
                {step === 3 ?
                    <Step3 data={props.form} handleData={props.handleForm} validateForm={validateForm}/>
                :
                    <></>
                }
                {step === 4 ?
                    <Step4 data={props.form} handleData={props.handleForm} validateForm={validateForm}
                           companyTypeData={props.companyTypeData} type={'admin'} />
                :
                    <></>
                }
                {step === 5 ?
                    <Step5 data={props.outputData} handleData={props.handleData} handleCompanyData={props.handleCompanyData} handleSurvey={props.handleSurvey} handleLoading={props.handleLoading} />
                    :
                    <></>
                }
            </div>
            <Footer handleUpdateStep={updateStep} currentStep={step} data={props.form} validateForm={validateForm} handleValidate={handleValidate} type={'companyAdmin'} admin={true} save={save} outputData={props.outputData} graphSummary={props.graphSummary} costData={props.data} summary={props.summary} loading={props.loading} />
        </div>
    );
}

export default AdminCompanyTypeSurvey;
